import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Swal from 'sweetalert2';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiroforBanco, formataDinheiro } from '../../../vendor/formatar';

import ModalNovaPessoa from '../../Cadastros/Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../../Cadastros/Pessoas/modals/modalDetalhesPessoa';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

import ModalNovoPagamentoContaAreceber from './modalNovoPagamentoContaAreceber';
import ButtonSecondaryOnlyIcon from '../../../componentes/ButtonSecondaryOnlyIcon';

export default function ModalEditContaAreceber({ id, visible, change, refreshcontas}) {

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
      <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  );

  let IconCancel = props =>(
    <svg id="close" xmlns="http://www.w3.org/2000/svg" width="17.001" height="17" viewBox="0 0 17.001 17">
        <path id="Caminho_94" data-name="Caminho 94" d="M16.565,14.12,3.242.8a1.487,1.487,0,0,0-2.1,0l-.7.7a1.487,1.487,0,0,0,0,2.1L13.76,16.924a1.487,1.487,0,0,0,2.1,0l.7-.7a1.486,1.486,0,0,0,0-2.1Zm0,0" transform="translate(0 -0.36)" fill="#f44336"/>
        <path id="Caminho_95" data-name="Caminho 95" d="M13.76.8.436,14.121a1.487,1.487,0,0,0,0,2.1l.7.7a1.487,1.487,0,0,0,2.1,0L16.565,3.6a1.486,1.486,0,0,0,0-2.1l-.7-.7a1.487,1.487,0,0,0-2.1,0Zm0,0" transform="translate(0 -0.362)" fill="#f44336"/>
    </svg>
  );

  let IconPagament = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 91.356 91.353">
        <g id="money-flow_1_" data-name="money-flow (1)" transform="translate(-1.997 -1.998)">
            <path id="Caminho_234" data-name="Caminho 234" d="M22.786,19.786a3.262,3.262,0,0,1,3.262,3.262h6.524a9.786,9.786,0,0,0-6.524-9.186V10H19.524v3.862a9.763,9.763,0,0,0,3.262,18.972A3.262,3.262,0,1,1,19.524,36.1H13a9.786,9.786,0,0,0,6.524,9.186v3.862h6.524V45.281A9.763,9.763,0,0,0,22.786,26.31a3.262,3.262,0,0,1,0-6.524Z" transform="translate(24.889 18.1)" fill="#4a4a4a"/>
            <path id="Caminho_235" data-name="Caminho 235" d="M79.423,55.038A32.515,32.515,0,0,0,40.309,15.923,19.572,19.572,0,1,0,15.922,40.31,32.515,32.515,0,0,0,55.04,79.421,19.572,19.572,0,1,0,79.423,55.038ZM8.531,21.576a13.015,13.015,0,0,1,25.551-3.52A32.773,32.773,0,0,0,18.059,34.08a13.028,13.028,0,0,1-9.528-12.5Zm13.048,26.1a26.1,26.1,0,1,1,26.1,26.1,26.1,26.1,0,0,1-26.1-26.1ZM73.77,86.816a13.028,13.028,0,0,1-12.5-9.528A32.773,32.773,0,0,0,77.29,61.265a13.015,13.015,0,0,1-3.52,25.551Z" transform="translate(0 0)" fill="#4a4a4a"/>
            <path id="Caminho_236" data-name="Caminho 236" d="M32.742,21.865l4.612,4.612,9.786-9.786a3.262,3.262,0,0,0,0-4.612L37.354,2.293,32.742,6.905l4.218,4.218H22v6.524H36.959Z" transform="translate(45.246 0.667)" fill="#4a4a4a"/>
            <path id="Caminho_237" data-name="Caminho 237" d="M17.354,26.905l-4.612-4.612L2.955,32.079a3.262,3.262,0,0,0,0,4.612l9.786,9.786,4.612-4.612-4.218-4.218H28.1V31.123H13.136Z" transform="translate(0.007 45.907)" fill="#4a4a4a"/>
        </g>
    </svg>
  );

  let IconMoneyPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="38.41" height="38.412" viewBox="0 0 38.41 38.412">
      <g id="dinheiro" transform="translate(-4.795 -4.793)">
        <g id="Grupo_413" data-name="Grupo 413">
          <path id="Caminho_438" data-name="Caminho 438" d="M24.405,38.652A15.992,15.992,0,0,1,6.813,22a15.971,15.971,0,0,1,31.923.764,15.927,15.927,0,0,1-.083,1.639.988.988,0,0,0,.826,1.065,1.007,1.007,0,0,0,1.167-.9,17.953,17.953,0,1,0-16.079,16.08,1.007,1.007,0,0,0,.9-1.168A.988.988,0,0,0,24.405,38.652Z" fill="#fff"/>
          <path id="Caminho_439" data-name="Caminho 439" d="M34.765,26.325a8.44,8.44,0,1,0,8.44,8.44A8.454,8.454,0,0,0,34.765,26.325Zm5.01,8.57a1,1,0,0,1-1,1h-2.89v2.88a1,1,0,0,1-1,1h-.24a1,1,0,0,1-1-1V35.9h-2.89a1,1,0,0,1-1-1v-.25a1,1,0,0,1,1-1h2.89v-2.89a1,1,0,0,1,1-1h.24a1,1,0,0,1,1,1v2.89h2.89a1,1,0,0,1,1,1Z" fill="#fff"/>
          <path id="Caminho_440" data-name="Caminho 440" d="M22.765,21.764a2.405,2.405,0,1,1,2.405-2.405,1,1,0,0,0,2,0,4.4,4.4,0,0,0-3.176-4.209V13.168a1,1,0,0,0-2,0v1.864a4.4,4.4,0,0,0,.77,8.732,2.406,2.406,0,1,1-2.405,2.406,1,1,0,0,0-2,0A4.4,4.4,0,0,0,21.994,30.5v1.866a1,1,0,0,0,2,0V30.379a4.394,4.394,0,0,0-1.229-8.615Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  );

  const [loaderPage, setloaderPage] = useState(false);
  const [caixas, setcaixas] = useState([]);

  const [contasOrcamentarias, setcontasOrcamentarias] = useState([]);
  const [dadosConta, setdadosConta] = useState([]);

  const [pessoas, setpessoas] = useState([]);
  const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
  const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();

  const [totalpagamentos, settotalpagamentos] = useState(0);
  const [pagamentos, setpagamentos] = useState([]);
  const [pagamentosSelecionados, setpagamentosSelecionados] = useState([]);
  const [pageSizeDataGridPagamentos, setpageSizeDataGridPagamentos] = useState(10);
  const [loadingDataGridPagamentos, setloadingDataGridPagamentos] = useState(false);

  const [showModalNovoPagamentoConta, setshowModalNovoPagamentoConta] = useState(false);

  const getContasOrcamentarias = async(operacao)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemContasOrcamentaria?operacao=${operacao}`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setcontasOrcamentarias(response.data);
  }

  const getPessoas = async(devedorId=null, codigoDevedor=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    setpessoas(arrayPessoas);

    if(codigoDevedor){
      //populando devedor no select
      let credorcode = arrayPessoas.find(x => x.codigo === codigoDevedor);
      credorcode && formik.setFieldValue('credor', JSON.stringify(credorcode));
      setcodigoDetalhesPessoa(codigoDevedor);
      // setshowModalDetalhesPessoa(true);

    }else if(devedorId){
      //populando devedor no select
      let devedor = arrayPessoas.find(x => x.id === devedorId);
      devedor && formik.setFieldValue('devedor', JSON.stringify(devedor));
      setcodigoDetalhesPessoa(devedor.codigo);
    }

  }


  const detalhesConta = async()=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    id && api.post('detalhesContaAreceber', {
        "id": id
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    }).then( async response => {
        
      let data = response.data;
      data.data && formik.setFieldValue('data', data.data);
      data.vencimento && formik.setFieldValue('vencimento', data.vencimento);
      formik.setFieldValue('prorrogacao', (data.proximoVencimento === null ? null : moment(data.proximoVencimento).format('YYYY-MM-DD HH:mm:ss')) );
      data.valor && formik.setFieldValue('valor', formataDinheiro(data.valor));
      data.planoContaOrcamentaria_id && formik.setFieldValue('contaorcamentaria', data.planoContaOrcamentaria_id);
      data.descricao && formik.setFieldValue('descricao', data.descricao);
      
      getPessoas(data.pessoa_id, null);
      setloaderPage(false);
      getPagamentos(data.id);
      setdadosConta(data);

    }).catch((error) => {
        
    });

  }

  useEffect(()=>{
    if(visible){
      detalhesConta();
      getContasOrcamentarias(1); //1 = crédito 2 = Débito
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);

  
  const validationSchema = yup.object({
    data: yup
      .date('').typeError("Insira a data do lançamento")
      .required('Data de lançamento Obrigatória'),
    vencimento: yup
      .date('').typeError("Insira a data do Vencimento")
      .required('Data de Vencimento Obrigatória'),
    devedor: yup
      .string('Selecione o Devedor')
      .required('Devedor Obrigatório')
      .test({
        message: () => "Selecione o Devedor",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    valor: yup
      .string('Informe o valor')
      .required('Valor Obrigatório'),
    contaorcamentaria: yup
      .number('Selecione uma conta orçamentária')
      .required('Conta orçamentária Obrigatória'),
    descricao: yup
      .string('Informe a descrição da conta')
      .required('Descrição Obrigatória'),
    
  });

  const formik = useFormik({
    initialValues: {
      data: new Date(),
      vencimento: null,
      prorrogacao: null,
      devedor: 'null',
      valor: '',
      contaorcamentaria: '',
      descricao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let devedor = JSON.parse(values.devedor);

      await api.put('updateContaAreceber', {
        "id": id,
        "data": values.data,
        "planoContaOrcamentaria_id": values.contaorcamentaria,
        "vencimento": values.vencimento,
        "prorrogacao": values.prorrogacao,
        "pessoa_id": devedor.id, //cliente/fornecedor/credor pessoa id
        "descricao": values.descricao,
        "valor": formataDinheiroforBanco(values.valor),
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      refreshcontas();
      change(false);

    }
  });

  const handleChangeDevedor = async(event, value, reason, details)=>{
    formik.setFieldValue('devedor', JSON.stringify(value));
    setcodigoDetalhesPessoa(value?.codigo);
  }

  const getPagamentos= async(contaId)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPagamentosContaAreceber?contaId=${contaId}`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let total = 0;
    
    for await (let pag of response.data) {
      total += (pag.valor);
    }

    settotalpagamentos(total);
    setpagamentos(response.data);
    refreshcontas();

  }

  const columnsDataGridPagamentos = [
    // { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'data',
      headerName: 'Data',
      flex: 0,
      minWidth: 140,
      editable: false,
      valueFormatter: (params) => {
          return moment(params.value).format('DD/MM/YYYY');
      },
    },
    {
        field: 'formaPagamento',
        headerName: 'Forma de recebimento',
        flex: 0,
        minWidth: 180,
        editable: false,
        renderCell: (params) => ( //renderiza componente
            <p title={params.value}>{params.value}</p>
        ),
    },
    {
      field: 'valor',
      headerName: 'Valor',
      type: 'string',
      flex: 0,
      minWidth: 130,
      editable: false,
      renderCell: (params) => ( //renderiza componente
      <p style={params.value < 0 ? {color: 'red'} : params.value > 0 ? {color: '#0554C2'} : {color: '#000000'}}>{formataDinheiro(params.value)}</p>
      ),        
    },
    {
        field: 'id',
        headerName: 'Ação',
        type: 'string',
        flex: 0,
        minWidth: 30,
        editable: false,
        renderCell: (params) => { //renderiza componente
          return <div><IconCancel /></div>
        },
    },
  ];

  function nenhumaPagamentoEncontrado(){
    return(
        <GridOverlay>
        <div className="container-no-data-grid" style={{ marginBottom: '50px' }}>
            <IconPagament/>
            <p style={{ fontSize:'14px'}}>Nenhum recebimento</p>
        </div>
        </GridOverlay>
    )
  }

  const handleDeletePagamento = async(props)=>{
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar o Recebimento selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#02A680',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        await api.delete('deleteContaRecebida', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: { 
            "contarecebidaId": props.id
          }
        });

        await getPagamentos(dadosConta.id);
        refreshcontas();

        // Swal.fire(
        //   'Deletado!',
        //   'Pagamento(s) deletado(s) com sucesso!',
        //   'success'
        // )

      }
    })
  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
    <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

    <ModalNovoPagamentoContaAreceber dadosConta={dadosConta} totalpagamentos={totalpagamentos} visible={showModalNovoPagamentoConta} change={setshowModalNovoPagamentoConta} refreshPagamentos={getPagamentos} />    

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Conta a Receber
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={4} >
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <DesktopDatePicker
                      disabled
                      label="Data"
                      inputFormat="dd/MM/yyyy"
                      name="data"
                      value={formik.values.data}
                      onChange={(data)=>{ formik.setFieldValue('data', data) }}
                      renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.data && Boolean(formik.errors.data)}/>}
                    />
                    <FormHelperText error>{formik.touched.data && formik.errors.data}</FormHelperText>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={4} >
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <DesktopDatePicker
                      disabled
                      label="Vencimento"
                      inputFormat="dd/MM/yyyy"
                      name="vencimento"
                      value={formik.values.vencimento}
                      onChange={(vencimento)=>{ formik.setFieldValue('vencimento', vencimento) }}
                      renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.vencimento && Boolean(formik.errors.vencimento)}/>}
                    />
                    <FormHelperText error>{formik.touched.vencimento && formik.errors.vencimento}</FormHelperText>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={4} >
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <DesktopDatePicker
                      label="Prorrogação"
                      inputFormat="dd/MM/yyyy"
                      name="prorrogacao"
                      value={formik.values.prorrogacao}
                      onChange={(prorrogacao)=>{ formik.setFieldValue('prorrogacao', prorrogacao) }}
                      renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.prorrogacao && Boolean(formik.errors.prorrogacao)}/>}
                    />
                    <FormHelperText error>{formik.touched.prorrogacao && formik.errors.prorrogacao}</FormHelperText>
                  </LocalizationProvider>
                </Grid>

                <Grid container item xs={12} md={12}>

                  <Grid item xs={formik.values.devedor === 'null' ? 10.1 : 8.25} md={formik.values.devedor === 'null' ? 10.66 : 9.3} >
                      <FormControl sx={{ m: 0, width: '100%' }} >
                        <Autocomplete
                            className='input'
                            // disablePortal
                            id="combo-box-devedor"
                            options={pessoas}
                            value={JSON.parse(formik.values.devedor)}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>{option.nome}</li>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={handleChangeDevedor}
                            noOptionsText="Nenhum devedor encontrado."
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="Devedor"  error={formik.touched.devedor && Boolean(formik.errors.devedor)}/>}
                        />
                        <FormHelperText error>{formik.touched.devedor && formik.errors.devedor}</FormHelperText>
                      </FormControl>
                  </Grid>

                  <Grid>
                    <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                  </Grid>

                  {
                    formik.values.devedor !== 'null' &&

                    <Grid>
                      <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                    </Grid>
                  }

                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    fullWidth
                    id="valor"
                    name="valor"
                    label="Valor"
                    margin="none"
                    type="text"
                    value={formik.values.valor}
                    onChange={formik.handleChange}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    onKeyUp={()=>{ formik.setFieldValue('valor', formik.values.valor && mvalor(formik.values.valor)) }}
                    inputProps={{
                        maxLength: 13,
                    }}
                  />
                </Grid>
                
                <Grid item xs={12} md={6} >
                  <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-contaorcamentaria">Conta Orçamentária</InputLabel>
                    <Select
                      labelId="label-contaorcamentaria"
                      id="contaorcamentaria-simple-select"
                      name="contaorcamentaria"
                      label="Conta Orçamentária"
                      placeholder='Conta Orçamentária'
                      value={formik.values.contaorcamentaria}
                      onChange={formik.handleChange}
                      error={formik.touched.contaorcamentaria && Boolean(formik.errors.contaorcamentaria)}
                    >
                      {contasOrcamentarias.map((v, k) =>{
                          return (
                            <MenuItem key={k} value={v.id}>{v.descricao}</MenuItem>
                          )
                      })}
                    </Select>
                    <FormHelperText error>{formik.touched.contaorcamentaria && formik.errors.contaorcamentaria}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="descricao"
                        name="descricao"
                        label="Descrição"
                        margin="none"
                        type="text"
                        multiline
                        rows={2}
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                        helperText={formik.touched.descricao && formik.errors.descricao}
                        inputProps={{
                            maxLength: 200,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                  <DataGrid
                    className="datatable-green-defalt"
                    sx={{ minHeight: '100%' }}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    autoHeight
                    rows={pagamentos}
                    columns={columnsDataGridPagamentos}
                    pageSize={pageSizeDataGridPagamentos}
                    onPageSizeChange={(newPageSize) => setpageSizeDataGridPagamentos(newPageSize)}
                    rowsPerPageOptions={[10, 50, 100]} 
                    // checkboxSelection={permissoesdoUsuario[1]?.delete}
                    disableSelectionOnClick
                    editMode='row'
                    loading={loadingDataGridPagamentos}
                    hideFooter
                    disableColumnMenu={true}
                    onSelectionModelChange={(props)=>{
                        setpagamentosSelecionados(props)
                    }}
                    // onRowClick
                    onCellClick={(props)=>{
                        if(props.field !== "__check__" && props.field !== "id"){
                            // detalhesPagamento(props.id);
                        }
                        if(props.field === "id"){
                            handleDeletePagamento(props)
                        }
                        
                    }}
                    components={{
                        NoRowsOverlay: nenhumaPagamentoEncontrado,
                    }}
                  />
                </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container direction="row" justifyContent="space-between" spacing={2} style={{marginRight: '15px'}}>

            <Grid container item md={5}>  
              <Grid item style={{ marginLeft: '15px' }}>
                  <div style={{ lineHeight: '1px'}}>
                    <p>Total pago</p>
                    <p style={{fontWeight: 'bold', fontSize: '20px'}}>{formataDinheiro(totalpagamentos)}</p>
                  </div>
              </Grid>
            </Grid>

            <Grid container item md={6} spacing={2}> 
              <Grid item xs={3} md={3}>
                <ButtonSecondaryOnlyIcon type="button" icon={<IconMoneyPlus/>} onClick={()=>{ setshowModalNovoPagamentoConta(true) }} />
              </Grid>
              
              <Grid item xs={9} md={9}>
                <ButtonDefault type="submit" icon=""  txt="Salvar" />
              </Grid>
            </Grid>   

          </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}