import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";

import moment from 'moment';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import ButtonCirclePrimary from '../../../componentes/ButtonCirclePrimary';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import api from '../../../services/api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import { formataDinheiroforBanco, formataDinheiro } from '../../../vendor/formatar';

import Swal from 'sweetalert2';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ModalVendasIncompletas({ visible, change, getdetalhesVenda, setitens, codigoVenda }) {
    
    let IconEntrada = props=>(
    <svg id="vendas" xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 53.91 53.91">
        <path id="Caminho_375" data-name="Caminho 375" d="M53.91,43.381V26.534A2.106,2.106,0,0,0,51.8,24.428H45.487V9.687a2.106,2.106,0,0,0-2.106-2.106H26.534a2.106,2.106,0,0,0-2.106,2.106V24.428H18.11A2.106,2.106,0,0,0,16,26.534V41.275H13.9a2.108,2.108,0,0,1-2.106-2.106V6.318A6.325,6.325,0,0,0,5.475,0H2.106a2.106,2.106,0,0,0,0,4.212H5.475A2.108,2.108,0,0,1,7.581,6.318V39.169A6.325,6.325,0,0,0,13.9,45.487h2.468a6.318,6.318,0,1,0,11.911,0h13.36a6.317,6.317,0,1,0,11.656-.616A2.1,2.1,0,0,0,53.91,43.381ZM49.7,41.275H37.063V28.64h4.212v2.106a2.106,2.106,0,0,0,4.212,0V28.64H49.7ZM32.851,11.793V13.9a2.106,2.106,0,0,0,4.212,0V11.793h4.212V24.428H28.64V11.793Zm-8.423,35.8a2.106,2.106,0,1,1-2.106-2.106A2.108,2.108,0,0,1,24.428,47.592Zm-4.212-6.318V28.64h4.212v2.106a2.106,2.106,0,0,0,4.212,0V28.64h4.212V41.275ZM47.592,49.7A2.106,2.106,0,1,1,49.7,47.592,2.108,2.108,0,0,1,47.592,49.7Z" fill="#4a4a4a"/>
    </svg>
    );

    let IconCancel = props =>(
        <svg id="close" xmlns="http://www.w3.org/2000/svg" width="17.001" height="17" viewBox="0 0 17.001 17">
            <path id="Caminho_94" data-name="Caminho 94" d="M16.565,14.12,3.242.8a1.487,1.487,0,0,0-2.1,0l-.7.7a1.487,1.487,0,0,0,0,2.1L13.76,16.924a1.487,1.487,0,0,0,2.1,0l.7-.7a1.486,1.486,0,0,0,0-2.1Zm0,0" transform="translate(0 -0.36)" fill="#f44336"/>
            <path id="Caminho_95" data-name="Caminho 95" d="M13.76.8.436,14.121a1.487,1.487,0,0,0,0,2.1l.7.7a1.487,1.487,0,0,0,2.1,0L16.565,3.6a1.486,1.486,0,0,0,0-2.1l-.7-.7a1.487,1.487,0,0,0-2.1,0Zm0,0" transform="translate(0 -0.362)" fill="#f44336"/>
        </svg>
    );

    let history = useHistory();

    const [loaderPage, setloaderPage] = useState(false);

    const [alertError, setalertError] = useState(false);
    const [alertErrorMsg, setalertErrorMsg] = useState('');

    const [alertSucess, setalertSucess] = useState(false);
    const [alertSucessMsg, setalertSucessMsg] = useState('');

    const [listagemVendasIncompletas, setlistagemVendasIncompletas] = useState([]);
    const [pageSizeDataGridComprasIncompletas, setpageSizeDataGridComprasIncompletas] = useState(10);
    const [loadingDataGridComprasIncompletas, setloadingDataGridComprasIncompletas] = useState(true);
  
    const [dadosVendaIncompleta, setdadosVendaIncompleta] = useState([]);
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setalertError(false);
    };

    const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setalertSucess(false);
    };


    const getVendasIncompletas = async()=>{
    
        setloadingDataGridComprasIncompletas(true);
    
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let response = await api.get(`listagemVendasIncompletas`,{
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        })
    
        setlistagemVendasIncompletas(response.data);    
        setloadingDataGridComprasIncompletas(false);
    
    }

    
    useEffect(async ()=>{ 
        if(visible){
            getVendasIncompletas();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[visible]);


    
    const columnsDataGridComprasIncompletas = [
        // { field: 'id', hide: true, headerName: 'ID', width: 90  },
        {   
            field: 'data',
            headerName: 'Data',
            flex: 0,
            minWidth: 150,
            editable: false,
            valueFormatter: (params) => {
                return moment(params.value).format('DD/MM/YYYY'); 
            },
        },
        {   
            field: 'cliente',
            headerName: 'Cliente',
            flex: 1.5,
            editable: false,
            valueFormatter: (params) => {
            return params.value; 
            },
        },
        {
            field: 'total',
            headerName: 'Valor',
            flex: 1,
            editable: false,
            valueFormatter: (params) => {
                return formataDinheiro(params.value); 
            },
        },
        {
          field: 'id',
          headerName: 'Ação',
          flex: 0,
          minWidth: 50,
          editable: false,
          renderCell: (params) => { //renderiza componente
            return <div><IconCancel /></div>
          },
        },
      
    ];

    function nenhumaVendaEncontrada(){
        return(
            <GridOverlay>
            <div className="container-no-data-grid">
                <IconEntrada/>
                <p>Nenhuma venda encontrada</p>
            </div>
            </GridOverlay>
        )
    }

    const handleDeleteVenda = async(vendaId)=>{
        Swal.fire({
          title: 'Tem certeza?',
          text: "Deseja deletar a compra?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0554C2',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim deletar',
          cancelButtonText: 'Cancelar',
        }).then(async(result) => {
          if (result.isConfirmed) {
            
            setloaderPage(true);

            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);
            
            await api.delete('deleteVenda', {
              headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
              },
              data: {  "vendasIds": [{ id: vendaId }] }
            });
            
            codigoVenda && await getdetalhesVenda(codigoVenda);
            codigoVenda && setitens([]);
            await getVendasIncompletas();
            setloaderPage(false);
    
            Swal.fire(
              'Deletado!',
              'Venda deletada com sucesso!',
              'success'
            )
    
          }
        })
    }

  return(
    <>

    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
            {alertSucessMsg}
        </Alert>
    </Snackbar>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
            {alertErrorMsg}
        </Alert>
    </Snackbar>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            { listagemVendasIncompletas.length === 1 ? `Existe 1 venda incompleta` : `Existe ${listagemVendasIncompletas.length} vendas incompletas.`}
            <p style={{fontSize: '12px', lineHeight: '0', color: '#999'}}>Clique na venda para continuá-la</p>
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>

          <DataGrid
            sx={{ minHeight: '350px' }}
            className="datatable-green-defalt"
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            // hideFooter={true}
            rows={listagemVendasIncompletas}
            columns={columnsDataGridComprasIncompletas}
            pageSize={pageSizeDataGridComprasIncompletas}
            onPageSizeChange={(newPageSize) => setpageSizeDataGridComprasIncompletas(newPageSize)}
            rowsPerPageOptions={[10, 50, 100]} 
            checkboxSelection={false}
            disableSelectionOnClick
            editMode='row'
            loading={loadingDataGridComprasIncompletas}
            // disableColumnMenu={true}
            onSelectionModelChange={(props)=>{
                // setcomprasSelecionadas(props);
            }}
            // onRowClick
            onCellClick={(props)=>{
              if(props.field === "id"){
                handleDeleteVenda(props.value)
              }
              
              if(props.field !== "__check__" && props.field !== "id"){
                history.push(`/vendas/ponto-de-venda/${props.row.codigo}`);
                change(false)
              }
            }}
            components={{
                NoRowsOverlay: nenhumaVendaEncontrada,
            }}
            // headerHeight={0}
            />

        </DialogContent>
    </Dialog>
    </>
  );

}