import React, {useState, useRef } from 'react';
import './style.css';

import api from '../../../../services/api';
import axios from 'axios';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { CEPMask, mnumero } from '../../../../vendor/mascaras';

export default function ModalNovoEndereco({ pessoaid, visible, change, refreshEnderecos }) {

  const inputNumero = useRef(null);
  const [loaderPage, setloaderPage] = useState(false);
  
  const validationSchema = yup.object({
    cep: yup
      .string('Insira o CEP')
      .required('CEP Obrigatório'),
    rua: yup
      .string('Insira o nome da rua')
      .required('Rua Obrigatória'),
    numero: yup
      .string('Insira o número')
      .required('Número Obrigatório'),
    bairro: yup
      .string('Insira o Bairro')
      .required('Bairro Obrigatório'),
    cidade: yup
      .string('Insira a Cidade')
      .required('Cidade Obrigatória'),
    estado: yup
      .string('Insira o estado')
      .required('Estado Obrigatório'),
    complemento: yup
      .string('Insira o complemento'),
    referencia: yup
      .string('Insira a referência'),
  });

  const formik = useFormik({
    initialValues: {
      cep: '',
      rua: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      complemento: '',
      referencia: '',
      codmunicipio: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.post('addEnderecoPesssoa', {
        "pessoaId": pessoaid,
        "cep": values.cep,
        "rua": values.rua,
        "numero": values.numero,
        "bairro": values.bairro,
        "cidade": values.cidade,
        "estado": values.estado,
        "complemento": values.complemento ? values.complemento : null,
        "referencia": values.referencia ? values.referencia : null,
        "codmunicipio": values.codmunicipio ? values.codmunicipio : null,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      refreshEnderecos(pessoaid);
      change(false);
      
    }
  });

  const handleBuscaCep = async(props)=>{
    formik.handleChange(props);
    
    let cep = props.target.value;
    
    if(cep.length >= 9){

      setloaderPage(true);

      let response = await axios({
        method: 'get',
        url: `https://viacep.com.br/ws/${cep}/json/`,
        headers: { 
          "Content-type": "application/json"
        }
      });

      let data = response.data;
        
      if(data.cep){
        formik.setFieldValue('cep', data.cep);
        formik.setFieldValue('rua', data.logradouro);
        formik.setFieldValue('bairro', data.bairro);
        formik.setFieldValue('cidade', data.localidade);
        formik.setFieldValue('estado', data.uf);
        formik.setFieldValue('codmunicipio', data.ibge);
        
        inputNumero.current.focus();
      }

      setloaderPage(false);
    }

  }


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='md' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo endereço
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} >

          <Grid item xs={12} md={2.5} >
            <TextField
              fullWidth
              id="cep"
              name="cep"
              label="CEP"
              margin="normal"
              type="text"
              value={formik.values.cep}
              onChange={handleBuscaCep}
              error={formik.touched.cep && Boolean(formik.errors.cep)}
              helperText={formik.touched.cep && formik.errors.cep}
              InputProps={{
                inputComponent: CEPMask,
              }}
            />
          </Grid>

          <Grid item xs={12} md={7.3} >
            <TextField
              fullWidth
              id="rua"
              name="rua"
              label="Rua"
              margin="normal"
              type="text"
              value={formik.values.rua}
              onChange={formik.handleChange}
              error={formik.touched.rua && Boolean(formik.errors.rua)}
              helperText={formik.touched.rua && formik.errors.rua}
            />
          </Grid>

          <Grid item xs={12} md={2.2} >
            <TextField
              inputRef={inputNumero}
              fullWidth
              id="numero"
              name="numero"
              label="Número"
              margin="normal"
              type="text"
              value={formik.values.numero}
              onChange={formik.handleChange}
              onKeyUp={()=>{ formik.setFieldValue('numero', formik.values.numero && mnumero(formik.values.numero)) }}
              error={formik.touched.numero && Boolean(formik.errors.numero)}
              helperText={formik.touched.numero && formik.errors.numero}
              inputProps={{
                maxLength: 6,
              }}
            />
          </Grid>

          <Grid item xs={12} md={5} >
            <TextField
              fullWidth
              id="bairro"
              name="bairro"
              label="Bairro"
              margin="normal"
              type="text"
              value={formik.values.bairro}
              onChange={formik.handleChange}
              error={formik.touched.bairro && Boolean(formik.errors.bairro)}
              helperText={formik.touched.bairro && formik.errors.bairro}
            />
          </Grid>

          <Grid item xs={12} md={5} >
            <TextField
              fullWidth
              id="cidade"
              name="cidade"
              label="Cidade"
              margin="normal"
              type="text"
              value={formik.values.cidade}
              onChange={formik.handleChange}
              error={formik.touched.cidade && Boolean(formik.errors.cidade)}
              helperText={formik.touched.cidade && formik.errors.cidade}
            />
          </Grid>

          <Grid item xs={12} md={2} >
            <TextField
              fullWidth
              id="estado"
              name="estado"
              label="UF"
              margin="normal"
              type="text"
              value={formik.values.estado}
              onChange={formik.handleChange}
              error={formik.touched.estado && Boolean(formik.errors.estado)}
              helperText={formik.touched.estado && formik.errors.estado}
            />
          </Grid>

          <Grid item xs={12} md={4} >
            <TextField
              fullWidth
              id="complemento"
              name="complemento"
              label="Complemento"
              margin="normal"
              type="text"
              value={formik.values.complemento}
              onChange={formik.handleChange}
              error={formik.touched.complemento && Boolean(formik.errors.complemento)}
              helperText={formik.touched.complemento && formik.errors.complemento}
            />
          </Grid>

          <Grid item xs={12} md={5} >
            <TextField
              fullWidth
              id="referencia"
              name="referencia"
              label="Referência"
              margin="normal"
              type="text"
              value={formik.values.referencia}
              onChange={formik.handleChange}
              error={formik.touched.referencia && Boolean(formik.errors.referencia)}
              helperText={formik.touched.referencia && formik.errors.referencia}
            />
          </Grid>

          <Grid item xs={12} md={3} >
            <TextField
              fullWidth
              id="codmunicipio"
              name="codmunicipio"
              label="Código Município"
              margin="normal"
              type="text"
              value={formik.values.codmunicipio}
              onChange={formik.handleChange}
              error={formik.touched.codmunicipio && Boolean(formik.errors.codmunicipio)}
              helperText={formik.touched.codmunicipio && formik.errors.codmunicipio}
            />
          </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}