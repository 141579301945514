import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useHistory } from "react-router-dom";
import './style.css';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Swal from 'sweetalert2';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import Grid from '@mui/material/Grid';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';
import SubMenuEstoque from '../subMenu';

import ModalNovoSetorInterno from './modals/modalNovoSetorInterno';
import ModalEditSetorInterno from './modals/modalEditSetorInterno';

import api from '../../../services/api';
import { AnoMask, mvalor, placaMask, mplaca } from '../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../vendor/formatar';

import ModalNovoTipoProduto from './modals/modalNovoTipoProduto';
import ModalEditTipoProduto from './modals/modalEditTipoProduto';

export default function NovoProduto() {

  let IconPlus = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
            <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
        </svg>
  );

  let IconInfo = props =>(
        <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
            <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
        </svg>
  )

  let history = useHistory();

  const [step, setstep] = useState(0);
  const [errorStep1, seterrorStep1] = useState(false);
  const [loaderPage, setloaderPage] = useState(false);

  const [setores, setsetores] = useState([]);
  const [especificacoesdoproduto, setespecificacoesdoproduto] = useState([]);
  const [especificacoesdoprodutovalores, setespecificacoesdoprodutovalores] = useState([]);
  
  const [showModalNovoSetor, setshowModalNovoSetor] = useState(false);
  const [showModalEditSetor, setshowModalEditSetor] = useState(false);
  const [idEditSetor, setidEditSetor] = useState();

  const [tiposproduto, settiposproduto] = useState([]);
  const [showModalNovoTipo, setshowModalNovoTipo] = useState(false);
  const [showModalEditTipo, setshowModalEditTipo] = useState(false);
  const [idEditTipo, setidEditTipo] = useState();

  
  const getTiposProduto = async(termo='', status='', idTipo=null)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemTiposProduto?termo=&status=1`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayTipos = [];
    
    for await (let tip of response.data) {
        let dados = {
          id: tip.id,
          tipo: tip.tipo,
          codigo: tip.code,
        }    
        
        arrayTipos.push(dados);
    }

    settiposproduto(arrayTipos);

    if(idTipo){
      //populando tipo no select
      let tipoid = arrayTipos.find(x => x.id === idTipo);
      tipoid && formik.setFieldValue('tipo', JSON.stringify(tipoid));
      setidEditTipo(idTipo);
    }

    // dadosProduto?.tipo_id && await handleEspecificacoesproduto(dadosProduto?.tipo_id);

  }


  const getsetores = async(idSetor=null, codigoSetor=null)=>{
    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemSetoresInterno`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arraySetores = [];
    
    for await (let setor of response.data) {
        let dados = {
            codigo: setor.codigo,
            nome: setor.nome,
            id: setor.id
        }    
        
        arraySetores.push(dados);
    }

    setsetores(arraySetores);


    if(codigoSetor){
        //populando setor no select
        let setorcode = arraySetores.find(x => x.codigo === codigoSetor);
        setorcode && formik.setFieldValue('setor', JSON.stringify(setorcode));
        setidEditSetor(setorcode.id);
    }else if(idSetor){
        //populando setor no select
        let setorid = arraySetores.find(x => x.id === idSetor);
        setorid && formik.setFieldValue('setor', JSON.stringify(setorid));
        setidEditSetor(idSetor);
    }

    setloaderPage(false);

  }


  useEffect(()=>{
    getsetores();
    getTiposProduto();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const validationSchema = yup.object({
    titulo: yup
      .string('Informe o título do produto')
      .test({
          message: () => "Título Obrigatório",
          test: async (values) => {
            if(step === 0 && !values){
              return false
            }else{
              return true
            } 
          },
        })
      .test({
        message: (data) => `Já existe um produto cadastrado com o título ${data.value}.`,
        test: async (values) => {
          if (step === 0) { 
            try {

              let auth = localStorage.getItem("auth");
              auth = JSON.parse(auth);

              let response = await api.post(`verificaTituloExistenteProduto`, {
                  "titulo": values,
              },
              {
                headers: {
                  "Content-type": "application/json",
                  "Authorization": `Bearer ${auth.token}`
                }
              });

              if (response.data.produtoExiste) {
                return false;
              }else{
                return true;
              }

            }catch (error) {
              // console.log(error);
            }
          }else{
              return true
          } 
        },
      }),

    setor: yup
      .string('Selecione o setor do produto')
      .test({
        message: () => "Selecione o setor do produto",
        test: async (values) => {
            if(step === 1 && !values){
                return false
            }else{
                return true
            } 
        },
      }),

    precovenda: yup
      .string('Informe o preço de venda do veículo')
      .test({
        message: () => "Preço de venda Obrigatório",
        test: async (values) => {
          if(step === 1 && !values){
            return false
          }else{
            return true
          } 
        },
      }),

  });

  const formik = useFormik({
    initialValues: {
      tipo: 'null',
      especificacoesdoproduto: [],
      titulo: '',
      setor: 'null',
      precovenda: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      step < 1 && setstep(step+1)

      if(step === 1){ //TUDO CERTO PRA CADASTRAR

        let setor = JSON.parse(values.setor);
        let tipo = JSON.parse(values.tipo);

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`addProduto`, {
          "tipo": tipo?.id ? tipo.id : null,
          "titulo": values.titulo,
          "setor": setor.id,
          "precoVenda": formataDinheiroforBanco(values.precovenda),
          "especificacoes": especificacoesdoprodutovalores,
          "especificacoesdoproduto": especificacoesdoproduto,
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        history.push(`/estoque/produto/detalhes/${response.data.codigo}`);

      }      
    }
  });


  useEffect(()=>{
    let erros = JSON.stringify(formik.errors);
    erros = erros.length - 2;

    if(erros === 0){
      step === 0 && seterrorStep1(false);
    }else{
      step === 0 && seterrorStep1(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.errors]);


  const handleChangeSetor = async(event, value, reason, details)=>{
    formik.setFieldValue('setor', JSON.stringify(value));
    setidEditSetor(value?.id);
  }


  const handleEspecificacoesproduto = async(tipoId)=>{
        
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post(`listagemEspecificacoesTipoProduto`, {
      "tipoId": tipoId,
      "principal": 1,
    },
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    response = response.data;
    setespecificacoesdoproduto(response);
  }

  const handleChangeTipo = async(event, value, reason, details)=>{

    formik.setFieldValue('tipo', JSON.stringify(value));
    formik.setFieldValue('especificacoesdoproduto', []);
    setespecificacoesdoproduto([]);
    setidEditTipo(value?.id);

    value?.id && handleEspecificacoesproduto(value?.id);

  }

  function primeiraLetraMaiuscula(string) {
    if (!string) {
      return '';
    }
  
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  

  useEffect(() => {
    let tipo = JSON.parse(formik.values.tipo);
  
    // Obtenha os valores válidos
    let valoresValidos = especificacoesdoproduto
      .map((v) => formik.values.especificacoesdoproduto[v.id]?.valor)
      .filter((valor) => typeof valor === 'string' && valor !== 'false' && valor !== 'undefined');
  
    // Obtenha os valores válidos juntamente com seus IDs e ordem
    let valoresComIds = especificacoesdoproduto
      .map((v) => ({
        id: v.id,
        valor: formik.values.especificacoesdoproduto[v.id]?.valor,
        ordem: v.ordem
      }))
      .filter((item) => typeof item.valor === 'string' && item.valor !== 'false' && item.valor !== 'undefined');
  
    let tituloConcatenado = `${primeiraLetraMaiuscula(tipo?.tipo)} ${valoresValidos.join(' ')}`;
  
    let titulo = formik.values.tipo !== 'null' ? tituloConcatenado : '';
    formik.setFieldValue('titulo', titulo);

    setespecificacoesdoprodutovalores(valoresComIds); // Saída com valores, IDs e suas respectivas ordens
  }, [formik.values.especificacoesdoproduto]);


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Novo produto</title>
        </Helmet>
      </HelmetProvider>

      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
        >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalNovoSetorInterno visible={showModalNovoSetor} change={setshowModalNovoSetor} getSetores={getsetores}/> 
      <ModalEditSetorInterno id={idEditSetor} visible={showModalEditSetor} change={setshowModalEditSetor} getSetores={getsetores}/> 

      <ModalNovoTipoProduto visible={showModalNovoTipo} change={setshowModalNovoTipo} getTipos={getTiposProduto} setidEditTipo={setidEditTipo} setshowModalEditTipo={setshowModalEditTipo} /> 
      <ModalEditTipoProduto id={idEditTipo} visible={showModalEditTipo} change={setshowModalEditTipo} getTipos={getTiposProduto}/> 

      <div>
        <div className='container-page'>
          
          <SubMenuEstoque active="produtos" />
          
          <div className='box-steps'>
            <Stepper activeStep={step} alternativeLabel>
                <Step>
                  <StepLabel error={errorStep1}>Produto</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Detalhes</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Finalizar</StepLabel>
                </Step>
            </Stepper>
          </div>

          <div className='container'>
            
            <div className='box-form-cadastro-pessoas'>

                <form method='post' onSubmit={formik.handleSubmit} style={{ width: '500px' }}>
                  <h2>Cadastro de produto</h2>
                  
                  {
                    step === 0 &&

                    <Grid container spacing={2} >

                        <Grid container item xs={12} md={12}>
                          <div style={{ width: '100%', display: 'flex' }}>
                            <Grid item style={{width: '100%'}}>
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <Autocomplete
                                        className='input'
                                        disablePortal
                                        id="combo-box-tipo"
                                        options={tiposproduto}
                                        value={JSON.parse(formik.values.tipo)}
                                        getOptionLabel={(option) => option.tipo}
                                        renderOption={(props, option) => (
                                        <li {...props} key={option.id}>{option.tipo}</li>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={handleChangeTipo}
                                        noOptionsText="Nenhum tipo encontrado."
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Tipo de produto"  error={formik.touched.tipo && Boolean(formik.errors.tipo)}/>}
                                    />
                                    <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid>
                                <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovoTipo(true) }}/>
                            </Grid>
                            
                            {
                              formik.values.tipo !== 'null' &&
                              <Grid>
                                <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalEditTipo(true) }}/>
                              </Grid>
                            }
                          </div>
                        </Grid>


                        {especificacoesdoproduto.map((v) => (
                          <Grid key={v.id} item xs={12} md={6}>
                            <TextField
                              className="input"
                              fullWidth
                              name={`especificacoesdoproduto[${v.id}]`}
                              label={`${primeiraLetraMaiuscula(v.nome)}`}
                              type="text"
                              value={formik.values.especificacoesdoproduto[v.id]?.valor || ''} // Use "formik.values.especificacoesdoproduto[v.id]?.valor"
                              onChange={(e) => {
                                formik.setFieldValue(`especificacoesdoproduto[${v.id}].valor`, e.target.value);
                              }}
                              error={formik.touched.especificacoesdoproduto && Boolean(formik.errors.especificacoesdoproduto)}
                              helperText={formik.touched.especificacoesdoproduto && formik.errors.especificacoesdoproduto}
                              onKeyUp={()=>{ v.nome === "PLACA" && formik.setFieldValue(`especificacoesdoproduto[${v.id}].valor`, mplaca(formik.values.especificacoesdoproduto[v.id]?.valor)) }}
                              inputProps={{
                                maxLength: v.nome === "PLACA" ? 8 : 140,
                              }}
                            />
                          </Grid>
                        ))}

                        <Grid item xs={12} md={12}>
                            <TextField
                                className='input'
                                fullWidth
                                id="titulo"
                                name="titulo"
                                label="Título"
                                type="text"
                                value={formik.values.titulo}
                                onChange={formik.handleChange}
                                error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                                helperText={formik.touched.titulo && formik.errors.titulo}
                                inputProps={{
                                    maxLength: 245,
                                }}
                            />
                        </Grid>

                    </Grid>

                  }
                  
                  {
                    step === 1 &&

                    <Grid container spacing={2} >

                        <Grid container spacing={1.3} item xs={12} md={12}>
                            <Grid item xs={formik.values.setor === 'null' ? 10.2 : 8.7} md={formik.values.setor === 'null' ? 10.2 : 8.7} >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <Autocomplete
                                        className='input'
                                        disablePortal
                                        id="combo-box-setor"
                                        options={setores}
                                        value={JSON.parse(formik.values.setor)}
                                        getOptionLabel={(option) => option.nome}
                                        renderOption={(props, option) => (
                                        <li {...props} key={option.id}>{option.nome}</li>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={handleChangeSetor}
                                        noOptionsText="Nenhum setor encontrado."
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Setor"  error={formik.touched.setor && Boolean(formik.errors.setor)}/>}
                                    />
                                    <FormHelperText error>{formik.touched.setor && formik.errors.setor}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={1.6} md={1.6} >
                                <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovoSetor(true) }}/>
                            </Grid>

                            {
                                formik.values.setor !== 'null' &&

                                <Grid item xs={1.2} md={1} >
                                <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalEditSetor(true) }}/>
                                </Grid>
                            }
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextField
                            className='input'
                            fullWidth
                            id="precovenda"
                            name="precovenda"
                            label="Preço de venda"
                            type="text"
                            value={formik.values.precovenda}
                            onChange={formik.handleChange}
                            error={formik.touched.precovenda && Boolean(formik.errors.precovenda)}
                            helperText={formik.touched.precovenda && formik.errors.precovenda}
                            onKeyUp={()=>{ formik.setFieldValue('precovenda', mvalor(formik.values.precovenda)) }}
                            inputProps={{
                              maxLength: 16,
                            }}
                            />
                        </Grid>
                        

                    
                    </Grid>

                  }



                  <div className='box-buttons-steps'>
                    {
                      step > 0 &&

                      <div className='box-tbn-steps'>
                        <ButtonOutlineSecondary type="button" icon="" txt="Voltar" onClick={ ()=>{  step > 0 && setstep(step-1)  } } />
                      </div>
                    }

                    <div className='box-tbn-steps'>
                      <ButtonDefault type="submit" icon="" txt={  step < 1 ? 'Continuar' : 'Finalizar'}/>
                    </div>
                  </div>
                </form>
                  
            </div>

          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}