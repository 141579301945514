import React, {useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import TabEntradas from './tabEntradas';

import Footer from '../../../componentes/Footer';
import SubMenuEstoque from '../subMenu';

export default function Produtos() {

  const [tabPage, settabPage] = useState('1');

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Entradas de produtos - Estoque - Nexcom</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>
          
          <SubMenuEstoque active="entradas" />

          <div className='container'>

            <TabContext value={tabPage}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabPage} onChange={handleChangeTabPage} aria-label="estoque-tabs" textColor="primary" indicatorColor="primary" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                  <Tab label="Entradas de produtos" value="1" />
                  {/* <Tab label="Tipos de produtos" value="2" /> */}
                </Tabs>
              </Box>
              
              <TabPanel value="1">
                <TabEntradas />
              </TabPanel>

              <TabPanel value="2">
                
              </TabPanel>

            </TabContext>


            
          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}