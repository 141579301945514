import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco } from '../../../../vendor/formatar';


export default function ModalNovoServico({ visible, change, getServicos}) {
  
  let history = useHistory();

  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };

  
  const validationSchema = yup.object({
    titulo: yup
        .string('Insira o Título do serviço')
        .required('Título Obrigatório'),
    precoVenda: yup
        .string('Insira o Preço de venda')
        .required('Preço de venda Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      titulo: '',
      status: 1,
      precoVenda: '',
      descricao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let response = await api.post('addServico', {
        "titulo": values.titulo,
        "status": values.status,
        "precoVenda": formataDinheiroforBanco(values.precoVenda),
        "descricao": values.descricao,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      formik.resetForm();
      change(false);
      getServicos();

      setalertSucess(true);        
      setalertSucessMsg(`Serviço Adicionado com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);

    }
  });


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
      <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
      </Alert>
    </Snackbar>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
      <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
      </Alert>
    </Snackbar>

    <Dialog open={visible} maxWidth='md' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo serviço
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={1} >
            
            <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="titulo"
                  name="titulo"
                  label="Título"
                  margin="normal"
                  type="text"
                  value={formik.values.titulo}
                  onChange={formik.handleChange}
                  error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                  helperText={formik.touched.titulo && formik.errors.titulo}
                />
            </Grid>

            <Grid item xs={12} md={6} >
                  <FormControl sx={{ width: '100%', marginTop: '16px' }} >
                    <InputLabel id="label-totalparcelas">Status</InputLabel>
                    <Select
                      labelId="label-status"
                      id="cartao-simple-status"
                      name="status"
                      label="Status"
                      placeholder='Status'
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={formik.touched.status && Boolean(formik.errors.status)}
                    >
                      
                      <MenuItem value={1}>Ativo</MenuItem>
                      <MenuItem value={0}>Inativo</MenuItem>
                    
                    </Select>
                    <FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
                  </FormControl>
            </Grid>

            <Grid item xs={12} md={6} >
                <TextField
                    fullWidth
                    id="precoVenda"
                    name="precoVenda"
                    label="Preço de venda"
                    margin="normal"
                    type="text"
                    value={formik.values.precoVenda}
                    onChange={formik.handleChange}
                    error={formik.touched.precoVenda && Boolean(formik.errors.precoVenda)}
                    helperText={formik.touched.precoVenda && formik.errors.precoVenda}
                    onKeyUp={()=>{ formik.setFieldValue('precoVenda', formik.values.precoVenda && mvalor(formik.values.precoVenda)) }}
                    inputProps={{
                        maxLength: 16,
                    }}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="descricao"
                  name="descricao"
                  label="Descrição"
                  margin="normal"
                  type="text"
                  multiline
                  rows={6}
                  value={formik.values.descricao}
                  onChange={formik.handleChange}
                  error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                  helperText={formik.touched.descricao && formik.errors.descricao}
                />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} >

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}