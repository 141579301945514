import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { styled } from '@mui/material/styles';

import Swal from 'sweetalert2';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';
import ButtonCircleDanger from '../../../../componentes/ButtonCircleDanger';
import ButtonCirclePrimary from '../../../../componentes/ButtonCirclePrimary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco } from '../../../../vendor/formatar';

export default function ModalEditContagem({ id, visible, change, getContagens}) {
  
  let IconComissao = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 20.093 19.5">
        <path id="comissoes" d="M6.75,13v7A1.752,1.752,0,0,1,5,21.75H4A1.752,1.752,0,0,1,2.25,20V13A1.752,1.752,0,0,1,4,11.25H5A1.752,1.752,0,0,1,6.75,13ZM17.5,9.25H15a.75.75,0,0,0,0,1.5h1.25V11a.75.75,0,0,0,1.5,0v-.275A2.244,2.244,0,0,0,17.5,6.25h-1a.75.75,0,0,1,0-1.5H19a.75.75,0,0,0,0-1.5H17.75V3a.75.75,0,0,0-1.5,0v.275A2.244,2.244,0,0,0,16.5,7.75h1a.75.75,0,0,1,0,1.5Zm4.64,6.34a1.961,1.961,0,0,0-2.37-.97l-1.7.57c-.12.04-.74.56-1.07.56H12a.75.75,0,0,1,0-1.5h3.68a1.767,1.767,0,0,0-1.1-1.19l-2.2-.76c-.04-.01-.08-.02-.13-.03l-.29-.05a.57.57,0,0,0-.13-.01H8.15a2.951,2.951,0,0,1,.1.79v7a3.081,3.081,0,0,1-.09.75h7.37a2.779,2.779,0,0,0,1.23-.29l4.5-2.25a1.962,1.962,0,0,0,.88-2.62Z" transform="translate(-2.25 -2.25)" fill="#4a4a4a"/>
    </svg>
  );

  let IconLixeira = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 21.241 28.294">
          <g id="delete" transform="translate(-63.818 0)">
          <path id="Caminho_186" data-name="Caminho 186" d="M85.059,9.084H71.973l5.86-5.86a.829.829,0,0,0,0-1.172L76.348.567a1.934,1.934,0,0,0-2.735,0L72.086,2.094,71.3,1.312a2.213,2.213,0,0,0-3.126,0L65.13,4.36a2.213,2.213,0,0,0,0,3.126l.782.782L64.385,9.795a1.934,1.934,0,0,0,0,2.735l1.485,1.485a.829.829,0,0,0,1.172,0l2.1-2.1V24.7a3.592,3.592,0,0,0,3.592,3.592h8.732A3.592,3.592,0,0,0,85.059,24.7ZM67.084,7.1,66.3,6.314a.553.553,0,0,1,0-.782L69.35,2.484a.553.553,0,0,1,.782,0l.782.782Zm7.158,16.557a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Z" transform="translate(0 0)" fill="#fff"/>
          <path id="Caminho_187" data-name="Caminho 187" d="M305.186,86.046a1.384,1.384,0,0,0-.919,1.152h7.954V85.82a1.382,1.382,0,0,0-1.825-1.309L305.2,86.04Z" transform="translate(-227.162 -79.771)" fill="#fff"/>
          </g>
      </svg>
  );

  let IconPlusC = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 26.723 26.721">
          <path id="adicionar" d="M24.84,11.635H18.547a.461.461,0,0,1-.461-.461V4.88A3.348,3.348,0,0,0,15.033,1.5a3.231,3.231,0,0,0-2.392.881,3.2,3.2,0,0,0-1.005,2.34v6.45a.461.461,0,0,1-.461.461H4.882A3.349,3.349,0,0,0,1.5,14.688a3.225,3.225,0,0,0,3.221,3.4h6.45a.461.461,0,0,1,.461.461v6.293a3.334,3.334,0,0,0,3.228,3.382A3.224,3.224,0,0,0,18.086,25v-6.45a.461.461,0,0,1,.461-.461H25a3.225,3.225,0,0,0,3.221-3.4A3.351,3.351,0,0,0,24.84,11.635Z" transform="translate(-1.5 -1.5)" fill="#fff"/>
      </svg>
  );
  

  const [tabPage, settabPage] = useState("1");
  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [contagem, setcontagem] = useState([]);

  const [comissoes, setcomissoes] = useState([]);
  const [comissoesSelecionadas, setcomissoesSelecionadas] = useState([]);
  const [pageSizeDataGridComissoes, setpageSizeDataGridComissoes] = useState(10);
  const [loadingDataGridComissoes, setloadingDataGridComissoes] = useState(true);

  const [showModalNovaComissao, setshowModalNovaComissao] = useState(false);
  const [showModalEditComissao, setshowModalEditComissao] = useState(false);
  const [idDetalhesComissao, setidDetalhesComissao] = useState();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };
  
  const validationSchema = yup.object({
  });

  const formik = useFormik({
    initialValues: {
        data: '',
        usuario: '',
        setor: '',
        status: '',
        produto: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('updateContagemEstoque', {
        "id": id,
        "status": values.status,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      getContagens();

      setalertSucess(true);        
      setalertSucessMsg(`Contagem de estoque Atualizada com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);

    }
  });


  const detalhesContagem = async()=>{
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);


    id && api.post('detalhesContagemEstoque', {
        "id": id
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    }).then( async response => {
        
        let data = response.data;
        setcontagem(data);

        formik.setFieldValue('data', moment(data.data).format('DD/MM/YYYY'));
        formik.setFieldValue('usuario', data.usuario);
        formik.setFieldValue('setor', data.setor);
        formik.setFieldValue('status', data.status);

    }).catch((error) => {
        change(false)
    });
    
  }

  useEffect(async()=>{
    await detalhesContagem();
    // await getComissoes(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[visible]);

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue); 
  };

  const getComissoes = async(idServico)=>{

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      // //COMISSOES
      // setloadingDataGridComissoes(true);
      // let response = await api.post('listagemComissoesServico', {
      //   "id": idServico
      // }, 
      // {
      //   headers: {
      //     "Content-type": "application/json",
      //     "Authorization": `Bearer ${auth.token}`
      //   }
      // })

      // setcomissoes(response.data);
      // setloadingDataGridComissoes(false);
  }


  const columnsDataGridComissoes = [
      { field: 'id', hide: true, headerName: 'ID', width: 90  },
      {
        field: 'usuario',
        headerName: 'Usuário',
        flex: 1,
        minWidth: 150,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
      {
          field: 'tipo',
          headerName: 'Tipo de calculo',
          flex: 1,
          minWidth: 150,
          editable: false,
          renderCell: (params) => { //renderiza componente
          
              switch (params.value) {
                  case 1:
                      return <span>Por valor</span>
                      break;
                  case 2:
                      return <span>Por %</span>
                      break;
              }
            },
      },
      {
          field: 'porcentagem',
          headerName: 'Porcentagem',
          flex: 1,
          minWidth: 150,
          editable: false,
          valueFormatter: (params) => {
            return params.value+'%'
          },
      },
      {
          field: 'valor',
          headerName: 'Valor',
          flex: 1,
          minWidth: 150,
          editable: false,
          valueFormatter: (params) => {
            return formataDinheiro(params.value);
          },
      },
  ];
    

  function nenhumaComissaoEncontrada(){
      return(
          <GridOverlay>
          <div className="container-no-data-grid">
              <IconComissao/>
              <p>Nenhuma Comissão Encontrada</p>
          </div>
          </GridOverlay>
      )
  }

  const handleDeleteComissao = async()=>{

      Swal.fire({
        title: 'Tem certeza?',
        text: "Deseja deletar as comissões selecionadas?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#02A680',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, deletar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {

          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);

          let comissoesid = [];

          for await (let id of comissoesSelecionadas) {
            let dados = { id: id }
            comissoesid.push(dados);
          }
          
          await api.delete('deleteComissaoServico', {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            },
            data: {  "id": id, "comissoesid": comissoesid }
          });

          await getComissoes(id);

          Swal.fire(
            'Deletado!',
            'Commissões deletada(s) com sucesso!',
            'success'
          )
        }
      })
  }

  const detalhesComissao = async(id)=>{
      setidDetalhesComissao(id);
      setshowModalEditComissao(true);
  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
      <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
      </Alert>
    </Snackbar>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
      <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
      </Alert>
    </Snackbar>

  

    <Dialog open={visible} maxWidth='lg' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Contagem {contagem?.setor}
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <TabContext value={tabPage}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}> 
              <Tabs value={tabPage} onChange={handleChangeTabPage} aria-label="estoque-tabs" textColor="primary" indicatorColor="primary" variant="scrollable">
                  <Tab label="Principal" value="1" />
                  <Tab label="Resumo" value="2" />
              </Tabs>
              </Box>

            <TabPanel value="1" style={{width: '100%'}}>
              <Grid container spacing={1} >
                
            
                <Grid item xs={12} md={3}>
                    <TextField
                      disabled
                      fullWidth
                      id="data"
                      name="data"
                      label="Data"
                      margin="normal"
                      type="text"
                      value={formik.values.data}
                      onChange={formik.handleChange}
                      error={formik.touched.data && Boolean(formik.errors.data)}
                      helperText={formik.touched.data && formik.errors.data}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                      disabled
                      fullWidth
                      id="usuario"
                      name="usuario"
                      label="Usuário"
                      margin="normal"
                      type="text"
                      value={formik.values.usuario}
                      onChange={formik.handleChange}
                      error={formik.touched.usuario && Boolean(formik.errors.usuario)}
                      helperText={formik.touched.usuario && formik.errors.usuario}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                      disabled
                      fullWidth
                      id="setor"
                      name="setor"
                      label="Setor"
                      margin="normal"
                      type="text"
                      value={formik.values.setor}
                      onChange={formik.handleChange}
                      error={formik.touched.setor && Boolean(formik.errors.setor)}
                      helperText={formik.touched.setor && formik.errors.setor}
                    />
                </Grid>

                <Grid item xs={12} md={3} >
                      <FormControl sx={{ width: '100%', marginTop: '16px' }} >
                        <InputLabel id="label-totalparcelas">Status</InputLabel>
                        <Select
                          labelId="label-status"
                          id="cartao-simple-status"
                          name="status"
                          label="Status"
                          placeholder='Status'
                          value={formik.values.status}
                          onChange={formik.handleChange}
                          error={formik.touched.status && Boolean(formik.errors.status)}
                        >
                          
                          <MenuItem value={1}>Contar</MenuItem>
                          <MenuItem value={2}>Contado</MenuItem>
                          <MenuItem value={3}>Conferido</MenuItem>

                        </Select>
                        <FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
                      </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      id="produto"
                      name="produto"
                      label="Produto"
                      placeholder="Digite o nome ou o código do produto e tecle ENTER"
                      margin="normal"
                      type="text"
                      value={formik.values.produto}
                      onChange={formik.handleChange}
                      error={formik.touched.produto && Boolean(formik.errors.produto)}
                      helperText={formik.touched.produto && formik.errors.produto}
                    />
                </Grid>

              </Grid>
            </TabPanel>

            <TabPanel value="2" style={{width: '100%'}}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>

              {
                  comissoesSelecionadas.length > 0 &&

                  <div style={{ marginRight: '10px' }}>
                  <ButtonCircleDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteComissao() }}/>
                  </div>
              }

              <ButtonCirclePrimary icon={<IconPlusC/>} onClick={()=>{ setshowModalNovaComissao(true) }}/>
  
            </div>

              <DataGrid
                sx={{ minHeight: '300px' }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                autoHeight
                rows={comissoes}
                columns={columnsDataGridComissoes}
                pageSize={pageSizeDataGridComissoes}
                onPageSizeChange={(newPageSize) => setpageSizeDataGridComissoes(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]} 
                checkboxSelection
                disableSelectionOnClick
                editMode='row'
                loading={loadingDataGridComissoes}
                disableColumnMenu={true}
                onSelectionModelChange={(props)=>{
                    setcomissoesSelecionadas(props)
                }}
                // onRowClick
                onCellClick={(props)=>{
                    if(props.field !== "__check__"){
                        detalhesComissao(props.id);
                    }
                }}
                components={{
                    NoRowsOverlay: nenhumaComissaoEncontrada,
                }}
              />   
            </TabPanel>

          </TabContext>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} >

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3} style={{ marginRight: 10 }}>
            <ButtonDefault type="submit" icon="" txt="Salvar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}