import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco } from '../../../../vendor/formatar';


export default function ModalNovaContagem({ visible, change, getContagens, handleDetalhesContagem}) {
  
  let history = useHistory();

  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');
  
  const [setores, setsetores] = useState([]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };
  
  const validationSchema = yup.object({
    setor: yup
      .string('Selecione um setor')
      .required('Setor Obrigatório')
      .test({
        message: () => "Selecione um setor",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      setor: 'null',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let setor = JSON.parse(values.setor);

      let response = await api.post('novaContagemEstoque', {
        "setor": setor.id,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      response = response.data;

      formik.resetForm();
      change(false);
      getContagens();

      handleDetalhesContagem(response.id);

      setalertSucess(true);        
      setalertSucessMsg(`Contagem Iniciada com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);

    }
  });


  const getSetores = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let setores = await api.get(`listagemSetoresInterno`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arraySetores = [];
    
    for await (let setor of setores.data) {
        let dados = {
            nome: setor.nome,
            id: setor.id
        }    
        
        arraySetores.push(dados);
    }

    setsetores(arraySetores);
  }

  useEffect(()=>{
    if(visible){
      getSetores();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);

  const handleChangeSetor = async(event, value, reason, details)=>{
    formik.setFieldValue('setor', JSON.stringify(value));
  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
      <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
      </Alert>
    </Snackbar>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
      <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
      </Alert>
    </Snackbar>

    <Dialog open={visible} maxWidth='xs' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Nova Contagem
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={1} >
            <Grid item xs={12} md={12} style={{ display: 'flex', marginTop: '12px' }}>
                <Grid item style={{width:'100%'}}>
                    <FormControl sx={{ m: 0, width: '100%' }} >
                        <Autocomplete
                            className='input'
                            // disablePortal
                            id="combo-box-setor"
                            options={setores}
                            value={JSON.parse(formik.values.setor)}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option) => (
                            <li {...props} key={option.id}>{option.nome}</li>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={handleChangeSetor}
                            noOptionsText="Nenhum setor encontrado."
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="Setor"  error={formik.touched.setor && Boolean(formik.errors.setor)}/>}
                        />
                        <FormHelperText error>{formik.touched.setor && formik.errors.setor}</FormHelperText>
                    </FormControl>
                </Grid>
              </Grid>   
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} >

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={5} style={{ marginRight: 16 }}>
            <ButtonDefault type="submit" icon="" txt="Iniciar contagem" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}