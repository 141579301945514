import React, { useState, useEffect, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useHistory } from "react-router-dom";
import './style.css';

import api from '../../../services/api';
import axios from 'axios';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

import { CEPMask, mArea, mnumero, mPeso, mporcentagem, mvalor } from '../../../vendor/mascaras';

import ModalNovaPessoa from '../Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../Pessoas/modals/modalDetalhesPessoa';
import { formataDinheiroforBanco } from '../../../vendor/formatar';

export default function NovoImovel() {

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
      <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  );

  let history = useHistory();
  const inputNumero = useRef(null);

  const [loaderPage, setloaderPage] = useState(false);

  const [step, setstep] = useState(0);
  const [errorStep1, seterrorStep1] = useState(false);
  const [pessoas, setpessoas] = useState([]);

  const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
  const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
  
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();


  const validationSchema = yup.object({
    fornecedor: yup
      .string('Selecione o Proprietário')
      .required('Proprietário Obrigatório')
      .test({
        message: () => "Selecione o Proprietário",
        test: async (values) => {
          if (step === 0) { 
            if (values === 'null') { return false; } else{ return true; }
          }else{
            return true;
          }
        },
    }),

    cep: yup
      .string('Insira o CEP')
      .test({
        message: () => "CEP Obrigatório",
        test: async (values) => {
          if (step === 1) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    rua: yup
      .string('Insira o nome da rua')
      .test({
        message: () => "Rua Obrigatória",
        test: async (values) => {
          if (step === 1) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    numero: yup
      .string('Insira o número')
      .test({
        message: () => "Número Obrigatório",
        test: async (values) => {
          if (step === 1) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    bairro: yup
      .string('Insira o Bairro')
      .test({
        message: () => "Bairro Obrigatório",
        test: async (values) => {
          if (step === 1) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    cidade: yup
      .string('Insira a Cidade')
      .test({
        message: () => "Cidade Obrigatória",
        test: async (values) => {
          if (step === 1) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    estado: yup
      .string('Insira o estado')
      .test({
        message: () => "Estado Obrigatório",
        test: async (values) => {
          if (step === 1) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),

    tipo: yup
      .string('Selecione o tipo do imóvel')
      .test({
        message: () => "Tipo do imóvel Obrigatório",
        test: async (values) => {
          if (step === 2) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),

    estadoimovel: yup
      .string('Insira o estado do imóvel')
      .test({
        message: () => "Estado do imóvel Obrigatório",
        test: async (values) => {
          if (step === 2) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),

  });

  const formik = useFormik({
    initialValues: {
      fornecedor: 'null',

      cep: '',
      rua: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      complemento: '',
      referencia: '',

      tipo: '',
      estadoimovel: '',
      placas: '',
      chaves: '',
      chavesdescricao: '',
      dormitorios: '',
      suites: '',
      banheiros: '',
      vagas: '',
      areaconstruida: '',
      areatotal: '',
      valorcondominio: ''

    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      step < 2 && setstep(step+1)

      if(step === 2){ //TUDO CERTO PRA CADASTRAR
        
        setloaderPage(true);
        
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let fornecedorJSON = JSON.parse(values.fornecedor);

        let valorcondominio = values.valorcondominio ? formataDinheiroforBanco(values.valorcondominio) : 0;

        let response = await api.post(`addImovel`, {
            "fornecedor": fornecedorJSON.id,
            "cep": values.cep,
            "rua": values.rua,
            "numero": values.numero,
            "bairro": values.bairro,
            "cidade": values.cidade,
            "estado": values.estado,
            "complemento": values.complemento,
            "referencia": values.referencia,
            "tipo": values.tipo,
            "estadoimovel": values.estadoimovel !== "" ? values.estadoimovel : null,
            "placas": values.placas !== "" ? values.placas : null,
            "chaves": values.chaves !== "" ? values.chaves : null,
            "chavesdescricao": values.chavesdescricao,
            "dormitorios": values.dormitorios !== "" ? values.dormitorios : null,
            "suites": values.suites !== "" ? values.suites : null,
            "banheiros": values.banheiros !== "" ? values.banheiros : null,
            "vagas": values.vagas !== "" ? values.vagas : null,
            "areaconstruida": values?.areaconstruida !== "" ? values?.areaconstruida?.replace('m²', '') : null,
            "areatotal": values?.areatotal !== "" ? values?.areatotal?.replace('m²', '') : null,
            "valorcondominio": valorcondominio,
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        setloaderPage(false);
        response?.data?.codigo && history.push(`/cadastro/imovel/${response.data.codigo}`);

      }      
    }
  });

  const getPessoas = async(fornecedorId=null, codigoFornecedor=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    setpessoas(arrayPessoas);


    if(codigoFornecedor){
      //populando fornecedor no select
      let fornecedorcode = arrayPessoas.find(x => x.codigo === codigoFornecedor);
      fornecedorcode && formik.setFieldValue('fornecedor', JSON.stringify(fornecedorcode));
      setcodigoDetalhesPessoa(codigoFornecedor);
      setshowModalDetalhesPessoa(true);

    }else if(fornecedorId){
      //populando fornecedor no select
      let fornecedor = arrayPessoas.find(x => x.id === fornecedorId);
      fornecedor && formik.setFieldValue('fornecedor', JSON.stringify(fornecedor));
      setcodigoDetalhesPessoa(fornecedor.codigo);
    }

  }


  useEffect(async()=>{
    await getPessoas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(()=>{
    let erros = JSON.stringify(formik.errors);
    erros = erros.length - 2;

    if(erros === 0){
      seterrorStep1(false);
    }else{
      seterrorStep1(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.errors]);


  const handleBuscaCep = async(props)=>{
    formik.handleChange(props);
    
    let cep = props.target.value;
    
    if(cep.length >= 9){

      setloaderPage(true);

      let response = await axios({
        method: 'get',
        url: `https://viacep.com.br/ws/${cep}/json/`,
        headers: { 
          "Content-type": "application/json"
        }
      });

      let data = response.data;
        
      if(data.cep){
        formik.setFieldValue('cep', data.cep);
        formik.setFieldValue('rua', data.logradouro);
        formik.setFieldValue('bairro', data.bairro);
        formik.setFieldValue('cidade', data.localidade);
        formik.setFieldValue('estado', data.uf);
        // formik.setFieldValue('codmunicipio', data.ibge);
        
        inputNumero.current.focus();
      }

      setloaderPage(false);
    }

  }

  const handleChangeFornecedor = async(event, value, reason, details)=>{
    formik.setFieldValue('fornecedor', JSON.stringify(value));
    setcodigoDetalhesPessoa(value?.codigo);
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcom - Novo Imóvel</title>
        </Helmet>
      </HelmetProvider>

      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
      <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

      <div>
        <div className='container-page'>
          <div className='box-steps'>
            <Stepper activeStep={step} alternativeLabel>
                <Step>
                  <StepLabel error={errorStep1}>Proprietário</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Endereço</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Detalhes</StepLabel>
                </Step>
            </Stepper>
          </div>

          <div className='container'>
            
            <div className='box-form-cadastro-imovel'>

                <form method='post' onSubmit={formik.handleSubmit}>
                  <h2>Cadastro do Imóvel</h2>

                  {
                    step === 0 &&

                    <div className='container-step-1-novo-imovel'>
                      <Grid container spacing={0.5} >

                      <Grid item xs={formik.values.fornecedor === 'null' ? 10.2 : 8.7} md={formik.values.fornecedor === 'null' ? 10.2 : 8.7} >
                            <FormControl sx={{ m: 0, width: '100%' }} >
                              <Autocomplete
                                  className='input'
                                  disablePortal
                                  id="combo-box-fornecedor"
                                  options={pessoas}
                                  value={JSON.parse(formik.values.fornecedor)}
                                  getOptionLabel={(option) => option.nome}
                                  renderOption={(props, option) => (
                                    <li {...props} key={option.id}>{option.nome}</li>
                                  )}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  onChange={handleChangeFornecedor}
                                  noOptionsText="Nenhum Proprietário encontrado."
                                  sx={{ width: '100%' }}
                                  renderInput={(params) => <TextField {...params} label="Proprietário"  error={formik.touched.fornecedor && Boolean(formik.errors.fornecedor)}/>}
                              />
                              <FormHelperText error>{formik.touched.fornecedor && formik.errors.fornecedor}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={1.6} md={1.6} >
                          <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                        </Grid>

                        {
                          formik.values.fornecedor !== 'null' &&

                          <Grid item xs={1.2} md={1} >
                            <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                          </Grid>
                        }


                      </Grid>
                    </div>

                  }


                  {
                    step === 1 &&

                    <div className='container-step-2-novo-imovel'>
                      <Grid container spacing={2} >
                        <Grid item xs={12} md={2.5} >
                          <TextField
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="cep"
                            name="cep"
                            label="CEP"
                            margin="normal"
                            type="text"
                            value={formik.values.cep}
                            onChange={handleBuscaCep}
                            error={formik.touched.cep && Boolean(formik.errors.cep)}
                            helperText={formik.touched.cep && formik.errors.cep}
                            InputProps={{
                              inputComponent: CEPMask,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6.5} >
                          <TextField
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="rua"
                            name="rua"
                            label="Rua"
                            margin="normal"
                            type="text"
                            value={formik.values.rua}
                            onChange={formik.handleChange}
                            error={formik.touched.rua && Boolean(formik.errors.rua)}
                            helperText={formik.touched.rua && formik.errors.rua}
                          />
                        </Grid>

                        <Grid item xs={12} md={3} >
                          <TextField
                            inputRef={inputNumero}
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="numero"
                            name="numero"
                            label="Número"
                            margin="normal"
                            type="text"
                            value={formik.values.numero}
                            onChange={formik.handleChange}
                            onKeyUp={()=>{ formik.setFieldValue('numero', formik.values.numero && mnumero(formik.values.numero)) }}
                            error={formik.touched.numero && Boolean(formik.errors.numero)}
                            helperText={formik.touched.numero && formik.errors.numero}
                            inputProps={{
                              maxLength: 6,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={5} >
                          <TextField
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="bairro"
                            name="bairro"
                            label="Bairro"
                            margin="normal"
                            type="text"
                            value={formik.values.bairro}
                            onChange={formik.handleChange}
                            error={formik.touched.bairro && Boolean(formik.errors.bairro)}
                            helperText={formik.touched.bairro && formik.errors.bairro}
                          />
                        </Grid>

                        <Grid item xs={12} md={5} >
                          <TextField
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="cidade"
                            name="cidade"
                            label="Cidade"
                            margin="normal"
                            type="text"
                            value={formik.values.cidade}
                            onChange={formik.handleChange}
                            error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                            helperText={formik.touched.cidade && formik.errors.cidade}
                          />
                        </Grid>

                        <Grid item xs={12} md={2} >
                          <TextField
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="estado"
                            name="estado"
                            label="UF"
                            margin="normal"
                            type="text"
                            value={formik.values.estado}
                            onChange={formik.handleChange}
                            error={formik.touched.estado && Boolean(formik.errors.estado)}
                            helperText={formik.touched.estado && formik.errors.estado}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} >
                          <TextField
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="complemento"
                            name="complemento"
                            label="Complemento"
                            margin="normal"
                            type="text"
                            value={formik.values.complemento}
                            onChange={formik.handleChange}
                            error={formik.touched.complemento && Boolean(formik.errors.complemento)}
                            helperText={formik.touched.complemento && formik.errors.complemento}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} >
                          <TextField
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="referencia"
                            name="referencia"
                            label="Ponto de Referência"
                            margin="normal"
                            type="text"
                            value={formik.values.referencia}
                            onChange={formik.handleChange}
                            error={formik.touched.referencia && Boolean(formik.errors.referencia)}
                            helperText={formik.touched.referencia && formik.errors.referencia}
                          />
                        </Grid>
                      </Grid>  
                    </div>

                  }

                  {
                    step === 2 &&

                    <div className='container-step-2-novo-imovel'>
                      <Grid container spacing={2}>

                        <Grid item xs={12} md={3} >
                          <FormControl sx={{ m: 0, width: '100%' }} >
                              <InputLabel id="label-tipo">Tipo do imóvel</InputLabel>
                              <Select
                              className='input'
                              labelId="label-tipo"
                              id="tipo-select"
                              name="tipo"
                              label="Tipo do imóvel"
                              placeholder='Tipo do imóvel'
                              value={formik.values.tipo}
                              onChange={formik.handleChange}
                              error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                              >
                              <MenuItem value={1}>Casa</MenuItem>
                              <MenuItem value={2}>Apartamento</MenuItem>
                              <MenuItem value={3}>Kitnet</MenuItem>
                              <MenuItem value={4}>Terreno urbano</MenuItem>
                              <MenuItem value={5}>Terreno rural</MenuItem>
                              <MenuItem value={6}>Sala comercial</MenuItem>
                              <MenuItem value={7}>Loja</MenuItem>
                              <MenuItem value={8}>Chácara</MenuItem>
                              <MenuItem value={9}>Sítio</MenuItem>
                              </Select>
                              <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                          </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} md={3} >
                          <FormControl sx={{ m: 0, width: '100%' }} >
                              <InputLabel id="label-estadoimovel">Estado do imóvel</InputLabel>
                              <Select
                              className='input'
                              labelId="label-estadoimovel"
                              id="demo-simple-select"
                              name="estadoimovel"
                              label="Estado do imóvel"
                              placeholder='Estado do imóvel'
                              value={formik.values.estadoimovel}
                              onChange={formik.handleChange}
                              error={formik.touched.estadoimovel && Boolean(formik.errors.estadoimovel)}
                              >
                              <MenuItem value={1}>Novo</MenuItem>
                              <MenuItem value={2}>Usado</MenuItem>
                              </Select>
                              <FormHelperText error>{formik.touched.estadoimovel && formik.errors.estadoimovel}</FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3} >
                          <FormControl sx={{ m: 0, width: '100%' }} >
                              <InputLabel id="label-placas">Placas</InputLabel>
                              <Select
                              className='input'
                              labelId="label-placas"
                              id="placas-select"
                              name="placas"
                              label="Placas"
                              placeholder='Placas'
                              value={formik.values.placas}
                              onChange={formik.handleChange}
                              error={formik.touched.placas && Boolean(formik.errors.placas)}
                              >
                              <MenuItem value={1}>Sim</MenuItem>
                              <MenuItem value={0}>Não</MenuItem>
                              </Select>
                              <FormHelperText error>{formik.touched.placas && formik.errors.placas}</FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3} >
                          <FormControl sx={{ m: 0, width: '100%' }} >
                              <InputLabel id="label-chaves">Chaves</InputLabel>
                              <Select
                              className='input'
                              labelId="label-chaves"
                              id="chaves-select"
                              name="chaves"
                              label="Chaves"
                              placeholder='Chaves'
                              value={formik.values.chaves}
                              onChange={formik.handleChange}
                              error={formik.touched.chaves && Boolean(formik.errors.chaves)}
                              >
                              <MenuItem value={1}>Imobiliaria</MenuItem>
                              <MenuItem value={2}>Proprietario</MenuItem>
                              <MenuItem value={3}>Outros</MenuItem>
                              </Select>
                              <FormHelperText error>{formik.touched.chaves && formik.errors.chaves}</FormHelperText>
                          </FormControl>
                        </Grid>

                        {

                          formik.values.chaves === 3 &&

                          <Grid item xs={12} md={12}>
                            <TextField
                              sx={{ m: 0, width: '100%' }} 
                              fullWidth
                              id="chavesdescricao"
                              name="chavesdescricao"
                              label="Info Chaves"
                              margin="normal"
                              type="text"
                              value={formik.values.chavesdescricao}
                              onChange={formik.handleChange}
                              error={formik.touched.chavesdescricao && Boolean(formik.errors.chavesdescricao)}
                              helperText={formik.touched.chavesdescricao && formik.errors.chavesdescricao}
                            />
                          </Grid>

                        }

                        <Grid item xs={12} md={3} >
                          <TextField
                            inputRef={inputNumero}
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="dormitorios"
                            name="dormitorios"
                            label="Dormitórios"
                            margin="normal"
                            type="text"
                            value={formik.values.dormitorios}
                            onChange={formik.handleChange}
                            onKeyUp={()=>{ formik.setFieldValue('dormitorios', formik.values.dormitorios && mnumero(formik.values.dormitorios)) }}
                            error={formik.touched.dormitorios && Boolean(formik.errors.dormitorios)}
                            helperText={formik.touched.dormitorios && formik.errors.dormitorios}
                            inputProps={{
                              maxLength: 3,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={3} >
                          <TextField
                            inputRef={inputNumero}
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="suites"
                            name="suites"
                            label="Suítes"
                            margin="normal"
                            type="text"
                            value={formik.values.suites}
                            onChange={formik.handleChange}
                            onKeyUp={()=>{ formik.setFieldValue('suites', formik.values.suites && mnumero(formik.values.suites)) }}
                            error={formik.touched.suites && Boolean(formik.errors.suites)}
                            helperText={formik.touched.suites && formik.errors.suites}
                            inputProps={{
                              maxLength: 3,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={3} >
                          <TextField
                            inputRef={inputNumero}
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="banheiros"
                            name="banheiros"
                            label="Banheiros"
                            margin="normal"
                            type="text"
                            value={formik.values.banheiros}
                            onChange={formik.handleChange}
                            onKeyUp={()=>{ formik.setFieldValue('banheiros', formik.values.banheiros && mnumero(formik.values.banheiros)) }}
                            error={formik.touched.banheiros && Boolean(formik.errors.banheiros)}
                            helperText={formik.touched.banheiros && formik.errors.banheiros}
                            inputProps={{
                              maxLength: 3,
                            }}
                          />
                        </Grid>
                        
                        <Grid item xs={12} md={3} >
                          <TextField
                            inputRef={inputNumero}
                            sx={{ m: 0, width: '100%' }} 
                            fullWidth
                            id="vagas"
                            name="vagas"
                            label="Vagas"
                            margin="normal"
                            type="text"
                            value={formik.values.vagas}
                            onChange={formik.handleChange}
                            onKeyUp={()=>{ formik.setFieldValue('vagas', formik.values.vagas && mnumero(formik.values.vagas)) }}
                            error={formik.touched.vagas && Boolean(formik.errors.vagas)}
                            helperText={formik.touched.vagas && formik.errors.vagas}
                            inputProps={{
                              maxLength: 3,
                            }}
                          />
                        </Grid>
                        
                        <Grid item xs={12} md={3.5} >
                          <TextField
                            className='input'
                            fullWidth
                            id="areaconstruida"
                            name="areaconstruida"
                            label="Área construída"
                            margin="none"
                            type="text"
                            value={formik.values.areaconstruida}
                            // disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.areaconstruida && Boolean(formik.errors.areaconstruida)}
                            helperText={formik.touched.areaconstruida && formik.errors.areaconstruida}
                            onKeyUp={()=>{ formik.setFieldValue('areaconstruida', formik.values.areaconstruida && mArea(formik.values.areaconstruida)) }}
                            inputProps={{
                              maxLength: 13,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={3.7} >
                          <TextField
                            className='input'
                            fullWidth
                            id="areatotal"
                            name="areatotal"
                            label="Área total do terreno"
                            margin="none"
                            type="text"
                            value={formik.values.areatotal}
                            // disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.areatotal && Boolean(formik.errors.areatotal)}
                            helperText={formik.touched.areatotal && formik.errors.areatotal}
                            onKeyUp={()=>{ formik.setFieldValue('areatotal', formik.values.areatotal && mArea(formik.values.areatotal)) }}
                            inputProps={{
                              maxLength: 13,
                            }}
                          />
                        </Grid>


                        <Grid item xs={12} md={4.8} >
                          <TextField
                            className='input'
                            fullWidth
                            id="valorcondominio"
                            name="valorcondominio"
                            label="Valor do Condomínio"
                            margin="none"
                            type="text"
                            value={formik.values.valorcondominio}
                            // disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.valorcondominio && Boolean(formik.errors.valorcondominio)}
                            helperText={formik.touched.valorcondominio && formik.errors.valorcondominio}
                            onKeyUp={()=>{ formik.setFieldValue('valorcondominio', formik.values.valorcondominio && mvalor(formik.values.valorcondominio)) }}
                            inputProps={{
                              maxLength: 13,
                            }}
                          />
                        </Grid>



                      </Grid>
                    </div> 
                  }

                  <div className='box-buttons-steps'>
                    {
                      step > 0 &&

                      <div className='box-tbn-steps'>
                        <ButtonOutlineSecondary type="button" icon="" txt="Voltar" onClick={ ()=>{  step > 0 && setstep(step-1)  } } />
                      </div>
                    }

                    <div className='box-tbn-steps'>
                      <ButtonDefault type="submit" icon="" txt={  step < 2 ? 'Continuar' : 'Finalizar'} onClick={ ()=>{  } } />
                    </div>
                  </div>
                </form>

            </div>

          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}