import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../vendor/formatar';

export default function ModalNovaTransferenciaCaixa({ tipoPadrao, visible, change, refreshlancamentos}) {


  const [loaderPage, setloaderPage] = useState(false);
  const [caixas, setcaixas] = useState([]);
  const [contas, setcontas] = useState([]);

  const [contasOrcamentarias, setcontasOrcamentarias] = useState([]);

  const handleDescricao = async(tipo)=>{
    switch (tipo) {
      case 1:
          return 'Transferência entre caixas'
        break;
      case 2:
          return 'Transferência do caixa para conta bancária'
          break;
      case 3:
          return 'Transferência entre Contas Bancárias'
        break;
      case 4:
          return 'Transferência da Conta Bancária para o Caixa'
          break;
    }
  }

  const getCaixas = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let caixas = await api.get(`listagemCaixas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setcaixas(caixas.data);

  }

  const getContas = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let contas = await api.get(`listagemContasBancarias`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setcontas(contas.data);

  }

  useEffect(async()=>{
    if(visible){
      getCaixas();
      getContas();

      let descricao = await handleDescricao(tipoPadrao);
      formik.setFieldValue('descricao', descricao);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);

  
  const validationSchema = yup.object({

    data: yup
      .date('').typeError("Insira a data do lançamento")
      .required('Data de lançamento Obrigatória'),
    caixaorigem: yup
      .number('Selecione o Caixa de Origem')
      .test({
        message: () => "Selecione o Caixa de Origem",
        test: async (values) => {
          if (formik.values.tipo <= 2) {
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    caixadestino: yup
      .number('Selecione o Caixa de Destino')
      .test({
        message: () => "Selecione o Caixa de Destino",
        test: async (values) => {
          if (formik.values.tipo === 1 || formik.values.tipo === 4) {
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    contaorigem: yup
      .number('Selecione a Conta de Origem')
      .test({
        message: () => "Selecione a Conta de Origem",
        test: async (values) => {
          if (formik.values.tipo > 2) {
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    contadestino: yup
      .number('Selecione a Conta de Destino')
      .test({
        message: () => "Selecione a Conta de Destino",
        test: async (values) => {
          if (formik.values.tipo === 2 || formik.values.tipo === 3) {
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    valor: yup
      .string('Informe o valor')
      .required('Valor Obrigatório'),
    descricao: yup
      .string('Informe a descrição do pagamento')
      .required('Descrição Obrigatória'),
    
  });

  const formik = useFormik({
    initialValues: {
      data: moment().format('MM/DD/YYYY'), //moment().format('YYYY-MM-DD'),
      tipo: tipoPadrao,
      caixaorigem: '',
      caixadestino: '',
      contaorigem: '',
      contadestino: '',
      valor: '',
      descricao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      if(values.tipo === 1 && values.caixaorigem === values.caixadestino){
        Swal.fire(
          'Erro de destino!',
          'O caixa de destino não pode ser igual a origem!',
          'error'
        )
      }else if(values.tipo === 3 && values.contaorigem === values.contadestino){
        Swal.fire(
          'Erro de destino!',
          'A Conta de destino não pode ser igual a origem!',
          'error'
        )
      }else{

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let data = moment(values.data).format('YYYY-MM-DD hh:mm:ss');

        await api.post('novaTransferencia', {
          "tipo": values.tipo,
          "caixaorigem": values.caixaorigem,
          "caixadestino": values.caixadestino,
          "contaorigem": values.contaorigem,
          "contadestino": values.contadestino,
          "valor": formataDinheiroforBanco(values.valor),
          "descricao": values.descricao,
          "data": data,
        }, 
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        })

        formik.resetForm();
        refreshlancamentos();
        change(false);
      }

    }
  });

  const handleChangeTipo = async(event, value, reason, details)=>{
    formik.setFieldValue('tipo', value.props.value);
    
    let descricao = await handleDescricao(value.props.value);

    formik.setFieldValue('descricao', descricao);
    formik.setFieldValue('caixaorigem', '');
    formik.setFieldValue('caixadestino', '');
    formik.setFieldValue('contaorigem', '');
    formik.setFieldValue('contadestino', '');
  }
  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Nova Transferência
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={5} >
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <DesktopDatePicker
                      label="Data"
                      inputFormat="dd/MM/yyyy"
                      name="data"
                      value={formik.values.data}
                      onChange={(data)=>{ formik.setFieldValue('data', data) }}
                      renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.data && Boolean(formik.errors.data)}/>}
                    />
                    <FormHelperText error>{formik.touched.data && formik.errors.data}</FormHelperText>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={7} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-tipo">Tipo de Transferência</InputLabel>
                    <Select
                        labelId="label-tipo"
                        name="tipo"
                        label="Tipo de Transferência"
                        placeholder='Tipo de Transferência'
                        value={formik.values.tipo}
                        onChange={handleChangeTipo}
                        error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                    >

                        <MenuItem value={1}>Entre caixas</MenuItem>
                        <MenuItem value={2}>Do Caixa para Conta Bancária</MenuItem>
                        <MenuItem value={3}>Entre Contas Bancárias</MenuItem>
                        <MenuItem value={4}>Da Conta Bancária para o Caixa</MenuItem>

                    </Select>
                    <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                    </FormControl>
                </Grid>

                {
                  
                formik.values.tipo <= 2  &&
                
                <>
                <Grid item xs={12} md={6} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-caixa">Caixa de origem</InputLabel>
                    <Select
                        labelId="label-caixaorigem"
                        id="caixaorigem-select"
                        name="caixaorigem"
                        label="Caixa de origem"
                        placeholder='Caixa de origem'
                        value={formik.values.caixaorigem}
                        onChange={formik.handleChange}
                        error={formik.touched.caixaorigem && Boolean(formik.errors.caixaorigem)}
                    >

                        {caixas.map((v, k) =>{
                            return (
                              <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                            )
                        })}

                    </Select>
                    <FormHelperText error>{formik.touched.caixaorigem && formik.errors.caixaorigem}</FormHelperText>
                    </FormControl>
                </Grid>
                </>

                }

                {
                  
                  formik.values.tipo > 2 &&
                  
                  <>
                    <Grid item xs={12} md={6} >
                        <FormControl sx={{ m: 0, width: '100%' }} >
                        <InputLabel id="label-conta">Conta Bancária de origem</InputLabel>
                        <Select
                            labelId="label-contaorigem"
                            id="contaorigem-select"
                            name="contaorigem"
                            label="Conta Bancária de origem"
                            placeholder='Conta Bancária de origem'
                            value={formik.values.contaorigem}
                            onChange={formik.handleChange}
                            error={formik.touched.contaorigem && Boolean(formik.errors.contaorigem)}
                        >

                            {contas.map((v, k) =>{
                                return (
                                  <MenuItem key={k} value={v.id}>{`${v.descricao} - ${v.banco} - ${v.agencia} - ${v.conta}`}</MenuItem>
                                )
                            })}

                        </Select>
                        <FormHelperText error>{formik.touched.contaorigem && formik.errors.contaorigem}</FormHelperText>
                        </FormControl>
                    </Grid>
                  </>

                }



                {
                  
                  formik.values.tipo === 1 &&

                  <>
                  <Grid item xs={12} md={6} >
                      <FormControl sx={{ m: 0, width: '100%' }} >
                      <InputLabel id="label-caixa">Caixa de destino</InputLabel>
                      <Select
                          labelId="label-caixadestino"
                          id="caixadestino-select"
                          name="caixadestino"
                          label="Caixa de destino"
                          placeholder='Caixa de destino'
                          value={formik.values.caixadestino}
                          onChange={formik.handleChange}
                          error={formik.touched.caixadestino && Boolean(formik.errors.caixadestino)}
                      >

                          {caixas.map((v, k) =>{
                              return (
                                <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                              )
                          })}

                      </Select>
                      <FormHelperText error>{formik.touched.caixadestino && formik.errors.caixadestino}</FormHelperText>
                      </FormControl>
                  </Grid>
                  </>
                  
                }


                {
                  
                  formik.values.tipo === 2 &&

                  <>
                  <Grid item xs={12} md={6} >
                      <FormControl sx={{ m: 0, width: '100%' }} >
                      <InputLabel id="label-conta">Conta Bancária de destino</InputLabel>
                      <Select
                          labelId="label-contadestino"
                          id="contadestino-select"
                          name="contadestino"
                          label="Conta Bancária de destino"
                          placeholder='Conta Bancária de destino'
                          value={formik.values.contadestino}
                          onChange={formik.handleChange}
                          error={formik.touched.contadestino && Boolean(formik.errors.contadestino)}
                      >

                          {contas.map((v, k) =>{
                              return (
                                <MenuItem key={k} value={v.id}>{`${v.descricao} - ${v.banco} - ${v.agencia} - ${v.conta}`}</MenuItem>
                              )
                          })}

                      </Select>
                      <FormHelperText error>{formik.touched.contadestino && formik.errors.contadestino}</FormHelperText>
                      </FormControl>
                  </Grid>
                  </>

                }

                {
                  
                  formik.values.tipo === 3 &&

                  <>
                  <Grid item xs={12} md={6} >
                      <FormControl sx={{ m: 0, width: '100%' }} >
                      <InputLabel id="label-conta">Conta Bancária de destino</InputLabel>
                      <Select
                          labelId="label-contadestino"
                          id="contadestino-select"
                          name="contadestino"
                          label="Conta Bancária de destino"
                          placeholder='Conta Bancária de destino'
                          value={formik.values.contadestino}
                          onChange={formik.handleChange}
                          error={formik.touched.contadestino && Boolean(formik.errors.contadestino)}
                      >

                          {contas.map((v, k) =>{
                              return (
                                <MenuItem key={k} value={v.id}>{`${v.descricao} - ${v.banco} - ${v.agencia} - ${v.conta}`}</MenuItem>
                              )
                          })}

                      </Select>
                      <FormHelperText error>{formik.touched.contadestino && formik.errors.contadestino}</FormHelperText>
                      </FormControl>
                  </Grid>
                  </>

                }

                {
                  
                  formik.values.tipo === 4 &&

                  <>
                  <Grid item xs={12} md={6} >
                      <FormControl sx={{ m: 0, width: '100%' }} >
                      <InputLabel id="label-caixa">Caixa de destino</InputLabel>
                      <Select
                          labelId="label-caixadestino"
                          id="caixadestino-select"
                          name="caixadestino"
                          label="Caixa de destino"
                          placeholder='Caixa de destino'
                          value={formik.values.caixadestino}
                          onChange={formik.handleChange}
                          error={formik.touched.caixadestino && Boolean(formik.errors.caixadestino)}
                      >

                          {caixas.map((v, k) =>{
                              return (
                                <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                              )
                          })}

                      </Select>
                      <FormHelperText error>{formik.touched.caixadestino && formik.errors.caixadestino}</FormHelperText>
                      </FormControl>
                  </Grid>
                  </>
                  
                }




                <Grid item xs={12} md={12} >
                    <TextField
                    fullWidth
                    id="valor"
                    name="valor"
                    label="Valor"
                    margin="none"
                    type="text"
                    value={formik.values.valor}
                    onChange={formik.handleChange}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    onKeyUp={()=>{ formik.setFieldValue('valor', formik.values.valor && mvalor(formik.values.valor)) }}
                    inputProps={{
                        maxLength: 13,
                    }}
                    />
                </Grid>
                
                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="descricao"
                        name="descricao"
                        label="Descrição"
                        margin="none"
                        type="text"
                        multiline
                        rows={3}
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                        helperText={formik.touched.descricao && formik.errors.descricao}
                        inputProps={{
                            maxLength: 200,
                        }}
                    />
                </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon=""  txt="Transferir" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}