import React, {useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Footer from '../../componentes/Footer';
import SubMenuVendas from './subMenu';
import CadastroPessoas from '../Cadastros/Pessoas/pessoas';

export default function VendasClientes() {

  


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Cadastro de clientes - Vendas - Nexcom</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>
          
          <SubMenuVendas active="clientes" />

          <div className='container' style={{ paddingTop: '15px' }}>
            <CadastroPessoas />
          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}