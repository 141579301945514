import React, {useState, useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import api from "../../../services/api";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import CadastroPessoas from './pessoas';
import CadastroUsuarios from '../Usuarios/usuarios';

export default function Pessoas({ dadosEmpresa }) {

  let IconPessoa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
      <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
        <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
        <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconModeloMensagem = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.703 26.675">
      <g id="mensagem" transform="translate(0 0)">
        <path id="Caminho_375" data-name="Caminho 375" d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0" transform="translate(0 0)" fill="#4a4a4a"/>
        <path id="Caminho_376" data-name="Caminho 376" d="M135,150h12.986v1.623H135Zm0,0" transform="translate(-127.696 -141.884)" fill="#4a4a4a"/>
        <path id="Caminho_377" data-name="Caminho 377" d="M135,210h12.986v1.623H135Zm0,0" transform="translate(-127.696 -198.637)" fill="#4a4a4a"/>
        <path id="Caminho_378" data-name="Caminho 378" d="M135,270h9.739v1.623H135Zm0,0" transform="translate(-127.696 -255.391)" fill="#4a4a4a"/>
      </g>
    </svg>

  );
  
  let IconCasa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 49.537 46.898">
      <path id="casa_1_" data-name="casa (1)" d="M38.428,60.583h-27.2a7.161,7.161,0,0,1-7.161-7.156V37.5H3.793a3.744,3.744,0,0,1-2.386-6.63L20.2,15.323a7.169,7.169,0,0,1,9.106-.013L48.219,30.86A3.744,3.744,0,0,1,45.84,37.5h-.256V53.427a7.161,7.161,0,0,1-7.156,7.156ZM4.715,33.419H6.107a2.038,2.038,0,0,1,2.038,2.038V53.427a3.084,3.084,0,0,0,3.081,3.081h27.2a3.085,3.085,0,0,0,3.081-3.081V35.457a2.037,2.037,0,0,1,2.037-2.038h1.369L26.722,18.457a3.086,3.086,0,0,0-3.92.006Z" transform="translate(-0.048 -13.685)" fill="#4a4a4a"/>
    </svg>
  );

  const [tabPage, settabPage] = useState('1');
  const [itensSubMenu, setitensSubMenu] = useState([]);

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };

  useEffect(async()=>{

    if(dadosEmpresa?.ramoAtividadeId === 3){//For Igual a Imobiliaria
      setitensSubMenu ([
        { active: "active", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
        { active: "", title: "Imóveis", rota: "/cadastros/imoveis", icon: <IconCasa/> },
        { active: "", title: "Modelos de mensagens", rota: "/cadastros/modelosmensagem", icon: <IconModeloMensagem/> },
      ])
    }else{
      setitensSubMenu ([
        { active: "active", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
        { active: "", title: "Modelos de mensagens", rota: "/cadastros/modelosmensagem", icon: <IconModeloMensagem/> },
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[dadosEmpresa]);


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcom - Cadastro Pessoas</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>
          {dadosEmpresa && <Submenu itens={itensSubMenu} />}
          
          <div className='container'>
            <TabContext value={tabPage}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTabPage} aria-label="" textColor="primary" indicatorColor="primary">
                  <Tab label="Clientes e fornecedores" value="1" />
                  <Tab label="Usuários" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <CadastroPessoas />
              </TabPanel>

              <TabPanel value="2">
                <CadastroUsuarios />
              </TabPanel>
            </TabContext>
          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}