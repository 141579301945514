import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import moment from 'moment';
import api from '../../services/api';
import { useHistory } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ButtonDefault from '../../componentes/ButtonDefault';
import ButtonSquadPrimary from '../../componentes/ButtonSquadPrimary';
import ButtonSquadSecondary from '../../componentes/ButtonSquadSecondary';
import ButtonSquadDanger from '../../componentes/ButtonSquadDanger';
import ButtonSquadGreen from '../../componentes/ButtonSquadGreen';
import InputSearchActions from '../../componentes/InputSearchActions';
import ButtonGreenDark from '../../componentes/ButtonGreenDark';

import { formataDinheiro } from '../../vendor/formatar';
import SubMenuorcamentos from './subMenu';
import Footer from '../../componentes/Footer';

import ModalNovoOrcamento from './modals/modalNovoOrcamento';
import ModalEditOrcamento from './modals/modalEditOrcamento';
import ModalEnvioOrcamentoWhatsApp from './modals/modalEnvioOrcamentoWhatsApp';

export default function Orcamentos() {

    let IconPlusBtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34">
        <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
    );

    let IconNoAuth = props =>(
    <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
        <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
        <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
    </svg>
    )

    let IconOrcamento = props=>(
      <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 66.478 67.384">
        <g id="orcamento" transform="translate(-3.429)">
          <path id="Caminho_396" data-name="Caminho 396" d="M67.72,34.584H54.584A20.8,20.8,0,0,0,53.5,29.962H67.924A1.982,1.982,0,0,0,69.905,28,27.66,27.66,0,0,0,42.236,0a1.982,1.982,0,0,0-1.982,1.982V17.113a20.805,20.805,0,0,0-4.511-.936V8.7a1.982,1.982,0,0,0-1.982-1.982,30.331,30.331,0,1,0,18.391,54.45,1.983,1.983,0,0,0,.364-2.788l-4.569-5.9a21.127,21.127,0,0,0,3.541-4.24l5.7,7.37a1.982,1.982,0,0,0,2.77.362A24.608,24.608,0,0,0,69.7,36.589,1.982,1.982,0,0,0,67.72,34.584ZM44.217,4.045A23.7,23.7,0,0,1,65.885,26H51.572a21.129,21.129,0,0,0-7.354-7.117Zm3.9,55.13A26.1,26.1,0,0,1,33.761,63.42a26.367,26.367,0,0,1-1.982-52.66v5.417a20.97,20.97,0,1,0,13.012,38.7ZM33.76,54.06A17.007,17.007,0,1,1,50.767,37.053,17.026,17.026,0,0,1,33.76,54.06Zm25.307-2.5-5.624-7.266a20.819,20.819,0,0,0,1.234-5.743H65.62a20.619,20.619,0,0,1-6.553,13.009Z" fill="#4a4a4a"/>
          <path id="Caminho_397" data-name="Caminho 397" d="M183.015,190.866h-4.256a1.841,1.841,0,0,1,0-3.681h4.105a2.772,2.772,0,0,1,1.974.9,1.982,1.982,0,0,0,2.892-2.711,6.748,6.748,0,0,0-4.861-2.15v-1.726a1.982,1.982,0,0,0-3.964,0v1.726a5.805,5.805,0,1,0-.146,11.609h4.256a1.841,1.841,0,1,1-.028,3.681h-4.447a2.8,2.8,0,0,1-1.537-.522,1.982,1.982,0,0,0-2.26,3.257,6.739,6.739,0,0,0,4.162,1.229V204.2a1.982,1.982,0,1,0,3.964,0v-1.726a5.805,5.805,0,1,0,.147-11.609Z" transform="translate(-147.126 -155.795)" fill="#4a4a4a"/>
        </g>
      </svg>
    );

    let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 115.029 153.224">
        <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z" transform="translate(-168.493 -59.168)" fill="#fff"/>
        </g>
    </svg>
    )

    let IconWhatsAppBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" viewBox="0 0 44.084 44.084">
        <g id="XMLID_468_" transform="translate(0)">
        <path id="XMLID_469_" d="M101.932,93.579c-.086-.041-3.3-1.624-3.871-1.829a2.223,2.223,0,0,0-.749-.165,1.274,1.274,0,0,0-1.082.641c-.321.477-1.293,1.613-1.593,1.953-.039.045-.093.1-.125.1s-.526-.2-.677-.27A16.912,16.912,0,0,1,87.412,88.3a.355.355,0,0,1-.054-.127,1.036,1.036,0,0,1,.188-.222c.175-.173.365-.4.548-.622.087-.1.174-.209.259-.308a3.462,3.462,0,0,0,.522-.829l.072-.145a1.5,1.5,0,0,0-.044-1.411c-.076-.152-1.433-3.427-1.577-3.771-.347-.83-.805-1.217-1.442-1.217-.059,0,0,0-.248.01a7.7,7.7,0,0,0-2.673.687A5.594,5.594,0,0,0,80.89,85.1a10.257,10.257,0,0,0,2.224,5.647c.017.022.047.067.091.132a20.936,20.936,0,0,0,8.98,7.8,14.6,14.6,0,0,0,5.424,1.387h0a8.475,8.475,0,0,0,.883-.052L98.65,100c1.075-.1,3.438-1.32,3.975-2.813a4.447,4.447,0,0,0,.253-2.928A2.029,2.029,0,0,0,101.932,93.579Z" transform="translate(-69.312 -68.248)" fill="#fff"/>
        <path id="XMLID_470_" d="M22.433,0A21.6,21.6,0,0,0,.781,21.49,21.332,21.332,0,0,0,3.749,32.357L.031,43.327a.572.572,0,0,0,.716.729l11.439-3.635a21.687,21.687,0,0,0,31.9-18.932A21.6,21.6,0,0,0,22.433,0Zm0,38.5A17.153,17.153,0,0,1,13,35.687a.573.573,0,0,0-.487-.067l-5.73,1.821,1.85-5.457a.573.573,0,0,0-.08-.522A16.78,16.78,0,0,1,5.283,21.49,17.15,17.15,0,1,1,22.433,38.5Z" transform="translate(0)" fill="#fff"/>
        </g>
    </svg>
    );


    let history = useHistory();
    let csvInstance = useRef();

    const [orcamentos, setorcamentos] = useState([]);
    const [orcamentosSelecionados, setorcamentosSelecionados] = useState([]);
    const [pageSizeDataGridorcamentos, setpageSizeDataGridorcamentos] = useState(10);
    const [loadingDataGridorcamentos, setloadingDataGridorcamentos] = useState(true);

    const [itens, setitens] = useState([]);
    const [itensSelecionados, setitensSelecionados] = useState([]);
    const [pageSizeDataGriditens, setpageSizeDataGriditens] = useState(10);
    const [loadingDataGriditens, setloadingDataGriditens] = useState(false);

    const [pagamentos, setpagamentos] = useState([]);
    const [pagamentosSelecionados, setpagamentosSelecionados] = useState([]);
    const [pageSizeDataGridPagamentos, setpageSizeDataGridPagamentos] = useState(10);
    const [loadingDataGridPagamentos, setloadingDataGridPagamentos] = useState(false);

    const [detalhesVenda, setdetalhesVenda] = useState([]);

    const [filtroTermo, setfiltroTermo] = useState();
    const [filtroStatus, setfiltroStatus] = useState('all');

    const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
    const [listagemorcamentosExport, setlistagemorcamentosExport] = useState([]);

    const [showModalNovoOrcamento, setshowModalNovoOrcamento] = useState(false);

    const [showModalEditOrcamento, setshowModalEditOrcamento] = useState(false);
    const [codigoEditOrcamento, setcodigoEditOrcamento] = useState();

    const [showModalEnvioWhatsApp, setshowModalEnvioWhatsApp] = useState(false);
  
    const getOrcamentos = async(termo='', status='',)=>{
        setloadingDataGridorcamentos(true);

        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.get(`listagemOrcamentos?termo=${termo}&status=${status}`, {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        if(response.data){
          setorcamentos(response.data);
        }else{
          setorcamentos([]);
        }
        setloadingDataGridorcamentos(false);
    }

    const permissoesUsuario = async()=>{

        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let permissoes = await api.post('permissoesUsuario', {}, 
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        });
        

        setpermissoesdoUsuario(permissoes.data.orcamentos);
    }
    
    
    useEffect(()=>{
        getOrcamentos();
        permissoesUsuario();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const columnsDataGridorcamentos = [
        { field: 'id', hide: true, headerName: 'ID', width: 90  },
        {   
          field: 'codigo',
          headerName: 'Código',
          flex: 0,
          minWidth: 130,
          editable: false,
          valueFormatter: (params) => {
              return params.value; 
          },
        },
        {   
            field: 'data',
            headerName: 'Data',
            flex: 0,
            minWidth: 150,
            editable: false,
            valueFormatter: (params) => {
                return moment(params.value).format('DD/MM/YYYY'); 
            },
        },
        {   
          field: 'usuario',
          headerName: 'Vendedor',
          flex: 1,
          minWidth: 150,
          editable: false,
          valueFormatter: (params) => {
          return params.value; 
          },
        },
        {   
            field: 'cliente',
            headerName: 'Cliente',
            flex: 1,
            minWidth: 150,
            editable: false,
            valueFormatter: (params) => {
            return params.value; 
            },
        },
        {   
            field: 'formaContato',
            headerName: 'Contato',
            flex: 1,
            minWidth: 150,
            editable: false,
            valueFormatter: (params) => {
            return params.value; 
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 120,
            editable: false,
            renderCell: (params) => { //renderiza componente
            
              switch (params.value) {
                  case 1:
                      return <span className='dotStatustb enviar'>Enviar</span>
                      break;
                  case 2:
                      return <span className='dotStatustb enviado'>Enviado</span>
                      break;
                  case 3:
                    return <span className='dotStatustb fechado'>Fechado</span>
                    break;
                  case 4:
                    return <span className='dotStatustb arquivofechado'>Arquivo Fechado</span>
                    break;
                  case 5:
                    return <span className='dotStatustb arquivoperdido'>Arquivo Perdido</span>
                    break;
                  case 6:
                    return <span className='dotStatustb cotar'>Cotar</span>
                    break;
                  case 7:
                    return <span className='dotStatustb cotado'>Cotado</span>
                    break;
                  case 8:
                    return <span className='dotStatustb encomendado'>Encomendado</span>
                    break;
                  case 9:
                    return <span className='dotStatustb encomendar'>Encomendar</span>
                    break;
              }
            },
        },
        {   
          field: 'dataRetorno',
          headerName: 'Retorno',
          flex: 0,
          minWidth: 150,
          editable: false,
          renderCell: (params) => { //renderiza componente
              
              let dataAtual = moment();
              let dataRetorno = moment(params.value);

              if(params.row.status === 4 || params.row.status === 5){
                return <p className='dotStatustb' style={{ background: '#ebebeb', color: '#000' }}>{moment(params.value).format('DD/MM/YYYY')}</p>
              }else{

                // Compare as datas usando o método `isSame`, `isBefore` e `isAfter`
                if(dataRetorno.isSame(dataAtual, 'day')){//A data de retorno é igual à data atual.
                  return <p className='dotStatustb' style={{ background: '#FFEE70', color: '#000' }}>{moment(params.value).format('DD/MM/YYYY')}</p>

                }else if(dataRetorno.isBefore(dataAtual, 'day')) {//A data de retorno é anterior à data atual.
                  return <p className='dotStatustb' style={{ background: '#F9C2C2', color: '#000' }}>{moment(params.value).format('DD/MM/YYYY')}</p>

                }else{ //A data de retorno é posterior à data atual.
                  return <p className='dotStatustb' style={{ background: '#C6FFC8', color: '#000' }}>{moment(params.value).format('DD/MM/YYYY')}</p>
                }

              }
          }
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            minWidth: 150,
            editable: false,
            valueFormatter: (params) => {
                return formataDinheiro(params.value); 
            },
        },
    
    ];

    function nenhumaVendaEncontrada(){
        return(
            <GridOverlay>
            <div className="container-no-data-grid">
                {
                permissoesdoUsuario[0]?.view ?
                    <>
                    <IconOrcamento/>
                    <p>Nenhum Orçamento encontrado</p>
                    </>
                :

                <>
                    <IconNoAuth/>
                    <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar as orcamentos de produtos!</p>
                </>

                }
            </div>
            </GridOverlay>
        )
    }




    const handleSearchOrcamento = async(event)=>{
        let termo = event.target.value;
        setfiltroTermo(termo);
    
        if(termo.length >= 3){
          getOrcamentos(termo, filtroStatus);
        }else{
          getOrcamentos('', filtroStatus);
        }
    }

    const handleFiltroStatus = async(props)=>{
        let status = props.target.value;
        setfiltroStatus(status);
        getOrcamentos(filtroTermo, status);
    }


    const getdetalhesVenda = async(vendaId)=>{

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let response = await api.post(`detalhesVenda`, {
          "vendaId": vendaId,
          "vendaCod": false
        },{
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        }); 
    
        setdetalhesVenda(response.data);
    }

    

    const handleDetalhesOrcamento = async(props)=>{
       setcodigoEditOrcamento(props.row.codigo);
       setshowModalEditOrcamento(true);
    }


    const handleDeleteOrcamentos = async()=>{
    
      Swal.fire({
        title: 'Tem certeza?',
        text: "Deseja deletar o(s) Orçamento(s) selecionado(s)?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#02A680',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, deletar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {
  
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
  
          let orcamentosid = [];
  
          for await (let id of orcamentosSelecionados) {
            let dados = { id: id }
            orcamentosid.push(dados);
          }

          await api.delete('deleteOrcamento', {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            },
            data: {  "orcamentosIds": orcamentosid }
          });
  
          getOrcamentos();
  
          Swal.fire(
            'Deletado!',
            'Orçamento(s) deletado(s) com sucesso!',
            'success'
          )
        }
      })
  
    }


    return (
        <>
        <HelmetProvider>
            <Helmet>
                <title>Orçamentos - Nexcom</title>
            </Helmet>
        </HelmetProvider>

        <CSVLink
            data={listagemorcamentosExport}
            filename={'listagem-de-orcamentos.csv'}
            ref={csvInstance}
            data-interception='off'
        />

        <ModalNovoOrcamento visible={showModalNovoOrcamento} change={setshowModalNovoOrcamento} getOrcamentos={getOrcamentos} setcodigoEditOrcamento={setcodigoEditOrcamento} setshowModalEditOrcamento={setshowModalEditOrcamento}/>
        <ModalEditOrcamento codigo={codigoEditOrcamento} visible={showModalEditOrcamento} change={setshowModalEditOrcamento} getOrcamentos={getOrcamentos} />

        <ModalEnvioOrcamentoWhatsApp orcamentosSelecionados={orcamentosSelecionados} visible={showModalEnvioWhatsApp} change={setshowModalEnvioWhatsApp} getOrcamentos={getOrcamentos}/>
        
        <div className='container-page'>

          <SubMenuorcamentos active="orcamentos" />

          <div className='container' style={{ paddingTop: '15px' }}>
            <div className='box-actions-vendas'>

                {
                    permissoesdoUsuario[2]?.insert &&
                    
                    <div>
                        <ButtonSquadPrimary icon={<IconPlusBtn/>} onClick={()=>{ setshowModalNovoOrcamento(true) }} />
                    </div>
                }


                {
                orcamentosSelecionados.length > 0 &&
                <>  
                    
                    <div style={{ 'marginLeft': '5px' }}>
                      <ButtonSquadGreen icon={<IconWhatsAppBtn/>} onClick={()=>{ setshowModalEnvioWhatsApp(true) }}/>
                    </div>

                    {/* <div style={{ 'marginLeft': '5px' }}>
                        <PDFDownloadLink document={<DocumentPDFpessoas pessoasIds={pessoasSelecionadas} />} fileName="cadastros-de-pessoas.pdf">
                            <ButtonSquadSecondary icon={<IconPDFbtn/>} />
                        </PDFDownloadLink>
                    </div> */}

                    {/* <div style={{ 'marginLeft': '5px' }}>
                        <ButtonSquadSecondary icon={<IconCSVBtn/>} onClick={()=>{ handleExportCSVProdutos() }}/> 
                    </div> */}

                    {
                        permissoesdoUsuario[1].delete &&

                        <div style={{ 'marginLeft': '5px' }}>
                            <ButtonSquadDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteOrcamentos() }} />
                        </div>
                    }

                </>
                }

                <div style={{ 'marginRight': '5px' }}>
                    <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
                        <InputLabel id="label-status">Status</InputLabel>
                        <Select
                        labelId="label-status"
                        id="demo-simple-select"
                        name="status"
                        label="Status"
                        size="small"
                        placeholder='Status'
                        value={filtroStatus}
                        onChange={handleFiltroStatus}
                        style={{ height: '43px' }}
                        >
                        <MenuItem value='all'>Todos os status</MenuItem>
                        <MenuItem value={1}>Enviar</MenuItem>
                        <MenuItem value={2}>Enviado</MenuItem>
                        <MenuItem value={3}>Fechado</MenuItem>
                        <MenuItem value={4}>Arquivo Fechado</MenuItem>
                        <MenuItem value={5}>Arquivo Perdido</MenuItem>
                        <MenuItem value={6}>Cotar</MenuItem>
                        <MenuItem value={7}>Cotado</MenuItem>
                        <MenuItem value={8}>Encomendado</MenuItem>
                        <MenuItem value={9}>Encomendar</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div style={{ 'marginLeft': '5px', 'width': '100%' }}>
                    <InputSearchActions onChange={handleSearchOrcamento}  placeholder="Procure um orçamento" />
                </div>
            </div>

            <div className='box-legendas-status'>
                <article> <span className='enviar'></span> <p>Enviar</p> </article> 
                <article> <span className='enviado'></span> <p>Enviado</p> </article> 
                <article> <span className='fechado'></span> <p>Fechado</p> </article> 
                <article> <span className='arquivofechado'></span> <p>Arquivo Fechado</p> </article> 
                <article> <span className='arquivoperdido'></span> <p>Arquivo Perdido</p> </article> 
                <article> <span className='cotar'></span> <p>Cotar</p> </article> 
                <article> <span className='cotado'></span> <p>Cotado</p> </article> 
                <article> <span className='encomendado'></span> <p>Encomendado</p> </article> 
                <article> <span className='encomendar'></span> <p>Encomendar</p> </article> 
            </div>
    
            <div className='container-data-grid'>
                <DataGrid
                    getRowId={(row) => row.id}
                    className="datatable-green-defalt"
                    sx={{ minHeight: '70vh' }}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    autoHeight
                    rows={orcamentos}
                    columns={columnsDataGridorcamentos}
                    pageSize={pageSizeDataGridorcamentos}
                    onPageSizeChange={(newPageSize) => setpageSizeDataGridorcamentos(newPageSize)}
                    rowsPerPageOptions={[10, 50, 100]} 
                    checkboxSelection
                    disableSelectionOnClick
                    editMode='row'
                    loading={loadingDataGridorcamentos}
                    disableColumnMenu={true}
                    onSelectionModelChange={(props)=>{
                        setorcamentosSelecionados(props);
                    }}
                    onCellClick={(props)=>{
                      if(props.field !== "__check__"){
                        handleDetalhesOrcamento(props);
                      }
                    }}
                    components={{
                        NoRowsOverlay: nenhumaVendaEncontrada,
                    }}
                />
            </div>

          </div>
          

          <Footer />
        </div>

        </>
    );
}