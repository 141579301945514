import React, {useState, useRef } from 'react';
import './style.css';

import api from '../../../services/api';
import axios from 'axios';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { CEPMask, mnumero, mPeso } from '../../../vendor/mascaras';

export default function ModalNovoVolumeVenda({ vendaNfeid, visible, change, refreshVolumes }) {

  const inputNumero = useRef(null);
  const [loaderPage, setloaderPage] = useState(false);
  
  const validationSchema = yup.object({
    quantidade: yup
      .string('Insira a quantidade')
      .required('Quantidade Obrigatória'),
    especie: yup
      .string('Insira a Espécie (EX: CX)')
      .required('Espécie Obrigatória (EX: CX)'),
  });

  const formik = useFormik({
    initialValues: {
      quantidade: '',
      especie: '',
      marcacao: '',
      numeracao: '',
      pesoliquido: '',
      pesobruto: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.post('addVolumeVenda', {
        "vendaNfe_id": vendaNfeid,
        "quantidade": values.quantidade,
        "especie": values.especie,
        "marcacao": values.marcacao,
        "numeracao": values.numeracao,
        "pesoliquido": values.pesoliquido,
        "pesobruto": values.pesobruto,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      formik.resetForm();
      refreshVolumes();
      change(false);
      
    }
  });



  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo Volume
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2} > 
          
            <Grid item xs={12} md={4}>
              <TextField
                  fullWidth
                  id="quantidade"
                  name="quantidade"
                  label="Quantidade"
                  margin="none"
                  type="text"
                  value={formik.values.quantidade}
                  onChange={formik.handleChange}
                  error={formik.touched.quantidade && Boolean(formik.errors.quantidade)}
                  helperText={formik.touched.quantidade && formik.errors.quantidade}
                  onKeyUp={()=>{ formik.setFieldValue('quantidade', formik.values.quantidade <= 0 ? 1 : mnumero(formik.values.quantidade)) }}
                  inputProps={{
                      maxLength: 6,
                  }}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                id="especie"
                name="especie"
                label="Espécie"
                margin="none"
                type="text"
                value={formik.values.especie}
                onChange={formik.handleChange}
                error={formik.touched.especie && Boolean(formik.errors.especie)}
                helperText={formik.touched.especie && formik.errors.especie}
              />
            </Grid>

            <Grid item xs={12} md={7}>
              <TextField
                fullWidth
                id="marcacao"
                name="marcacao"
                label="Marcação"
                margin="none"
                type="text"
                value={formik.values.marcacao}
                onChange={formik.handleChange}
                error={formik.touched.marcacao && Boolean(formik.errors.marcacao)}
                helperText={formik.touched.marcacao && formik.errors.marcacao}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                id="numeracao"
                name="numeracao"
                label="Numeração"
                margin="none"
                type="text"
                value={formik.values.numeracao}
                onChange={formik.handleChange}
                error={formik.touched.numeracao && Boolean(formik.errors.numeracao)}
                helperText={formik.touched.numeracao && formik.errors.numeracao}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
                <TextField
                    className='input'
                    fullWidth
                    id="pesoliquido"
                    name="pesoliquido"
                    label="Peso Líquido (kg)"
                    type="text"
                    value={formik.values.pesoliquido}
                    onChange={formik.handleChange}
                    error={formik.touched.pesoliquido && Boolean(formik.errors.pesoliquido)}
                    helperText={formik.touched.pesoliquido && formik.errors.pesoliquido}
                    onKeyUp={()=>{ formik.setFieldValue('pesoliquido', mPeso(formik.values.pesoliquido)) }}
                    inputProps={{
                        maxLength: 13,
                    }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    className='input'
                    fullWidth
                    id="pesobruto"
                    name="pesobruto"
                    label="Peso Bruto (kg)"
                    type="text"
                    value={formik.values.pesobruto}
                    onChange={formik.handleChange}
                    error={formik.touched.pesobruto && Boolean(formik.errors.pesobruto)}
                    helperText={formik.touched.pesobruto && formik.errors.pesobruto}
                    onKeyUp={()=>{ formik.setFieldValue('pesobruto', mPeso(formik.values.pesobruto)) }}
                    inputProps={{
                        maxLength: 13,
                    }}
                />
            </Grid>
     
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}