import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import api from '../../../services/api';

import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

import ButtonSquadPrimary from '../../../componentes/ButtonSquadPrimary';
import ButtonSquadSecondary from '../../../componentes/ButtonSquadSecondary';
import ButtonSquadDanger from '../../../componentes/ButtonSquadDanger';
import InputSearchActions from '../../../componentes/InputSearchActions';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import { formataDinheiro } from '../../../vendor/formatar';


export default function Imoveis({dadosEmpresa}) {

  let IconPessoa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
      <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
        <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
        <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconPlusBtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconPDFbtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="27.758" viewBox="0 0 24.93 27.758">
      <g id="pdf" transform="translate(-26.077)">
        <g id="Grupo_19" data-name="Grupo 19" transform="translate(28.387 25.607)">
          <g id="Grupo_18" data-name="Grupo 18">
            <path id="Caminho_62" data-name="Caminho 62" d="M68.677,472.334a3.073,3.073,0,0,0,2.914,2.15H86.074a3.072,3.072,0,0,0,2.914-2.15Z" transform="translate(-68.677 -472.334)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_21" data-name="Grupo 21" transform="translate(43.912 0.508)">
          <g id="Grupo_20" data-name="Grupo 20">
            <path id="Caminho_63" data-name="Caminho 63" d="M359.085,13.324l-3.563-3.563a3.106,3.106,0,0,0-.475-.389v4.407a.182.182,0,0,0,.182.182h4.345A3.046,3.046,0,0,0,359.085,13.324Z" transform="translate(-355.047 -9.372)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_23" data-name="Grupo 23" transform="translate(28.238)">
          <g id="Grupo_22" data-name="Grupo 22">
            <path id="Caminho_64" data-name="Caminho 64" d="M86.543,6.724H81.791a1.811,1.811,0,0,1-1.809-1.808V0H69a3.066,3.066,0,0,0-3.063,3.063v8.354H86.543V6.724Z" transform="translate(-65.935)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_25" data-name="Grupo 25" transform="translate(37.827 16.638)">
          <g id="Grupo_24" data-name="Grupo 24">
            <path id="Caminho_65" data-name="Caminho 65" d="M243.562,306.9h-.744v3.836h.744q.752,0,.752-.836v-2.165Q244.314,306.9,243.562,306.9Z" transform="translate(-242.818 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_27" data-name="Grupo 27" transform="translate(33.072 16.638)">
          <g id="Grupo_26" data-name="Grupo 26">
            <path id="Caminho_66" data-name="Caminho 66" d="M155.928,306.9H155.1v1.763h.827q.752,0,.752-.836v-.092Q156.681,306.9,155.928,306.9Z" transform="translate(-155.101 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_29" data-name="Grupo 29" transform="translate(26.077 13.044)">
          <g id="Grupo_28" data-name="Grupo 28">
            <path id="Caminho_67" data-name="Caminho 67" d="M48.952,240.6H28.132a2.057,2.057,0,0,0-2.055,2.055v6.826a2.057,2.057,0,0,0,2.055,2.055h20.82a2.057,2.057,0,0,0,2.055-2.055V242.66A2.057,2.057,0,0,0,48.952,240.6Zm-13,4.421h0a1.806,1.806,0,0,1-.564,1.467,2.248,2.248,0,0,1-1.492.472h-.827v1.805a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.316a.356.356,0,0,1,.134-.276.514.514,0,0,1,.351-.117h1.73a2.121,2.121,0,0,1,1.433.464,1.823,1.823,0,0,1,.539,1.458v.042Zm4.672,2.173a1.859,1.859,0,0,1-.56,1.5,2.232,2.232,0,0,1-1.5.476H37.092a.672.672,0,0,1-.41-.117.338.338,0,0,1-.159-.276v-5.324a.338.338,0,0,1,.159-.276.673.673,0,0,1,.41-.117h1.479a2.232,2.232,0,0,1,1.5.476,1.859,1.859,0,0,1,.56,1.5Zm4.584-3.168a.324.324,0,0,1-.288.167H42.759v1.412h1.262a.333.333,0,0,1,.288.15.615.615,0,0,1,.1.359.6.6,0,0,1-.1.343.33.33,0,0,1-.293.15H42.759v2.156a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.307a.331.331,0,0,1,.167-.3.742.742,0,0,1,.4-.1h2.9a.322.322,0,0,1,.293.167.749.749,0,0,1,.1.393A.759.759,0,0,1,45.212,244.032Z" transform="translate(-26.077 -240.605)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  );

  let IconCSVBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21"  viewBox="0 0 22.974 27.355">
      <g id="csv" transform="translate(-41)">
        <path id="Caminho_199" data-name="Caminho 199" d="M41,465.107a2.407,2.407,0,0,0,2.4,2.4H59.432a2.407,2.407,0,0,0,2.4-2.4V465H41Z" transform="translate(0 -440.156)" fill="#fff"/>
        <path id="Caminho_200" data-name="Caminho 200" d="M55.729,13.927V8.787l-5.942,5.942h5.14A.8.8,0,0,0,55.729,13.927Z" transform="translate(-8.318 -8.318)" fill="#fff"/>
        <path id="Caminho_201" data-name="Caminho 201" d="M61.837,2.4a2.407,2.407,0,0,0-2.4-2.4H49.014V5.61a2.407,2.407,0,0,1-2.4,2.4H41v2.939H61.837Z" fill="#fff"/>
        <path id="Caminho_202" data-name="Caminho 202" d="M63.172,235H41v10.685H63.172a.8.8,0,0,0,.8-.8V235.8A.8.8,0,0,0,63.172,235Zm-13.636,7.306a2.35,2.35,0,0,1-1.858.842,2.8,2.8,0,1,1,1.569-5.13.668.668,0,0,1-.748,1.107,1.469,1.469,0,1,0-.821,2.688,1.059,1.059,0,0,0,.729-.255,1.048,1.048,0,0,0,.106-.109.668.668,0,0,1,1.023.858Zm5.093-.633a1.893,1.893,0,0,1-1.927,1.475,2.667,2.667,0,0,1-1.981-.851.668.668,0,1,1,.99-.9,1.348,1.348,0,0,0,.991.413.591.591,0,0,0,.612-.374c.032-.177-.224-.338-.473-.43-.666-.245-1.257-.509-1.282-.52a.671.671,0,0,1-.093-.05,1.489,1.489,0,0,1-.664-1.474,1.633,1.633,0,0,1,1.182-1.321,2.249,2.249,0,0,1,2.449.7.668.668,0,0,1-1.108.735,1.112,1.112,0,0,0-.956-.152.309.309,0,0,0-.245.232.161.161,0,0,0,.044.143c.167.073.635.273,1.135.458A1.732,1.732,0,0,1,54.63,241.673Zm5.437-3.249-1.4,4.2a.766.766,0,0,1-.72.523h-.005a.765.765,0,0,1-.72-.508l-1.463-4.215a.668.668,0,0,1,1.262-.438l.909,2.62L58.8,238a.668.668,0,0,1,1.268.421Z" transform="translate(0 -222.445)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 115.029 153.224">
      <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z" transform="translate(-168.493 -59.168)" fill="#fff"/>
      </g>
    </svg>
  )

  let IconNoAuth = props =>(
    <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
      <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
      <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
    </svg>
  )

  let IconImovel = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 66.34 66.176">
      <g id="casa" transform="translate(0 -0.634)">
        <path id="Caminho_442" data-name="Caminho 442" d="M388.447,17.716a8.541,8.541,0,1,0-8.541-8.541A8.551,8.551,0,0,0,388.447,17.716Zm0-13.2a4.662,4.662,0,1,1-4.661,4.662,4.667,4.667,0,0,1,4.661-4.662Z" transform="translate(-330.778)" fill="#4a4a4a"/>
        <path id="Caminho_443" data-name="Caminho 443" d="M64.4,63.06H63.014V45.748a1.939,1.939,0,0,0,2.544-2.927l-5.686-5.044V27.724a1.94,1.94,0,1,0-3.88,0v6.611L45.82,25.312V15.272a14.489,14.489,0,0,0-28.979,0v4.213a62.409,62.409,0,0,0-4.348-7,1.94,1.94,0,0,0-3.12,0C9,13,.125,25.125.125,35.36A30.232,30.232,0,0,0,2.93,48.671c1.6,3.165,3.7,5.206,6.063,5.927V63.06H1.94a1.94,1.94,0,0,0,0,3.88H64.4a1.94,1.94,0,1,0,0-3.879Zm-31.042,0h-3.88V48.651h3.88ZM35.3,44.771H27.539a1.94,1.94,0,0,0-1.94,1.94V63.06H21.72V42.309l18.707-16.6,18.707,16.6V63.06h-21.9V46.711A1.94,1.94,0,0,0,35.3,44.771Zm-14.577-29.5a10.61,10.61,0,0,1,21.22,0v6.6l-.226-.2a1.94,1.94,0,0,0-2.574,0l-5.967,5.293V16.65a1.94,1.94,0,1,0-3.88,0v5.912L28,21.257a1.94,1.94,0,1,0-2.757,2.73l4.049,4.09V30.4l-6.15,5.455a13.323,13.323,0,0,1-1.491-2.313,26.73,26.73,0,0,0-.932-4.784ZM6.394,46.924A26.324,26.324,0,0,1,4,35.36c0-6.535,4.479-14.427,6.93-18.257a52.157,52.157,0,0,1,5.958,12.363,1.913,1.913,0,0,0,.068.227,20.679,20.679,0,0,1,.9,5.668,31.685,31.685,0,0,1-.489,5.618L15.3,42.821a1.937,1.937,0,0,0,.572,3.252c-.127.291-.258.576-.4.851-1.309,2.6-2.964,4.085-4.539,4.085S7.7,49.52,6.394,46.924ZM12.873,54.6A9.562,9.562,0,0,0,17.84,50.55V63.06H12.873Z" transform="translate(0 -0.13)" fill="#4a4a4a"/>
        <path id="Caminho_444" data-name="Caminho 444" d="M322.038,354.194h9.374a1.94,1.94,0,0,0,1.94-1.94v-9.374a1.94,1.94,0,0,0-1.94-1.94h-9.374a1.94,1.94,0,0,0-1.94,1.94v9.374A1.94,1.94,0,0,0,322.038,354.194Zm1.94-9.374h5.494v5.494h-5.494Z" transform="translate(-278.704 -296.299)" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconModeloMensagem = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.703 26.675">
      <g id="mensagem" transform="translate(0 0)">
        <path id="Caminho_375" data-name="Caminho 375" d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0" transform="translate(0 0)" fill="#4a4a4a"/>
        <path id="Caminho_376" data-name="Caminho 376" d="M135,150h12.986v1.623H135Zm0,0" transform="translate(-127.696 -141.884)" fill="#4a4a4a"/>
        <path id="Caminho_377" data-name="Caminho 377" d="M135,210h12.986v1.623H135Zm0,0" transform="translate(-127.696 -198.637)" fill="#4a4a4a"/>
        <path id="Caminho_378" data-name="Caminho 378" d="M135,270h9.739v1.623H135Zm0,0" transform="translate(-127.696 -255.391)" fill="#4a4a4a"/>
      </g>
    </svg>

  );

  let IconCasa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 49.537 46.898">
      <path id="casa_1_" data-name="casa (1)" d="M38.428,60.583h-27.2a7.161,7.161,0,0,1-7.161-7.156V37.5H3.793a3.744,3.744,0,0,1-2.386-6.63L20.2,15.323a7.169,7.169,0,0,1,9.106-.013L48.219,30.86A3.744,3.744,0,0,1,45.84,37.5h-.256V53.427a7.161,7.161,0,0,1-7.156,7.156ZM4.715,33.419H6.107a2.038,2.038,0,0,1,2.038,2.038V53.427a3.084,3.084,0,0,0,3.081,3.081h27.2a3.085,3.085,0,0,0,3.081-3.081V35.457a2.037,2.037,0,0,1,2.037-2.038h1.369L26.722,18.457a3.086,3.086,0,0,0-3.92.006Z" transform="translate(-0.048 -13.685)" fill="#4a4a4a"/>
    </svg>
  );
  
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [Imoveis, setImoveis] = useState([]);
  const [ImoveisSelecionados, setImoveisSelecionados] = useState([]);
  const [pageSizeDataGridImoveis, setpageSizeDataGridImoveis] = useState(10);
  const [loadingDataGridImoveis, setloadingDataGridImoveis] = useState(true);

  const [filtroTermo, setfiltroTermo] = useState();
  const [filtroStatus, setfiltroStatus] = useState('all');
  const [filtroTipo, setfiltroTipo] = useState('all');

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  const [itensSubMenu, setitensSubMenu] = useState([]);
  
  const getImoveis = async(termo='', status='all', tipo='all')=>{
    setloadingDataGridImoveis(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemImoveis?termo=${termo}&status=${status}&tipo=${tipo}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    !response.data.error_code && setImoveis(response.data);
    setloadingDataGridImoveis(false);
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.modelosMensagens);
  }



  useEffect(async()=>{
    getImoveis();
    permissoesUsuario();

    if(dadosEmpresa?.ramoAtividadeId === 3){//For Igual a Imobiliaria
      setitensSubMenu ([
        { active: "", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
        { active: "active", title: "Imóveis", rota: "/cadastros/imoveis", icon: <IconCasa/> },
        { active: "", title: "Modelos de mensagens", rota: "/cadastros/modelosmensagem", icon: <IconModeloMensagem/> },
      ])
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[dadosEmpresa]);

  const handleDetalhesImovel = async(props)=>{
    let codigo = props.row.codigo;
    history.push(`/cadastro/imovel/${codigo}`);
  }

  const handleDeleteImovel = async()=>{
    
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar os imóveis selecionados?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#02A680',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let imoveisid = [];

        for await (let id of ImoveisSelecionados) {
          let dados = { id: id }
          imoveisid.push(dados);
        }
        
        await api.delete('deleteImovel', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "imoveisIds": imoveisid }
        });

        getImoveis(filtroTermo, filtroStatus, filtroTipo);

        Swal.fire(
          'Deletado!',
          'Imóveis deletados com sucesso!',
          'success'
        )
      }
    })

  }

  const columnsDataGridImoveis = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'exibirSite',
      headerName: 'Exibir Site',
      flex: 0,
      minWidth: 20,
      editable: false,
      renderCell: (params) => { //renderiza componente
        
        switch (params.value) {
            case 0:
                    return <span>Não</span>
                break;
            case 1:
                    return <span>Sim</span>
                break;
        }
    
      },
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 0,
      minWidth: 20,
      editable: false,
      renderCell: (params) => { //renderiza componente
        
        switch (params.value) {
          case 1:
              return <span>Casa</span>;
          case 2:
              return <span>Apartamento</span>;
          case 3:
              return <span>Kitnet</span>;
          case 4:
              return <span>Terreno urbano</span>;
          case 5:
              return <span>Terreno rural</span>;
          case 6:
              return <span>Sala comercial</span>;
          case 7:
              return <span>Loja</span>;
          case 8:
              return <span>Chácara</span>;
          case 9:
              return <span>Sítio</span>;
        }
    
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0,
      minWidth: 20,
      editable: false,
      renderCell: (params) => { //renderiza componente
        
        switch (params.value) {
            case 0:
                    return <span className='dotStatus inativo'></span>
                break;
            case 1:
                    return <span className='dotStatus ativo'></span>
                break;
        }
    
      },
    },
    {
      field: 'usuario',
      headerName: 'Corretor',
      flex: 0,
      minWidth: 200,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'fornecedor',
      headerName: 'Proprietário',
      flex: 0,
      minWidth: 200,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'ruanumero',
      headerName: 'Rua',
      flex: 2,
      minWidth: 320,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'bairro',
      headerName: 'Bairro',
      flex: 0,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'cidade',
      headerName: 'Cidade',
      flex: 0,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'valorLocacao',
      headerName: 'Valor locação',
      flex: 0,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value); 
      },
    },
    {
      field: 'valorVenda',
      headerName: 'Valor venda',
      flex: 0,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value); 
      },
    }
  ];

  function nenhumImovelEncontrado(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          {
            permissoesdoUsuario[0]?.view ?
              <>
                <IconImovel/>
                <p>Nenhum Imovel encontrado</p>
              </>
            :

            <>
              <IconNoAuth/>
              <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar o cadastro de Modelos Mensagens!</p>
            </>

          }
        </div>
      </GridOverlay>
    )
  }

  const handleSearchModelosMensagens = async(event)=>{
    let termo = event.target.value;

    if(termo.length >= 3){
      setfiltroTermo(termo);
      getImoveis(termo, filtroStatus, filtroTipo);
    }else{
      getImoveis('', filtroStatus, filtroTipo);
    }
  }

  const handleFiltroStatus = async(props)=>{
    let status = props.target.value;
    setfiltroStatus(status);
    getImoveis(filtroTermo, status, filtroTipo);
  }

  const handleFiltroTipo = async(props)=>{
    let tipo = props.target.value;
    setfiltroTipo(tipo);
    getImoveis(filtroTermo, filtroStatus, tipo);
  }

 
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcom - Imoveis</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>
          {dadosEmpresa && <Submenu itens={itensSubMenu} />}
          
          <div className='container'>
            <div className='box-actions-veiculos'>

                {
                  permissoesdoUsuario[2]?.insert &&
                  
                  <ButtonSquadPrimary icon={<IconPlusBtn/>} onClick={()=>{ history.push('/cadastros/imoveis/novo') }} />
                }

                {
                ImoveisSelecionados.length > 0 &&
                <>  

                    {
                      permissoesdoUsuario[1].delete &&

                      <div style={{ 'marginLeft': '5px' }}>
                          <ButtonSquadDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteImovel() }} />
                      </div>
                    }

                </>
                }

              <div style={{ 'marginRight': '5px' }}>
                <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
                  <InputLabel id="label-status">Status</InputLabel>
                  <Select
                    labelId="label-status"
                    id="demo-simple-select"
                    name="status"
                    label="Status"
                    size="small"
                    placeholder='Status'
                    value={filtroStatus}
                    onChange={handleFiltroStatus}
                    style={{ height: '43px' }}
                  >
                    <MenuItem value='all'>Todos</MenuItem>
                    <MenuItem value={1}>Ativos</MenuItem>
                    <MenuItem value={0}>Inativos</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div style={{ 'marginRight': '5px' }}>
                <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
                  <InputLabel id="label-status">Tipo</InputLabel>
                  <Select
                    labelId="label-status"
                    id="demo-simple-select"
                    name="status"
                    label="Status"
                    size="small"
                    placeholder='Status'
                    value={filtroTipo}
                    onChange={handleFiltroTipo}
                    style={{ height: '43px' }}
                  >
                    <MenuItem value='all'>Todos</MenuItem>
                    <MenuItem value={1}>Casa</MenuItem>
                    <MenuItem value={2}>Apartamento</MenuItem>
                    <MenuItem value={3}>Kitnet</MenuItem>
                    <MenuItem value={4}>Terreno urbano</MenuItem>
                    <MenuItem value={5}>Terreno rural</MenuItem>
                    <MenuItem value={6}>Sala comercial</MenuItem>
                    <MenuItem value={7}>Loja</MenuItem>
                    <MenuItem value={8}>Chácara</MenuItem>
                    <MenuItem value={9}>Sítio</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div style={{ 'marginLeft': '5px', 'width': '100%' }}>
                  <InputSearchActions onChange={handleSearchModelosMensagens}  placeholder="Procure um Imóvel" />
              </div>
            </div>

            <div className='box-legendas-status'>
                <article> <span className='ativo'></span> <p>Ativo</p> </article> 
                <article> <span className='inativo'></span> <p>Inativo</p> </article> 
            </div>

            <div className='container-data-grid'>
                <DataGrid
                sx={{ minHeight: '400px' }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                autoHeight
                rows={Imoveis}
                columns={columnsDataGridImoveis}
                pageSize={pageSizeDataGridImoveis}
                onPageSizeChange={(newPageSize) => setpageSizeDataGridImoveis(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]} 
                checkboxSelection
                disableSelectionOnClick
                editMode='row'
                loading={loadingDataGridImoveis}
                disableColumnMenu={true}
                onSelectionModelChange={(props)=>{
                  setImoveisSelecionados(props);
                }}
                // onRowClick
                onCellClick={(props)=>{
                  if(props.field !== "__check__"){
                    handleDetalhesImovel(props)
                  }
                }}
                components={{
                    NoRowsOverlay: nenhumImovelEncontrado,
                }}
                />
            </div>

          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}