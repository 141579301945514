import React, {useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Footer from '../../componentes/Footer';
import TabOrdemServicos from './tabOS';

export default function OrdemServicos() {

  const [tabPage, settabPage] = useState('1');

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Ordem de serviços - Nexcom</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>
          
          <div className='container' style={{ paddingTop: '3px' }}>

            <TabContext value={tabPage}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabPage} onChange={handleChangeTabPage} aria-label="estoque-tabs" textColor="primary" indicatorColor="primary" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                  <Tab label="Listagem de O.S" value="1" />
                </Tabs>
              </Box>
              
              <TabPanel value="1">
                <TabOrdemServicos />
              </TabPanel>

            </TabContext>
            
          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}