import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';
import ButtonSecondaryOnlyIcon from '../../../../componentes/ButtonSecondaryOnlyIcon'; 

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ModalNovaEspecificacao from './modalNovaEspecificacao';

export default function ModalEditTipoProduto({ id, visible, change, getTipos}) {
  
  let IconServico = props=>(
    <svg xmlns="http://www.w3.org/2000/svg"  width="40" viewBox="0 0 50.702 50.702">
      <defs>
        <clipPath id="clipPath">
          <path id="path2643" d="M0-682.665H50.7v50.7H0Z" transform="translate(0 682.665)" fill="#4a4a4a"/>
        </clipPath>
      </defs>
      <g id="servico" transform="translate(0 -631.964)">
        <g id="g2637" transform="translate(0 1314.629)">
          <g id="g2639" transform="translate(0 -682.665)">
            <g id="g2641" clipPath="url(#clipPath)">
              <g id="g2647" transform="translate(39.329 0)">
                <path id="path2649" d="M-126.05-57.712a1.483,1.483,0,0,1,1.051.435l2.8,2.8a1.485,1.485,0,0,1,.138,1.942l-4.2,5.6a1.485,1.485,0,0,1-1.188.594h-4.2a1.485,1.485,0,0,1-1.485-1.485v-4.2a1.485,1.485,0,0,1,.594-1.188l5.6-4.2A1.481,1.481,0,0,1-126.05-57.712Zm.84,4.426-.979-.979-3.977,2.983v1.973h1.973Z" transform="translate(133.137 57.712)" fill="#4a4a4a"/>
              </g>
              <g id="g2651" transform="translate(0 28.697)">
                <path id="path2653" d="M-155.546-31.716a4.427,4.427,0,0,1,3.151,1.305l2.8,2.8a4.462,4.462,0,0,1,0,6.3l-10.292,10.292a4.427,4.427,0,0,1-3.151,1.305,4.427,4.427,0,0,1-3.151-1.305l-2.8-2.8a4.427,4.427,0,0,1-1.305-3.151,4.427,4.427,0,0,1,1.305-3.151L-158.7-30.411A4.427,4.427,0,0,1-155.546-31.716Zm-7.491,19.035a1.476,1.476,0,0,0,1.05-.435l10.292-10.292a1.487,1.487,0,0,0,0-2.1l-2.8-2.8a1.476,1.476,0,0,0-1.05-.435,1.476,1.476,0,0,0-1.05.435l-10.292,10.292a1.476,1.476,0,0,0-.435,1.05,1.475,1.475,0,0,0,.435,1.05l2.8,2.8A1.476,1.476,0,0,0-163.037-12.681Z" transform="translate(170.294 31.716)" fill="#4a4a4a"/>
              </g>
              <g id="g2655" transform="translate(0.002 0)">
                <path id="path2657" d="M-610.639-543.691a10.31,10.31,0,0,1-7.339-3.039,10.328,10.328,0,0,1-2.756-9.758l-17.407-17.407a10.443,10.443,0,0,1-2.418.283,10.31,10.31,0,0,1-7.339-3.038,10.413,10.413,0,0,1-2.025-11.827,1.485,1.485,0,0,1,1.089-.824,1.485,1.485,0,0,1,1.3.414l4.885,4.885,2.1-2.1-4.885-4.885a1.485,1.485,0,0,1-.414-1.3,1.485,1.485,0,0,1,.824-1.089,10.488,10.488,0,0,1,4.488-1.014,10.31,10.31,0,0,1,7.339,3.039,10.33,10.33,0,0,1,2.756,9.758l17.407,17.407a10.443,10.443,0,0,1,2.418-.283,10.31,10.31,0,0,1,7.339,3.038,10.413,10.413,0,0,1,2.025,11.827,1.486,1.486,0,0,1-1.089.824,1.485,1.485,0,0,1-1.3-.414l-4.885-4.885-2.1,2.1,4.885,4.885a1.485,1.485,0,0,1,.414,1.3,1.485,1.485,0,0,1-.824,1.089A10.487,10.487,0,0,1-610.639-543.691Zm-27.068-33.356a1.485,1.485,0,0,1,1.051.435l18.641,18.641a1.485,1.485,0,0,1,.358,1.523,7.387,7.387,0,0,0,1.78,7.618,7.359,7.359,0,0,0,5.238,2.169,7.445,7.445,0,0,0,1.056-.076l-4.194-4.194a1.485,1.485,0,0,1-.435-1.05,1.485,1.485,0,0,1,.435-1.05l4.2-4.2a1.485,1.485,0,0,1,2.1,0l4.194,4.194a7.431,7.431,0,0,0-2.094-6.3,7.359,7.359,0,0,0-5.239-2.168,7.469,7.469,0,0,0-2.379.388,1.485,1.485,0,0,1-1.523-.358l-18.641-18.641a1.485,1.485,0,0,1-.358-1.523,7.387,7.387,0,0,0-1.78-7.618,7.359,7.359,0,0,0-5.238-2.169,7.445,7.445,0,0,0-1.056.076l4.194,4.194a1.485,1.485,0,0,1,0,2.1l-4.2,4.2a1.485,1.485,0,0,1-2.1,0l-4.194-4.194a7.431,7.431,0,0,0,2.094,6.3,7.359,7.359,0,0,0,5.239,2.168,7.469,7.469,0,0,0,2.379-.388A1.484,1.484,0,0,1-637.707-577.047Z" transform="translate(650.934 594.393)" fill="#4a4a4a"/>
              </g>
              <g id="g2659" transform="translate(16.764 26.667)">
                <path id="path2661" d="M-76.416-12.729a1.481,1.481,0,0,1-1.05-.435,1.485,1.485,0,0,1,0-2.1l4.3-4.3a1.485,1.485,0,0,1,2.1,0,1.485,1.485,0,0,1,0,2.1l-4.3,4.3A1.481,1.481,0,0,1-76.416-12.729Z" transform="translate(77.901 20)" fill="#4a4a4a"/>
              </g>
              <g id="g2663" transform="translate(26.667 8.601)">
                <path id="path2665" d="M-18.515-172.382a1.481,1.481,0,0,1-1.05-.435,1.485,1.485,0,0,1,0-2.1L-7.1-187.382a1.485,1.485,0,0,1,2.1,0,1.485,1.485,0,0,1,0,2.1l-12.464,12.464A1.481,1.481,0,0,1-18.515-172.382Z" transform="translate(20 187.817)" fill="#4a4a4a"/>
              </g>
              <g id="g2667" transform="translate(0 37.758)">
                <path id="path2669" d="M-62.171-129.621a4.427,4.427,0,0,1-3.151-1.305l-2.8-2.8a4.427,4.427,0,0,1-1.305-3.151,4.427,4.427,0,0,1,1.305-3.151l2.1-2.1a1.485,1.485,0,0,1,2.1,0l7,7a1.485,1.485,0,0,1,.435,1.05,1.485,1.485,0,0,1-.435,1.05l-2.1,2.1A4.427,4.427,0,0,1-62.171-129.621Zm-2.8-9.358-1.05,1.05a1.476,1.476,0,0,0-.435,1.05,1.476,1.476,0,0,0,.435,1.05l2.8,2.8a1.476,1.476,0,0,0,1.05.435,1.476,1.476,0,0,0,1.05-.435l1.05-1.05Z" transform="translate(69.428 142.565)" fill="#4a4a4a"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  );

  let IconCancel = props =>(
    <svg id="close" xmlns="http://www.w3.org/2000/svg" width="17.001" height="17" viewBox="0 0 17.001 17">
        <path id="Caminho_94" data-name="Caminho 94" d="M16.565,14.12,3.242.8a1.487,1.487,0,0,0-2.1,0l-.7.7a1.487,1.487,0,0,0,0,2.1L13.76,16.924a1.487,1.487,0,0,0,2.1,0l.7-.7a1.486,1.486,0,0,0,0-2.1Zm0,0" transform="translate(0 -0.36)" fill="#f44336"/>
        <path id="Caminho_95" data-name="Caminho 95" d="M13.76.8.436,14.121a1.487,1.487,0,0,0,0,2.1l.7.7a1.487,1.487,0,0,0,2.1,0L16.565,3.6a1.486,1.486,0,0,0,0-2.1l-.7-.7a1.487,1.487,0,0,0-2.1,0Zm0,0" transform="translate(0 -0.362)" fill="#f44336"/>
    </svg>
  );

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let history = useHistory();

  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [especificacoes, setespecificacoes] = useState([]);
  const [showModalNovaEspecificacao, setshowModalNovaEspecificacao] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };

  
  const validationSchema = yup.object({
    nome: yup
        .string('Insira o nome do tipo')
        .required('Nome Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
        nome: '',
        status: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('updateTipoProduto', {
        "id": id,
        "nome": values.nome,
        "status": values.status,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      getTipos();

      setalertSucess(true);        
      setalertSucessMsg(`Tipo Atualizado com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);

    }
  });


  const detalhesTipoProduto = async()=>{
    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);


    id && api.post('detalhesTipoProduto', {
        "id": id
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    }).then( async response => {
        
        let data = response.data;

        formik.setFieldValue('nome', data.tipo);
        formik.setFieldValue('status', data.status);

        await getEspecificacoes(id);

    }).catch((error) => {
        change(false)
    });

    setloaderPage(false);
    
  }

  const getEspecificacoes = async(id)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('listagemEspecificacoesTipoProduto', {
        "tipoId": id
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    response = response.data;
    setespecificacoes(response);
    
  }

  useEffect(async()=>{
    await detalhesTipoProduto();
    

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[visible]);


  const handlePrincipalEspecificacao = async(event, idEspecificacao)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.put('definirPrincipalEspecificacao', {
      "tipoId": id,
      "idEspecificacao": idEspecificacao,
      "principal": event.target.checked
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    await getEspecificacoes(id);

  }

  
  const handleDeleteEspecificacao = async(especificacaoId)=>{
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar a Especificação?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#02A680',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {
        
        setloaderPage(true);

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
        
        await api.delete('deleteEspecificacao', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "especificacoesIds": [{ id: especificacaoId }] }
        });
        
        await getEspecificacoes(id);
        setloaderPage(false);

        Swal.fire(
          'Deletado!',
          'Especificação deletada com sucesso!',
          'success'
        )

      }
    })
  }


  const handleDragEspecificacao = async(result)=>{
    
    const items = Array.from(especificacoes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    let dadosArray = [];

    for await (let [key, item] of  Object.entries(items)) {
      let dados = {
          id: item.id,
          ordem: parseInt(key)
      }

      dadosArray.push(dados);
    }

    setespecificacoes(items);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.put(`updateOrdemEspecificacoes`,
    {
        "tipoId": id,
        "dados": dadosArray
    },
    {
      headers: {
      "Content-type": "application/json",
      "Authorization": `Bearer ${auth.token}`
      }
    })

  }
  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
      <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
      </Alert>
    </Snackbar>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
      <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
      </Alert>
    </Snackbar>

    <ModalNovaEspecificacao visible={showModalNovaEspecificacao} change={setshowModalNovaEspecificacao} getEspecificacoes={getEspecificacoes} tipoId={id} />

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Detalhes Tipo de produto
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }} dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={1} >
            
            <Grid item xs={12} md={4} >
                  <FormControl sx={{ width: '100%', marginTop: '16px' }} >
                    <InputLabel id="label-totalparcelas">Status</InputLabel>
                    <Select
                      labelId="label-status"
                      id="cartao-simple-status"
                      name="status"
                      label="Status"
                      placeholder='Status'
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={formik.touched.status && Boolean(formik.errors.status)}
                    >
                      
                      <MenuItem value={1}>Ativo</MenuItem>
                      <MenuItem value={0}>Inativo</MenuItem>
                    
                    </Select>
                    <FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
                  </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  id="nome"
                  name="nome"
                  label="Nome do tipo"
                  margin="normal"
                  type="text"
                  value={formik.values.nome}
                  onChange={formik.handleChange}
                  error={formik.touched.nome && Boolean(formik.errors.nome)}
                  helperText={formik.touched.nome && formik.errors.nome}
                />
            </Grid>

            <Grid item xs={12} md={12}>
              
              <DragDropContext onDragEnd={handleDragEspecificacao}>
                  <Droppable droppableId="droppable" direction="vertical">
                  {(provided) => (
                      <>
                        <Grid item container xs={12} md={12}
                          style={{
                            backgroundColor: '#005440',
                            color: '#fff',
                            padding: '10px',
                            fontSize: '14px'
                          }}
                         >
                          <Grid item xs={4} md={4}>Principal</Grid>
                          <Grid item xs={6} md={6}>Especificação</Grid>
                          <Grid item xs={2} md={2} style={{ textAlign: 'right' }}><div style={{ marginRight: '10px' }}>Ação</div></Grid>
                        </Grid>

                        <Grid item container xs={12} md={12} {...provided.droppableProps} ref={provided.innerRef} id={id}>
                          {especificacoes.map((v, k) =>{
                            return (
                              <Draggable key={v.id} draggableId={v.id.toString()} index={k}>
                                {(provided) => (
                                  <Grid item container xs={12} md={12} style={{ width: '100%' }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <Grid item xs={4} md={4} style={{padding: '10px', borderTop: '1px solid #EBEBEB'}}>
                                      <Switch
                                        // disabled={!permissoesdoUsuario[3]?.update}
                                        checked={v.principal ? true : false}
                                        onChange={(event)=>{ handlePrincipalEspecificacao(event, v.id) }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      />
                                    </Grid>
                                    <Grid item xs={6} md={6} style={{padding: '10px', borderTop: '1px solid #EBEBEB'}}>{v.nome}</Grid>
                                    <Grid item xs={2} md={2} style={{ borderTop: '1px solid #EBEBEB', textAlign: 'right', padding: '10px' }}>
                                      <div style={{ marginRight: '20px', cursor: 'pointer' }} onClick={()=>{ handleDeleteEspecificacao(v.id) }}><IconCancel /></div>
                                    </Grid>
                                  </Grid>
                                )}
                              </Draggable>
                            )
                          })}
                        </Grid>

                        {provided.placeholder}
                      </>
                  )}
                  </Droppable>
              </DragDropContext>
          
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between" spacing={2} style={{ paddingInline: '18px' }}>
            
            <Grid item xs={4} md={3}>
              <div style={{ width: '30px' }}>
                <ButtonSecondaryOnlyIcon type="button" icon={<IconPlus/>} onClick={()=>{ setshowModalNovaEspecificacao(true) }} />
              </div>
            </Grid>
            
            <Grid item xs={4} md={3}>
              <ButtonDefault type="submit" icon="" txt="Salvar" />
            </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}