import React, { useEffect, useState, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";
import './style.css';
import moment from 'moment';


import { useFormik } from 'formik';
import * as yup from 'yup';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import ButtonCirclePrimary from '../../../componentes/ButtonCirclePrimary';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

import api from '../../../services/api';
import axios from 'axios';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Skeleton from '@mui/material/Skeleton';

import { mArea, mnumero, CEPMask, mvalor, mMedida, mporcentagem } from '../../../vendor/mascaras';
import { formataDinheiroforBanco, formataDinheiro } from '../../../vendor/formatar';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Swal from 'sweetalert2';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Fancybox from '../../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react

import LinearProgress from '@mui/material/LinearProgress';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FsLightbox from 'fslightbox-react';

import ModalNovaPessoa from '../Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../Pessoas/modals/modalDetalhesPessoa';

import AnexosImovelVenda from './anexosImovelVenda';
import AnexosImovelLocacao from './anexosImovelLocacao';

import MultSelectCaracteristicasImovel from '../../../componentes/MultSelectCaracteristicasImovel';

export default function ImovelDetalhes(props) {

    let IconPessoa = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
            <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
            <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
            <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
            </g>
        </svg>
    );

    let IconSave = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 24.15 24.15">
        <path id="floppy-disk" d="M22.97,3.037,21.113,1.179A4.02,4.02,0,0,0,18.267,0H2.767A2.767,2.767,0,0,0,0,2.767V21.383A2.767,2.767,0,0,0,2.767,24.15H21.383a2.767,2.767,0,0,0,2.767-2.767V5.882A4.02,4.02,0,0,0,22.97,3.037ZM4.025,6.289V3.773a.755.755,0,0,1,.755-.755H15.345a.755.755,0,0,1,.755.755V6.289a.755.755,0,0,1-.755.755H4.78A.755.755,0,0,1,4.025,6.289Zm8.05,13.836A4.528,4.528,0,1,1,16.6,15.6,4.529,4.529,0,0,1,12.075,20.125Zm0,0" transform="translate(0)" fill="#fff"/>
      </svg>
    )

    let IconZoom = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 12.515 12.516">
        <g id="icon" transform="translate(-5.267 -5.266)">
          <path id="XMLID_231_" d="M17.782,5.835V8.68a.569.569,0,1,1-1.137,0V7.209l-3.3,3.308a.569.569,0,0,1-.8-.8L15.841,6.4h-1.47a.569.569,0,0,1,0-1.138h2.843a.569.569,0,0,1,.569.569ZM17.214,13.8a.569.569,0,0,0-.569.569v1.47L13.4,12.616a.588.588,0,0,0-.82,0,.569.569,0,0,0-.008.8l3.217,3.223H14.311a.569.569,0,1,0,0,1.138h2.842a.642.642,0,0,0,.433-.167.6.6,0,0,0,.2-.4V14.368a.569.569,0,0,0-.569-.569Zm-7.56-1.207L6.4,15.838V14.368a.564.564,0,0,0-.564-.568h0a.568.568,0,0,0-.568.568v2.845a.569.569,0,0,0,.569.569H8.688a.569.569,0,0,0,0-1.138H7.218L10.464,13.4a.571.571,0,0,0-.81-.8ZM7.208,6.455h1.47a.569.569,0,0,0,0-1.138H5.836a.568.568,0,0,0-.569.569V8.731a.569.569,0,1,0,1.137,0V7.261l3.279,3.282a.569.569,0,1,0,.8-.8Z" fill="#fff"/>
        </g>
      </svg>
    )

    let IconCloseTimes = props =>(
      <svg id="close" xmlns="http://www.w3.org/2000/svg" width="13.663" height="13.506" viewBox="0 0 13.663 13.506">
        <g id="close_1_" transform="translate(0 0)">
          <path id="Caminho_85" data-name="Caminho 85" d="M1.3,15.943a1.294,1.294,0,0,1-.919-.311,1.294,1.294,0,0,1,0-1.825L11.365,2.82a1.294,1.294,0,0,1,1.89,1.76L2.2,15.633a1.294,1.294,0,0,1-.906.311Z" transform="translate(-0.001 -2.444)" fill="#fff"/>
          <path id="Caminho_86" data-name="Caminho 86" d="M13.733,15.343a1.294,1.294,0,0,1-.906-.375L1.839,3.98A1.294,1.294,0,0,1,3.664,2.155L14.717,13.143a1.294,1.294,0,0,1,.06,1.829q-.029.031-.06.06a1.294,1.294,0,0,1-.984.311Z" transform="translate(-1.463 -1.844)" fill="#fff"/>
        </g>
      </svg>
    )

    let IconUpload = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="30"viewBox="0 0 38.069 31.819">
        <g id="upload" transform="translate(-0.997 -4.993)">
          <path id="Caminho_87" data-name="Caminho 87" d="M29.948,12.692a10.167,10.167,0,0,0-19.722-.011,10.179,10.179,0,0,0,.954,20.314H15a1.272,1.272,0,1,0,0-2.545H11.18a7.634,7.634,0,0,1-.045-15.269,1.321,1.321,0,0,0,1.4-1.087,7.623,7.623,0,0,1,15.093,0,1.374,1.374,0,0,0,1.365,1.087,7.634,7.634,0,1,1,0,15.269H25.176a1.272,1.272,0,0,0,0,2.545h3.817a10.179,10.179,0,0,0,.954-20.3Z" transform="translate(0)" fill="#fff"/>
          <path id="Caminho_88" data-name="Caminho 88" d="M23.1,21.534a1.272,1.272,0,1,0,1.8-1.8l-6.362-6.362a1.272,1.272,0,0,0-1.8,0l-6.362,6.362a1.272,1.272,0,1,0,1.8,1.8l4.19-4.19V33.358a1.272,1.272,0,0,0,2.545,0V17.344Z" transform="translate(2.452 2.181)" fill="#fff"/>
        </g>
      </svg>

    )

    let IconModeloMensagem = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.703 26.675">
        <g id="mensagem" transform="translate(0 0)">
          <path id="Caminho_375" data-name="Caminho 375" d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0" transform="translate(0 0)" fill="#4a4a4a"/>
          <path id="Caminho_376" data-name="Caminho 376" d="M135,150h12.986v1.623H135Zm0,0" transform="translate(-127.696 -141.884)" fill="#4a4a4a"/>
          <path id="Caminho_377" data-name="Caminho 377" d="M135,210h12.986v1.623H135Zm0,0" transform="translate(-127.696 -198.637)" fill="#4a4a4a"/>
          <path id="Caminho_378" data-name="Caminho 378" d="M135,270h9.739v1.623H135Zm0,0" transform="translate(-127.696 -255.391)" fill="#4a4a4a"/>
        </g>
      </svg>
    );

    let IconCasa = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 49.537 46.898">
          <path id="casa_1_" data-name="casa (1)" d="M38.428,60.583h-27.2a7.161,7.161,0,0,1-7.161-7.156V37.5H3.793a3.744,3.744,0,0,1-2.386-6.63L20.2,15.323a7.169,7.169,0,0,1,9.106-.013L48.219,30.86A3.744,3.744,0,0,1,45.84,37.5h-.256V53.427a7.161,7.161,0,0,1-7.156,7.156ZM4.715,33.419H6.107a2.038,2.038,0,0,1,2.038,2.038V53.427a3.084,3.084,0,0,0,3.081,3.081h27.2a3.085,3.085,0,0,0,3.081-3.081V35.457a2.037,2.037,0,0,1,2.037-2.038h1.369L26.722,18.457a3.086,3.086,0,0,0-3.92.006Z" transform="translate(-0.048 -13.685)" fill="#4a4a4a"/>
        </svg>
    );

    let IconPlus = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
          <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
        </svg>
    );
    
    let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
    );

    let { codigo } = useParams();
    let history = useHistory();
    const inputNumero = useRef(null);

    const [loaderPage, setloaderPage] = useState(false);     
    const [loaderskeleton, setloaderskeleton] = useState(false);

    const [tabPage, settabPage] = useState('1');
    const [dadosImovel, setdadosImovel] = useState([]);
    const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

    const [alertError, setalertError] = useState(false);
    const [alertErrorMsg, setalertErrorMsg] = useState('');

    const [alertSucess, setalertSucess] = useState(false);
    const [alertSucessMsg, setalertSucessMsg] = useState('');

    const [fotosDoImovel, setfotosDoImovel] = useState([]);

    const [progressUploadFotos, setprogressUploadFotos] = useState(0);
    const [itensSubMenu, setitensSubMenu] = useState([]);

    const [pessoas, setpessoas] = useState([]);
    const [usuarios, setusuarios] = useState([]);
    const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
    const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
    const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();

    const [caracteristicas, setcaracteristicas] = useState([]);
    const [caracteristicasdoimovel, setcaracteristicasdoimovel] = useState([]);
    
    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlertError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertError(false);
    };

    const handleCloseAlertSuccess = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertSucess(false);
    };

    const getfotosDoImovel = async(imovelid)=>{
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let fotos = await api.post(`listagemFotosImovel`,
      {
          "id": imovelid
      },
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });
      

      let fotosGaleria = [];

      for await (let foto of fotos.data) {
        fotosGaleria.push(foto.url)
      }
      
      setfotosDoImovel(fotos.data);

    }

    const getCaracteristicas = async()=>{
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let caracteristicas = await api.get(`listagemCaracteristicasimob`,
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });

      setcaracteristicas(caracteristicas.data);

    }

    const detalhesImovel = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        codigo === 'undefined' && history.push("/cadastros/imoveis");
        setloaderskeleton(true);
        
        api.post('detalhesImovel', {
            "codigo": codigo
        }, 
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        }).then( async response => {
            
            let data = response.data;
            
            await data.tituloSite !== null && formik.setFieldValue('titulosite', data.tituloSite);
            await data.status !== null && formik.setFieldValue('status', data.status);
            await data.tipo !== null && formik.setFieldValue('tipo', data.tipo);
            await data.estadoImovel && formik.setFieldValue('estadodoimovel', data.estadoImovel);
            await data.exibirSite !== null && formik.setFieldValue('exibirsite', data.exibirSite);
            await data.destacarSite !== null && formik.setFieldValue('destacarsite', data.destacarSite);
            await data.placas !== null && formik.setFieldValue('placas', data.placas);

            await data.chaves !== null && formik.setFieldValue('chaves', data.chaves);
            await data.chavesDescricao !== null && formik.setFieldValue('chavesdescricao', data.chavesDescricao);
            await data.dormitorios !== null && formik.setFieldValue('dormitorios', data.dormitorios);
            await data.suites !== null && formik.setFieldValue('suites', data.suites);
            await data.banheiros !== null && formik.setFieldValue('banheiros', data.banheiros);
            await data.vagas !== null && formik.setFieldValue('vagas', data.vagas);
            await data.areaConstruida && formik.setFieldValue('areaconstruida', mArea(data.areaConstruida));
            await data.areaTotal && formik.setFieldValue('areatotal', mArea(data.areaTotal));
            await data.valorCondominio && formik.setFieldValue('valorcondominio', formataDinheiro(data.valorCondominio));
            await data.descricao && formik.setFieldValue('descricao', data.descricao);
            
            await data.cep !== null && formik.setFieldValue('cep', data.cep);
            await data.rua !== null && formik.setFieldValue('rua', data.rua);
            await data.numero !== null && formik.setFieldValue('numero', data.numero);
            await data.bairro !== null && formik.setFieldValue('bairro', data.bairro);
            await data.cidade !== null && formik.setFieldValue('cidade', data.cidade);
            await data.estado !== null && formik.setFieldValue('estado', data.estado);
            await data.complemento !== null && formik.setFieldValue('complemento', data.complemento);
            await data.referencia !== null && formik.setFieldValue('referencia', data.referencia);

            await data.areaAverbacao !== null && formik.setFieldValue('areaaverbacao', data.areaAverbacao);
            await data.tipoTerreno !== null && formik.setFieldValue('tipoterreno', data.tipoTerreno);
            await data.frente !== null && formik.setFieldValue('frente', mMedida(data.frente));
            await data.fundos !== null && formik.setFieldValue('fundos', mMedida(data.fundos));
            await data.lateralEsquerda !== null && formik.setFieldValue('lateralesquerda', mMedida(data.lateralEsquerda));
            await data.lateralDireita !== null && formik.setFieldValue('lateraldireita', mMedida(data.lateralDireita));
            await data.lote !== null && formik.setFieldValue('lote', data.lote);
            await data.quadra !== null && formik.setFieldValue('quadra', data.quadra);
            await data.numeroMatricula !== null && formik.setFieldValue('numeromatricula', data.numeroMatricula);
            await data.tipoCasa !== null && formik.setFieldValue('tipocasa', data.tipoCasa);
            await data.ocupaInquilino !== null && formik.setFieldValue('ocupainquilino', data.ocupaInquilino);
            await data.escritura !== null && formik.setFieldValue('escritura', data.escritura);
            await data.contratoGavetaAverbada !== null && formik.setFieldValue('contratogavetaaverbada', data.contratoGavetaAverbada);
            await data.inventario !== null && formik.setFieldValue('inventario', data.inventario);
            await data.numInventariantes !== null && formik.setFieldValue('numinventariantes', data.numInventariantes);
            await data.tempoParaTerminar !== null && formik.setFieldValue('tempoparaterminar', data.tempoParaTerminar);
            await data.comissaoVenda !== null && formik.setFieldValue('comissaovenda', mporcentagem(data.comissaoVenda));
            await data.valorVenda !== null && formik.setFieldValue('valorvenda', formataDinheiro(data.valorVenda));
            await data.valormaoproprietario !== null && formik.setFieldValue('valormaoproprietario', formataDinheiro(data.valormaoproprietario));
            await data.aceitaPropostaVenda !== null && formik.setFieldValue('aceitapropostavenda', data.aceitaPropostaVenda);
            await data.obsVenda !== null && formik.setFieldValue('obsvenda', data.obsVenda);

            await data.valorLocacao !== null && formik.setFieldValue('valorlocacao', formataDinheiro(data.valorLocacao));
            await data.valorIPTU !== null && formik.setFieldValue('valoriptu', formataDinheiro(data.valorIPTU));
            await data.caucao !== null && formik.setFieldValue('caucao', data.caucao);
            await data.fiador !== null && formik.setFieldValue('fiador', data.fiador);
            await data.seguroFianca !== null && formik.setFieldValue('segurofianca', data.seguroFianca);
            await data.primeiroAluguel !== null && formik.setFieldValue('primeiroaluguel', mporcentagem(data.primeiroAluguel));
            await data.administracaoMensal !== null && formik.setFieldValue('administracaomensal', mporcentagem(data.administracaoMensal));
            await data.obsLocacao !== null && formik.setFieldValue('obslocacao', data.obsLocacao);


            await getfotosDoImovel(data.id);
            await getPessoas(data.fornecedorId, null);
            await getUsuarios(data.usuarioId, null);
            await getCaracteristicasdoImovel(data.id);
            setdadosImovel(data);
            setloaderskeleton(false);

            
        }).catch((error) => {
            history.push("/cadastros/imoveis");
        });
            
    }

    const permissoesUsuario = async()=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let permissoes = await api.post('permissoesUsuario', {}, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });
      setpermissoesdoUsuario(permissoes.data.produtos);
    }

    const getPessoas = async(fornecedorId=null, codigoFornecedor=null)=>{

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let response = await api.get(`listagemPessoas`, {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });
    
        let arrayPessoas = [];
        
        for await (let pessoa of response.data) {
            let dados = {
                codigo: pessoa.codigo,
                nome: pessoa.nome,
                id: pessoa.id
            }    
            
            arrayPessoas.push(dados);
        }
    
        setpessoas(arrayPessoas);
    
    
        if(codigoFornecedor){
          //populando fornecedor no select
          let fornecedorcode = arrayPessoas.find(x => x.codigo === codigoFornecedor);
          fornecedorcode && formik.setFieldValue('fornecedor', JSON.stringify(fornecedorcode));
          setcodigoDetalhesPessoa(codigoFornecedor);
          setshowModalDetalhesPessoa(true);
    
        }else if(fornecedorId){
          //populando fornecedor no select
          let fornecedor = arrayPessoas.find(x => x.id === fornecedorId);
          fornecedor && formik.setFieldValue('fornecedor', JSON.stringify(fornecedor));
          setcodigoDetalhesPessoa(fornecedor.codigo);
        }
    
    }

    const getUsuarios = async(usuarioId=null, codigoUsuario=null)=>{

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let response = await api.get(`listagemUsuarios`, {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });
  
      let arrayUsuarios = [];
      
      for await (let usuario of response.data) {
          let dados = {
              codigo: usuario.codigo,
              nome: usuario.nome,
              id: usuario.id
          }    
          
          arrayUsuarios.push(dados);
      }
  
      setusuarios(arrayUsuarios);
  
  
      if(codigoUsuario){
        //populando usuario no select
        let usuariocode = arrayUsuarios.find(x => x.codigo === codigoUsuario);
        usuariocode && formik.setFieldValue('usuario', JSON.stringify(usuariocode));
  
      }else if(usuarioId){
        //populando usuario no select
        let usuario = arrayUsuarios.find(x => x.id === usuarioId);
        usuario && formik.setFieldValue('usuario', JSON.stringify(usuario));
      }
  
    }

    
    const validationSchema = yup.object({
      // fornecedor: yup
      //   .number('Selecione o Proprietário')
      //   .required('Proprietário Obrigatório'),

     
    });

    const formik = useFormik({
      initialValues: {

        fornecedor: 'null',
        usuario: 'null',

        cep: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        complemento: '',
        referencia: '',

        titulosite: '',
        status: '',
        tipo: '',
        exibirsite: '',
        destacarsite: '',
        estadodoimovel: '',
        placas: '',
        chaves: '',
        chavesdescricao: '',
        dormitorios: '',
        suites: '',
        banheiros: '',
        vagas: '',
        areaconstruida: '',
        areatotal: '',
        valorcondominio: '',
        descricao: '',

        areaaverbacao: '',
        tipoterreno: '',
        frente: '',
        fundos: '',
        lateralesquerda: '',
        lateraldireita: '',
        lote: '',
        quadra: '',
        numeromatricula: '',
        tipocasa: '',
        ocupainquilino: '',
        escritura: '',
        contratogavetaaverbada: '',
        inventario: '',
        numinventariantes: '',
        tempoparaterminar: '',
        comissaovenda: '',
        valorvenda: '',
        valormaoproprietario: '',
        aceitapropostavenda: '',
        obsvenda: '',

        valorlocacao: '',
        valoriptu: '',
        caucao: '',
        fiador: '',
        segurofianca: '',
        primeiroaluguel: '',
        administracaomensal: '',
        obslocacao: '',

      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
      
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let fornecedor = JSON.parse(values.fornecedor);
        let usuario = JSON.parse(values.usuario);

        let valorcondominio = values.valorcondominio ? formataDinheiroforBanco(values.valorcondominio) : 0;
        let valorvenda = values.valorvenda ? formataDinheiroforBanco(values.valorvenda) : 0;
        let valormaoproprietario = values.valormaoproprietario ? formataDinheiroforBanco(values.valormaoproprietario) : 0;
        let valorlocacao = values.valorlocacao ? formataDinheiroforBanco(values.valorlocacao) : 0;
        let valoriptu = values.valoriptu ? formataDinheiroforBanco(values.valoriptu) : 0;
        
        
        await api.put(`updateImovel`, {
            "id": dadosImovel.id,
            "fornecedor": fornecedor.id,
            "usuario": usuario?.id ? usuario?.id : null,
            
            "cep": values.cep,
            "rua": values.rua,
            "numero": values.numero,
            "bairro": values.bairro,
            "cidade": values.cidade,
            "estado": values.estado,
            "complemento": values.complemento,
            "referencia": values.referencia,

            "titulosite": values.titulosite,
            "status": values.status,
            "tipo": values.tipo,
            "exibirsite": values.exibirsite, 
            "destacarsite": values.destacarsite, 
            "estadodoimovel": values.estadodoimovel !== "" ? values.estadodoimovel : null,
            "placas": values.placas !== "" ? values.placas : null,
            "chaves": values.chaves !== "" ? values.chaves : null,
            "chavesdescricao": values.chavesdescricao,
            "dormitorios": values.dormitorios !== "" ? values.dormitorios : null,
            "suites": values.suites !== "" ? values.suites : null,
            "banheiros": values.banheiros !== "" ? values.banheiros : null,
            "vagas": values.vagas !== "" ? values.vagas : null,
            "areaconstruida": values?.areaconstruida !== "" ? values?.areaconstruida?.replace('m²', '') : 0,
            "areatotal": values?.areatotal !== "" ? values?.areatotal?.replace('m²', '') : 0,
            "valorcondominio": valorcondominio,
            "descricao": values.descricao,

            "areaaverbacao": values?.areaaverbacao !== "" ? values?.areaaverbacao?.replace('m²', '') : 0,
            "tipoterreno": values.tipoterreno !== "" ? values.tipoterreno : null,
            "frente": values?.frente !== "" ? values?.frente?.replace('m', '') : null,
            "fundos": values?.fundos !== "" ? values?.fundos?.replace('m', '') : null,
            "lateralesquerda": values?.lateralesquerda !== "" ? values?.lateralesquerda?.replace('m', '') : null,
            "lateraldireita": values?.lateraldireita !== "" ? values?.lateraldireita?.replace('m', '') : null,
            "lote": values?.lote,
            "quadra": values?.quadra,
            "numeromatricula": values.numeromatricula,
            "tipocasa": values.tipocasa !== "" ? values.tipocasa : null,
            "ocupainquilino": values.ocupainquilino !== "" ? values.ocupainquilino : null,
            "escritura": values.escritura !== "" ? values.escritura : null,
            "contratogavetaaverbada":  values.contratogavetaaverbada !== "" ? values.contratogavetaaverbada : null,
            "inventario": values.inventario !== "" ? values.inventario : null,
            "numinventariantes": values.numinventariantes,
            "tempoparaterminar": values.tempoparaterminar,
            "comissaovenda": values?.comissaovenda !== "" ? values?.comissaovenda?.replace('%', '') : null,
            "valorvenda": valorvenda,
            "valormaoproprietario": valormaoproprietario,
            "aceitapropostavenda": values.aceitapropostavenda !== "" ? values.aceitapropostavenda : null,
            "obsvenda": values.obsvenda,

            "valorlocacao": valorlocacao,
            "valoriptu": valoriptu,
            "caucao": values.caucao !== "" ? values.caucao : null,
            "fiador": values.fiador !== "" ? values.fiador : null,
            "segurofianca": values.segurofianca !== "" ? values.segurofianca : null,
            "primeiroaluguel": values?.primeiroaluguel !== "" ? values?.primeiroaluguel?.replace('%', '') : null,
            "administracaomensal": values?.administracaomensal !== "" ? values?.administracaomensal?.replace('%', '') : null,
            "obslocacao": values.obslocacao,
            
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        setalertSucess(true);        
        setalertSucessMsg(`Dados Atualizados com sucesso!`);    
        setTimeout(() => { setalertSucess(false) }, 6000);   

      }
    });

    
    useEffect(async ()=>{ 
        await detalhesImovel();
        await permissoesUsuario();
        await getCaracteristicas();

        setitensSubMenu ([
            { active: "", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
            { active: "active", title: "Imóveis", rota: "/cadastros/imoveis", icon: <IconCasa/> },
            { active: "", title: "Modelos de mensagens", rota: "/cadastros/modelosmensagem", icon: <IconModeloMensagem/> },
        ])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    

   
    const Input = styled('input')({
      display: 'none',
    });

    let handleFotoImovel = async(e)=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let imagens = e.target.files;

      let formData = new FormData();
      formData.append('id', dadosImovel.id);


      if(imagens.length > 7){
        Swal.fire(
          'Ops, Limite por vez atingido!',
          'Você só pode enviar 7 imagens por vez, e inserir até 20 fotos no veículo.',
          'warning'
        )
      }else{

        for await (let imagem of imagens) {

          if(imagem.type === 'image/png' || imagem.type === 'image/gif' || imagem.type === 'image/jpeg'){
            formData.append('imagens', imagem);
          }else{
            setalertErrorMsg('Foto inválida, Apenas os formatos .png, .jpg e .jpeg são permitidos!')
            setalertError(true);
            setTimeout(() => { setalertError(false) }, 6000);   
          }
          
        }

        let response = await api.put("uploadFotosImovel", formData, {
          headers: { 
            "Content-type": "multipart/form-data",
            "Authorization": `Bearer ${auth.token}`
          },
          onUploadProgress: e => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total));
            setprogressUploadFotos(progress)
          },
        });

        if(response.data.error_code === "MAXIMUM_FILES"){
          setalertErrorMsg(response.data.message)
          setalertError(true);
          setTimeout(() => { setalertError(false) }, 6000);
        }

        // await new Promise(r => setTimeout(r, imagens.length * 150));
        setprogressUploadFotos(0);
        getfotosDoImovel(dadosImovel.id);
      }
      

    }

    const handleDeleteFoto = async(idFoto)=>{
    
      Swal.fire({
        title: 'Tem certeza?',
        text: "Deseja deletar a Foto selecionada?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#02A680',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, deletar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {
  
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
          
          await api.delete('deleteFotoImovel', {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            },
            data: {  "id": idFoto, "imovelId": dadosImovel.id }
          });

          await getfotosDoImovel(dadosImovel.id);
  
          Swal.fire(
            'Deletado!',
            'Foto deletada com sucesso!',
            'success'
          )
        }
      })
  
    }

    const handleDragFoto = async(result)=>{
      

      if(permissoesdoUsuario[3]?.update){

        const items = Array.from(fotosDoImovel);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let dadosArray = [];

        for await (let [key, item] of  Object.entries(items)) {
          let dados = {
            id: item.id,
            ordem: parseInt(key)
          }

          dadosArray.push(dados);

        }

        setfotosDoImovel(items);

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        await api.put(`updateOrdemFotosImovel`,
        {
            "imovelId": dadosImovel.id,
            "dados": dadosArray
        },
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        })

      }else{
        setalertErrorMsg('Você não possui autorização para modificar a ordem das fotos.')
        setalertError(true);
        setTimeout(() => { setalertError(false) }, 6000);
      }
 
    }

    const handleBuscaCep = async(props)=>{
        formik.handleChange(props);
        
        let cep = props.target.value;
        
        if(cep.length >= 9){
    
            setloaderPage(true);
        
            let response = await axios({
                method: 'get',
                url: `https://viacep.com.br/ws/${cep}/json/`,
                headers: { 
                "Content-type": "application/json"
                }
            });
        
            let data = response.data;
                
            if(data.cep){
                formik.setFieldValue('cep', data.cep);
                formik.setFieldValue('rua', data.logradouro);
                formik.setFieldValue('bairro', data.bairro);
                formik.setFieldValue('cidade', data.localidade);
                formik.setFieldValue('estado', data.uf);
                // formik.setFieldValue('codmunicipio', data.ibge);
                
                inputNumero.current.focus();
            }
        
            setloaderPage(false);
        }
    }

    const handleChangeFornecedor = async(event, value, reason, details)=>{
        formik.setFieldValue('fornecedor', JSON.stringify(value));
        setcodigoDetalhesPessoa(value?.codigo);
    }  

    const handleChangeUsuario = async(event, value, reason, details)=>{
      formik.setFieldValue('usuario', JSON.stringify(value));
    }  
    
    const handleChangeTabPage = (event, newValue) => {
      settabPage(newValue);
    };

    const getCaracteristicasdoImovel = async(imovelid)=>{
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let caracteristicasDoImovel = await api.post(`listagemCaracteristicasImovel`,
      {
          "id": imovelid
      },
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });

      setcaracteristicasdoimovel(caracteristicasDoImovel.data);

    }
    

    return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcom - Destalhes Imóvel</title>
        </Helmet>
      </HelmetProvider>

      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertSucessMsg}
        </Alert>
      </Snackbar>

      <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
      <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

      <div>
        <div className='container-page'>
          <Submenu itens={ itensSubMenu } />
          
          <div className='container'>
          <form method='post' onSubmit={formik.handleSubmit}>
            <Box style={{ marginTop: '15px' }}>

              <TabContext value={tabPage}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
                  <TabList onChange={handleChangeTabPage} aria-label="" textColor="primary" indicatorColor="primary">
                    <Tab label="Imóvel" value="1" />
                    <Tab label="Informações Venda" value="2" />
                    <Tab label="Informações Locação" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Grid container spacing={2}>
                  
                    <Grid item xs={12} md={6}>
                      <div className='box-content'>
                        
                      {
                        loaderskeleton ?

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                          <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          
                          <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                        </Grid>
                        
                        :
                        <>
                        <div className='header-box-content'>
                          <h3 className='title'>Geral</h3>
                          {
                            permissoesdoUsuario[3]?.update &&
                            <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                          }
                        </div>

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>

                            <Grid item xs={12} md={3} >
                            <FormControl sx={{ m: 0, width: '100%' }} >
                                <InputLabel id="label-status">Status</InputLabel>
                                <Select
                                labelId="label-status"
                                id="status-select"
                                name="status"
                                label="Status"
                                placeholder='Status'
                                value={formik.values.status}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                >
                                <MenuItem value={0}>Inativo</MenuItem>
                                <MenuItem value={1}>Ativo</MenuItem>
                                </Select>
                                <FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
                            </FormControl>
                            </Grid>


                            <Grid item xs={12} md={9} display='flex' >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <Autocomplete
                                        className='input'
                                        disablePortal
                                        id="combo-box-fornecedor"
                                        options={pessoas}
                                        value={JSON.parse(formik.values.fornecedor)}
                                        getOptionLabel={(option) => option.nome}
                                        renderOption={(props, option) => (
                                        <li {...props} key={option.id}>{option.nome}</li>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={handleChangeFornecedor}
                                        noOptionsText="Nenhum Proprietário encontrado."
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Proprietário"  error={formik.touched.fornecedor && Boolean(formik.errors.fornecedor)}/>}
                                    />
                                    <FormHelperText error>{formik.touched.fornecedor && formik.errors.fornecedor}</FormHelperText>
                                </FormControl>

                                <Grid>
                                    <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                                </Grid>

                                {
                                    formik.values.fornecedor !== 'null' &&

                                    <Grid>
                                        <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                                    </Grid>
                                }           

                            </Grid>

                            <Grid item xs={12} md={6} display='flex' >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <Autocomplete
                                        className='input'
                                        disablePortal
                                        id="combo-box-usuario"
                                        options={usuarios}
                                        value={JSON.parse(formik.values.usuario)}
                                        getOptionLabel={(option) => option.nome}
                                        renderOption={(props, option) => (
                                        <li {...props} key={option.id}>{option.nome}</li>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={handleChangeUsuario}
                                        noOptionsText="Nenhum Corretor/Usuário encontrado."
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Corretor Responsável"  error={formik.touched.usuario && Boolean(formik.errors.usuario)}/>}
                                    />
                                    <FormHelperText error>{formik.touched.usuario && formik.errors.usuario}</FormHelperText>
                                </FormControl>

                            </Grid>
                            
                            <Grid item xs={12} md={6} >
                                <TextField
                                inputRef={inputNumero}
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="titulosite"
                                name="titulosite"
                                label="Título site"
                                margin="normal"
                                type="text"
                                value={formik.values.titulosite}
                                onChange={formik.handleChange}
                                error={formik.touched.titulosite && Boolean(formik.errors.titulosite)}
                                helperText={formik.touched.titulosite && formik.errors.titulosite}
                                inputProps={{
                                    maxLength: 175,
                                }}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <InputLabel id="label-exibirsite">Exibir no site</InputLabel>
                                    <Select
                                    labelId="label-exibirsite"
                                    id="exibirsite-select"
                                    name="exibirsite"
                                    label="Exibir no site"
                                    placeholder='Exibir no site'
                                    value={formik.values.exibirsite}
                                    disabled={!permissoesdoUsuario[3]?.update}
                                    onChange={formik.handleChange}
                                    error={formik.touched.exibirsite && Boolean(formik.errors.exibirsite)}
                                    >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                    </Select>
                                    <FormHelperText error>{formik.touched.exibirsite && formik.errors.exibirsite}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <InputLabel id="label-destacarsite">Destacar no site</InputLabel>
                                    <Select
                                    labelId="label-destacarsite"
                                    id="destacarsite-select"
                                    name="destacarsite"
                                    label="Destacar no site"
                                    placeholder='Destacar no site'
                                    value={formik.values.destacarsite}
                                    disabled={!permissoesdoUsuario[3]?.update}
                                    onChange={formik.handleChange}
                                    error={formik.touched.destacarsite && Boolean(formik.errors.destacarsite)}
                                    >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                    </Select>
                                    <FormHelperText error>{formik.touched.destacarsite && formik.errors.destacarsite}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <InputLabel id="label-estadodoimovel">Estado do imóvel</InputLabel>
                                    <Select
                                    labelId="label-estadodoimovel"
                                    id="estadodoimovel-select"
                                    name="estadodoimovel"
                                    label="Estado do imóvel"
                                    placeholder='Estado do imóvel'
                                    value={formik.values.estadodoimovel}
                                    disabled={!permissoesdoUsuario[3]?.update}
                                    onChange={formik.handleChange}
                                    error={formik.touched.estadodoimovel && Boolean(formik.errors.estadodoimovel)}
                                    >
                                    <MenuItem value={1}>Novo</MenuItem>
                                    <MenuItem value={2}>Usado</MenuItem>
                                    </Select>
                                    <FormHelperText error>{formik.touched.estadodoimovel && formik.errors.estadodoimovel}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3} >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <InputLabel id="label-placas">Placas</InputLabel>
                                    <Select
                                    labelId="label-placas"
                                    id="placas-select"
                                    name="placas"
                                    label="Placas"
                                    placeholder='Placas'
                                    value={formik.values.placas}
                                    disabled={!permissoesdoUsuario[3]?.update}
                                    onChange={formik.handleChange}
                                    error={formik.touched.placas && Boolean(formik.errors.placas)}
                                    >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                    </Select>
                                    <FormHelperText error>{formik.touched.placas && formik.errors.placas}</FormHelperText>
                                </FormControl>
                            </Grid>
                            
                            {
                                formik.values.chaves === 3 &&
                                <Grid item xs={12} md={12} style={{ height: '68px' }}></Grid>
                            }
                            
                        </Grid>
                        </>
                      }

                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className='box-content'>
                        
                      {
                        loaderskeleton ?

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                          <Grid item xs={12} md={2.4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2.4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2.4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2.4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2.4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                        </Grid>
                        
                        :
                        <>
                        <div className='header-box-content'>
                          <h3 className='title'>Detalhes</h3>
                          {
                            permissoesdoUsuario[3]?.update &&
                            <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                          }
                        </div>

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>

                            <Grid item xs={12} md={4} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-tipo">Tipo do imóvel</InputLabel>
                                  <Select
                                  className='input'
                                  labelId="label-tipo"
                                  id="tipo-select"
                                  name="tipo"
                                  label="Tipo do imóvel"
                                  placeholder='Tipo do imóvel'
                                  value={formik.values.tipo}
                                  onChange={formik.handleChange}
                                  error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                                  >
                                  <MenuItem value={1}>Casa</MenuItem>
                                  <MenuItem value={2}>Apartamento</MenuItem>
                                  <MenuItem value={3}>Kitnet</MenuItem>
                                  <MenuItem value={4}>Terreno urbano</MenuItem>
                                  <MenuItem value={5}>Terreno rural</MenuItem>
                                  <MenuItem value={6}>Sala comercial</MenuItem>
                                  <MenuItem value={7}>Loja</MenuItem>
                                  <MenuItem value={8}>Chácara</MenuItem>
                                  <MenuItem value={9}>Sítio</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <InputLabel id="label-chaves">Chaves</InputLabel>
                                    <Select
                                    labelId="label-chaves"
                                    id="chaves-select"
                                    name="chaves"
                                    label="Chaves"
                                    placeholder='Chaves'
                                    value={formik.values.chaves}
                                    disabled={!permissoesdoUsuario[3]?.update}
                                    onChange={formik.handleChange}
                                    error={formik.touched.chaves && Boolean(formik.errors.chaves)}
                                    >
                                    <MenuItem value={1}>Imobiliária</MenuItem>
                                    <MenuItem value={2}>Proprietário</MenuItem>
                                    <MenuItem value={3}>Outros</MenuItem>
                                    </Select>
                                    <FormHelperText error>{formik.touched.chaves && formik.errors.chaves}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <TextField
                                inputRef={inputNumero}
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="dormitorios"
                                name="dormitorios"
                                label="Dormitórios"
                                margin="normal"
                                type="text"
                                value={formik.values.dormitorios}
                                onChange={formik.handleChange}
                                onKeyUp={()=>{ formik.setFieldValue('dormitorios', formik.values.dormitorios && mnumero(formik.values.dormitorios)) }}
                                error={formik.touched.dormitorios && Boolean(formik.errors.dormitorios)}
                                helperText={formik.touched.dormitorios && formik.errors.dormitorios}
                                inputProps={{
                                    maxLength: 3,
                                }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <TextField
                                inputRef={inputNumero}
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="suites"
                                name="suites"
                                label="Suítes"
                                margin="normal"
                                type="text"
                                value={formik.values.suites}
                                onChange={formik.handleChange}
                                onKeyUp={()=>{ formik.setFieldValue('suites', formik.values.suites && mnumero(formik.values.suites)) }}
                                error={formik.touched.suites && Boolean(formik.errors.suites)}
                                helperText={formik.touched.suites && formik.errors.suites}
                                inputProps={{
                                    maxLength: 3,
                                }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <TextField
                                inputRef={inputNumero}
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="banheiros"
                                name="banheiros"
                                label="Banheiros"
                                margin="normal"
                                type="text"
                                value={formik.values.banheiros}
                                onChange={formik.handleChange}
                                onKeyUp={()=>{ formik.setFieldValue('banheiros', formik.values.banheiros && mnumero(formik.values.banheiros)) }}
                                error={formik.touched.banheiros && Boolean(formik.errors.banheiros)}
                                helperText={formik.touched.banheiros && formik.errors.banheiros}
                                inputProps={{
                                    maxLength: 3,
                                }}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={4} >
                                <TextField
                                inputRef={inputNumero}
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="vagas"
                                name="vagas"
                                label="Vagas"
                                margin="normal"
                                type="text"
                                value={formik.values.vagas}
                                onChange={formik.handleChange}
                                onKeyUp={()=>{ formik.setFieldValue('vagas', formik.values.vagas && mnumero(formik.values.vagas)) }}
                                error={formik.touched.vagas && Boolean(formik.errors.vagas)}
                                helperText={formik.touched.vagas && formik.errors.vagas}
                                inputProps={{
                                    maxLength: 3,
                                }}
                                />
                            </Grid>

                          
                            <Grid item xs={12} md={4} >
                                <TextField
                                className='input'
                                fullWidth
                                id="areaconstruida"
                                name="areaconstruida"
                                label="Área construída"
                                margin="none"
                                type="text"
                                value={formik.values.areaconstruida}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.areaconstruida && Boolean(formik.errors.areaconstruida)}
                                helperText={formik.touched.areaconstruida && formik.errors.areaconstruida}
                                onKeyUp={()=>{ formik.setFieldValue('areaconstruida', formik.values.areaconstruida && mArea(formik.values.areaconstruida)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <TextField
                                className='input'
                                fullWidth
                                id="areatotal"
                                name="areatotal"
                                label="Área total do terreno"
                                margin="none"
                                type="text"
                                value={formik.values.areatotal}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.areatotal && Boolean(formik.errors.areatotal)}
                                helperText={formik.touched.areatotal && formik.errors.areatotal}
                                onKeyUp={()=>{ formik.setFieldValue('areatotal', formik.values.areatotal && mArea(formik.values.areatotal)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <TextField
                                className='input'
                                fullWidth
                                id="valorcondominio"
                                name="valorcondominio"
                                label="Valor do Condomínio"
                                margin="none"
                                type="text"
                                value={formik.values.valorcondominio}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.valorcondominio && Boolean(formik.errors.valorcondominio)}
                                helperText={formik.touched.valorcondominio && formik.errors.valorcondominio}
                                onKeyUp={()=>{ formik.setFieldValue('valorcondominio', formik.values.valorcondominio && mvalor(formik.values.valorcondominio)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                />
                            </Grid>

                            {

                              formik.values.chaves === 3 &&

                              <Grid item xs={12} md={12}>
                              <TextField
                                  sx={{ m: 0, width: '100%' }} 
                                  fullWidth
                                  id="chavesdescricao"
                                  name="chavesdescricao"
                                  label="Info Chaves"
                                  margin="normal"
                                  type="text"
                                  value={formik.values.chavesdescricao}
                                  onChange={formik.handleChange}
                                  error={formik.touched.chavesdescricao && Boolean(formik.errors.chavesdescricao)}
                                  helperText={formik.touched.chavesdescricao && formik.errors.chavesdescricao}
                              />
                              </Grid>

                            }

                        </Grid>
                        </>
                      }

                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className='box-content'>
                        
                      {
                        loaderskeleton ?

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>

                          <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={3.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={3.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                        </Grid>
                        
                        :
                        <>
                        <div className='header-box-content'>
                          <h3 className='title'>Endereço</h3>
                          {
                            permissoesdoUsuario[3]?.update &&
                            <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                          }
                        </div>

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>

                            <Grid item xs={12} md={2} >
                              <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="cep"
                                name="cep"
                                label="CEP"
                                margin="normal"
                                type="text"
                                value={formik.values.cep}
                                onChange={handleBuscaCep}
                                error={formik.touched.cep && Boolean(formik.errors.cep)}
                                helperText={formik.touched.cep && formik.errors.cep}
                                InputProps={{
                                  inputComponent: CEPMask,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={5} >
                              <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="rua"
                                name="rua"
                                label="Rua"
                                margin="normal"
                                type="text"
                                value={formik.values.rua}
                                onChange={formik.handleChange}
                                error={formik.touched.rua && Boolean(formik.errors.rua)}
                                helperText={formik.touched.rua && formik.errors.rua}
                              />
                            </Grid>

                            <Grid item xs={12} md={2} >
                              <TextField
                                inputRef={inputNumero}
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="numero"
                                name="numero"
                                label="Número"
                                margin="normal"
                                type="text"
                                value={formik.values.numero}
                                onChange={formik.handleChange}
                                onKeyUp={()=>{ formik.setFieldValue('numero', formik.values.numero && mnumero(formik.values.numero)) }}
                                error={formik.touched.numero && Boolean(formik.errors.numero)}
                                helperText={formik.touched.numero && formik.errors.numero}
                                inputProps={{
                                  maxLength: 6,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={3} >
                              <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="bairro"
                                name="bairro"
                                label="Bairro"
                                margin="normal"
                                type="text"
                                value={formik.values.bairro}
                                onChange={formik.handleChange}
                                error={formik.touched.bairro && Boolean(formik.errors.bairro)}
                                helperText={formik.touched.bairro && formik.errors.bairro}
                              />
                            </Grid>

                            <Grid item xs={12} md={3} >
                              <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="cidade"
                                name="cidade"
                                label="Cidade"
                                margin="normal"
                                type="text"
                                value={formik.values.cidade}
                                onChange={formik.handleChange}
                                error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                                helperText={formik.touched.cidade && formik.errors.cidade}
                              />
                            </Grid>

                            <Grid item xs={12} md={2} >
                              <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="estado"
                                name="estado"
                                label="UF"
                                margin="normal"
                                type="text"
                                value={formik.values.estado}
                                onChange={formik.handleChange}
                                error={formik.touched.estado && Boolean(formik.errors.estado)}
                                helperText={formik.touched.estado && formik.errors.estado}
                              />
                            </Grid>

                            <Grid item xs={12} md={3.5} >
                              <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="complemento"
                                name="complemento"
                                label="Complemento"
                                margin="normal"
                                type="text"
                                value={formik.values.complemento}
                                onChange={formik.handleChange}
                                error={formik.touched.complemento && Boolean(formik.errors.complemento)}
                                helperText={formik.touched.complemento && formik.errors.complemento}
                              />
                            </Grid>

                            <Grid item xs={12} md={3.5} >
                              <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="referencia"
                                name="referencia"
                                label="Ponto de Referência"
                                margin="normal"
                                type="text"
                                value={formik.values.referencia}
                                onChange={formik.handleChange}
                                error={formik.touched.referencia && Boolean(formik.errors.referencia)}
                                helperText={formik.touched.referencia && formik.errors.referencia}
                              />
                            </Grid>

                        </Grid>
                        </>
                      }

                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className='box-content'>
                        
                      {
                        loaderskeleton ?
                          
                        <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                          <Grid item xs={12} md={12}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={125} style={{ borderRadius: '5px' }}/>
                          </Grid>
                        </Grid>

                        :
                        <>
                        <div className='header-box-content'>
                          <h3 className='title'>Descrição (site)</h3>
                          {
                            permissoesdoUsuario[3]?.update &&
                            <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                          }
                        </div>

                        <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                            <Grid item xs={12} md={12} >
                              <TextField
                                fullWidth
                                id="descricao"
                                name="descricao"
                                margin="none"
                                multiline
                                disabled={!permissoesdoUsuario[3]?.update}
                                rows={4.5}
                                defaultValue={formik.values.descricao}
                                onChange={formik.handleChange}
                                error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                                helperText={formik.touched.descricao && formik.errors.descricao}
                              />
                            </Grid>
                        </Grid>
                        </>

                      }
                        

                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className='box-content'>
                        
                      {
                        loaderskeleton ?
                          
                        <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                          <Grid item xs={12} md={12}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={60} style={{ borderRadius: '5px' }}/>
                          </Grid>
                        </Grid>

                        :

                        <>
                        <div className='header-box-content'>
                          <h3 className='title'>Características</h3>
                        </div>
                        
                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '-5px' }}>
                            <Grid item xs={12} md={12}>
                              <MultSelectCaracteristicasImovel idImovel={dadosImovel.id} dados={caracteristicas} caracteristicasDoImovel={caracteristicasdoimovel} atualizar={getCaracteristicasdoImovel} disabled={!permissoesdoUsuario[3]?.update}/>
                            </Grid>
                        </Grid>
                        </>
                      }

                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className='box-content'>

                      {
                        loaderskeleton ?

                        <Grid container spacing={2} item xs={12} md={12} style={{ marginTop: '10px' }}>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                        </Grid>
                        
                        :

                        <>
                        <div className='header-box-content'>
                          <h3 className='title'>Fotos</h3>
                        </div>

                        <div className='box-fotos-detalhes-imoveis'>

                        <Fancybox>
                          <DragDropContext onDragEnd={handleDragFoto}>
                            <Droppable droppableId="droppable" direction="horizontal">
                              {(provided) => (
                                <aside {...provided.droppableProps} ref={provided.innerRef} id={props.galleryID}>
                                  {fotosDoImovel.map((v, k) =>{
                                    return (
                                      <Draggable key={v.id} draggableId={v.id.toString()} index={k}>
                                        {(provided) => (
                                          <div className='box-element-foto' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <div className='element-foto' style={{ backgroundImage: `url(${v.url})` }}>
                                              
                                              {
                                                permissoesdoUsuario[3]?.update &&

                                                <div className='box-deleteFotoDImovel'>
                                                  <button type='button' onClick={()=>{ handleDeleteFoto(v.id) }} className='deleteFotoDImovel'><IconCloseTimes/></button>
                                                </div>
                                              }

                                              <div className='box-abrirFotoDImovel'>
                                                  <button type='button' data-fancybox="gallery" href={v.url}>
                                                    <IconZoom/>
                                                  </button>
                                              </div>

                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </aside>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Fancybox>
                          
                          {
                              permissoesdoUsuario[2]?.insert &&
                              
                              <div className='box-button-upload'>
                                <div style={{ width: '220px' }}>

                                  {
                                    progressUploadFotos === 0 &&

                                    <label htmlFor="button-file-fotos-imovel">
                                      <Input accept="image/png, image/gif, image/jpeg" id="button-file-fotos-imovel" multiple onChange={handleFotoImovel} type="file" />
                                      <ButtonDefault type="button" icon={<IconUpload/>} component="span" txt="Adicionar fotos"  />
                                    </label>
                                  }
                                  
                                  {
                                    progressUploadFotos > 0 && 
                                    <LinearProgress variant="determinate" value={progressUploadFotos} />
                                  }

                                </div>
                                <p>Formatos aceitos: .JPG, .JPEG, .PNG | Tamanho máximo: 5MB</p>
                              </div>
                          }

                        </div>
                        </>
                      }

                      </div>
                    </Grid>
                    
                  </Grid>
                </TabPanel>

                <TabPanel value="2">
                  <Grid container spacing={2}>
                      
                    <Grid item xs={12} md={12}>
                      <div className='box-content'>
                        
                      {
                        loaderskeleton ?

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                          
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={100} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          
                        </Grid>
                        
                        :
                        <>
                        <div className='header-box-content'>
                          <h3 className='title'>Principal</h3>
                          {
                            permissoesdoUsuario[3]?.update &&
                            <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                          }
                        </div>

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>

                          <Grid item xs={12} md={2} >
                              <TextField
                              className='input'
                              fullWidth
                              id="areaconstruida"
                              name="areaconstruida"
                              label="Área construída"
                              margin="none"
                              type="text"
                              value={formik.values.areaconstruida}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.areaconstruida && Boolean(formik.errors.areaconstruida)}
                              helperText={formik.touched.areaconstruida && formik.errors.areaconstruida}
                              onKeyUp={()=>{ formik.setFieldValue('areaconstruida', formik.values.areaconstruida && mArea(formik.values.areaconstruida)) }}
                              inputProps={{
                                  maxLength: 13,
                              }}
                              />
                          </Grid>

                          <Grid item xs={12} md={2} >
                              <TextField
                              className='input'
                              fullWidth
                              id="areaaverbacao"
                              name="areaaverbacao"
                              label="Área averbação"
                              margin="none"
                              type="text"
                              value={formik.values.areaaverbacao}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.areaaverbacao && Boolean(formik.errors.areaaverbacao)}
                              helperText={formik.touched.areaaverbacao && formik.errors.areaaverbacao}
                              onKeyUp={()=>{ formik.setFieldValue('areaaverbacao', formik.values.areaaverbacao && mArea(formik.values.areaaverbacao)) }}
                              inputProps={{
                                  maxLength: 13,
                              }}
                              />
                          </Grid>
                          
                          <Grid item xs={12} md={2} >
                                <TextField
                                className='input'
                                fullWidth
                                id="areatotal"
                                name="areatotal"
                                label="Área total"
                                margin="none"
                                type="text"
                                value={formik.values.areatotal}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.areatotal && Boolean(formik.errors.areatotal)}
                                helperText={formik.touched.areatotal && formik.errors.areatotal}
                                onKeyUp={()=>{ formik.setFieldValue('areatotal', formik.values.areatotal && mArea(formik.values.areatotal)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                />
                          </Grid>

                          <Grid item xs={12} md={2} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-tipoterreno">Tipo de terreno</InputLabel>
                                  <Select
                                  labelId="label-tipoterreno"
                                  id="tipoterreno-select"
                                  name="tipoterreno"
                                  label="Tipo de terreno"
                                  placeholder='Tipo de terreno'
                                  value={formik.values.tipoterreno}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.tipoterreno && Boolean(formik.errors.tipoterreno)}
                                  >
                                    <MenuItem value={1}>Aclive</MenuItem>
                                    <MenuItem value={2}>Declive</MenuItem>
                                    <MenuItem value={3}>Plano</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.tipoterreno && formik.errors.tipoterreno}</FormHelperText>
                              </FormControl>
                          </Grid>

                          <Grid item xs={12} md={2} >
                                <TextField
                                className='input'
                                fullWidth
                                id="frente"
                                name="frente"
                                label="Frente"
                                margin="none"
                                type="text"
                                value={formik.values.frente}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.frente && Boolean(formik.errors.frente)}
                                helperText={formik.touched.frente && formik.errors.frente}
                                onKeyUp={()=>{ formik.setFieldValue('frente', formik.values.frente && mMedida(formik.values.frente)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                />
                          </Grid>

                          <Grid item xs={12} md={2} >
                                <TextField
                                className='input'
                                fullWidth
                                id="fundos"
                                name="fundos"
                                label="Fundos"
                                margin="none"
                                type="text"
                                value={formik.values.fundos}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.fundos && Boolean(formik.errors.fundos)}
                                helperText={formik.touched.fundos && formik.errors.fundos}
                                onKeyUp={()=>{ formik.setFieldValue('fundos', formik.values.fundos && mMedida(formik.values.fundos)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                />
                          </Grid>

                          <Grid item xs={12} md={2} >
                                <TextField
                                className='input'
                                fullWidth
                                id="lateralesquerda"
                                name="lateralesquerda"
                                label="Lateral Esquera"
                                margin="none"
                                type="text"
                                value={formik.values.lateralesquerda}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.lateralesquerda && Boolean(formik.errors.lateralesquerda)}
                                helperText={formik.touched.lateralesquerda && formik.errors.lateralesquerda}
                                onKeyUp={()=>{ formik.setFieldValue('lateralesquerda', formik.values.lateralesquerda && mMedida(formik.values.lateralesquerda)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                />
                          </Grid>

                          <Grid item xs={12} md={2} >
                                <TextField
                                className='input'
                                fullWidth
                                id="lateraldireita"
                                name="lateraldireita"
                                label="Lateral Direita"
                                margin="none"
                                type="text"
                                value={formik.values.lateraldireita}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.lateraldireita && Boolean(formik.errors.lateraldireita)}
                                helperText={formik.touched.lateraldireita && formik.errors.lateraldireita}
                                onKeyUp={()=>{ formik.setFieldValue('lateraldireita', formik.values.lateraldireita && mMedida(formik.values.lateraldireita)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                />
                          </Grid>

                          <Grid item xs={12} md={2} >
                                <TextField
                                className='input'
                                fullWidth
                                id="lote"
                                name="lote"
                                label="Lote"
                                margin="none"
                                type="text"
                                value={formik.values.lote}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.lote && Boolean(formik.errors.lote)}
                                helperText={formik.touched.lote && formik.errors.lote}
                                inputProps={{
                                    maxLength: 75,
                                }}
                                />
                          </Grid>

                          <Grid item xs={12} md={2} >
                                <TextField
                                className='input'
                                fullWidth
                                id="quadra"
                                name="quadra"
                                label="Quadra"
                                margin="none"
                                type="text"
                                value={formik.values.quadra}
                                // disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.quadra && Boolean(formik.errors.quadra)}
                                helperText={formik.touched.quadra && formik.errors.quadra}
                                inputProps={{
                                    maxLength: 75,
                                }}
                                />
                          </Grid>
                          
                          <Grid item xs={12} md={2}>
                            <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="numeromatricula"
                                name="numeromatricula"
                                label="Número da Matrícula"
                                margin="normal"
                                type="text"
                                value={formik.values.numeromatricula}
                                onChange={formik.handleChange}
                                error={formik.touched.numeromatricula && Boolean(formik.errors.numeromatricula)}
                                helperText={formik.touched.numeromatricula && formik.errors.numeromatricula}
                            />
                          </Grid>

                          <Grid item xs={12} md={2} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-tipocasa">Tipo de casa</InputLabel>
                                  <Select
                                  labelId="label-tipocasa"
                                  id="tipocasa-select"
                                  name="tipocasa"
                                  label="Tipo de casa"
                                  placeholder='Tipo de casa'
                                  value={formik.values.tipocasa}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.tipocasa && Boolean(formik.errors.tipocasa)}
                                  >
                                    <MenuItem value={1}>Nova</MenuItem>
                                    <MenuItem value={2}>Usada</MenuItem>
                                    <MenuItem value={3}>Financiada</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.tipocasa && formik.errors.tipocasa}</FormHelperText>
                              </FormControl>
                          </Grid>
                          
                          <Grid item xs={12} md={3} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-ocupainquilino">Ocupa inquilino</InputLabel>
                                  <Select
                                  labelId="label-ocupainquilino"
                                  id="ocupainquilino-select"
                                  name="ocupainquilino"
                                  label="Ocupa inquilino"
                                  placeholder='Ocupa inquilino'
                                  value={formik.values.ocupainquilino}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.ocupainquilino && Boolean(formik.errors.ocupainquilino)}
                                  >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.ocupainquilino && formik.errors.ocupainquilino}</FormHelperText>
                              </FormControl>
                          </Grid>

                          <Grid item xs={12} md={3} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-escritura">Possuí escritura?</InputLabel>
                                  <Select
                                  labelId="label-escritura"
                                  id="escritura-select"
                                  name="escritura"
                                  label="Possuí escritura"
                                  placeholder='Possuí escritura'
                                  value={formik.values.escritura}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.escritura && Boolean(formik.errors.escritura)}
                                  >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.escritura && formik.errors.escritura}</FormHelperText>
                              </FormControl>
                          </Grid>

                          <Grid item xs={12} md={3} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-contratogavetaaverbada">Possuí contrato de gaveta?</InputLabel>
                                  <Select
                                  labelId="label-contratogavetaaverbada"
                                  id="contratogavetaaverbada-select"
                                  name="contratogavetaaverbada"
                                  label="Possuí contrato de gaveta averbada?"
                                  placeholder='Possuí contrato de gaveta averbada?'
                                  value={formik.values.contratogavetaaverbada}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.contratogavetaaverbada && Boolean(formik.errors.contratogavetaaverbada)}
                                  >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.contratogavetaaverbada && formik.errors.contratogavetaaverbada}</FormHelperText>
                              </FormControl>
                          </Grid>
                        
                          <Grid item xs={12} md={3} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-inventario">Possuí inventário?</InputLabel>
                                  <Select
                                  labelId="label-inventario"
                                  id="inventario-select"
                                  name="inventario"
                                  label="Possuí inventário?"
                                  placeholder='Possuí inventário?'
                                  value={formik.values.inventario}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.inventario && Boolean(formik.errors.inventario)}
                                  >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.inventario && formik.errors.inventario}</FormHelperText>
                              </FormControl>
                          </Grid>
                          
                          <Grid item xs={12} md={3} >
                              <TextField
                              inputRef={inputNumero}
                              sx={{ m: 0, width: '100%' }} 
                              fullWidth
                              id="numinventariantes"
                              name="numinventariantes"
                              label="Nº inventariantes"
                              margin="normal"
                              type="text"
                              value={formik.values.numinventariantes}
                              onChange={formik.handleChange}
                              onKeyUp={()=>{ formik.setFieldValue('numinventariantes', formik.values.numinventariantes && mnumero(formik.values.numinventariantes)) }}
                              error={formik.touched.numinventariantes && Boolean(formik.errors.numinventariantes)}
                              helperText={formik.touched.numinventariantes && formik.errors.numinventariantes}
                              inputProps={{
                                  maxLength: 3,
                              }}
                              />
                          </Grid>
                          
                          <Grid item xs={12} md={3}>
                            <TextField
                                sx={{ m: 0, width: '100%' }} 
                                fullWidth
                                id="tempoparaterminar"
                                name="tempoparaterminar"
                                label="Tempo para terminar"
                                margin="normal"
                                type="text"
                                value={formik.values.tempoparaterminar}
                                onChange={formik.handleChange}
                                error={formik.touched.tempoparaterminar && Boolean(formik.errors.tempoparaterminar)}
                                helperText={formik.touched.tempoparaterminar && formik.errors.tempoparaterminar}
                            />
                          </Grid>

                          <Grid item xs={12} md={3}>
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-aceitapropostavenda">Aceita proposta?</InputLabel>
                                  <Select
                                  labelId="label-aceitapropostavenda"
                                  id="aceitapropostavenda-select"
                                  name="aceitapropostavenda"
                                  label="Aceita proposta?"
                                  placeholder='Aceita proposta?'
                                  value={formik.values.aceitapropostavenda}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.aceitapropostavenda && Boolean(formik.errors.aceitapropostavenda)}
                                  >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.aceitapropostavenda && formik.errors.aceitapropostavenda}</FormHelperText>
                              </FormControl>
                          </Grid>
                          
                          <Grid item xs={12} md={3} >
                              <TextField
                              className='input'
                              fullWidth
                              id="valorvenda"
                              name="valorvenda"
                              label="Valor de venda total"
                              margin="none"
                              type="text"
                              value={formik.values.valorvenda}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.valorvenda && Boolean(formik.errors.valorvenda)}
                              helperText={formik.touched.valorvenda && formik.errors.valorvenda}
                              onKeyUp={()=>{ formik.setFieldValue('valorvenda', formik.values.valorvenda && mvalor(formik.values.valorvenda)) }}
                              inputProps={{
                                  maxLength: 15,
                              }}
                              />
                          </Grid>

                          <Grid item xs={12} md={3} >
                              <TextField
                              className='input'
                              fullWidth
                              id="valormaoproprietario"
                              name="valormaoproprietario"
                              label="Valor na mão do Proprietário"
                              margin="none"
                              type="text"
                              value={formik.values.valormaoproprietario}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.valormaoproprietario && Boolean(formik.errors.valormaoproprietario)}
                              helperText={formik.touched.valormaoproprietario && formik.errors.valormaoproprietario}
                              onKeyUp={()=>{ formik.setFieldValue('valormaoproprietario', formik.values.valormaoproprietario && mvalor(formik.values.valormaoproprietario)) }}
                              inputProps={{
                                  maxLength: 15,
                              }}
                              />
                          </Grid>

                          <Grid item xs={12} md={3} >
                              <TextField
                              className='input'
                              fullWidth
                              id="comissaovenda"
                              name="comissaovenda"
                              label="% Comissão"
                              margin="none"
                              type="text"
                              value={formik.values.comissaovenda}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.comissaovenda && Boolean(formik.errors.comissaovenda)}
                              helperText={formik.touched.comissaovenda && formik.errors.comissaovenda}
                              onKeyUp={()=>{ formik.setFieldValue('comissaovenda', formik.values.comissaovenda && mporcentagem(formik.values.comissaovenda)) }}
                              inputProps={{
                                  maxLength: 7,
                              }}
                              />
                          </Grid>
                  
                          <Grid item xs={12} md={12} >
                            <TextField
                              fullWidth
                              id="obsvenda"
                              name="obsvenda"
                              label="Obs"
                              margin="none"
                              multiline
                              disabled={!permissoesdoUsuario[3]?.update}
                              rows={2.5}
                              defaultValue={formik.values.obsvenda}
                              onChange={formik.handleChange}
                              error={formik.touched.obsvenda && Boolean(formik.errors.obsvenda)}
                              helperText={formik.touched.obsvenda && formik.errors.obsvenda}
                            />
                          </Grid>
                            
                        </Grid>
                        </>
                      }

                      </div>

                      <div style={{ marginTop: '20px' }}>
                        <AnexosImovelVenda dadosImovel={dadosImovel} />
                      </div>
                    </Grid>

                  </Grid>
                </TabPanel>

                <TabPanel value="3">
                  <Grid container spacing={2}>

                    <Grid item xs={12} md={12}>
                      <div className='box-content'>
                        
                      {
                        loaderskeleton ?

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                          
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.72}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={1.72}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={100} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          
                        </Grid>
                        
                        :
                        <>
                        <div className='header-box-content'>
                          <h3 className='title'>Principal</h3>
                          {
                            permissoesdoUsuario[3]?.update &&
                            <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                          }
                        </div>

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>

                          <Grid item xs={12} md={1.5} >
                              <TextField
                              className='input'
                              fullWidth
                              id="valorlocacao"
                              name="valorlocacao"
                              label="Valor de locação"
                              margin="none"
                              type="text"
                              value={formik.values.valorlocacao}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.valorlocacao && Boolean(formik.errors.valorlocacao)}
                              helperText={formik.touched.valorlocacao && formik.errors.valorlocacao}
                              onKeyUp={()=>{ formik.setFieldValue('valorlocacao', formik.values.valorlocacao && mvalor(formik.values.valorlocacao)) }}
                              inputProps={{
                                  maxLength: 15,
                              }}
                              />
                          </Grid>

                          <Grid item xs={12} md={1.5} >
                              <TextField
                              className='input'
                              fullWidth
                              id="valoriptu"
                              name="valoriptu"
                              label="Valor do IPTU"
                              margin="none"
                              type="text"
                              value={formik.values.valoriptu}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.valoriptu && Boolean(formik.errors.valoriptu)}
                              helperText={formik.touched.valoriptu && formik.errors.valoriptu}
                              onKeyUp={()=>{ formik.setFieldValue('valoriptu', formik.values.valoriptu && mvalor(formik.values.valoriptu)) }}
                              inputProps={{
                                  maxLength: 15,
                              }}
                              />
                          </Grid>
                            
                          <Grid item xs={12} md={1.5} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-caucao">Aceita caução?</InputLabel>
                                  <Select
                                  labelId="label-caucao"
                                  id="caucao-select"
                                  name="caucao"
                                  label="Aceita caução?"
                                  placeholder='Aceita caução?'
                                  value={formik.values.caucao}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.caucao && Boolean(formik.errors.caucao)}
                                  >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.caucao && formik.errors.caucao}</FormHelperText>
                              </FormControl>
                          </Grid>

                          <Grid item xs={12} md={2} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-fiador">Aceita fiador?</InputLabel>
                                  <Select
                                  labelId="label-fiador"
                                  id="fiador-select"
                                  name="fiador"
                                  label="Aceita fiador?"
                                  placeholder='Aceita fiador?'
                                  value={formik.values.fiador}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.fiador && Boolean(formik.errors.fiador)}
                                  >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.fiador && formik.errors.fiador}</FormHelperText>
                              </FormControl>
                          </Grid>

                          <Grid item xs={12} md={2} >
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                  <InputLabel id="label-segurofianca">Aceita Seguro Fiança?</InputLabel>
                                  <Select
                                  labelId="label-segurofianca"
                                  id="segurofianca-select"
                                  name="segurofianca"
                                  label="Aceita Seguro Fiança?"
                                  placeholder='Aceita Seguro Fiança?'
                                  value={formik.values.segurofianca}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.segurofianca && Boolean(formik.errors.segurofianca)}
                                  >
                                    <MenuItem value={0}>Não</MenuItem>
                                    <MenuItem value={1}>Sim</MenuItem>
                                  </Select>
                                  <FormHelperText error>{formik.touched.segurofianca && formik.errors.segurofianca}</FormHelperText>
                              </FormControl>
                          </Grid>

                          <Grid item xs={12} md={1.72} >
                              <TextField
                              className='input'
                              fullWidth
                              id="primeiroaluguel"
                              name="primeiroaluguel"
                              label="% do primeiro aluguel"
                              margin="none"
                              type="text"
                              value={formik.values.primeiroaluguel}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.primeiroaluguel && Boolean(formik.errors.primeiroaluguel)}
                              helperText={formik.touched.primeiroaluguel && formik.errors.primeiroaluguel}
                              onKeyUp={()=>{ formik.setFieldValue('primeiroaluguel', formik.values.primeiroaluguel && mporcentagem(formik.values.primeiroaluguel)) }}
                              inputProps={{
                                  maxLength: 7,
                              }}
                              />
                          </Grid>

                          <Grid item xs={12} md={1.72} >
                              <TextField
                              className='input'
                              fullWidth
                              id="administracaomensal"
                              name="administracaomensal"
                              label="% administração mensal"
                              margin="none"
                              type="text"
                              value={formik.values.administracaomensal}
                              // disabled={!permissoesdoUsuario[3]?.update}
                              onChange={formik.handleChange}
                              error={formik.touched.administracaomensal && Boolean(formik.errors.administracaomensal)}
                              helperText={formik.touched.administracaomensal && formik.errors.administracaomensal}
                              onKeyUp={()=>{ formik.setFieldValue('administracaomensal', formik.values.administracaomensal && mporcentagem(formik.values.administracaomensal)) }}
                              inputProps={{
                                  maxLength: 7,
                              }}
                              />
                          </Grid>
                  
                          <Grid item xs={12} md={12} >
                            <TextField
                              fullWidth
                              id="obslocacao"
                              name="obslocacao"
                              label="Obs"
                              margin="none"
                              multiline
                              disabled={!permissoesdoUsuario[3]?.update}
                              rows={2.5}
                              defaultValue={formik.values.obslocacao}
                              onChange={formik.handleChange}
                              error={formik.touched.obslocacao && Boolean(formik.errors.obslocacao)}
                              helperText={formik.touched.obslocacao && formik.errors.obslocacao}
                            />
                          </Grid>
                            
                        </Grid>
                        </>
                      }

                      </div>

                      <div style={{ marginTop: '20px' }}>
                        <AnexosImovelLocacao dadosImovel={dadosImovel} />
                      </div>
                    </Grid>
                  
                  </Grid>
                </TabPanel>
              </TabContext>


              
            </Box>
          </form>
          </div>

        </div>
        <Footer/>
      </div>
        
    </>

    )
}