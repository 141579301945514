import React, {useState, useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import Footer from '../../componentes/Footer';

export default function Resumo() {


  useEffect(()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);
    

  },[]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcom - Resumo</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>
          
          

        </div>
        <Footer/>
      </div>
    </>
    
  );
}