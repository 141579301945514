import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useHistory } from "react-router-dom";
import './style.css';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import api from '../../../services/api';
import { DataMask, CpfMask, CnpjMask } from '../../../vendor/mascaras';


export default function NovaPessoa() {

  let history = useHistory();

  const [step, setstep] = useState(0);
  const [errorStep1, seterrorStep1] = useState(false);

  const [dataNascimento, setdataNascimento] = useState(null);

  const handleChangeDataNascimento = (data) => {
    setdataNascimento(data);
  };

  const validationSchema = yup.object({
    tipo: yup
      .number('Selecione o tipo de pessoa')
      .required('Tipo Obrigatório'),
    nome: yup
      .string('Insira o nome da pessoa')
      .required('Nome Obrigatório'),
    // email: yup
    //   .string('Insira seu E-mail')
    //   .email('Insira um E-mail válido')
    //   .required('E-mail Obrigatório'),
    rg: yup
      .string('Insira o RG'),
    cpf: yup
      .string('Insira o RG'),
    nascimento: yup
      .string('Insira a data de nascimento'),
    cnpj: yup
      .string('Insira o CNPJ'),
    representante: yup
      .string('Insira o nome do representante da empresa'),
    inscricaoestadual: yup
      .string('Insira a inscrição estadual da empresa'),
    inscricaomunicipal: yup
      .string('Insira a inscrição municipal da empresa'),
  });

  const formik = useFormik({
    initialValues: {
      tipo: '',
      nome: '',
      email: '',
      rg: '',
      cpf: '',
      nascimento: '',
      cnpj: '',
      representante: '',
      inscricaoestadual: '',
      inscricaomunicipal: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      step < 1 && setstep(step+1)

      if(step === 1){ //TUDO CERTO PRA CADASTRAR
               
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`addPessoa`, {
            "tipo": values.tipo,
            "nome": values.nome,
            "email": values.email,
            "cpf": values.cpf,
            "rg":  values.rg,
            "nascimento": dataNascimento,
            "cnpj": values.cnpj,
            "inscricaoestadual": values.inscricaoestadual,
            "inscricaomunicipal": values.inscricaomunicipal,
            "representante": values.representante
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        history.push(`/cadastro/pessoa/${response.data.codigo}`);

      }      
    }
  });


  useEffect(()=>{
    let erros = JSON.stringify(formik.errors);
    erros = erros.length - 2;

    if(erros === 0){
      seterrorStep1(false);
    }else{
      seterrorStep1(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.errors]);


  return (
    <>
      {/* <HelmetProvider>
        <Helmet>
            <title>Nexcom - Nova Pessoa</title>
        </Helmet>
      </HelmetProvider> */}

      <div>
        <div className='container-page'>
          <div className='box-steps'>
            <Stepper activeStep={step} alternativeLabel>
                <Step>
                  <StepLabel error={errorStep1}>Pessoa</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Detalhes</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Finalizar</StepLabel>
                </Step>
            </Stepper>
          </div>

          <div className='container'>
            
            <div className='box-form-cadastro-pessoas'>

                <form method='post' onSubmit={formik.handleSubmit}>
                  <h2>Cadastro de pessoa</h2>

                  {
                    step === 0 &&

                    <>
                      <FormControl sx={{ m: 1, width: '100%' }} >
                        <InputLabel id="label-tipo">Tipo</InputLabel>
                        <Select
                          className='input'
                          labelId="label-tipo"
                          id="demo-simple-select"
                          name="tipo"
                          label="Tipo"
                          placeholder='Tipo'
                          value={formik.values.tipo}
                          onChange={formik.handleChange}
                          error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                        >
                          <MenuItem value={1}>Física</MenuItem>
                          <MenuItem value={2}>Jurídica</MenuItem>
                        </Select>
                        <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                      </FormControl>

                      {
                        formik.values.tipo &&

                        <>
                        <TextField
                          className='input'
                          fullWidth
                          id="nome"
                          name="nome"
                          label="nome"
                          margin="normal"
                          type="text"
                          value={formik.values.nome}
                          onChange={formik.handleChange}
                          error={formik.touched.nome && Boolean(formik.errors.nome)}
                          helperText={formik.touched.nome && formik.errors.nome}
                        />

                        <TextField
                          className='input'
                          fullWidth
                          id="email"
                          name="email"
                          label="E-mail"
                          margin="normal"
                          type="text"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                        />
                        </>

                      }

                    </>

                  }


                  {
                    step === 1 &&

                    <>
                      {
                        formik.values.tipo === 1 ? 

                        <>
                          <TextField
                            className='input'
                            fullWidth
                            id="rg"
                            name="rg"
                            label="RG"
                            margin="normal"
                            type="text"
                            value={formik.values.rg}
                            onChange={formik.handleChange}
                            error={formik.touched.rg && Boolean(formik.errors.rg)}
                            helperText={formik.touched.rg && formik.errors.rg}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="cpf"
                            name="cpf"
                            label="CPF"
                            margin="normal"
                            type="text"
                            value={formik.values.cpf}
                            onChange={formik.handleChange}
                            error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                            helperText={formik.touched.cpf && formik.errors.cpf}
                            InputProps={{
                              inputComponent: CpfMask,
                            }}
                          />

                          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                            <DesktopDatePicker
                              label="Data de Nascimento"
                              inputFormat="dd/MM/yyyy"
                              name="nascimento"
                              value={dataNascimento}
                              onChange={handleChangeDataNascimento}
                              renderInput={(params) => <TextField className='input' style={{ width: '100%', marginTop: '15px'}} {...params} />}
                            />
                          </LocalizationProvider>

                          {/* <TextField
                            fullWidth
                            id="nascimento"
                            name="nascimento"
                            label="Data de Nascimento"
                            margin="normal"
                            type="text"
                            value={formik.values.nascimento}
                            onChange={formik.handleChange}
                            error={formik.touched.nascimento && Boolean(formik.errors.nascimento)}
                            helperText={formik.touched.nascimento && formik.errors.nascimento}
                            InputProps={{
                              inputComponent: DataMask,
                            }}
                          /> */}
                        
                         
                        </>

                        : formik.values.tipo === 2 &&

                        <>
                          <TextField
                            className='input'
                            fullWidth
                            id="cnpj"
                            name="cnpj"
                            label="CNPJ"
                            margin="normal"
                            type="text"
                            value={formik.values.cnpj}
                            onChange={formik.handleChange}
                            error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                            helperText={formik.touched.cnpj && formik.errors.cnpj}
                            InputProps={{
                              inputComponent: CnpjMask,
                            }}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="inscricaoestadual"
                            name="inscricaoestadual"
                            label="Inscrição estadual"
                            margin="normal"
                            type="text"
                            value={formik.values.inscricaoestadual}
                            onChange={formik.handleChange}
                            error={formik.touched.inscricaoestadual && Boolean(formik.errors.inscricaoestadual)}
                            helperText={formik.touched.inscricaoestadual && formik.errors.inscricaoestadual}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="inscricaomunicipal"
                            name="inscricaomunicipal"
                            label="Inscrição municipal"
                            margin="normal"
                            type="text"
                            value={formik.values.inscricaomunicipal}
                            onChange={formik.handleChange}
                            error={formik.touched.inscricaomunicipal && Boolean(formik.errors.inscricaomunicipal)}
                            helperText={formik.touched.inscricaomunicipal && formik.errors.inscricaomunicipal}
                          />


                          <TextField
                            className='input'
                            fullWidth
                            id="representante"
                            name="representante"
                            label="Nome completo do Representante"
                            margin="normal"
                            type="text"
                            value={formik.values.representante}
                            onChange={formik.handleChange}
                            error={formik.touched.representante && Boolean(formik.errors.representante)}
                            helperText={formik.touched.representante && formik.errors.representante}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="rg"
                            name="rg"
                            label="RG do Representante"
                            margin="normal"
                            type="text"
                            value={formik.values.rg}
                            onChange={formik.handleChange}
                            error={formik.touched.rg && Boolean(formik.errors.rg)}
                            helperText={formik.touched.rg && formik.errors.rg}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="cpf"
                            name="cpf"
                            label="CPF do Representante"
                            margin="normal"
                            type="text"
                            value={formik.values.cpf}
                            onChange={formik.handleChange}
                            error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                            helperText={formik.touched.cpf && formik.errors.cpf}
                            InputProps={{
                              inputComponent: CpfMask,
                            }}
                          />

                        </>

                      }
                    </>

                  }

                  <div className='box-buttons-steps'>
                    {
                      step > 0 &&

                      <div className='box-tbn-steps'>
                        <ButtonOutlineSecondary type="button" icon="" txt="Voltar" onClick={ ()=>{  step > 0 && setstep(step-1)  } } />
                      </div>
                    }

                    <div className='box-tbn-steps'>
                      <ButtonDefault type="submit" icon="" txt={  step < 1 ? 'Continuar' : 'Finalizar'} onClick={ ()=>{  } } />
                    </div>
                  </div>
                </form>

            </div>

          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}