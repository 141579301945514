import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../../componentes/ButtonSquadPrimaryForInput';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { mvalor, mporcentagem } from '../../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco, formataPorcentagemforBanco } from '../../../../vendor/formatar';


export default function ModalEditComissao({ id, visible, change, getComissoes, codigoProduto}) {

  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  );

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );
  
  let history = useHistory();
  
  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [usuarios, setusuarios] = useState([]);
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();



  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };

  const getPessoas = async(ClienteId=null, codigoCliente=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemUsuarios`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];

    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.usuario_id
        }    
        
        arrayPessoas.push(dados);
    }

    if(codigoCliente){
      //populando cliente no select
      let clientecode = arrayPessoas.find(x => x.codigo === codigoCliente);
      clientecode && formik.setFieldValue('usuario', JSON.stringify(clientecode));
      setcodigoDetalhesPessoa(codigoCliente);
      // setshowModalDetalhesPessoa(true);

    }else if(ClienteId){
      //populando cliente no select
      let cliente = arrayPessoas.find(x => x.id === ClienteId);
      cliente && formik.setFieldValue('usuario', JSON.stringify(cliente));
      setcodigoDetalhesPessoa(cliente.codigo);
    }

    setusuarios(arrayPessoas);

  }

  const detalhesComissao = async()=>{
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    setloaderPage(true);

    codigoProduto && api.post('detalhesComissaoProduto', {
        "codigoProduto": codigoProduto,
        "comissoesid": id,
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    }).then( async response => {
        
        let data = response.data;

        formik.setFieldValue('tipo', data.tipo);
        formik.setFieldValue('valor', formataDinheiro(data.valor));
        formik.setFieldValue('porcentagem', data.porcentagem+'%');

        getPessoas(data.usuario_id, null);
        setloaderPage(false);

    }).catch((error) => {
        change(false);
        setloaderPage(false);
    });
    
  }

  useEffect(async()=>{
    if(visible){
      formik.resetForm();
      detalhesComissao();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[visible]);
  
  const validationSchema = yup.object({
    usuario: yup
      .string('Selecione o Usuário')
      .required('Usuário Obrigatório')
      .test({
        message: () => "Selecione o Usuário",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    tipo: yup
      .string('Selecione o tipo')
      .required('Tipo Obrigatório')
      .test({
        message: () => "Selecione o tipo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    valor: yup
      .string('Insira o Valor')
      .test({
        message: () => "Insira o Valor",
        test: async (values) => {
          if (formik.values.tipo === 1) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
    porcentagem: yup
    .string('Insira a Porcentagem')
    .test({
      message: () => "Insira a Porcentagem",
      test: async (values) => {
        if (formik.values.tipo === 2) { 
          if (!values) { return false; } else{ return true; }
        }else{
          return true;
        }
      },
    }),
  });

  const formik = useFormik({
    initialValues: {
      usuario: 'null',
      tipo: 1,
      valor: '',
      porcentagem: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // setloaderPage(true);
      
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('updateComissaoProduto', {
        "id": id,
        "tipo": values.tipo,
        "valor": values.valor ? formataDinheiroforBanco(values.valor) : 0.00,
        "porcentagem": values.porcentagem ? formataPorcentagemforBanco(values.porcentagem) : 0.00,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      change(false);
      getComissoes(codigoProduto);
      setloaderPage(false);

      setalertSucess(true);        
      setalertSucessMsg(`Comissão Atualizada com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);
      

    }
  });


  const handleChangeUsuario = async(event, value, reason, details)=>{
    formik.setFieldValue('usuario', JSON.stringify(value));
    // setcodigoDetalhesPessoa(value?.codigo);
  }

  const handleChangeTipo = async(event, value, reason, details)=>{
    formik.setFieldValue('tipo', value.props.value);
    formik.setFieldValue('valor', '');
    formik.setFieldValue('porcentagem', '');
  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
      <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
      </Alert>
    </Snackbar>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
      <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
      </Alert>
    </Snackbar>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Detalhes comissão
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={1} >

            <Grid item xs={12} md={12} style={{ display: 'flex', marginTop: '15px' }}>
              <Grid item style={{width:'100%'}}>
                  <FormControl sx={{ m: 0, width: '100%' }} >
                      <Autocomplete
                          disabled
                          className='input'
                          // disablePortal
                          id="combo-box-usuario"
                          options={usuarios}
                          value={JSON.parse(formik.values.usuario)}
                          getOptionLabel={(option) => option.nome}
                          renderOption={(props, option) => (
                          <li {...props} key={option.id}>{option.nome}</li>
                          )}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={handleChangeUsuario}
                          noOptionsText="Nenhum usuário encontrado."
                          sx={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label="Usuário"  error={formik.touched.usuario && Boolean(formik.errors.usuario)}/>}
                      />
                      <FormHelperText error>{formik.touched.usuario && formik.errors.usuario}</FormHelperText>
                  </FormControl>
              </Grid>

              {/* <Grid>
                  <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
              </Grid> */}

              {
                  // formik.values.cliente !== 'null' &&

                  // <Grid>
                  //     <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                  // </Grid>
              }
            </Grid>
            
            <Grid item xs={12} md={6} >
                <FormControl sx={{ width: '100%', marginTop: '16px' }} >
                  <InputLabel id="label-totalparcelas">Tipo de calculo</InputLabel>
                  <Select
                    labelId="label-tipo"
                    id="cartao-simple-tipo"
                    name="tipo"
                    label="Tipo de calculo"
                    placeholder='Tipo de calculo'
                    value={formik.values.tipo}
                    onChange={handleChangeTipo}
                    error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                  >
                    
                    <MenuItem value={1}>Por Valor</MenuItem>
                    <MenuItem value={2}>Por %</MenuItem>
                  </Select>
                  <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                </FormControl>
            </Grid>

            {
              formik.values.tipo === 1 ?

              <Grid item xs={12} md={6} >
                  <TextField
                      fullWidth
                      id="valor"
                      name="valor"
                      label="Valor"
                      margin="normal"
                      type="text"
                      value={formik.values.valor}
                      onChange={formik.handleChange}
                      error={formik.touched.valor && Boolean(formik.errors.valor)}
                      helperText={formik.touched.valor && formik.errors.valor}
                      onKeyUp={()=>{ formik.setFieldValue('valor', formik.values.valor && mvalor(formik.values.valor)) }}
                      inputProps={{
                          maxLength: 16,
                      }}
                  />
              </Grid>

              :

              <Grid item xs={12} md={6} >
                  <TextField
                      fullWidth
                      id="porcentagem"
                      name="porcentagem"
                      label="Porcentagem"
                      margin="normal"
                      type="text"
                      value={formik.values.porcentagem}
                      onChange={formik.handleChange}
                      error={formik.touched.porcentagem && Boolean(formik.errors.porcentagem)}
                      helperText={formik.touched.porcentagem && formik.errors.porcentagem}
                      onKeyUp={()=>{ formik.setFieldValue('porcentagem', formik.values.porcentagem && mporcentagem(formik.values.porcentagem)) }}
                      inputProps={{
                          maxLength: 7,
                      }}
                  />
              </Grid>
            }

          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} >

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Salvar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}