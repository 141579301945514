import React, { useState, useEffect } from 'react';
import './style.css';

import api from '../../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import Swal from 'sweetalert2';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../../componentes/ButtonSquadPrimaryForInput';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mplaca } from '../../../../vendor/mascaras';

import ModalNovoTipoProduto from '../../../Estoque/Produtos/modals/modalNovoTipoProduto';
import ModalEditTipoProduto from '../../../Estoque/Produtos/modals/modalEditTipoProduto';

export default function ModalNovoEquipamento({ pessoaid, visible, change, getEquipamentos, titleEquipamento }) {

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconInfo = props =>(
      <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
          <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
      </svg>
  )

  const [loaderPage, setloaderPage] = useState(false);

  const [especificacoesdoproduto, setespecificacoesdoproduto] = useState([]);
  const [especificacoesdoprodutovalores, setespecificacoesdoprodutovalores] = useState([]);

  const [tiposproduto, settiposproduto] = useState([]);
  const [showModalNovoTipo, setshowModalNovoTipo] = useState(false);
  const [showModalEditTipo, setshowModalEditTipo] = useState(false);
  const [idEditTipo, setidEditTipo] = useState();
  

  const getTiposProduto = async(termo='', status='', idTipo=null)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemTiposProduto?termo=&status=1`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayTipos = [];
    
    for await (let tip of response.data) {
        let dados = {
          id: tip.id,
          tipo: tip.tipo,
          codigo: tip.code,
        }    
        
        arrayTipos.push(dados);
    }

    settiposproduto(arrayTipos);

    if(idTipo){
      //populando tipo no select
      let tipoid = arrayTipos.find(x => x.id === idTipo);
      tipoid && formik.setFieldValue('tipo', JSON.stringify(tipoid));
      setidEditTipo(idTipo);
    }

    // dadosProduto?.tipo_id && await handleEspecificacoesproduto(dadosProduto?.tipo_id);

  }


  const validationSchema = yup.object({
    tipo: yup
      .string('Selecione o tipo')
      .test({
        message: () => "Selecione o tipo",
        test: async (values) => {
            if(values === 'null'){
                return false
            }else{
                return true
            } 
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      tipo: 'null',
      especificacoesdoproduto: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let tipo = JSON.parse(values.tipo);
    
      await api.post('addEquipamentoPessoa', {
        "pessoaId": pessoaid,
        "tipo": tipo.id,
        "especificacoes": especificacoesdoprodutovalores,
        "especificacoesdoproduto": especificacoesdoproduto,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      getEquipamentos(pessoaid);
      change(false);
      
    }
  });

  useEffect(async()=>{
    if(visible){
      await getTiposProduto();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);


  const handleEspecificacoesproduto = async(tipoId)=>{
        
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post(`listagemEspecificacoesTipoProduto`, {
      "tipoId": tipoId,
      "principal": 1,
    },
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    response = response.data;
    setespecificacoesdoproduto(response);
  }

  const handleChangeTipo = async(event, value, reason, details)=>{

    formik.setFieldValue('tipo', JSON.stringify(value));
    formik.setFieldValue('especificacoesdoproduto', []);
    setespecificacoesdoproduto([]);
    setidEditTipo(value?.id);

    value?.id && handleEspecificacoesproduto(value?.id);

  }

  function primeiraLetraMaiuscula(string) {
    if (!string) {
      return '';
    }
  
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  

  useEffect(() => {
    let tipo = JSON.parse(formik.values.tipo);
  
    // Obtenha os valores válidos
    let valoresValidos = especificacoesdoproduto
      .map((v) => formik.values.especificacoesdoproduto[v.id]?.valor)
      .filter((valor) => typeof valor === 'string' && valor !== 'false' && valor !== 'undefined');
  
    // Obtenha os valores válidos juntamente com seus IDs e ordem
    let valoresComIds = especificacoesdoproduto
      .map((v) => ({
        id: v.id,
        valor: formik.values.especificacoesdoproduto[v.id]?.valor,
        ordem: v.ordem
      }))
      .filter((item) => typeof item.valor === 'string' && item.valor !== 'false' && item.valor !== 'undefined');
  
    // let tituloConcatenado = `${primeiraLetraMaiuscula(tipo?.tipo)} ${valoresValidos.join(' ')}`;
  
    // let titulo = formik.values.tipo !== 'null' ? tituloConcatenado : '';
    // formik.setFieldValue('titulo', titulo);

    setespecificacoesdoprodutovalores(valoresComIds); // Saída com valores, IDs e suas respectivas ordens
  }, [formik.values.especificacoesdoproduto]);


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <ModalNovoTipoProduto visible={showModalNovoTipo} change={setshowModalNovoTipo} getTipos={getTiposProduto} setidEditTipo={setidEditTipo} setshowModalEditTipo={setshowModalEditTipo} /> 
    <ModalEditTipoProduto id={idEditTipo} visible={showModalEditTipo} change={setshowModalEditTipo} getTipos={getTiposProduto}/> 

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo {titleEquipamento?.singular}
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2} >
       
            <Grid container item xs={12} md={12}>
              <div style={{ width: '100%', display: 'flex' }}>
                <Grid item style={{width: '100%'}}>
                    <FormControl sx={{ m: 0, width: '100%' }} >
                        <Autocomplete
                            className='input'
                            // disablePortal
                            id="combo-box-tipo"
                            options={tiposproduto}
                            value={JSON.parse(formik.values.tipo)}
                            getOptionLabel={(option) => option.tipo}
                            renderOption={(props, option) => (
                            <li {...props} key={option.id}>{option.tipo}</li>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={handleChangeTipo}
                            noOptionsText="Nenhum tipo encontrado."
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="Tipo"  error={formik.touched.tipo && Boolean(formik.errors.tipo)}/>}
                        />
                        <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid>
                    <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovoTipo(true) }}/>
                </Grid>
                
                {
                  formik.values.tipo !== 'null' &&
                  <Grid>
                    <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalEditTipo(true) }}/>
                  </Grid>
                }
              </div>
            </Grid>


            {especificacoesdoproduto.map((v) => (
              <Grid key={v.id} item xs={12} md={6}>
                <TextField
                  className="input"
                  fullWidth
                  name={`especificacoesdoproduto[${v.id}]`}
                  label={`${primeiraLetraMaiuscula(v.nome)}`}
                  type="text"
                  value={formik.values.especificacoesdoproduto[v.id]?.valor || ''} // Use "formik.values.especificacoesdoproduto[v.id]?.valor"
                  onChange={(e) => {
                    formik.setFieldValue(`especificacoesdoproduto[${v.id}].valor`, e.target.value);
                  }}
                  error={formik.touched.especificacoesdoproduto && Boolean(formik.errors.especificacoesdoproduto)}
                  helperText={formik.touched.especificacoesdoproduto && formik.errors.especificacoesdoproduto}
                  onKeyUp={()=>{ v.nome === "PLACA" && formik.setFieldValue(`especificacoesdoproduto[${v.id}].valor`, mplaca(formik.values.especificacoesdoproduto[v.id]?.valor)) }}
                  inputProps={{
                    maxLength: v.nome === "PLACA" ? 8 : 140,
                  }}
                />
              </Grid>
            ))}
          
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}