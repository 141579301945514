import React, {useState, useEffect } from 'react';
import api from '../../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor } from '../../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco } from '../../../../vendor/formatar';

export default function ModalEditTitleAnexo({ idAnexo, visible, change, refreshAnexos}) {

  const [loaderPage, setloaderPage] = useState(false);

  
  const detalhesTitulo = async()=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('detalhesTituloAnexoImovel', {
        "id": idAnexo
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let data = response.data;
    if(data.titulo){
      formik.setFieldValue('titulo', data.titulo);
    }else{
        formik.setFieldValue('titulo', '');
    }

    setloaderPage(false);

  }


  useEffect(()=>{
    idAnexo && detalhesTitulo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);


  const validationSchema = yup.object({
    titulo: yup
      .string('Informe o titulo')
      .required('Titulo Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
        titulo: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('updateTituloAnexosImovel', {
        "id": idAnexo,
        "titulo": values.titulo,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      refreshAnexos();
      change(false);

    }
  });

  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Editar título anexo
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="titulo"
                        name="titulo"
                        label="Título"
                        margin="none"
                        type="text"
                        value={formik.values.titulo}
                        onChange={formik.handleChange}
                        error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                        helperText={formik.touched.titulo && formik.errors.titulo}
                        inputProps={{
                            maxLength: 100,
                        }}
                    />
                </Grid>

                
          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon=""  txt="Salvar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}