import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import moment from 'moment';
import api from '../../services/api';
import { useHistory, useParams } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import EditIcon from '@mui/icons-material/Edit';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ButtonSquadPrimaryForInput from '../../componentes/ButtonSquadPrimaryForInput';
import ButtonOutlineRoundWite from '../../componentes/ButtonOutlineRoundWite';
import ButtonDefault from '../../componentes/ButtonDefault';
import ButtonDanger from '../../componentes/ButtonDanger';
import ButtonBlue from '../../componentes/ButtonBlue';
import ButtonGreen from '../../componentes/ButtonGreen';

import { formataDinheiro, formataDinheiroforBanco } from '../../vendor/formatar';

import ModalDetalhesProduto from '../Estoque/Produtos/modals/modalDetalhesProduto';
import ModalNovoProduto from '../Estoque/Produtos/modals/modalNovoProduto';

import ModalNovaPessoa from '../Cadastros/Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../Cadastros/Pessoas/modals/modalDetalhesPessoa';

import ModalVendasIncompletas from './modals/modalVendasIncompletas';
import ModalEditItemVenda from './modals/modalEditItemVenda';

import ModalNovoPagamentoVenda from './modals/modalNovoPagamentoVenda';
import ModalDetalhesVenda from './modals/modalDetalhesVenda';

export default function PontoVenda() {

    let Logo = props =>(
        <svg id="Camada_x0020_1" xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 200.909 45.179">
            <g id="_2401354388688" transform="translate(0 0)">
                <path id="Caminho_11" data-name="Caminho 11" d="M5.117,389.08,0,418.1H8.266l2.612-14.815L25.443,418.1h3.793l5.117-29.021H26.087l-2.648,14.994L9.018,389.08h-3.9Zm31.1,0L31.1,418.1H55.251l1.145-6.549H40.544l.93-5.3H53.462l1.145-6.585H42.619l.716-4.008H58.759l1.181-6.585H36.214Z" transform="translate(0 -382.349)" fill="#fefefe"/>
                <path id="Caminho_12" data-name="Caminho 12" d="M3253.241,1181.227c2.29-4.812,2.386-9.287.115-13.6l-15.726,15.553h13.655Z" transform="translate(-3181.619 -1147.43)" fill="#005440" fillRule="evenodd"/>
                <path id="Caminho_13" data-name="Caminho 13" d="M389.823,20.652c2.271,4.31,2.2,8.763-.089,13.575L400,23.957c2.375-2.374,2.254-4.574,0-6.827C379.719-3.151,350.671-.654,324.15,1.232,355.811,2.292,369.508,2.478,389.823,20.652Z" transform="translate(-318.543 0.001)" fill="#02a680" fillRule="evenodd"/>
                <path id="Caminho_14" data-name="Caminho 14" d="M4581.7,335.436l13.644-13.646h-12.035l-7.2,7.2C4579.116,332,4580.984,333.852,4581.7,335.436Z" transform="translate(-4496.942 -316.223)" fill="#02a680" fillRule="evenodd"/>
                <path id="Caminho_15" data-name="Caminho 15" d="M5411.381,349.7l1.3-7.289a18.4,18.4,0,0,1-10.233,3.084c-6.484,0-10.619-3.575-9.848-7.956.771-4.416,6.168-7.92,12.651-7.92a12.613,12.613,0,0,1,9.007,3.4l1.332-7.64c-2.278-1.858-5.748-2.909-9.777-2.909-9.953,0-19.275,6.728-20.747,15.069s5.467,15.1,15.42,15.1A20.368,20.368,0,0,0,5411.381,349.7Zm23.97-27.23c-9.988,0-19.309,6.728-20.781,15.069s5.467,15.1,15.455,15.1,19.275-6.764,20.747-15.1S5445.339,322.47,5435.352,322.47ZM5422.1,337.539c.771-4.416,6.133-7.92,11.986-7.92s9.917,3.5,9.146,7.92c-.771,4.381-6.1,7.956-11.95,7.956S5421.333,341.92,5422.1,337.539Zm32.8-14.193-5.011,28.421h8.1l2.313-13.212,8.446,9.111h1.4l11.705-9.111-2.313,13.212h8.06l5.012-28.421H5489.6l-18.363,14.3-13.353-14.3Z" transform="translate(-5291.707 -316.891)" fill="#fefefe"/>
                <path id="Caminho_16" data-name="Caminho 16" d="M4576.109,1284.441c12.709,12.8,37.358,16.459,60.582,16.625,19.413.139,46.984-4.518,56.6-5.949-18.619,1.343-43.223,3.6-55.993,2.573-37.755-3.031-52.087-14.116-55.6-19.69C4580.966,1279.58,4579.109,1281.441,4576.109,1284.441Z" transform="translate(-4496.942 -1255.891)" fill="#02a680" fillRule="evenodd"/>
            </g>
        </svg>
    );

    let IconCaixa = props =>(
        <svg id="_28-Cash_register" data-name="28-Cash register" xmlns="http://www.w3.org/2000/svg" width="38.618" height="38.618" viewBox="0 0 38.618 38.618">
            <path id="Caminho_54" data-name="Caminho 54" d="M46.292,14.482H31.81A1.81,1.81,0,0,1,30,12.671V1.81A1.81,1.81,0,0,1,31.81,0H46.292A1.81,1.81,0,0,1,48.1,1.81V12.671A1.81,1.81,0,0,1,46.292,14.482ZM31.81,1.207a.6.6,0,0,0-.6.6V12.671a.6.6,0,0,0,.6.6H46.292a.6.6,0,0,0,.6-.6V1.81a.6.6,0,0,0-.6-.6Z" transform="translate(-11.898 0)" fill="#fff"/>
            <path id="Caminho_55" data-name="Caminho 55" d="M45.431,26.827H40.6a.6.6,0,0,1-.6-.6V22.6a.6.6,0,0,1,.6-.6h4.827a.6.6,0,0,1,.6.6v3.62A.6.6,0,0,1,45.431,26.827ZM41.207,25.62h3.62V23.207h-3.62Z" transform="translate(-15.864 -8.725)" fill="#fff"/>
            <path id="Caminho_56" data-name="Caminho 56" d="M35.6,48.929H3.017A3.021,3.021,0,0,1,0,45.912v-4.6a4.334,4.334,0,0,1,.033-.523l1.65-13.2A1.81,1.81,0,0,1,3.479,26H4.827v1.207H3.479a.6.6,0,0,0-.6.529l-1.65,13.2a3.092,3.092,0,0,0-.023.375v4.6a1.81,1.81,0,0,0,1.81,1.81H35.6a1.81,1.81,0,0,0,1.81-1.81v-4.6a3.128,3.128,0,0,0-.023-.376l-1.65-13.2a.6.6,0,0,0-.6-.529H30.774V26h4.365a1.81,1.81,0,0,1,1.8,1.586l1.65,13.2a4.369,4.369,0,0,1,.033.524v4.6A3.021,3.021,0,0,1,35.6,48.929Z" transform="translate(0 -10.311)" fill="#fff"/>
            <path id="Caminho_57" data-name="Caminho 57" d="M28,26h6.637v1.207H28Z" transform="translate(-11.105 -10.311)" fill="#fff"/>
            <path id="Caminho_58" data-name="Caminho 58" d="M19.878,33.017H6.6a.6.6,0,0,1-.6-.6V30.6a.6.6,0,0,1,.6-.6h1.81v1.207H7.207v.6H19.275v-.6H18.068V30h1.81a.6.6,0,0,1,.6.6v1.81A.6.6,0,0,1,19.878,33.017Z" transform="translate(-2.38 -11.898)" fill="#fff"/>
            <path id="Caminho_59" data-name="Caminho 59" d="M46.877,41.448H33.6a.6.6,0,0,1-.6-.653l.6-7.241a.6.6,0,0,1,.6-.553H46.274a.6.6,0,0,1,.6.553l.6,7.241a.6.6,0,0,1-.6.653ZM34.258,40.241H46.221l-.5-6.034H34.761Z" transform="translate(-13.086 -13.088)" fill="#fff"/>
            <path id="Caminho_60" data-name="Caminho 60" d="M49,34h1.207v7.241H49Z" transform="translate(-19.433 -13.484)" fill="#fff"/>
            <path id="Caminho_61" data-name="Caminho 61" d="M39,34h1.207v7.241H39Z" transform="translate(-15.467 -13.484)" fill="#fff"/>
            <path id="Caminho_62" data-name="Caminho 62" d="M44,34h1.207v7.241H44Z" transform="translate(-17.45 -13.484)" fill="#fff"/>
            <path id="Caminho_63" data-name="Caminho 63" d="M34,41H47.275v1.207H34Z" transform="translate(-13.484 -16.26)" fill="#fff"/>
            <path id="Caminho_64" data-name="Caminho 64" d="M34,37H47.275v1.207H34Z" transform="translate(-13.484 -14.674)" fill="#fff"/>
            <path id="Caminho_65" data-name="Caminho 65" d="M19.258,26.275H9.6a.6.6,0,0,1-.6-.6V13.6a.6.6,0,0,1,1.03-.427l.78.78.78-.78a.6.6,0,0,1,.853,0l.78.78.78-.78a.6.6,0,0,1,.853,0l.78.78.78-.78a.6.6,0,0,1,.853,0l.78.78.78-.78a.6.6,0,0,1,1.03.427V25.672a.6.6,0,0,1-.6.6Zm-9.051-1.207h8.448V15.06l-.177.177a.6.6,0,0,1-.853,0l-.78-.78-.78.78a.6.6,0,0,1-.853,0l-.78-.78-.78.78a.6.6,0,0,1-.853,0l-.78-.78-.78.78a.6.6,0,0,1-.853,0l-.177-.177Z" transform="translate(-3.569 -5.156)" fill="#fff"/>
            <path id="Caminho_66" data-name="Caminho 66" d="M19.878,36.414H6.6a.6.6,0,0,1-.6-.6V34H7.207v1.207H19.275V34h1.207v1.81A.6.6,0,0,1,19.878,36.414Z" transform="translate(-2.38 -13.484)" fill="#fff"/>
            <path id="Caminho_67" data-name="Caminho 67" d="M7,50H39.584v1.207H7Z" transform="translate(-2.776 -19.83)" fill="#fff"/>
            <path id="Caminho_68" data-name="Caminho 68" d="M3,50H4.207v1.207H3Z" transform="translate(-1.19 -19.83)" fill="#fff"/>
            <path id="Caminho_69" data-name="Caminho 69" d="M46.878,13.861H33.6a.6.6,0,0,1-.6-.6V3.6a.6.6,0,0,1,.6-.6H46.878a.6.6,0,0,1,.6.6v1.81H46.275V4.207H34.207v8.448H46.275V6.62h1.207v6.637A.6.6,0,0,1,46.878,13.861Z" transform="translate(-13.088 -1.19)" fill="#fff"/>
            <path id="Caminho_70" data-name="Caminho 70" d="M34.292,57.62H18.6a.6.6,0,0,1-.6-.6V54.6a.6.6,0,0,1,.6-.6H34.292a.6.6,0,0,1,.6.6v2.414A.6.6,0,0,1,34.292,57.62ZM19.207,56.414H33.689V55.207H19.207Z" transform="translate(-7.139 -21.416)" fill="#fff"/>
            <path id="Caminho_71" data-name="Caminho 71" d="M13,18h1.207v1.207H13Z" transform="translate(-5.156 -7.139)" fill="#fff"/>
            <path id="Caminho_72" data-name="Caminho 72" d="M17,18h1.207v1.207H17Z" transform="translate(-6.742 -7.139)" fill="#fff"/>
            <path id="Caminho_73" data-name="Caminho 73" d="M13,21h1.207v1.207H13Z" transform="translate(-5.156 -8.329)" fill="#fff"/>
            <path id="Caminho_74" data-name="Caminho 74" d="M17,21h1.207v1.207H17Z" transform="translate(-6.742 -8.329)" fill="#fff"/>
            <path id="Caminho_75" data-name="Caminho 75" d="M13,25h6.034v1.207H13Z" transform="translate(-5.156 -9.915)" fill="#fff"/>
            <path id="Caminho_76" data-name="Caminho 76" d="M13,29h6.034v1.207H13Z" transform="translate(-5.156 -11.501)" fill="#fff"/>
            <path id="Caminho_77" data-name="Caminho 77" d="M37,7h1.207V8.207H37Z" transform="translate(-14.674 -2.776)" fill="#fff"/>
            <path id="Caminho_78" data-name="Caminho 78" d="M41,7h1.207V8.207H41Z" transform="translate(-16.26 -2.776)" fill="#fff"/>
            <path id="Caminho_79" data-name="Caminho 79" d="M37,10h1.207v1.207H37Z" transform="translate(-14.674 -3.966)" fill="#fff"/>
            <path id="Caminho_80" data-name="Caminho 80" d="M41,10h1.207v1.207H41Z" transform="translate(-16.26 -3.966)" fill="#fff"/>
        </svg>
    );

    let IconOperador = props =>(
        <svg id="man" xmlns="http://www.w3.org/2000/svg" width="35" viewBox="0 0 41.167 61">
        <path id="Caminho_86" data-name="Caminho 86" d="M208.19,168.993a1.017,1.017,0,0,0-1.016,1.016v1.779a1.016,1.016,0,1,0,2.033,0v-1.779A1.017,1.017,0,0,0,208.19,168.993Z" transform="translate(-190.767 -147.521)" fill="#fff"/>
        <path id="Caminho_87" data-name="Caminho 87" d="M258.19,172.8a1.017,1.017,0,0,0,1.016-1.016v-1.779a1.016,1.016,0,1,0-2.033,0v1.779A1.017,1.017,0,0,0,258.19,172.8Z" transform="translate(-234.414 -147.521)" fill="#fff"/>
        <path id="Caminho_88" data-name="Caminho 88" d="M214.817,235.383a1.016,1.016,0,0,0-1.437,1.438,4.452,4.452,0,0,0,6.289,0,1.016,1.016,0,0,0-1.438-1.438A2.417,2.417,0,0,1,214.817,235.383Z" transform="translate(-195.924 -205.216)" fill="#fff"/>
        <path id="Caminho_89" data-name="Caminho 89" d="M220.52,327.639c-4-1.387-5.489-1.9-5.476-1.892a5.888,5.888,0,0,1-2.965-2.291,1.018,1.018,0,0,0-1.656-.21c-.312.349,1.267-1.416-3.833,4.284h-2.667l-1.423-1.59a1.016,1.016,0,1,0-1.515,1.355l1.4,1.563-2.2,12.823a1.016,1.016,0,1,0,2,.343l2.133-12.462h1.862l2.133,12.462a1.017,1.017,0,1,0,2-.343l-1.741-10.17,2.817,2.8a1.017,1.017,0,0,0,1.7-.479l.79-3.234a1.016,1.016,0,1,0-1.975-.482l-.362,1.483-3.113-3.1,2.674-2.988a7.933,7.933,0,0,0,3.262,2.151l5.467,1.889a5.865,5.865,0,0,1,3.943,5.545v6.763a1.016,1.016,0,1,0,2.033,0V335.1A7.9,7.9,0,0,0,220.52,327.639Z" transform="translate(-184.655 -281.882)" fill="#fff"/>
        <path id="Caminho_90" data-name="Caminho 90" d="M91.181,44.964A7.905,7.905,0,0,0,94.7,39.712,13.617,13.617,0,0,0,111.905,29.7a3.852,3.852,0,0,0,1.7.641,15.536,15.536,0,0,1-3.178,6.906,1.016,1.016,0,0,0,1.584,1.275A17.562,17.562,0,0,0,115.727,30a3.88,3.88,0,0,0,2.23-3.508V22.425a1.016,1.016,0,0,0-2.033,0v4.066a1.842,1.842,0,1,1-3.685,0c0-7.528.022-7.018-.05-7.239a1.016,1.016,0,0,0-1.087-.694A25.26,25.26,0,0,1,92.274,13.55,1.017,1.017,0,0,0,90.7,14a7.417,7.417,0,0,1-4.918,4.591,1.016,1.016,0,0,0-.739.978v5.467a2.1,2.1,0,0,1-1.135-1.861V10.79a2.416,2.416,0,0,1,2.418-2.412,1.016,1.016,0,0,0,.946-.638,9.045,9.045,0,0,1,8.438-5.706h8.6a9.084,9.084,0,0,1,9.081,9.1v3.553a1.016,1.016,0,1,0,2.033,0V11.132A11.116,11.116,0,0,0,104.311,0h-8.6a11.064,11.064,0,0,0-10.07,6.4,4.45,4.45,0,0,0-3.764,4.392V23.172a4.138,4.138,0,0,0,3.179,4.021,13.573,13.573,0,0,0,3.412,8.525,1.016,1.016,0,0,0,1.521-1.349A11.546,11.546,0,0,1,87.082,26.7V20.3a9.372,9.372,0,0,0,4.981-4.388A27.236,27.236,0,0,0,110.206,20.7V26.7A11.578,11.578,0,0,1,94.217,37.382a1.018,1.018,0,0,0-1.423.944,5.865,5.865,0,0,1-3.955,5.527c-7.034,2.42-5.155,1.765-5.478,1.885A8.01,8.01,0,0,0,78.044,53.2v6.763a1.016,1.016,0,0,0,2.033,0V53.2a5.851,5.851,0,0,1,3.97-5.545c.455-.158-1.8.619,5.256-1.809l1.492,6.11a1.016,1.016,0,1,0,1.975-.482Z" transform="translate(-78.044 0)" fill="#fff"/>
        <path id="Caminho_91" data-name="Caminho 91" d="M308.748,437.993H305.19a1.016,1.016,0,0,0,0,2.033h3.558a1.016,1.016,0,0,0,0-2.033Z" transform="translate(-275.442 -382.342)" fill="#fff"/>
        </svg>
    );

    let IconCart = props =>(
        <svg id="ecommerce" xmlns="http://www.w3.org/2000/svg" width="70" viewBox="0 0 107 107">
            <path id="Caminho_81" data-name="Caminho 81" d="M94.053,52.15a3.337,3.337,0,0,0-4.119,2.321l-1.451,5.216a3.44,3.44,0,0,1-3.217,2.5H35.444a3.335,3.335,0,0,1-3.223-2.6L23.848,22.062H36.781a3.344,3.344,0,1,0,0-6.687H22.356L21.126,9.844A10.042,10.042,0,0,0,11.369,2H3.344a3.344,3.344,0,0,0,0,6.688h8.025a3.317,3.317,0,0,1,3.223,2.6l11.1,49.755a10.063,10.063,0,0,0,9.75,7.831H85.266A10.184,10.184,0,0,0,94.9,61.545l1.471-5.283a3.323,3.323,0,0,0-2.321-4.113Z" transform="translate(0 11.375)" fill="#636363"/>
            <path id="Caminho_82" data-name="Caminho 82" d="M15.031,13A10.031,10.031,0,1,0,25.063,23.031,10.043,10.043,0,0,0,15.031,13Zm0,13.375a3.344,3.344,0,1,1,3.344-3.344A3.345,3.345,0,0,1,15.031,26.375Z" transform="translate(28.437 73.938)" fill="#636363"/>
            <path id="Caminho_83" data-name="Caminho 83" d="M20.031,13A10.031,10.031,0,1,0,30.063,23.031,10.043,10.043,0,0,0,20.031,13Zm0,13.375a3.344,3.344,0,1,1,3.344-3.344A3.345,3.345,0,0,1,20.031,26.375Z" transform="translate(56.875 73.938)" fill="#636363"/>
            <path id="Caminho_84" data-name="Caminho 84" d="M37.094,0A30.094,30.094,0,1,0,67.188,30.094,30.13,30.13,0,0,0,37.094,0Zm0,53.5A23.406,23.406,0,1,1,60.5,30.094,23.432,23.432,0,0,1,37.094,53.5Z" transform="translate(39.812)" fill="#636363"/>
            <path id="Caminho_85" data-name="Caminho 85" d="M33.378,3.975,19.027,18.333l-4.32-4.327a3.343,3.343,0,0,0-4.728,4.728l6.688,6.688a3.311,3.311,0,0,0,2.361.983,3.359,3.359,0,0,0,2.367-.976L38.113,8.71a3.348,3.348,0,0,0-4.735-4.735Z" transform="translate(51.192 17.064)" fill="#636363"/>
        </svg>
    );

    let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
    );

    let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
    );

    let IconPagament = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 91.356 91.353">
            <g id="money-flow_1_" data-name="money-flow (1)" transform="translate(-1.997 -1.998)">
                <path id="Caminho_234" data-name="Caminho 234" d="M22.786,19.786a3.262,3.262,0,0,1,3.262,3.262h6.524a9.786,9.786,0,0,0-6.524-9.186V10H19.524v3.862a9.763,9.763,0,0,0,3.262,18.972A3.262,3.262,0,1,1,19.524,36.1H13a9.786,9.786,0,0,0,6.524,9.186v3.862h6.524V45.281A9.763,9.763,0,0,0,22.786,26.31a3.262,3.262,0,0,1,0-6.524Z" transform="translate(24.889 18.1)" fill="#4a4a4a"/>
                <path id="Caminho_235" data-name="Caminho 235" d="M79.423,55.038A32.515,32.515,0,0,0,40.309,15.923,19.572,19.572,0,1,0,15.922,40.31,32.515,32.515,0,0,0,55.04,79.421,19.572,19.572,0,1,0,79.423,55.038ZM8.531,21.576a13.015,13.015,0,0,1,25.551-3.52A32.773,32.773,0,0,0,18.059,34.08a13.028,13.028,0,0,1-9.528-12.5Zm13.048,26.1a26.1,26.1,0,1,1,26.1,26.1,26.1,26.1,0,0,1-26.1-26.1ZM73.77,86.816a13.028,13.028,0,0,1-12.5-9.528A32.773,32.773,0,0,0,77.29,61.265a13.015,13.015,0,0,1-3.52,25.551Z" transform="translate(0 0)" fill="#4a4a4a"/>
                <path id="Caminho_236" data-name="Caminho 236" d="M32.742,21.865l4.612,4.612,9.786-9.786a3.262,3.262,0,0,0,0-4.612L37.354,2.293,32.742,6.905l4.218,4.218H22v6.524H36.959Z" transform="translate(45.246 0.667)" fill="#4a4a4a"/>
                <path id="Caminho_237" data-name="Caminho 237" d="M17.354,26.905l-4.612-4.612L2.955,32.079a3.262,3.262,0,0,0,0,4.612l9.786,9.786,4.612-4.612-4.218-4.218H28.1V31.123H13.136Z" transform="translate(0.007 45.907)" fill="#4a4a4a"/>
            </g>
        </svg>
    );

    let IconCancel = props =>(
        <svg id="close" xmlns="http://www.w3.org/2000/svg" width="17.001" height="17" viewBox="0 0 17.001 17">
            <path id="Caminho_94" data-name="Caminho 94" d="M16.565,14.12,3.242.8a1.487,1.487,0,0,0-2.1,0l-.7.7a1.487,1.487,0,0,0,0,2.1L13.76,16.924a1.487,1.487,0,0,0,2.1,0l.7-.7a1.486,1.486,0,0,0,0-2.1Zm0,0" transform="translate(0 -0.36)" fill="#f44336"/>
            <path id="Caminho_95" data-name="Caminho 95" d="M13.76.8.436,14.121a1.487,1.487,0,0,0,0,2.1l.7.7a1.487,1.487,0,0,0,2.1,0L16.565,3.6a1.486,1.486,0,0,0,0-2.1l-.7-.7a1.487,1.487,0,0,0-2.1,0Zm0,0" transform="translate(0 -0.362)" fill="#f44336"/>
        </svg>
    );

    let IconComissao = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="20.093" height="19.5" viewBox="0 0 20.093 19.5">
          <path id="comissoes" d="M6.75,13v7A1.752,1.752,0,0,1,5,21.75H4A1.752,1.752,0,0,1,2.25,20V13A1.752,1.752,0,0,1,4,11.25H5A1.752,1.752,0,0,1,6.75,13ZM17.5,9.25H15a.75.75,0,0,0,0,1.5h1.25V11a.75.75,0,0,0,1.5,0v-.275A2.244,2.244,0,0,0,17.5,6.25h-1a.75.75,0,0,1,0-1.5H19a.75.75,0,0,0,0-1.5H17.75V3a.75.75,0,0,0-1.5,0v.275A2.244,2.244,0,0,0,16.5,7.75h1a.75.75,0,0,1,0,1.5Zm4.64,6.34a1.961,1.961,0,0,0-2.37-.97l-1.7.57c-.12.04-.74.56-1.07.56H12a.75.75,0,0,1,0-1.5h3.68a1.767,1.767,0,0,0-1.1-1.19l-2.2-.76c-.04-.01-.08-.02-.13-.03l-.29-.05a.57.57,0,0,0-.13-.01H8.15a2.951,2.951,0,0,1,.1.79v7a3.081,3.081,0,0,1-.09.75h7.37a2.779,2.779,0,0,0,1.23-.29l4.5-2.25a1.962,1.962,0,0,0,.88-2.62Z" transform="translate(-2.25 -2.25)" fill="#4a4a4a"/>
        </svg>
    );
    
    let { codigo } = useParams()
    let history = useHistory();
    let csvInstance = useRef();

    const [loaderPage, setloaderPage] = useState(false);

    const [alertError, setalertError] = useState(false);
    const [alertErrorMsg, setalertErrorMsg] = useState('');
  
    const [alertSucess, setalertSucess] = useState(false);
    const [alertSucessMsg, setalertSucessMsg] = useState('');
  
    const [produtos, setprodutos] = useState([]);
    const [codigoDetalhesProduto, setcodigoDetalhesProduto] = useState();
  
    const [showModalNovoProduto, setshowModalNovoProduto] = useState(false);  
    const [showModalDetalhesProduto, setshowModalDetalhesProduto] = useState(false);  

    const [detalhesVenda, setdetalhesVenda] = useState([]);


    const [itens, setitens] = useState([]);
    const [itensSelecionados, setitensSelecionados] = useState([]);
    const [pageSizeDataGriditens, setpageSizeDataGriditens] = useState(10);
    const [loadingDataGriditens, setloadingDataGriditens] = useState(false);

    const [pagamentos, setpagamentos] = useState([]);
    const [pagamentosSelecionados, setpagamentosSelecionados] = useState([]);
    const [pageSizeDataGridPagamentos, setpageSizeDataGridPagamentos] = useState(10);
    const [loadingDataGridPagamentos, setloadingDataGridPagamentos] = useState(false);

    const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
    const [dadosUser, setdadosUser] = useState([]);
    const [pessoas, setpessoas] = useState([]);

    const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
    const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
    const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();
    
    const [valorTotaldebitos, setvalorTotaldebitos] = useState(0);
    const [valorTotalcreditos, setvalorTotalcreditos] = useState(0);
    const [valorSubTotalVenda, setvalorSubTotalVenda] = useState(0);
    const [valordescontoVenda, setvalordescontoVenda] = useState(0);
    const [valorTotalVenda, setvalorTotalVenda] = useState(0);


    const [showModalVendasIncompletas, setshowModalVendasIncompletas] = useState(false);
    const [showModalnovoPagamento, setshowModalnovoPagamento] = useState(false);

    const [showModaleditItem, setshowModaleditItem] = useState(false);
    const [idDetalhesItem, setidDetalhesItem] = useState();
    const [tipoDetalhesItem, settipoDetalhesItem] = useState();

    const [showModalDetalhesVenda, setshowModalDetalhesVenda] = useState(false);
    
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlertError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setalertError(false);
    };

    const handleCloseAlertSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setalertSucess(false);
    };

    const getdataUser = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        if(auth){
          let response = await api.post('detalhesUsuarioLogado', {}, 
          {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
          });
    
          setdadosUser(response.data);
        }
    }
  
    const getdetalhesVenda = async(codigo)=>{
        
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`detalhesVenda`, {
            "vendaId": false,
            "vendaCod": codigo
          },{
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
        });

        response = response.data;
        setdetalhesVenda(response);
        response && await getPagamentos(response.id);

        !response && history.push('/vendas/ponto-de-venda');

        if(response.clienteId){
            formik.setFieldValue('cliente', JSON.stringify({
                codigo: response.clienteCodigo,
                nome: response.cliente,
                id: response.clienteId
            }));

            setcodigoDetalhesPessoa(response.clienteCodigo);
        }else{
            formik.setFieldValue('cliente', 'null');
        }
        
    }

    const permissoesUsuario = async()=>{

        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let permissoes = await api.post('permissoesUsuario', {}, 
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        });
        

        setpermissoesdoUsuario(permissoes.data.vendas);
    }
    

    const getProdutos = async(productCodigo=null)=>{

        setloaderPage(true);
    
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let produtos = await api.post('estoqueAtual', {}, 
        {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
            }
        });

        let servicos = await api.get(`listagemServicos?termo=&status=1`, {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
        });

        let arrayProdutos = [];
        
        for await (let produto of produtos.data) {
            let dados = {
                id: produto.id,
                codigo: produto.codigo,
                codigoInterno: produto.codigoInterno,
                nome: produto.titulo,
                estoque_atual: produto.estoque_atual,
                ean: produto.ean,
                precoVenda: produto.precoVenda,
            }    
            
            arrayProdutos.push(dados);
        }

        for await (let servico of servicos.data) {
            let dados = {
                id: `servico-${servico.id}`,
                codigo: servico.codigo,
                codigoInterno: '',
                nome: servico.titulo,
                estoque_atual: 1,
                ean: '',
                precoVenda: servico.precoVenda,
            }    
            
            arrayProdutos.push(dados);
        }
    
        setprodutos(arrayProdutos);
    
        if(productCodigo){
            //populando produto no select
            let productcode = arrayProdutos.find(x => x.codigo === productCodigo);
            //productcode && formik.setFieldValue('produto', JSON.stringify(productcode));
            //setcodigoDetalhesProduto(productCodigo);
            //setshowModalDetalhesProduto(true);
            await handleNewVendaOrAddItem(productcode.id);

        }
    
        setloaderPage(false);
    }
    
    useEffect(async()=>{
        getProdutos();
        getPessoas();
        permissoesUsuario();
        getdataUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    useEffect(async()=>{

        if(codigo){
            await getdetalhesVenda(codigo.toString());
            await getItens(codigo);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[codigo]);


    const validationSchema = yup.object({
        produto: yup
          .string('Selecione o Produto')
          .required('Produto Obrigatório')
          .test({
            message: () => "Selecione o Produto",
            test: async (values) => {
              if (values) { 
                if (values === 'null') { return false; } else{ return true; }
              }
            },
        }),
        quantidade: yup
          .string('Insira a quantidade')
          .required('Quantidade Obrigatória'),
        valor: yup
          .string('Insira o Valor')
          .required('Valor Obrigatório'),
    });

    const formik = useFormik({
    initialValues: {
        produto: 'null',
        cliente: 'null',
        quantidade: 1,
        valor: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

    //   let produto = JSON.parse(values.produto)

    //   //GET DADOS USER
    //   let auth = localStorage.getItem("auth");
    //   auth = JSON.parse(auth);

    //   await api.put('updateItemCompra', {
    //     "id": id,
    //     "produto_id": produto.id,
    //     "quantidade": values.quantidade,
    //     "valor": formataDinheiroforBanco(values.valor),

    //   }, 
    //   {
    //     headers: {
    //       "Content-type": "application/json",
    //       "Authorization": `Bearer ${auth.token}`
    //     }
    //   }).then(async(result) => {
        
    //     setalertSucess(true);        
    //     setalertSucessMsg(`Item Atualizado com sucesso!`);    
    //     setTimeout(() => { setalertSucess(false) }, 6000);

    //     formik.resetForm();
    //     refreshItens(compraId);
    //     change(false);

    //   }).catch(async(error) => {
        
    //     setalertError(true);        
    //     setalertErrorMsg(`Houve um erro: ${error}`);    
    //     setTimeout(() => { setalertError(false) }, 6000);

    //   });
        
    }
    });

    const getPessoas = async(ClienteId=null, codigoCliente=null)=>{

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let response = await api.get(`listagemPessoas`, {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });
    
        let arrayPessoas = [];
        
        for await (let pessoa of response.data) {
            let dados = {
                codigo: pessoa.codigo,
                nome: pessoa.nome,
                id: pessoa.id
            }    
            
            arrayPessoas.push(dados);
        }
    
        setpessoas(arrayPessoas);
    
        if(codigoCliente){
          //populando cliente no select
          let clientecode = arrayPessoas.find(x => x.codigo === codigoCliente);
          clientecode && formik.setFieldValue('cliente', JSON.stringify(clientecode));
          setcodigoDetalhesPessoa(codigoCliente);
          setshowModalDetalhesPessoa(true);

          if(detalhesVenda?.id){

            //GET DADOS USER
            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);

            await api.put(`updateClienteVenda`, {
                "codigo": codigo,
                "cliente": clientecode?.id,
            },{
                headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
                }
            });
          }

    
        }else if(ClienteId){
          //populando fornecedor no select
          let cliente = arrayPessoas.find(x => x.id === ClienteId);
          cliente && formik.setFieldValue('cliente', JSON.stringify(cliente));
          setcodigoDetalhesPessoa(cliente.codigo);

        }
    
    }

    const handleQuantidadeItemTable = async(id, quantidade)=>{
    
        if(quantidade > 0 ){
          //GET DADOS USER
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
          
          await api.put('updateQuantidadeItemVenda', {
            "id": id,
            "quantidade": quantidade,
          }, 
          {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
          }).then(async(result) => {
            
            setalertSucess(true);        
            setalertSucessMsg(`Quantidade Atualizada com sucesso!`);    
            setTimeout(() => { setalertSucess(false) }, 6000);
    
            getItens(codigo);
    
          }).catch(async(error) => {
            
            setalertError(true);        
            setalertErrorMsg(`Houve um erro: ${error}`);    
            setTimeout(() => { setalertError(false) }, 6000);
    
          });
        }
    
    }
    
    function ComponenteEditQuantidadeTable({ value }) {
        const [cellValue, setCellValue] = useState(value);
      
        const handleBlur = (e) => {
          const newValue = e.target.value;
        };
      
        const handleChange = (e) => {
          setCellValue(e.target.value);
        };
      
        return (
          <TextField
            style={{ padding: '10px', width: '80px', textAlign: 'center' }}
            type="text"
            value={cellValue}
            onBlur={handleBlur}
            onChange={handleChange}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
        );
    }

    const columnsDataGridItens = [
        // { field: 'id', hide: true, headerName: 'ID', width: 90  },
        // {
        //     field: 'usuario_id',
        //     headerName: '$',
        //     type: 'string',
        //     flex: 0,
        //     minWidth: 30,
        //     editable: false,
        //     renderCell: (params) => ( //renderiza componente
        //       params.value && <p title="Item contém comissão"><IconComissao /></p>
        //     ),        
        // },
        {
          field: 'titulo',
          headerName: 'Produto',
          type: 'string',
          flex: 1,
          minWidth: 220,
          editable: false,
          renderCell: (params) => ( //renderiza componente

            params.row.status_comissao === 'comissao_existente' ?
            <>
                <p title="Item contém comissão"><IconComissao />&nbsp;&nbsp;</p>
                <p title={params.value}>{params.value}</p>
            </>
            
            :

            <p title={params.value}>{params.value}</p>

          ),        
        },
        {
          field: 'quantidade',
          headerName: 'Quantidade',
          type: 'string',
          flex: 0,
          minWidth: 100,
          editable: true, // tornar a célula editável
          renderCell: (params) => ( //renderiza componente
            <p>
            {(params.value)} <EditIcon style={{ fontSize: '12px' }}/>
            </p>
          ),
          renderEditCell: (params) => (
            <ComponenteEditQuantidadeTable value={params.value} />
          ),   
        },
        {
          field: 'vUnitario',
          headerName: 'Valor Unitário',
          type: 'string',
          flex: 0,
          minWidth: 120,
          editable: false,
          renderCell: (params) => ( //renderiza componente
            <p>{formataDinheiro(params.value)}</p>
          ),        
        },
        {
            field: 'subTotal',
            headerName: 'Sub Total',
            type: 'string',
            flex: 0,
            minWidth: 100,
            editable: false,
            renderCell: (params) => ( //renderiza componente
              <p>{formataDinheiro(params.value)}</p>
            ),        
        },
        {
            field: 'desconto',
            headerName: 'Desconto',
            type: 'string',
            flex: 0,
            minWidth: 100,
            editable: false,
            renderCell: (params) => ( //renderiza componente
              <p>{formataDinheiro(params.value)}</p>
            ),        
        },
        {
          field: 'vTotal',
          headerName: 'Valor Total',
          type: 'string',
          flex: 0,
          minWidth: 100,
          editable: false,
          renderCell: (params) => ( //renderiza componente
            <p>{formataDinheiro(params.value)}</p>
          ),        
        },
        {
            field: 'id',
            headerName: 'Ação',
            type: 'string',
            flex: 0,
            minWidth: 30,
            editable: false,
            renderCell: (params) => { //renderiza componente
              return <div><IconCancel /></div>
            },
        },
    ];
    
    function nenhumItemEncontrado(){
        return(
            <GridOverlay>
            <div className="container-no-data-grid">
                <IconCart/>
                <p style={{ fontSize:'16px', fontWeight: 'bold', color: '#636363' }}>Caixa livre</p>
            </div>
            </GridOverlay>
        )
    }

    const columnsDataGridPagamentos = [
        // { field: 'id', hide: true, headerName: 'ID', width: 90  },
        {
            field: 'data',
            headerName: 'Data',
            flex: 0,
            minWidth: 140,
            editable: false,
            valueFormatter: (params) => {
                return moment(params.value).format('DD/MM/YYYY HH:mm');
            },
        },
        {
            field: 'formaPagamento',
            headerName: 'Forma',
            flex: 0,
            minWidth: 120,
            editable: false,
            renderCell: (params) => ( //renderiza componente
                <p title={params.value}>{params.value}</p>
            ),
        },
        {
            field: 'lancamento',
            headerName: 'Lançamento',
            flex: 0,
            minWidth: 120,
            editable: false,
            renderCell: (params) => ( //renderiza componente
                <p title={params.value}>{params.value}</p>
            ),
        },
        // {
        //     field: 'vencimento',
        //     headerName: 'Vencimento',
        //     flex: 1,
        //     minWidth: 100,
        //     editable: false,
        //     valueFormatter: (params) => {
            
        //     if(params.value){
        //         return moment(params.value).format('DD/MM/YYYY');
        //     }

        //     },
        // },
        {
            field: 'valor',
            headerName: 'Valor',
            type: 'string',
            flex: 0,
            minWidth: 130,
            editable: false,
            renderCell: (params) => ( //renderiza componente
            <p style={params.value < 0 ? {color: 'red'} : params.value > 0 ? {color: '#0554C2'} : {color: '#000000'}}>{formataDinheiro(params.value)}</p>
            ),        
        },
        {
            field: 'id',
            headerName: 'Ação',
            flex: 0,
            minWidth: 50,
            editable: false,
            renderCell: (params) => { //renderiza componente
              return <div><IconCancel /></div>
            },
        },
    ];

    function nenhumPagamentoEncontrado(){
        return(
            <GridOverlay>
            <div className="container-no-data-grid">
                <IconPagament/>
                <p style={{ fontSize:'13px', fontWeight: 'bold', color: '#636363' }}>Pagamento não informado</p>
            </div>
            </GridOverlay>
        )
    }


    const getItens = async(codigo)=>{

        setloadingDataGriditens(true);
    
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let response = await api.post(`listagemItensVenda`, {
          "vendaId": false,
          "vendaCodigo": codigo
        },{
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        }); 
    
        let totalItens = 0
        let subtotalItens = 0
        let descontoItens = 0
    
        for await (let data of response.data) {
          totalItens += Number(data.vTotal);
          subtotalItens += Number(data.subTotal);
          descontoItens += Number(data.desconto);
        }
    
        setvalorTotalVenda(totalItens);
        setvalorSubTotalVenda(subtotalItens);
        setvalordescontoVenda(descontoItens);

        
      
        setloadingDataGriditens(false);
        setitens(response.data);
    }

    const addItem = async(idProduto, codigo)=>{
        
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        await api.post(`addItemVenda`, {
            "venda_codigo": codigo,
            "compraItem_id": null,
            "produto_id": typeof idProduto === 'number' ? idProduto : null,
            "servico_id": typeof idProduto !== 'number' ? Number(idProduto?.replace('servico-', '')) : null,
            "quantidade": 1
          },{
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
        });

        formik.setFieldValue('produto', 'null');
        await getItens(codigo);

        // response = response.data;
        // setdetalhesVenda(response);


    }

    const handleNewVendaOrAddItem = async(idProduto)=>{
        if(detalhesVenda?.id){
            await addItem(idProduto, codigo);
        }else{
            
            let cliente = JSON.parse(formik.values.cliente);

            //GET DADOS USER
            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);

            let response = await api.post(`novaVendaIncompleta`, {
                "cliente": cliente ? cliente.id : null
            },{
                headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
                }
            });

            let codigo = response.data.codigo.toString();
            history.push(`/vendas/ponto-de-venda/${codigo}`);
            await addItem(idProduto, codigo);

        }
    }
    
    const handleChangeProduto = async(event, value, reason, details)=>{
        if(value){

            let estoqueAtual = Number(value.estoque_atual);

            if(estoqueAtual <= 0){
                
                Swal.fire({
                    title: 'Atenção, produto sem estoque!',
                    html: `O estoque atual do produto: <br> <b>${value.nome}</b> <br> é de <b style="color: red">${value.estoque_atual}un.</b>
                    <br> <b>Deseja continuar?</b>`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#02A680',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, continuar',
                    cancelButtonText: 'Cancelar',
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        formik.setFieldValue('produto', JSON.stringify(value));
                        setcodigoDetalhesProduto(value?.codigo);
                        await handleNewVendaOrAddItem(value.id);
                    }
                });

            }else{
                formik.setFieldValue('produto', JSON.stringify(value));
                setcodigoDetalhesProduto(value?.codigo);
                await handleNewVendaOrAddItem(value.id);
            }            
        }
    }

    const handleChangeCliente = async(event, value, reason, details)=>{
        formik.setFieldValue('cliente', JSON.stringify(value));
        setcodigoDetalhesPessoa(value?.codigo);

        if(detalhesVenda?.id){

            //GET DADOS USER
            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);

            await api.put(`updateClienteVenda`, {
                "codigo": codigo,
                "cliente": value?.id,
            },{
                headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
                }
            });

            await getdetalhesVenda(codigo.toString());
        }
    }


    const handleAguardar = async()=>{
        setdetalhesVenda([]);
        setitens([]);
        setpagamentos([]);
        setvalorTotalVenda(0);
        formik.setFieldValue('cliente', 'null');
        setcodigoDetalhesProduto("");
        setcodigoDetalhesPessoa("");
        history.push('/vendas/ponto-de-venda');
    }

    const handleFinalizarVenda = async()=>{

        let toalPagamentos = valorTotaldebitos + valorTotalcreditos;

        if(Number(valorTotalVenda.toFixed(2)) <= toalPagamentos){
            Swal.fire({
                title: 'Confirma a venda?',
                text: "Você Deseja finalizar a venda?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#02A680',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, finalizar',
                cancelButtonText: 'Não',
            }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        //GET DADOS USER
                        let auth = localStorage.getItem("auth");
                        auth = JSON.parse(auth);
            
                        await api.post(`finalizarVenda`, {
                            "vendaId": detalhesVenda?.id
                        },{
                            headers: {
                            "Content-type": "application/json",
                            "Authorization": `Bearer ${auth.token}`
                            }
                        }); 
            
                        Swal.fire({
                            title: 'Venda Finalizada!',
                            text: 'Parabéns! A sua venda foi concluída com êxito!',
                            icon: 'success',
                            confirmButtonColor: '#02A680',
                        }).then(async(result) => {
                            if (result.isConfirmed) {
                                setshowModalDetalhesVenda(true);
                                // handleAguardar();  
                            }
                        })
        
                    }catch(error){
                        Swal.fire(
                            'Ops, Houve um problema!',
                            `${error}`,
                            'error'
                        );
                    }
                }
            })

        }else{
            Swal.fire(
                'Ops, divergência de valores!',
                'Total informado menor que o total da venda.',
                'error'
            );
        }

        

    }  

    const TeclasAtalho = () => {

        const handleKeyDown = (event) => {

            if(event.key === 'F8'){
                detalhesVenda?.id && setshowModalnovoPagamento(true);
            }
            if(event.key === 'F9'){
                detalhesVenda?.id && handleDeleteVenda(detalhesVenda?.id)
            }
            if(event.key === 'F4'){
                detalhesVenda?.id && handleAguardar();
            }
            if(event.key === 'F2'){
               detalhesVenda?.id && handleFinalizarVenda();
            }

            if (event.altKey && (event.key === 'a' || event.key === 'A')) {
                setshowModalVendasIncompletas(true);
            }
        };
      
        useEffect(() => {
          window.addEventListener('keydown', handleKeyDown);
      
          return () => {
            window.removeEventListener('keydown', handleKeyDown);
          };
        }, []);
      
        return <></>;
    };
    

    const calculaValorApagar = (valorTotaldebitos, valorTotalcreditos, totalVenda )=>{
    
        let totalapagar =  valorTotaldebitos - (totalVenda);
        totalapagar = Math.sign(totalapagar) === -1 ? totalapagar * -1 : totalapagar;
        totalapagar = totalapagar - valorTotalcreditos;
    
        return totalapagar;
    
    }

    const getPagamentos = async(vendaId)=>{

        setloadingDataGridPagamentos(true);
    
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let response = await api.post(`listagemPagamentosVenda`, {
            "vendaId": vendaId
        },{
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
        });
    
        let totCredito = 0;
        let totDebito = 0;
    
        for await (let data of response.data) {
          if(data.operacao === 2){
            totCredito += Number(data.valor);
          }else if(data.operacao === 1){
            totDebito += Number(data.valor);
          }
        }
    
        setvalorTotaldebitos(totDebito)
        setvalorTotalcreditos(totCredito)
      
        setloadingDataGridPagamentos(false);
        setpagamentos(response.data);
    
    }

    const handleDeletePagamento = async(props)=>{
        Swal.fire({
          title: 'Tem certeza?',
          text: "Deseja deletar o(s) Pagamento(s) selecionado(s)?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#02A680',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim deletar',
          cancelButtonText: 'Cancelar',
        }).then(async(result) => {
          if (result.isConfirmed) {
    
            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);
            
            let pg = pagamentos.find(x => x.id === props.id);

            await api.delete('deletePagamentosVenda', {
              headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
              },
              data: { "pagamentos": [{ 
                    idLancamento: pg.idLancamento,
                    tipoLancamento: pg.tipoLancamento,
                }]
              }
            });
    
            await getPagamentos(detalhesVenda.id);
    
            // Swal.fire(
            //   'Deletado!',
            //   'Pagamento(s) deletado(s) com sucesso!',
            //   'success'
            // )
    
          }
        })
    }
    
    const handleDeleteItem = async(props)=>{
        Swal.fire({
            title: 'Tem certeza?',
            text: "Deseja deletar o Item?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#02A680',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim deletar',
            cancelButtonText: 'Cancelar',
        }).then(async(result) => {
            if (result.isConfirmed) {

                let auth = localStorage.getItem("auth");
                auth = JSON.parse(auth);

                await api.delete('deleteItemVenda', {
                    headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${auth.token}`
                    },
                    data: { "itensIds": [{ id: props.id }] }
                });

                await getItens(codigo);

                // Swal.fire(
                //     'Deletado!',
                //     'Item deletado com sucesso!',
                //     'success'
                // )

            }
        })
    }

    const detalhesItemVenda = async(props)=>{

        setidDetalhesItem(props.row.id);
        settipoDetalhesItem(props.row.tipo);
        setshowModaleditItem(true);
        
    }

    const handleDeleteVenda = async(vendaId)=>{
        
        Swal.fire({
            title: 'Tem certeza?',
            text: "Deseja deletar a venda?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#02A680',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim deletar',
            cancelButtonText: 'Cancelar',
        }).then(async(result) => {
            if (result.isConfirmed) {

                setloaderPage(true);

                let auth = localStorage.getItem("auth");
                auth = JSON.parse(auth);
                
                await api.delete('deleteVenda', {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": `Bearer ${auth.token}`
                    },
                    data: {  "vendasIds": [{ id: vendaId }] }
                });  
                
                await handleAguardar();
                setloaderPage(false);

                // Swal.fire(
                //     'Deletado!',
                //     'Item deletado com sucesso!',
                //     'success'
                // )

            }
        })
        

    }


    function displaytotalApagar() {
        let total = ( valorTotalVenda - (valorTotaldebitos + valorTotalcreditos) )
        
        if (total < 0) {
            return 0;
        }else{
            return total;
        }
    }

    function displaytotalTroco() {
        let total = ( valorTotalVenda - (valorTotaldebitos + valorTotalcreditos) )
        
        if (total < 0) {
            return total*-1;
        }else{
            return 0;
        }
    }


    return (
        <>
        <Backdrop
            sx={{ color: '#fff', zIndex: '2000' }}
            open={loaderPage}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
            <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
                {alertErrorMsg}
            </Alert>
        </Snackbar>

        <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
            <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
                {alertSucessMsg}
            </Alert>
        </Snackbar>

        <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
        <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

        <ModalDetalhesProduto codigo={codigoDetalhesProduto} visible={showModalDetalhesProduto} change={setshowModalDetalhesProduto} />
        <ModalNovoProduto visible={showModalNovoProduto} change={setshowModalNovoProduto} atualizarProdutos={getProdutos} />

        <ModalVendasIncompletas visible={showModalVendasIncompletas} change={setshowModalVendasIncompletas} getdetalhesVenda={getdetalhesVenda} setitens={setitens} codigoVenda={codigo}/>
        <ModalEditItemVenda id={idDetalhesItem} tipo={tipoDetalhesItem} vendaCodigo={codigo} visible={showModaleditItem} change={setshowModaleditItem} refreshItens={getItens}/>

        <ModalNovoPagamentoVenda vendaId={detalhesVenda?.id} dadosVenda={detalhesVenda} totalapagar={calculaValorApagar(valorTotaldebitos, valorTotalcreditos, valorTotalVenda)} visible={showModalnovoPagamento} change={setshowModalnovoPagamento} refreshPagamentos={getPagamentos} />

        <ModalDetalhesVenda id={detalhesVenda?.id} visible={showModalDetalhesVenda} change={setshowModalDetalhesVenda} handleAguardar={handleAguardar}/>

        <TeclasAtalho />

        <div className='container-pdv'>
            
            <div className='header-pdv'>
                <div className='container'>
                    <div><Logo /></div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconCaixa />
                        <div style={{ lineHeight: '0px', marginLeft: '10px', color: '#fff' }}>
                            <p style={{fontSize: '13px'}}>Ponto de</p>
                            <h3>Venda</h3>
                        </div>
                    </div>

                    <div>
                        <ButtonOutlineRoundWite txt="Sair do PDV" onClick={()=>{ history.push("/vendas"); }}/>
                    </div>
                </div>
            </div>

            <div id="corpo-pdv" className='container'>

                <Grid container item xs={12} md={12}>
                    <div style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid item style={{width: '100%'}}>
                            <FormControl fullWidth={true} >
                                <Autocomplete
                                    className='input'
                                    // disablePortal
                                    id="combo-box-produto"
                                    options={produtos}
                                    value={JSON.parse(formik.values.produto)}
                                    getOptionLabel={(option) => `${option.nome} ${option.ean != null ? ' - '+option.ean : ''} ${option.codigoInterno != null ? ' - '+option.codigoInterno : ''}`} 
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>{`${option.nome}  - ${formataDinheiro(option.precoVenda)} ${option.ean != null ? ' - '+option.ean : ''} ${option.codigoInterno != null ? ' - '+option.codigoInterno : ''}`}</li>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={handleChangeProduto}
                                    noOptionsText="Nenhum produto encontrado."
                                    sx={{ width: '100%' }}
                                    autoHighlight  // Foca automaticamente o primeiro item 
                                    renderInput={(params) => <TextField {...params} label="Produto"  error={formik.touched.produto && Boolean(formik.errors.produto)}/>}
                                />
                                <FormHelperText error>{formik.touched.produto && formik.errors.produto}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid>
                            <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovoProduto(true) }}/>
                        </Grid>

                        {
                        formik.values.produto !== 'null' &&

                        <Grid>
                            <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesProduto(true) }}/>
                        </Grid>
                        }
                    </div>
                </Grid>

                <Grid container spacing={2} flexGrow="1" style={{height: '100%', paddingBlock: '15px'}}>

                    <Grid container spacing={2} item xs={12} md={5}>

                        <Grid item xs={12} md={12} style={{ marginBottom: '20px' }}>
                            <div style={{ height: '10%', marginBottom: '20px', display: 'flex' }}>
                                <Grid item style={{width:'100%'}}>
                                    <FormControl sx={{ m: 0, width: '100%' }} >
                                        <Autocomplete
                                            className='input'
                                            disablePortal
                                            id="combo-box-cliente"
                                            options={pessoas}
                                            value={JSON.parse(formik.values.cliente)}
                                            getOptionLabel={(option) => option.nome}
                                            renderOption={(props, option) => (
                                            <li {...props} key={option.id}>{option.nome}</li>
                                            )}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onChange={handleChangeCliente}
                                            noOptionsText="Nenhum cliente encontrado."
                                            sx={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} label="Cliente"  error={formik.touched.cliente && Boolean(formik.errors.cliente)}/>}
                                        />
                                        <FormHelperText error>{formik.touched.cliente && formik.errors.cliente}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid>
                                    <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                                </Grid>

                                {
                                    formik.values.cliente !== 'null' &&

                                    <Grid>
                                        <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                                    </Grid>
                                }
                            </div>

                            <div style={{ background: '#fff', height: '90%' }}>
                                <DataGrid
                                    className="datatable-green-defalt"
                                    sx={{ minHeight: '100%' }}
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    autoHeight
                                    rows={pagamentos}
                                    columns={columnsDataGridPagamentos}
                                    pageSize={pageSizeDataGridPagamentos}
                                    onPageSizeChange={(newPageSize) => setpageSizeDataGridPagamentos(newPageSize)}
                                    rowsPerPageOptions={[10, 50, 100]} 
                                    // checkboxSelection={permissoesdoUsuario[1]?.delete}
                                    disableSelectionOnClick
                                    editMode='row'
                                    loading={loadingDataGridPagamentos}
                                    disableColumnMenu={true}
                                    onSelectionModelChange={(props)=>{
                                        setpagamentosSelecionados(props)
                                    }}
                                    // onRowClick
                                    onCellClick={(props)=>{
                                        if(props.field !== "__check__" && props.field !== "id"){
                                            // detalhesPagamento(props.id);
                                        }
                                        if(props.field === "id"){
                                            handleDeletePagamento(props)
                                        }
                                    }}
                                    components={{
                                        NoRowsOverlay: nenhumPagamentoEncontrado,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={7} flexGrow="1">
                        <div style={{ background: '#fff', height: '100%' }}>
                            <DataGrid
                                className="datatable-green-defalt"
                                sx={{ minHeight: '100%'}}
                                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                autoHeight
                                rows={itens}
                                columns={columnsDataGridItens}
                                pageSize={pageSizeDataGriditens}
                                onPageSizeChange={(newPageSize) => setpageSizeDataGriditens(newPageSize)}
                                rowsPerPageOptions={[10, 50, 100]} 
                                // checkboxSelection
                                disableSelectionOnClick
                                editMode='row'
                                onRowEditStop={(e, v)=>{
                                    handleQuantidadeItemTable(e.id, v.target.value)
                                }}
                                loading={loadingDataGriditens}
                                disableColumnMenu={true}
                                onSelectionModelChange={(props)=>{
                                    setitensSelecionados(props);
                                }}
                                // onRowClick
                                onCellClick={(props)=>{
                                    if(props.field !== "__check__" && props.field !== "id" && props.field !== "quantidade"){
                                        detalhesItemVenda(props);
                                    }

                                    if(props.field == "id"){
                                        handleDeleteItem(props)
                                    }
                                }}
                                components={{
                                    NoRowsOverlay: nenhumItemEncontrado,
                                }}
                            />
                        </div>
                    </Grid>
                
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <div style={{ maxWidth: '240px' }}>
                            <ButtonDefault txt="ADICIONAR PAGAMENTO (F8)" disabled={Boolean(!detalhesVenda?.id)} height="38px" onClick={()=>{ setshowModalnovoPagamento(true) }}/>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ minWidth: '145px' }}>
                            <ButtonDanger txt="CANCELAR (F9)" disabled={Boolean(!detalhesVenda?.id)} height="38px" onClick={()=>{ handleDeleteVenda(detalhesVenda?.id) }}/>
                        </div>
                        <div style={{ minWidth: '145px', marginLeft: '10px' }}>
                            <ButtonBlue txt="AGUARDAR (F4)" disabled={Boolean(!detalhesVenda?.id)} height="38px" onClick={()=>{ handleAguardar() }}/>
                        </div>
                        <div style={{ minWidth: '145px', marginLeft: '10px' }}>
                            <ButtonGreen txt="FINALIZAR (F2)" disabled={Boolean(!detalhesVenda?.id)} height="38px" onClick={()=>{ handleFinalizarVenda() }}/>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className='footer-pdv'>
                <div className='container'>

                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconOperador />
                            <div style={{ lineHeight: '0', marginLeft: '10px', color: '#fff' }}>
                                <p style={{fontSize:'12px'}}><b>Operador</b></p>
                                <p style={{fontSize:'14px'}}>{dadosUser?.nome}</p>
                            </div>
                        </div>

                        <div className='box-info' style={{ cursor: 'pointer' }} onClick={()=>{ setshowModalVendasIncompletas(true); }}>
                            <p>PEDIDOS EM ABERTO (ALT + A)</p>
                        </div>
                    </div>

                    <div style={{ display: 'flex'}}>

                        <div className='box-subtotal'>
                            <p>Subtotal</p>
                            <h3>{formataDinheiro(valorSubTotalVenda)}</h3>
                        </div>     

                        <div className='box-subtotal'>
                            <p>Total desconto</p>
                            <h3>{formataDinheiro(valordescontoVenda)}</h3>
                        </div>    

                        <div className='box-subtotal'>
                            <p>Total</p>
                            <h3>{formataDinheiro(valorTotalVenda)}</h3>
                        </div> 

                        <div className='box-subtotal'>
                            <p>Troco</p>
                            <h3>{formataDinheiro(displaytotalTroco())}</h3>
                        </div>      



                        {/* <div className='box-subtotal'>
                            <p>Pagamentos</p>
                            <h3>{formataDinheiro(valorTotaldebitos)}</h3>
                        </div>
                        <div className='box-subtotal'>
                            <p>Troco</p>
                            <h3>{formataDinheiro(displaytotalTroco())}</h3>
                        </div>

                        <div className='box-subtotal'>
                            <p>A pagar</p>
                            <h3>{formataDinheiro(displaytotalApagar())}</h3>
                            <h3>{formataDinheiro(calculaValorApagar(valorTotaldebitos, valorTotalcreditos, valorTotalVenda ))}</h3>
                        </div>
                        <div className='box-subtotal'>
                            <p>Total</p>
                            <h2>{formataDinheiro(valorTotalVenda)}</h2>
                        </div> */}
                    </div>

                </div>
            </div>

        </div>
        </>
    );
}