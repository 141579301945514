import React from 'react';
import './style.css';
import Button from '@mui/material/Button';

export default function ButtonDanger({ type, icon, txt, onClick, component, disabled = false, height = '50px', margin = '10px 0' }) {

  return (
    <Button style={{ height: height, margin: margin, }} disabled={disabled} component={component} onClick={onClick} id='btn-danger-padrao' type={type}>
        {icon} {txt}
    </Button>
  );
}