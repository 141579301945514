import React, {useState, useRef } from 'react';
import './style.css';

import api from '../../../services/api';
import axios from 'axios';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';

export default function ModalCancelarNfe({ vendaid, visible, change, detalhesVenda }) {

  const inputNumero = useRef(null);
  const [loaderPage, setloaderPage] = useState(false);
  
  const validationSchema = yup.object({
    motivo: yup
      .string('Insira o Motivo')
      .required('Motivo Obrigatório')
      .min(15, 'O Motivo deve ter pelo menos 15 caracteres')
      .max(255, 'O Motivo deve ter no máximo 255 caracteres'),
  });

  const formik = useFormik({
    initialValues: {
      motivo: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      Swal.fire({
          title: 'Deseja continuar?',
          text: "Confirma o envio do Cancelamento da NF-e?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#02A680',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, enviar',
          cancelButtonText: 'Cancelar',
      }).then(async(result) => {
          if (result.isConfirmed) {
            setloaderPage(true);

            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);

            let response = await api.post('cancelamentoNFEvenda', {
                "vendaId": vendaid,
                "xJust": values.motivo,
            }, 
            {
              headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
              }
            });

            response = response.data;
            setloaderPage(false);
            detalhesVenda();
            formik.resetForm();
            change(false);

            Swal.fire(
                'Retorno NFe!',
                `${response.retornoMsg}`,
                `${response.type}`
            )

          }
      })
    
    }
  });



  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Cancelar NF-e
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2} > 
          
            <Grid item xs={12} md={12}>
              <TextField
                  className='input'
                  fullWidth
                  id="motivo"
                  name="motivo"
                  label="Motivo do cancelamento"
                  type="text"
                  multiline
                  rows={3}
                  value={formik.values.motivo}
                  onChange={formik.handleChange}
                  error={formik.touched.motivo && Boolean(formik.errors.motivo)}
                  helperText={formik.touched.motivo && formik.errors.motivo}
                  inputProps={{
                      maxLength: 255,
                  }}
              />
            </Grid>
     
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Enviar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}