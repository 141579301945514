import React, {useState, useEffect } from 'react';

import api from '../../../services/api';
import axios from 'axios';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco } from '../../../vendor/formatar';

import ModalDetalhesProduto from '../../Estoque/Produtos/modals/modalDetalhesProduto';
import ModalNovoProduto from '../../Estoque/Produtos/modals/modalNovoProduto';

export default function ModalEditItemOs({ id, tipo, visible, change, refreshItens }) {

  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  );

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconCancel = props =>(
    <svg id="close" xmlns="http://www.w3.org/2000/svg" width="17.001" height="17" viewBox="0 0 17.001 17">
        <path id="Caminho_94" data-name="Caminho 94" d="M16.565,14.12,3.242.8a1.487,1.487,0,0,0-2.1,0l-.7.7a1.487,1.487,0,0,0,0,2.1L13.76,16.924a1.487,1.487,0,0,0,2.1,0l.7-.7a1.486,1.486,0,0,0,0-2.1Zm0,0" transform="translate(0 -0.36)" fill="#f44336"/>
        <path id="Caminho_95" data-name="Caminho 95" d="M13.76.8.436,14.121a1.487,1.487,0,0,0,0,2.1l.7.7a1.487,1.487,0,0,0,2.1,0L16.565,3.6a1.486,1.486,0,0,0,0-2.1l-.7-.7a1.487,1.487,0,0,0-2.1,0Zm0,0" transform="translate(0 -0.362)" fill="#f44336"/>
    </svg>
  );

  let IconComissao = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="20.093" height="19.5" viewBox="0 0 20.093 19.5">
        <path id="comissoes" d="M6.75,13v7A1.752,1.752,0,0,1,5,21.75H4A1.752,1.752,0,0,1,2.25,20V13A1.752,1.752,0,0,1,4,11.25H5A1.752,1.752,0,0,1,6.75,13ZM17.5,9.25H15a.75.75,0,0,0,0,1.5h1.25V11a.75.75,0,0,0,1.5,0v-.275A2.244,2.244,0,0,0,17.5,6.25h-1a.75.75,0,0,1,0-1.5H19a.75.75,0,0,0,0-1.5H17.75V3a.75.75,0,0,0-1.5,0v.275A2.244,2.244,0,0,0,16.5,7.75h1a.75.75,0,0,1,0,1.5Zm4.64,6.34a1.961,1.961,0,0,0-2.37-.97l-1.7.57c-.12.04-.74.56-1.07.56H12a.75.75,0,0,1,0-1.5h3.68a1.767,1.767,0,0,0-1.1-1.19l-2.2-.76c-.04-.01-.08-.02-.13-.03l-.29-.05a.57.57,0,0,0-.13-.01H8.15a2.951,2.951,0,0,1,.1.79v7a3.081,3.081,0,0,1-.09.75h7.37a2.779,2.779,0,0,0,1.23-.29l4.5-2.25a1.962,1.962,0,0,0,.88-2.62Z" transform="translate(-2.25 -2.25)" fill="#4a4a4a"/>
      </svg>
  );


  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [loaderPage, setloaderPage] = useState(false);
  const [produtos, setprodutos] = useState([]);
  const [codigoDetalhesProduto, setcodigoDetalhesProduto] = useState();

  const [detalhesItem, setdetalhesItem] = useState([]);

  const [comissoes, setcomissoes] = useState([]);
  const [comissoesSelecionadas, setcomissoesSelecionadas] = useState([]);
  const [pageSizeDataGridComissoes, setpageSizeDataGridComissoes] = useState(10);
  const [loadingDataGridComissoes, setloadingDataGridComissoes] = useState(false);

  const [showModalNovoProduto, setshowModalNovoProduto] = useState(false);  
  const [showModalDetalhesProduto, setshowModalDetalhesProduto] = useState(false);  

  const [usuarios, setusuarios] = useState([]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };

  const validationSchema = yup.object({
    produto: yup
      .string('Selecione o Produto')
      .required('Produto Obrigatório')
      .test({
        message: () => "Selecione o Produto",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
    }),
    quantidade: yup
      .string('Insira a quantidade')
      .required('Quantidade Obrigatória'),
    valor: yup
      .string('Insira o Valor')
      .required('Valor Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      produto: 'null',
      quantidade: 1,
      valor: '',
      desconto: '',
      usuario: 'null',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      let produto = JSON.parse(values.produto)
      
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let usuario = JSON.parse(values.usuario);

      await api.put('updateItemOs', {
        "id": id,
        "produto_id": typeof produto.id === 'number' ? produto.id : null,
        "servico_id": typeof produto.id !== 'number' ? Number(produto.id?.replace('servico-', '')) : null,
        "quantidade": values.quantidade,
        "valor": formataDinheiroforBanco(values.valor),
        "desconto": formataDinheiroforBanco(values.desconto),
        "usuario_id": null // usuario?.id ? usuario?.id : null,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      }).then(async(result) => {
        
        setalertSucess(true);        
        setalertSucessMsg(`Item Atualizado com sucesso!`);    
        setTimeout(() => { setalertSucess(false) }, 6000);

        formik.resetForm();
        refreshItens();
        change(false);

      }).catch(async(error) => {
        
        setalertError(true);        
        setalertErrorMsg(`Houve um erro: ${error}`);    
        setTimeout(() => { setalertError(false) }, 6000);

      });
      
    }
  });


  const getProdutos = async(productCodigo=null)=>{

    setloaderPage(true);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let produtos = await api.post('estoqueAtual', {}, 
    {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
        }
    });

    let servicos = await api.get(`listagemServicos?termo=&status=1`, {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
    });

    let arrayProdutos = [];
    
    for await (let produto of produtos.data) {
        let dados = {
            id: produto.id,
            codigo: produto.codigo,
            nome: produto.titulo,
            estoque_atual: produto.estoque_atual,
            ean: produto.ean,
            precoVenda: produto.precoVenda,
        }    
        
        arrayProdutos.push(dados);
    }

    for await (let servico of servicos.data) {
        let dados = {
            id: `servico-${servico.id}`,
            codigo: servico.codigo,
            nome: servico.titulo,
            estoque_atual: 1,
            ean: '',
            precoVenda: servico.precoVenda,
        }    
        
        arrayProdutos.push(dados);
    }

    setprodutos(arrayProdutos);

    if(productCodigo){
      //populando produto no select
      let productcode = arrayProdutos.find(x => x.codigo === productCodigo);
      productcode && formik.setFieldValue('produto', JSON.stringify(productcode));
      setcodigoDetalhesProduto(productCodigo);
      setshowModalDetalhesProduto(true);
    }

    setloaderPage(false);
    return arrayProdutos;
  }

  const getComissoes = async(idItem)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('listagemComissoesItemOs', {
      "idItem": idItem
    }, 
    {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
        }
    });
    
    response.data && setcomissoes(response.data);
    
  }

  const detalhesItemOrcamento = async()=>{
    setloaderPage(true);

    let arrProdutos = await getProdutos();

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

  
    id && api.post('detalhesItemOs', {
        "id": id
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    }).then( async response => {
        
        let data = response.data;

        if(tipo === 'produto'){
          //populando produto no select
          let productid = arrProdutos.find(x => x.id === data.produto_id);
          productid && formik.setFieldValue('produto', JSON.stringify(productid));
          setcodigoDetalhesProduto(productid.codigo);

        }else if(tipo === 'servico'){
          //populando produto no select
          let productid = arrProdutos.find(x => x.id === `servico-${data.servico_id}`);
          productid && formik.setFieldValue('produto', JSON.stringify(productid));
          setcodigoDetalhesProduto("");
        }

        formik.setFieldValue('quantidade', data.quantidade);
        formik.setFieldValue('valor', formataDinheiro(data.valor));
        formik.setFieldValue('desconto', formataDinheiro(data.desconto));

        setdetalhesItem(data);

        await getComissoes(data.id);
        await getPessoas(null, null);

    }).catch((error) => {
        // change(false)
    });

    setloaderPage(false);
    
  }

  const getPessoas = async(ClienteId=null, codigoCliente=null)=>{
    formik.setFieldValue('usuario', 'null');

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemUsuarios`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];

    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.usuario_id
        }    
        
        arrayPessoas.push(dados);
    }

    if(codigoCliente){
      //populando cliente no select
      let clientecode = arrayPessoas.find(x => x.codigo === codigoCliente);
      clientecode && formik.setFieldValue('usuario', JSON.stringify(clientecode));
      // setcodigoDetalhesPessoa(codigoCliente);
      // setshowModalDetalhesPessoa(true);

    }else if(ClienteId){
      //populando cliente no select
      let cliente = arrayPessoas.find(x => x.id === ClienteId);
      cliente && formik.setFieldValue('usuario', JSON.stringify(cliente));
      // setcodigoDetalhesPessoa(cliente.codigo);
    }

    setusuarios(arrayPessoas);

  }

  useEffect(async()=>{

    if(visible){
      formik.resetForm();
      await detalhesItemOrcamento();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[visible]);

  const handleChangeProduto = async(event, value, reason, details)=>{

    if(value){

        let estoqueAtual = Number(value.estoque_atual);

        if(estoqueAtual <= 0){
            
            Swal.fire({
                title: 'Atenção, produto sem estoque!',
                html: `O estoque atual do produto: <br> <b>${value.nome}</b> <br> é de <b style="color: red">${value.estoque_atual}un.</b>
                <br> <b>Deseja continuar?</b>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#02A680',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, continuar',
                cancelButtonText: 'Cancelar',
            }).then(async(result) => {
                if (result.isConfirmed) {
                  formik.setFieldValue('produto', JSON.stringify(value));
                  formik.setFieldValue('valor', formataDinheiro(value.precoVenda));
              
                  let stringidsp = value.id.toString();
                  let produtoServico = stringidsp.indexOf('servico-');
              
                  if (produtoServico !== -1) { //SE FOR SERVIÇO
                    setcodigoDetalhesProduto('');
                  }else{ // SE FOR PRODUTO
                    setcodigoDetalhesProduto(value?.codigo);
                  }
                }
            });

        }else{
          formik.setFieldValue('produto', JSON.stringify(value));
          formik.setFieldValue('valor', formataDinheiro(value.precoVenda));
      
          let stringidsp = value.id.toString();
          let produtoServico = stringidsp.indexOf('servico-');
      
          if (produtoServico !== -1) { //SE FOR SERVIÇO
            setcodigoDetalhesProduto('');
          }else{ // SE FOR PRODUTO
            setcodigoDetalhesProduto(value?.codigo);
          }
        }            
    }

  }


  const handleChangeUsuario = async(event, value, reason, details)=>{
    formik.setFieldValue('usuario', JSON.stringify(value));
    // setcodigoDetalhesPessoa(value?.codigo);
    if(value.id){
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let response = await api.post('addComissaoItemOs', {
        "idItem": detalhesItem.id,
        "usuario_id": value.id
      }, 
      {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
          }
      });

      if(response?.data?.msg){
        Swal.fire(
          'Error!',
          response?.data?.msg,
          'error'
        )
      }

      await getComissoes(detalhesItem.id);
    }

    formik.setFieldValue('usuario', 'null');

  }

  const handleDeleteComissao = async(props)=>{
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar a Comissão?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#02A680',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        await api.delete('deleteComissaoItemOs', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: { 
            "id": props.id
          }
        });

        await getComissoes(detalhesItem.id);

        Swal.fire(
          'Deletado!',
          'Comissão deletada com sucesso!',
          'success'
        )

      }
    })
  }

  const columnsDataGridComissoes = [
    // { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'usuario',
      headerName: 'Comissão',
      type: 'string',
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params) => ( //renderiza componente
        <p>{params.value}</p>
      ),        
    },
    {
        field: 'id',
        headerName: 'Ação',
        type: 'string',
        flex: 0,
        minWidth: 30,
        editable: false,
        renderCell: (params) => { //renderiza componente
          return <div><IconCancel /></div>
        },
    },
  ];

  function nenhumaComissaoEncontrada(){
    return(
        <GridOverlay>
        <div className="container-no-data-grid" style={{ marginBottom: '50px' }}>
            <IconComissao/>
            <p style={{ fontSize:'14px'}}>Sem comissões</p>
        </div>
        </GridOverlay>
    )
  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
            {alertErrorMsg}
        </Alert>
    </Snackbar>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
            {alertSucessMsg}
        </Alert>
    </Snackbar>

    <ModalDetalhesProduto codigo={codigoDetalhesProduto} visible={showModalDetalhesProduto} change={setshowModalDetalhesProduto} />
    <ModalNovoProduto visible={showModalNovoProduto} change={setshowModalNovoProduto} atualizarProdutos={getProdutos} />

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Editar item
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} >
            
            <Grid container item xs={12} md={12}>
                <Grid item xs={formik.values.produto === 'null' ? 10.1 : 8.2} md={formik.values.produto === 'null' ? 10.6 : 9.3} >
                    <FormControl fullWidth={true} >
                        <Autocomplete
                            className='input'
                            // disablePortal
                            id="combo-box-produto"
                            options={produtos}
                            value={JSON.parse(formik.values.produto)}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>{option.nome}</li>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={handleChangeProduto}
                            noOptionsText="Nenhum produto encontrado."
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="Produto"  error={formik.touched.produto && Boolean(formik.errors.produto)}/>}
                        />
                        <FormHelperText error>{formik.touched.produto && formik.errors.produto}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid>
                    <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovoProduto(true) }}/>
                </Grid>

                {
                formik.values.produto !== 'null' && codigoDetalhesProduto &&

                  <Grid>
                      <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesProduto(true) }}/>
                  </Grid>
                }

            </Grid>

            <Grid item xs={12} md={3} >
                <TextField
                    fullWidth
                    id="quantidade"
                    name="quantidade"
                    label="Quantidade"
                    margin="normal"
                    type="text"
                    value={formik.values.quantidade}
                    onChange={formik.handleChange}
                    error={formik.touched.quantidade && Boolean(formik.errors.quantidade)}
                    helperText={formik.touched.quantidade && formik.errors.quantidade}
                    onKeyUp={()=>{ formik.setFieldValue('quantidade', formik.values.quantidade <= 0 ? 1 : mnumero(formik.values.quantidade)) }}
                    inputProps={{
                        maxLength: 10,
                    }}
                />
            </Grid>

            <Grid item xs={12} md={4.5} >
                <TextField
                    fullWidth
                    id="valor"
                    name="valor"
                    label="Valor uni."
                    margin="normal"
                    type="text"
                    value={formik.values.valor}
                    onChange={formik.handleChange}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    onKeyUp={()=>{ formik.setFieldValue('valor', formik.values.valor && mvalor(formik.values.valor)) }}
                    inputProps={{
                        maxLength: 16,
                    }}
                />
            </Grid>

            <Grid item xs={12} md={4.5} >
                <TextField
                    fullWidth
                    id="desconto"
                    name="desconto"
                    label="Desconto"
                    margin="normal"
                    type="text"
                    value={formik.values.desconto}
                    onChange={formik.handleChange}
                    error={formik.touched.desconto && Boolean(formik.errors.desconto)}
                    helperText={formik.touched.desconto && formik.errors.desconto}
                    onKeyUp={()=>{ formik.setFieldValue('desconto', formik.values.desconto && mvalor(formik.values.desconto)) }}
                    inputProps={{
                        maxLength: 16,
                    }}
                />
            </Grid>

            <Grid item xs={12} md={12} style={{ display: 'flex', marginTop: '15px' }}>
              <Grid item style={{width:'100%'}}>
                  <FormControl sx={{ m: 0, width: '100%' }} >
                      <Autocomplete
                          className='input'
                          // disablePortal
                          id="combo-box-usuario"
                          options={usuarios}
                          value={JSON.parse(formik.values.usuario)}
                          getOptionLabel={(option) => option.nome}
                          renderOption={(props, option) => (
                          <li {...props} key={option.id}>{option.nome}</li>
                          )}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={handleChangeUsuario}
                          noOptionsText="Nenhum usuário encontrado."
                          sx={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label="Comissão para o Usuário"  error={formik.touched.usuario && Boolean(formik.errors.usuario)}/>}
                      />
                      <FormHelperText error>{formik.touched.usuario && formik.errors.usuario}</FormHelperText>
                  </FormControl>
              </Grid>

              {/* <Grid>
                  <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
              </Grid> */}

              {
                  // formik.values.cliente !== 'null' &&

                  // <Grid>
                  //     <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                  // </Grid>
              }
            </Grid>

            <Grid item xs={12} md={12}>
              <DataGrid
                className="datatable-green-defalt"
                sx={{ minHeight: '100%' }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                autoHeight
                rows={comissoes}
                columns={columnsDataGridComissoes}
                pageSize={pageSizeDataGridComissoes}
                onPageSizeChange={(newPageSize) => setpageSizeDataGridComissoes(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]} 
                // checkboxSelection={permissoesdoUsuario[1]?.delete}
                disableSelectionOnClick
                editMode='row'
                loading={loadingDataGridComissoes}
                hideFooter
                disableColumnMenu={true}
                onSelectionModelChange={(props)=>{
                    setcomissoesSelecionadas(props)
                }}
                // onRowClick
                onCellClick={(props)=>{
                    if(props.field !== "__check__" && props.field !== "id"){
                        // detalhesPagamento(props.id);
                    }
                    if(props.field === "id"){
                        handleDeleteComissao(props)
                    }
                }}
                components={{
                    NoRowsOverlay: nenhumaComissaoEncontrada,
                }}
              />
          </Grid>

        </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Salvar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}