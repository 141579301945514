import React from 'react';
import './style.css';
import Button from '@mui/material/Button';

export default function ButtonOutlineRoundWite({ type, icon, txt, onClick }) {

  return (
    <Button onClick={onClick} id='btn-outiline-wite-round' type={type}>
        {icon} {txt}
    </Button>
  );
}