import React, {useEffect, useState} from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config
import moment from 'moment';
import { formataDinheiro } from '../../../vendor/formatar';


export default function DocumentPDFprodutos({ produtos, custototal, totalprecovenda }) {

  const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            display: 'flex',
            flexDirection: 'column',
            padding: '20px'
        },
        header:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
        },  
        row: {
            display: 'flex',
            flexDirection: 'row',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '7px',
        },
        rowBG: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#f6f6f8',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        rowHeader:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#000',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
            marginTop: '10px'
        },
        txtrowHeader:{
            fontSize: '11px',
            color: '#fff',
            fontWeight: 'bold'
        },
        txtrow:{
            fontSize: '10px'
        },
        rowSaldo:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#ecedee',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
            paddingVertical: '5px'
        },
        txtrowSaldo:{
            fontSize: '11px',
            color: '#000',
            fontWeight: 'bold'
        },
  });
  
  return (
    <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>

        
        <View style={styles.header}>
            <Text>Estoque de produtos</Text>
        </View>


        <View style={ styles.rowHeader}>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Cod. Interno</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>EAN</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Tipo</Text>
            </View>
            <View style={{ display: 'flex', flex: '3' }}>
                <Text style={styles.txtrowHeader}>Título</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Estoque</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Setor</Text>
            </View>
            <View style={{ display: 'flex', flex: '1', paddingRight: '15px' }}>
                <Text style={styles.txtrowHeader}>Preço de venda</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Última compra</Text>
            </View>
        </View>

        {produtos?.map((v, k) =>{

            return (
                <View key={k} style={  k++ % 2 === 0 ? styles.row : styles.rowBG }>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.codigoInterno}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.ean}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1'}}>
                        <Text style={styles.txtrow}>{v.tipo_produto}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '3', paddingRight: '10px' }}>
                        <Text style={styles.txtrow}>{v.titulo}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1'}}>
                        <Text style={styles.txtrow}>{v.estoque_atual}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1'}}>
                        <Text style={styles.txtrow}>{v.nome_setor}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{formataDinheiro(v.precoVenda)}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{formataDinheiro(v.ultimo_valor_compra)}</Text>
                    </View>
                </View>
            )
        })}

        <View style={ styles.rowSaldo }>

            <View style={{ display: 'flex', flex: '4' }}></View>

            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>Custo Total</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(custototal)}</Text>
            </View>

            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>Valor total de venda</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(totalprecovenda)}</Text>
            </View>
        
        </View>
        
        </Page>
    </Document> 
  );
}