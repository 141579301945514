import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";
import './style.css';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


import Footer from '../../../componentes/Footer';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

import api from '../../../services/api';
import ModalNovaPessoa from '../../Cadastros/Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../../Cadastros/Pessoas/modals/modalDetalhesPessoa';
import SubMenuEstoque from '../subMenu';

export default function NovaCompra() {

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
      <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  )

  let history = useHistory();

  const [step, setstep] = useState(0);
  const [errorStep1, seterrorStep1] = useState(false);
  const [errorStep2, seterrorStep2] = useState(false);

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  const [pessoas, setpessoas] = useState([]);

  const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
  const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSucess(false);
  };
  
  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.compras);
  }

  const getPessoas = async(fornecedorId=null, codigoFornecedor=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    setpessoas(arrayPessoas);


    if(codigoFornecedor){
      //populando fornecedor no select
      let fornecedorcode = arrayPessoas.find(x => x.codigo === codigoFornecedor);
      fornecedorcode && formik.setFieldValue('fornecedor', JSON.stringify(fornecedorcode));
      setcodigoDetalhesPessoa(codigoFornecedor);
      setshowModalDetalhesPessoa(true);

    }else if(fornecedorId){
      //populando fornecedor no select
      let fornecedor = arrayPessoas.find(x => x.id === fornecedorId);
      fornecedor && formik.setFieldValue('fornecedor', JSON.stringify(fornecedor));
      setcodigoDetalhesPessoa(fornecedor.codigo);
    }

  }


  useEffect(async()=>{
    await permissoesUsuario();
    await getPessoas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);



  const validationSchema = yup.object({
    fornecedor: yup
      .string('Selecione o Fornecedor')
      .required('Fornecedor Obrigatório')
      .test({
        message: () => "Selecione o Fornecedor",
        test: async (values) => {
          if (step === 0) { 
            if (values === 'null') { return false; } else{ return true; }
          }else{
            return true;
          }
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      fornecedor: 'null',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      let fornecedor = JSON.parse(values.fornecedor);

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      api.post(`novaCompra`, { //CONCRETIZA COMPRA INCOMPLETA 
        "fornecedor": fornecedor.id,
      },
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      }).then((response) => {

        let { codigo } = response.data;
        history.push(`/estoque/entradas/detalhes/${codigo}`);

      }).cath((error) => {
        setalertError(true);        
        setalertErrorMsg(`Houve um erro interno: ${error}`);        
        setTimeout(() => { setalertError(false) }, 6000);
      })
         
    }
  });


  useEffect(()=>{

    //VALIDAÇÃO STEEP 1
    if(!Boolean(formik.errors.fornecedor)){
      seterrorStep1(false);
    }else{
      seterrorStep1(true);
    }

    //VALIDAÇÃO STEEP 2 
    if(!Boolean(formik.errors.veiculo) && !Boolean(formik.errors.contrato) && !Boolean(formik.errors.precocompra) && !Boolean(formik.errors.km)){
      seterrorStep2(false);
    }else{
      seterrorStep2(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.errors]);

  const handleChangeFornecedor = async(event, value, reason, details)=>{
    formik.setFieldValue('fornecedor', JSON.stringify(value));
    setcodigoDetalhesPessoa(value?.codigo);
  }


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Nova Compra</title>
        </Helmet>
      </HelmetProvider>

      <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertSucessMsg}
        </Alert>
      </Snackbar>

      <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
      <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

      <div>
        <div className='container-page'>
          
          <SubMenuEstoque active="entradas" />

          <div className='box-steps'>
            <Stepper activeStep={step} alternativeLabel>
                <Step>
                  <StepLabel error={errorStep1}>Fornecedor</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={errorStep2}>Detalhes</StepLabel>
                </Step>
            </Stepper>
          </div>

          <div className='container'>
            
            <div className='box-form-cadastro-compra'>

                <form method='post' onSubmit={formik.handleSubmit}>
                  <h2>Entrada no estoque</h2>

                  {
                    step === 0 &&

                    <div className='container-step-1-nova-compra'>
                    <Grid container spacing={0.5} >

                    <Grid item xs={formik.values.fornecedor === 'null' ? 10.2 : 8.7} md={formik.values.fornecedor === 'null' ? 10.2 : 8.7} >
                          <FormControl sx={{ m: 0, width: '100%' }} >
                            <Autocomplete
                                className='input'
                                disablePortal
                                id="combo-box-fornecedor"
                                options={pessoas}
                                value={JSON.parse(formik.values.fornecedor)}
                                getOptionLabel={(option) => option.nome}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.id}>{option.nome}</li>
                                )}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={handleChangeFornecedor}
                                noOptionsText="Nenhum fornecedor encontrado."
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label="Fornecedor"  error={formik.touched.fornecedor && Boolean(formik.errors.fornecedor)}/>}
                            />
                            <FormHelperText error>{formik.touched.fornecedor && formik.errors.fornecedor}</FormHelperText>
                          </FormControl>
                      </Grid>

                      <Grid item xs={1.6} md={1.6} >
                        <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                      </Grid>

                      {
                        formik.values.fornecedor !== 'null' &&

                        <Grid item xs={1.2} md={1} >
                          <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                        </Grid>
                      }

                    </Grid>
                    </div>

                  }

                  <div className='box-buttons-steps'>
                    <Grid container spacing={2} >
                    
                        <Grid item xs={12} md={12} >
                          <ButtonDefault type="submit" icon="" txt='Continuar' />
                        </Grid>
                    
                    </Grid>
                  </div>
                </form>

            </div>

          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}