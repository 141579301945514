import axios from 'axios';
axios.defaults.timeout = 30000;


const api = axios.create({
  // baseURL: 'http://localhost:3333'
  // baseURL: 'http://192.168.0.140:3333' 
  baseURL: 'https://api.nexcom.digital'   
});

export default api;