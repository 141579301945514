import React, {useState, useEffect, useRef} from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import api from '../../../services/api';

import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ButtonSquadPrimary from '../../../componentes/ButtonSquadPrimary';
import ButtonSquadSecondary from '../../../componentes/ButtonSquadSecondary';
import ButtonSquadDanger from '../../../componentes/ButtonSquadDanger';
import InputSearchActions from '../../../componentes/InputSearchActions';

import ModalNovoTipoProduto from './modals/modalNovoTipoProduto';
import ModalEditTipoProduto from './modals/modalEditTipoProduto';

export default function TabTiposProdutos() {

  let IconPlusBtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconPDFbtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="27.758" viewBox="0 0 24.93 27.758">
      <g id="pdf" transform="translate(-26.077)">
        <g id="Grupo_19" data-name="Grupo 19" transform="translate(28.387 25.607)">
          <g id="Grupo_18" data-name="Grupo 18">
            <path id="Caminho_62" data-name="Caminho 62" d="M68.677,472.334a3.073,3.073,0,0,0,2.914,2.15H86.074a3.072,3.072,0,0,0,2.914-2.15Z" transform="translate(-68.677 -472.334)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_21" data-name="Grupo 21" transform="translate(43.912 0.508)">
          <g id="Grupo_20" data-name="Grupo 20">
            <path id="Caminho_63" data-name="Caminho 63" d="M359.085,13.324l-3.563-3.563a3.106,3.106,0,0,0-.475-.389v4.407a.182.182,0,0,0,.182.182h4.345A3.046,3.046,0,0,0,359.085,13.324Z" transform="translate(-355.047 -9.372)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_23" data-name="Grupo 23" transform="translate(28.238)">
          <g id="Grupo_22" data-name="Grupo 22">
            <path id="Caminho_64" data-name="Caminho 64" d="M86.543,6.724H81.791a1.811,1.811,0,0,1-1.809-1.808V0H69a3.066,3.066,0,0,0-3.063,3.063v8.354H86.543V6.724Z" transform="translate(-65.935)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_25" data-name="Grupo 25" transform="translate(37.827 16.638)">
          <g id="Grupo_24" data-name="Grupo 24">
            <path id="Caminho_65" data-name="Caminho 65" d="M243.562,306.9h-.744v3.836h.744q.752,0,.752-.836v-2.165Q244.314,306.9,243.562,306.9Z" transform="translate(-242.818 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_27" data-name="Grupo 27" transform="translate(33.072 16.638)">
          <g id="Grupo_26" data-name="Grupo 26">
            <path id="Caminho_66" data-name="Caminho 66" d="M155.928,306.9H155.1v1.763h.827q.752,0,.752-.836v-.092Q156.681,306.9,155.928,306.9Z" transform="translate(-155.101 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_29" data-name="Grupo 29" transform="translate(26.077 13.044)">
          <g id="Grupo_28" data-name="Grupo 28">
            <path id="Caminho_67" data-name="Caminho 67" d="M48.952,240.6H28.132a2.057,2.057,0,0,0-2.055,2.055v6.826a2.057,2.057,0,0,0,2.055,2.055h20.82a2.057,2.057,0,0,0,2.055-2.055V242.66A2.057,2.057,0,0,0,48.952,240.6Zm-13,4.421h0a1.806,1.806,0,0,1-.564,1.467,2.248,2.248,0,0,1-1.492.472h-.827v1.805a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.316a.356.356,0,0,1,.134-.276.514.514,0,0,1,.351-.117h1.73a2.121,2.121,0,0,1,1.433.464,1.823,1.823,0,0,1,.539,1.458v.042Zm4.672,2.173a1.859,1.859,0,0,1-.56,1.5,2.232,2.232,0,0,1-1.5.476H37.092a.672.672,0,0,1-.41-.117.338.338,0,0,1-.159-.276v-5.324a.338.338,0,0,1,.159-.276.673.673,0,0,1,.41-.117h1.479a2.232,2.232,0,0,1,1.5.476,1.859,1.859,0,0,1,.56,1.5Zm4.584-3.168a.324.324,0,0,1-.288.167H42.759v1.412h1.262a.333.333,0,0,1,.288.15.615.615,0,0,1,.1.359.6.6,0,0,1-.1.343.33.33,0,0,1-.293.15H42.759v2.156a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.307a.331.331,0,0,1,.167-.3.742.742,0,0,1,.4-.1h2.9a.322.322,0,0,1,.293.167.749.749,0,0,1,.1.393A.759.759,0,0,1,45.212,244.032Z" transform="translate(-26.077 -240.605)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  );

  let IconCSVBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21"  viewBox="0 0 22.974 27.355">
      <g id="csv" transform="translate(-41)">
        <path id="Caminho_199" data-name="Caminho 199" d="M41,465.107a2.407,2.407,0,0,0,2.4,2.4H59.432a2.407,2.407,0,0,0,2.4-2.4V465H41Z" transform="translate(0 -440.156)" fill="#fff"/>
        <path id="Caminho_200" data-name="Caminho 200" d="M55.729,13.927V8.787l-5.942,5.942h5.14A.8.8,0,0,0,55.729,13.927Z" transform="translate(-8.318 -8.318)" fill="#fff"/>
        <path id="Caminho_201" data-name="Caminho 201" d="M61.837,2.4a2.407,2.407,0,0,0-2.4-2.4H49.014V5.61a2.407,2.407,0,0,1-2.4,2.4H41v2.939H61.837Z" fill="#fff"/>
        <path id="Caminho_202" data-name="Caminho 202" d="M63.172,235H41v10.685H63.172a.8.8,0,0,0,.8-.8V235.8A.8.8,0,0,0,63.172,235Zm-13.636,7.306a2.35,2.35,0,0,1-1.858.842,2.8,2.8,0,1,1,1.569-5.13.668.668,0,0,1-.748,1.107,1.469,1.469,0,1,0-.821,2.688,1.059,1.059,0,0,0,.729-.255,1.048,1.048,0,0,0,.106-.109.668.668,0,0,1,1.023.858Zm5.093-.633a1.893,1.893,0,0,1-1.927,1.475,2.667,2.667,0,0,1-1.981-.851.668.668,0,1,1,.99-.9,1.348,1.348,0,0,0,.991.413.591.591,0,0,0,.612-.374c.032-.177-.224-.338-.473-.43-.666-.245-1.257-.509-1.282-.52a.671.671,0,0,1-.093-.05,1.489,1.489,0,0,1-.664-1.474,1.633,1.633,0,0,1,1.182-1.321,2.249,2.249,0,0,1,2.449.7.668.668,0,0,1-1.108.735,1.112,1.112,0,0,0-.956-.152.309.309,0,0,0-.245.232.161.161,0,0,0,.044.143c.167.073.635.273,1.135.458A1.732,1.732,0,0,1,54.63,241.673Zm5.437-3.249-1.4,4.2a.766.766,0,0,1-.72.523h-.005a.765.765,0,0,1-.72-.508l-1.463-4.215a.668.668,0,0,1,1.262-.438l.909,2.62L58.8,238a.668.668,0,0,1,1.268.421Z" transform="translate(0 -222.445)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconPessoaCancel = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="35" viewBox="0 0 312.858 306.449">
      <path id="user_1_" data-name="user (1)" d="M238.867,143.97a73.556,73.556,0,0,0-35.6,9.14,114.809,114.809,0,0,0-32.255-17.6,96.246,96.246,0,0,0,20.239-40.263c3.921-16.946,2.821-34.358-3.1-49.028-10.332-25.608-33.585-40.541-63.8-40.97h-.208c-30.212.43-53.464,15.362-63.8,40.97-5.919,14.671-7.019,32.082-3.1,49.029a96.241,96.241,0,0,0,20.236,40.26c-25.212,9-45.505,25.418-59.135,47.992C4.02,207.249-2.234,237.279.747,268.06A7.332,7.332,0,0,0,4.1,273.532c35.231,22.509,71.1,35.193,106.607,37.7q6.614.467,13.244.467c31.078,0,62.428-7.684,93.377-22.9A74.014,74.014,0,1,0,238.867,143.97ZM73.954,51.709c8.121-20.128,25.982-31.415,50.3-31.792,24.319.376,42.179,11.664,50.3,31.792,10.219,25.327,2.015,58.228-19.949,80.01-10.236,10.152-20.448,15.3-30.351,15.3s-20.115-5.148-30.352-15.3C71.938,109.937,63.735,77.036,73.954,51.709ZM15.042,263.1C10.873,206.363,39.566,161.484,89.082,147.18c11.4,9.628,23.2,14.5,35.173,14.5s23.776-4.877,35.173-14.5a103.183,103.183,0,0,1,31.148,14.76,73.94,73.94,0,0,0,9.145,118.828C137.649,307.506,77.08,301.689,15.041,263.1Zm223.825,14.234a59.347,59.347,0,1,1,59.347-59.347,59.414,59.414,0,0,1-59.347,59.347ZM269.1,198.118l-19.864,19.865L269.1,237.847a7.333,7.333,0,1,1-10.37,10.371l-19.864-19.864L219,248.218a7.333,7.333,0,1,1-10.37-10.371L228.5,217.983l-19.864-19.864A7.333,7.333,0,0,1,219,187.748l19.864,19.864,19.864-19.865A7.333,7.333,0,1,1,269.1,198.118Z" transform="translate(-0.02 -5.25)" fill="#4a4a4a"/>
    </svg>
  )

  let IconNoAuth = props =>(
    <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
      <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
      <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
    </svg>
  )

  let IconProdutoOptions = props=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="55" viewBox="0 0 59.364 61.096">
      <path id="product-management" d="M34.418,36.507l-1.688.975v3.924l1.688-.975Zm24.43,3.907H52.217v21.8h6.631V58.774a2.725,2.725,0,0,1,2.721-2.721h8.6a2.727,2.727,0,0,1,2.581,1.859l1.136-1.136A.866.866,0,0,1,75.111,58q-4.116,4.116-8.233,8.233a.86.86,0,0,1-1.245-.024L61.95,62.267a.864.864,0,0,1,1.265-1.177l3.071,3.286L71.159,59.5v-.729a.993.993,0,0,0-.989-.989h-8.6a.993.993,0,0,0-.989.989v8.6a.993.993,0,0,0,.989.989h8.6a.993.993,0,0,0,.989-.989V64.4l1.731-1.732v4.707A2.725,2.725,0,0,1,70.17,70.1h-8.6a2.725,2.725,0,0,1-2.721-2.721V63.94h-7.5a.866.866,0,0,1-.866-.866V40.414h-9.9V38.682h9.9V16.021a.866.866,0,0,1,.866-.866h7.5V11.721A2.725,2.725,0,0,1,61.569,9h8.6a2.727,2.727,0,0,1,2.581,1.859l1.136-1.136a.866.866,0,0,1,1.224,1.224L66.878,19.18a.86.86,0,0,1-1.245-.024L61.95,15.214a.864.864,0,0,1,1.265-1.177l3.071,3.286,4.874-4.873v-.729a.993.993,0,0,0-.989-.989h-8.6a.993.993,0,0,0-.989.989v8.6a.993.993,0,0,0,.989.989h8.6a.993.993,0,0,0,.989-.989V17.358l1.731-1.731v4.7a2.725,2.725,0,0,1-2.721,2.721h-8.6a2.725,2.725,0,0,1-2.721-2.721V16.887H52.217v21.8h6.631V35.247a2.725,2.725,0,0,1,2.721-2.721h8.6a2.727,2.727,0,0,1,2.581,1.859l1.136-1.136a.866.866,0,1,1,1.224,1.224q-4.116,4.116-8.233,8.233a.86.86,0,0,1-1.245-.024L61.95,38.741a.864.864,0,0,1,1.265-1.177l3.071,3.286,4.874-4.874v-.729a.993.993,0,0,0-.989-.989h-8.6a.993.993,0,0,0-.989.989v8.6a.993.993,0,0,0,.989.989h8.6a.993.993,0,0,0,.989-.989V40.873l1.731-1.731v4.707a2.725,2.725,0,0,1-2.721,2.721h-8.6a2.725,2.725,0,0,1-2.721-2.721ZM27.427,26.348l-2.713,1.566,10.569,6.1L38,32.45ZM22.991,28.91l10.569,6.1-1.7.979L21.3,29.889Zm-3.42,1.974,10.569,6.1-2.713,1.566-10.569-6.1ZM16,33.946l10.562,6.1v12.2L16,46.15Zm12.293,6.1L31,38.481V42.9a.867.867,0,0,0,1.3.747l3.35-1.934a.866.866,0,0,0,.506-.788V35.507l2.705-1.562v12.2l-10.562,6.1v-12.2Z" transform="translate(-16 -9)" fill="#4a4a4a" fillRule="evenodd"/>
    </svg>
  );

  let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 115.029 153.224">
      <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z" transform="translate(-168.493 -59.168)" fill="#fff"/>
      </g>
    </svg>
  )
  
  let history = useHistory();
  let csvInstance = useRef();

  const [tiposprodutos, settiposprodutos] = useState([]);
  const [tiposprodutosSelecionados, settiposprodutosSelecionados] = useState([]);
  const [pageSizeDataGridtiposprodutos, setpageSizeDataGridtiposprodutos] = useState(10);
  const [loadingDataGridtiposprodutos, setloadingDataGridtiposprodutos] = useState(true);

  const [filtroTermo, setfiltroTermo] = useState();
  const [filtroStatus, setfiltroStatus] = useState('all');

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  const [listagemtiposprodutosExport, setlistagemtiposprodutosExport] = useState([]);

  const [showModalNovoTipo, setshowModalNovoTipo] = useState(false);
  const [showModalEditTipo, setshowModalEditTipo] = useState(false);
  const [idEditTipo, setidEditTipo] = useState();
  


  const gettiposprodutos = async(termo='', status='', tipo='')=>{
    setloadingDataGridtiposprodutos(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemTiposProduto?termo=${termo}&status=${status}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    !response.data.error_code && settiposprodutos(response.data);
    setloadingDataGridtiposprodutos(false);
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });
    

    setpermissoesdoUsuario(permissoes.data.produtos);
  }


  useEffect(()=>{
    gettiposprodutos();
    permissoesUsuario();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  
  const handleDetalhesTipoProduto = async(props)=>{
    let id = props.id;
    setidEditTipo(id);
    setshowModalEditTipo(true);
  }

 

  const handleDeleteTipoProduto = async()=>{
    
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar os tipos de produtos selecionados?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#02A680',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let tiposprodutosids = [];

        for await (let id of tiposprodutosSelecionados) {
          let dados = { id: id }
          tiposprodutosids.push(dados);
        }
        
        await api.delete('deleteTipoProduto', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "tiposIds": tiposprodutosids }
        });

        gettiposprodutos(filtroTermo, filtroStatus);

        Swal.fire(
          'Deletado!',
          'Tipo(s) de produto deletado(s) com sucesso!',
          'success'
        )
      }
    })

  }

  const handleSearchSetor = async(event)=>{
    let termo = event.target.value;
    setfiltroTermo(termo);

    if(termo.length >= 3){
      gettiposprodutos(termo, filtroStatus, '');
    }else{
      gettiposprodutos();
    }
  }

  const handleFiltroStatus = async(props)=>{
    let status = props.target.value;
    setfiltroStatus(status);
    gettiposprodutos(filtroTermo, status, '');
  }

  const columnsDataGridtiposprodutos = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0,
      minWidth: 20,
      editable: false,
      renderCell: (params) => { //renderiza componente
        
        switch (params.value) {
            case 0:
                    return <span className='dotStatus inativo'></span>
                break;
            case 1:
                    return <span className='dotStatus ativo'></span>
                break;
        }
    
      },
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      editable: false,
      renderCell: (params) => ( //renderiza componente
        <p>{params.value}</p>
      ),
    },
  ];

  function nenhumTipoEncontrado(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          {
            permissoesdoUsuario[0]?.view ?
              <>
                <IconProdutoOptions/>
                <p>Nenhum Tipo de produto encontrado</p>
              </>
            :

            <>
              <IconNoAuth/>
              <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar o cadastro de tipos de produtos!</p>
            </>

          }
        </div>
      </GridOverlay>
    )
  }


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Tipos de produtos - Estoque - Nexcom</title>
        </Helmet>
      </HelmetProvider>

      <ModalNovoTipoProduto visible={showModalNovoTipo} change={setshowModalNovoTipo} getTipos={gettiposprodutos} setidEditTipo={setidEditTipo} setshowModalEditTipo={setshowModalEditTipo} /> 
      <ModalEditTipoProduto id={idEditTipo} visible={showModalEditTipo} change={setshowModalEditTipo} getTipos={gettiposprodutos}/> 

      
      <CSVLink
          data={listagemtiposprodutosExport}
          filename={'cadastros-de-tiposprodutos.csv'}
          ref={csvInstance}
          data-interception='off'
      />
            
      <div className='box-actions-produtos'>

          {
              permissoesdoUsuario[2]?.insert &&
              
              <ButtonSquadPrimary icon={<IconPlusBtn/>} onClick={()=>{ setshowModalNovoTipo(true) }} />
          }


          {
          tiposprodutosSelecionados.length > 0 &&
          <>  
              {/* <div style={{ 'marginLeft': '5px' }}>
                  <PDFDownloadLink document={<DocumentPDFpessoas pessoasIds={pessoasSelecionadas} />} fileName="cadastros-de-pessoas.pdf">
                      <ButtonSquadSecondary icon={<IconPDFbtn/>} />
                  </PDFDownloadLink>
              </div>

              <div style={{ 'marginLeft': '5px' }}>
                  <ButtonSquadSecondary icon={<IconCSVBtn/>} onClick={()=>{ handleExportCSVtiposprodutos() }}/>
              </div> */}


              {
                  permissoesdoUsuario[1].delete &&

                  <div style={{ 'marginLeft': '5px' }}>
                      <ButtonSquadDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteTipoProduto() }} />
                  </div>
              }

          </>
          }

          <div style={{ 'marginRight': '5px' }}>
            <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
              <InputLabel id="label-status">Status</InputLabel>
              <Select
                labelId="label-status"
                id="demo-simple-select"
                name="status"
                label="Status"
                size="small"
                placeholder='Status'
                value={filtroStatus}
                onChange={handleFiltroStatus}
                style={{ height: '43px' }}
              >
                <MenuItem value='all'>Todos</MenuItem>
                <MenuItem value={1}>Ativos</MenuItem>
                <MenuItem value={0}>Inativos</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{ 'marginLeft': '5px', 'width': '100%' }}>
              <InputSearchActions onChange={handleSearchSetor}  placeholder="Procure um tipo de produto" />
          </div>
      </div>

      <div className='container-data-grid'>
          <DataGrid
            sx={{ minHeight: '400px' }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={tiposprodutos}
            columns={columnsDataGridtiposprodutos}
            pageSize={pageSizeDataGridtiposprodutos}
            onPageSizeChange={(newPageSize) => setpageSizeDataGridtiposprodutos(newPageSize)}
            rowsPerPageOptions={[10, 50, 100]} 
            checkboxSelection
            disableSelectionOnClick
            editMode='row'
            loading={loadingDataGridtiposprodutos}
            disableColumnMenu={true}
            onSelectionModelChange={(props)=>{
                settiposprodutosSelecionados(props);
            }}
            // onRowClick
            onCellClick={(props)=>{
                if(props.field !== "__check__"){
                    handleDetalhesTipoProduto(props)
                }
            }}
            components={{
                NoRowsOverlay: nenhumTipoEncontrado,
            }}
          />
      </div>
          
    </>
    
  );
}