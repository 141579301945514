import React from 'react';
import './style.css';
import Button from '@mui/material/Button';

export default function ButtonGreenDark({ type, icon, txt, onClick, component, disabled = false, height = '50px', margin = '10px 0', padding = "" }) {

  return (
    <Button style={{ height: height, margin: margin, padding: padding, }} disabled={disabled} component={component} onClick={onClick} id='btn-green-dark' type={type}>
        {icon} {txt}
    </Button>
  );
}