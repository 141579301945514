import React, {useState, useEffect } from 'react';

import api from '../../../services/api';
import { useHistory } from "react-router-dom";

import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';
import ButtonOutlineRoundWite from '../../../componentes/ButtonOutlineRoundWite';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonDanger from '../../../componentes/ButtonDanger';
import ButtonBlue from '../../../componentes/ButtonBlue';
import ButtonGreen from '../../../componentes/ButtonGreen';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco } from '../../../vendor/formatar';

import ModalNovaPessoa from '../../Cadastros/Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../../Cadastros/Pessoas/modals/modalDetalhesPessoa';

export default function ModalNovaOs({ visible, change, getOs, setcodigoEditOs, setshowModalEditOs, titleEquipamento, ramoAtividadeId }) {
  
  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  );

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );


  let history = useHistory();

  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [prestadores, setprestadores] = useState([]);
  const [pessoas, setpessoas] = useState([]);
  const [detalhesUsuario, setdetalhesUsuario] = useState([]);

  const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
  const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();

  const [showModalNovoPrestador, setshowModalNovoPrestador] = useState(false);
  const [showModalDetalhesPrestador, setshowModalDetalhesPrestador] = useState(false);
  const [codigoDetalhesPrestador, setcodigoDetalhesPrestador] = useState();

  const [equipamentos, setequipamentos] = useState([]);
  const [tabDetalhesPessoa, settabDetalhesPessoa] = useState("1");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };


  const getPessoas = async(ClienteId=null, codigoCliente=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    if(codigoCliente){
      //populando cliente no select
      let clientecode = arrayPessoas.find(x => x.codigo === codigoCliente);
      clientecode && formik.setFieldValue('cliente', JSON.stringify(clientecode));
      setcodigoDetalhesPessoa(codigoCliente);
      setshowModalDetalhesPessoa(true);

    }else if(ClienteId){
      //populando cliente no select
      let cliente = arrayPessoas.find(x => x.id === ClienteId);
      cliente && formik.setFieldValue('cliente', JSON.stringify(cliente));
      setcodigoDetalhesPessoa(cliente.codigo);
      setshowModalDetalhesPessoa(true);
    }

    settabDetalhesPessoa("1");
    setpessoas(arrayPessoas);

  }

  const getPrestadores = async(ClienteId=null, codigoCliente=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    if(codigoCliente){
      //populando cliente no select
      let clientecode = arrayPessoas.find(x => x.codigo === codigoCliente);
      clientecode && formik.setFieldValue('prestador', JSON.stringify(clientecode));
      setcodigoDetalhesPrestador(codigoCliente);
      setshowModalDetalhesPrestador(true);

    }else if(ClienteId){
      //populando cliente no select
      let cliente = arrayPessoas.find(x => x.id === ClienteId);
      cliente && formik.setFieldValue('prestador', JSON.stringify(cliente));
      setcodigoDetalhesPrestador(cliente.codigo);
      setshowModalDetalhesPrestador(true);
    }

    setprestadores(arrayPessoas);

  }

  const detalhesdoUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let responde = await api.post('detalhesUsuarioLogado', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    
    formik.setFieldValue('usuario', responde.data.nome)
    setdetalhesUsuario(responde.data);
  }

  const getEquipamentos = async(idPessoa)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    //EQUIPAMENTOS
    let response = await api.post('listagemEquipamentosPessoa', {
      "pessoaId": idPessoa
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    let arrayEquip = [];

    for await (let equip of response.data) {
      let dados = {
          codigo: equip.numero,
          nome: equip.equipamento,
          id: equip.id
      }    
      
      arrayEquip.push(dados);
    }

    setequipamentos(arrayEquip);
  }


  useEffect(async()=>{
    if(visible){
      getPessoas();
      getPrestadores();
      detalhesdoUsuario();

      formik.setFieldValue('dataprevisao', moment().add(1, 'd'));
      formik.setFieldValue('data', moment());
      
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);


  useEffect(async()=>{
    
    if(formik.values.cliente !== 'null'){
      let cliente = JSON.parse(formik.values.cliente);
      await getEquipamentos(cliente.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showModalDetalhesPessoa]);
  

  
  const validationSchema = yup.object({
    dataprevisao: yup
      .date('').typeError("Insira a data da entrada")
      .required('Data da entrada Obrigatória'),
    equipamento: yup
      .string(`Selecione o ${titleEquipamento}`)
      .required(`${titleEquipamento} Obrigatório`)
      .test({
        message: () => `Selecione o ${titleEquipamento}`,
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    km: yup
      .string(`Insira o KM`)
      .test({
        message: () => `Insira o KM`,
        test: async (values) => {
          if (ramoAtividadeId === 2) { 
            if (!values) { return false; } else{ return true; }
          }else{
            return true;
          }
        },
    }),
    // prestador: yup
    //   .string('Selecione o Prestador')
    //   .required('Prestador Obrigatório')
    //   .test({
    //     message: () => "Selecione o Prestador",
    //     test: async (values) => {
    //       if (values) { 
    //         if (values === 'null') { return false; } else{ return true; }
    //       }
    //     },
    //   }),
    cliente: yup
      .string('Selecione o Cliente')
      .required('Cliente Obrigatório')
      .test({
        message: () => "Selecione o Cliente",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    solicitacao: yup
      .string('Insira a solicitação do serviço')
      .required('Solicitação Obrigatório')
  });

  const formik = useFormik({
    initialValues: {
      usuario: '',
      status: 1,
      dataprevisao: '',
      equipamento: 'null',
      km: '0',
      prestador: 'null',
      cliente: 'null',
      estadoequipamento: '',
      acessorios: '',
      solicitacao: '',
      obs: '',
      data: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let equipamento = JSON.parse(values.equipamento);
      let prestador = JSON.parse(values.prestador);
      let cliente = JSON.parse(values.cliente);
      let dataprevisao = moment(moment(values.dataprevisao, 'YYYY-MM-DD')).format('YYYY-MM-DD');
      let data = moment(moment(values.data, 'YYYY-MM-DD')).format('YYYY-MM-DD hh:mm:ss');

      let response = await api.post('novaOs', {
        "cliente": cliente.id,
        "equipamento": equipamento.id,
        "km": values.km ? values.km : 0,
        "prestador": prestador?.id,
        "previsao": dataprevisao,
        "solicitacao": values.solicitacao,
        "obs": values.obs,
        "estadoEquipamento": values.estadoequipamento,
        "acessorios": values.acessorios,
        "data": data,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });


      formik.resetForm();
      change(false);
      getOs();


      await setcodigoEditOs(response.data.codigo.toString());
      setshowModalEditOs(true);

      setalertSucess(true);        
      setalertSucessMsg(`Ordem Adicionada com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);

    }
  });


  const handleChangeCliente = async(event, value, reason, details)=>{
      formik.setFieldValue('cliente', JSON.stringify(value));
      setcodigoDetalhesPessoa(value?.codigo);
      if(value?.id){
        await getEquipamentos(value?.id);
      }else{
        setequipamentos([]);
        formik.setFieldValue('equipamento', 'null');
      }
  }

  const handleChangePrestador = async(event, value, reason, details)=>{
    formik.setFieldValue('prestador', JSON.stringify(value));
    setcodigoDetalhesPrestador(value?.codigo);
  }

  const handleChangeEquipamento = async(event, value, reason, details)=>{
    formik.setFieldValue('equipamento', JSON.stringify(value));
  }
  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
      <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
      </Alert>
    </Snackbar>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
      <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
      </Alert>
    </Snackbar>

    <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
    <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa} tab={tabDetalhesPessoa}/>

    <ModalNovaPessoa visible={showModalNovoPrestador} change={setshowModalNovoPrestador} atualizarPessoas={getPrestadores}/> 
    <ModalDetalhesPessoa codigo={codigoDetalhesPrestador} visiblemodal={showModalDetalhesPrestador} changemodal={setshowModalDetalhesPrestador}/>

    <Dialog open={visible} maxWidth='md' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Nova O.S
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={1} rowSpacing={-0.5}>
            
            <Grid item xs={12} md={3} >
              <div style={{ marginTop: '15px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                <DesktopDatePicker
                  label="Data"
                  inputFormat="dd/MM/yyyy"
                  name="data"
                  value={formik.values.data}
                  onChange={(data)=>{ formik.setFieldValue('data', data) }}
                  renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.data && Boolean(formik.errors.data)}/>}
                />
                <FormHelperText error>{formik.touched.data && formik.errors.data}</FormHelperText>
              </LocalizationProvider>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  id="usuario"
                  name="usuario"
                  label="Usuário"
                  margin="normal"
                  type="text"
                  value={formik.values.usuario}
                  onChange={formik.handleChange}
                  error={formik.touched.usuario && Boolean(formik.errors.usuario)}
                  helperText={formik.touched.usuario && formik.errors.usuario}
                  disabled
                />
            </Grid>

            <Grid item xs={12} md={3} >
                  <FormControl sx={{ width: '100%', marginTop: '16px' }} >
                    <InputLabel id="label-totalparcelas">Status</InputLabel>
                    <Select
                      labelId="label-status"
                      id="cartao-simple-status"
                      name="status"
                      label="Status"
                      disabled
                      placeholder='Status'
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={formik.touched.status && Boolean(formik.errors.status)}
                    >
                      <MenuItem value={1}>Aberta</MenuItem>
                      <MenuItem value={9}>Em Avaliação</MenuItem>
                      <MenuItem value={2}>Pendente</MenuItem>
                      <MenuItem value={3}>Pendente avisado</MenuItem>
                      <MenuItem value={4}>Fechado</MenuItem>
                      <MenuItem value={5}>Fechado avisado</MenuItem>
                      <MenuItem value={6}>Aguardando peça</MenuItem>
                      <MenuItem value={7}>Entregue</MenuItem>
                      <MenuItem value={8}>Arquivo</MenuItem>
                    
                    </Select>
                    <FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
                  </FormControl>
            </Grid>

            <Grid item xs={12} md={3} >
              <div style={{ marginTop: '15px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                <DesktopDatePicker
                  label="Data previsão"
                  inputFormat="dd/MM/yyyy"
                  name="dataprevisao"
                  value={formik.values.dataprevisao}
                  onChange={(data)=>{ formik.setFieldValue('dataprevisao', data) }}
                  renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.dataprevisao && Boolean(formik.errors.dataprevisao)}/>}
                />
                <FormHelperText error>{formik.touched.dataprevisao && formik.errors.dataprevisao}</FormHelperText>
              </LocalizationProvider>
              </div>
            </Grid>
            
            <Grid item xs={12} md={6} style={{ display: 'flex', marginTop: '12px' }}>
              <Grid item style={{width:'100%'}}>
                  <FormControl sx={{ m: 0, width: '100%' }} >
                      <Autocomplete
                          className='input'
                          // disablePortal
                          id="combo-box-prestador"
                          options={prestadores}
                          value={JSON.parse(formik.values.prestador)}
                          getOptionLabel={(option) => option.nome}
                          renderOption={(props, option) => (
                          <li {...props} key={option.id}>{option.nome}</li>
                          )}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={handleChangePrestador}
                          noOptionsText="Nenhum Prestador encontrado."
                          sx={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label="Prestador"  error={formik.touched.prestador && Boolean(formik.errors.prestador)}/>}
                      />
                      <FormHelperText error>{formik.touched.prestador && formik.errors.prestador}</FormHelperText>
                  </FormControl>
              </Grid>

              <Grid>
                  <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovoPrestador(true) }}/>
              </Grid>

              {
                  formik.values.prestador !== 'null' &&

                  <Grid>
                      <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPrestador(true) }}/>
                  </Grid>
              }
            </Grid>

            <Grid item xs={12} md={6} style={{ display: 'flex', marginTop: '12px' }}>
              <Grid item style={{width:'100%'}}>
                  <FormControl sx={{ m: 0, width: '100%' }} >
                      <Autocomplete
                          className='input'
                          // disablePortal
                          id="combo-box-cliente"
                          options={pessoas}
                          value={JSON.parse(formik.values.cliente)}
                          getOptionLabel={(option) => option.nome}
                          renderOption={(props, option) => (
                          <li {...props} key={option.id}>{option.nome}</li>
                          )}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={handleChangeCliente}
                          noOptionsText="Nenhum cliente encontrado."
                          sx={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label="Cliente"  error={formik.touched.cliente && Boolean(formik.errors.cliente)}/>}
                      />
                      <FormHelperText error>{formik.touched.cliente && formik.errors.cliente}</FormHelperText>
                  </FormControl>
              </Grid>

              <Grid>
                  <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
              </Grid>

              {
                  formik.values.cliente !== 'null' &&

                  <Grid>
                      <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true); settabDetalhesPessoa("1"); }}/>
                  </Grid>
              }
            </Grid>

            <Grid item xs={12} md={ ramoAtividadeId === 2 ? 9 : 12} style={{ display: 'flex', marginTop: '16.5px' }}>
              <Grid item style={{width:'100%'}}>
                  <FormControl sx={{ m: 0, width: '100%' }} >
                      <Autocomplete
                          className='input'
                          // disablePortal
                          id="combo-box-equipamento"
                          options={equipamentos}
                          value={JSON.parse(formik.values.equipamento)}
                          getOptionLabel={(option) => option.nome}
                          renderOption={(props, option) => (
                          <li {...props} key={option.id}>{option.nome}</li>
                          )}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={handleChangeEquipamento}
                          noOptionsText="Nenhum Equipamento encontrado."
                          sx={{ width: '100%' }}
                          renderInput={(params) => <TextField {...params} label={titleEquipamento}  error={formik.touched.equipamento && Boolean(formik.errors.equipamento)}/>}
                      />
                      <FormHelperText error>{formik.touched.equipamento && formik.errors.equipamento}</FormHelperText>
                  </FormControl>
              </Grid>

              {
                  formik.values.cliente !== 'null' &&

                  <Grid>
                      <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true); settabDetalhesPessoa("4"); }}/>
                  </Grid>
              }
            </Grid>

            {

              ramoAtividadeId === 2 && 

              <Grid item xs={12} md={3} >
                  <TextField
                      fullWidth
                      id="km"
                      name="km"
                      label="KM"
                      margin="normal"
                      type="text"
                      value={formik.values.km}
                      onChange={formik.handleChange}
                      error={formik.touched.km && Boolean(formik.errors.km)}
                      helperText={formik.touched.km && formik.errors.km}
                      onKeyUp={()=>{ formik.setFieldValue('km', mnumero(formik.values.km)) }}
                      inputProps={{
                        maxLength: 10,
                      }}
                  />
              </Grid>

            }
          
            <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="estadoequipamento"
                  name="estadoequipamento"
                  label={`Estado do ${titleEquipamento}`}
                  margin="normal"
                  type="text"
                  value={formik.values.estadoequipamento}
                  onChange={formik.handleChange}
                  error={formik.touched.estadoequipamento && Boolean(formik.errors.estadoequipamento)}
                  helperText={formik.touched.estadoequipamento && formik.errors.estadoequipamento}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="acessorios"
                  name="acessorios"
                  label="Acessórios"
                  margin="normal"
                  type="text"
                  value={formik.values.acessorios}
                  onChange={formik.handleChange}
                  error={formik.touched.acessorios && Boolean(formik.errors.acessorios)}
                  helperText={formik.touched.acessorios && formik.errors.acessorios}
                />
            </Grid>
            

            <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="solicitacao"
                  name="solicitacao"
                  label="Solicitação"
                  margin="normal"
                  type="text"
                  multiline
                  rows={2}
                  value={formik.values.solicitacao}
                  onChange={formik.handleChange}
                  error={formik.touched.solicitacao && Boolean(formik.errors.solicitacao)}
                  helperText={formik.touched.solicitacao && formik.errors.solicitacao}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="obs"
                  name="obs"
                  label="Observações"
                  margin="normal"
                  type="text"
                  multiline
                  rows={2}
                  value={formik.values.obs}
                  onChange={formik.handleChange}
                  error={formik.touched.obs && Boolean(formik.errors.obs)}
                  helperText={formik.touched.obs && formik.errors.obs}
                />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} >

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}