import React, { useState } from 'react';

export const formataDinheiro = (n)=> {
    if(n != null){
        // return "R$ " + Number(n).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        
        // Converte o valor para um número com duas casas decimais
        let v = parseFloat(n).toFixed(2);
        // Separa o valor em parte inteira e parte decimal
        var partes = v.split('.');
        // Adiciona os separadores de milhar à parte inteira
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        // Retorna a string formatada como dinheiro em Real (BRL)
        return "R$ "+partes.join(",");

    }else{
        return "";
    }
}

export const formataDinheironoRs = (n)=> {
    if(n != null){
        // return Number(n).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");

        // Converte o valor para um número com duas casas decimais
        let v = parseFloat(n).toFixed(2);
        // Separa o valor em parte inteira e parte decimal
        var partes = v.split('.');
        // Adiciona os separadores de milhar à parte inteira
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        // Retorna a string formatada como dinheiro em Real (BRL)
        return partes.join(",");

    }else{
        return "";
    }
}

export const formataDinheiroforBanco = (n)=> {
    if(n != null){
        // let v = n.replace('R$ ', '').replace('.', '').replace(',', '.');
        // return Number(v).toFixed(2);

        // Remove o "R$" do início da string e os pontos e a vírgula do valor
        let v = n.replace(/^R\$|\./g, '').replace(',', '.');
        // Converte o valor para número de ponto flutuante
        v = parseFloat(v);
        // Formata o valor como dinheiro em Real (BRL)
        v = v.toFixed(2);
        // Retorna o valor formatado
        return v;

    }else{
        return "";
    }
}

export const formataPorcentagemforBanco = (n)=> {
    if(n != null){
        // let v = n.replace('R$ ', '').replace('.', '').replace(',', '.');
        // return Number(v).toFixed(2);

        // Remove o "%" da string e os pontos e a vírgula do valor
        let v = n.replace('%', '').replace(',', '.');
        // Converte o valor para número de ponto flutuante
        v = parseFloat(v);
        // Formata o valor como dinheiro em Real (BRL)
        v = v.toFixed(2);
        // Retorna o valor formatado
        return v;

    }else{
        return "";
    }
}