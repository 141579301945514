import React from 'react';
import Submenu from '../../componentes/SubMenu';

export default function SubMenuEstoque({ active }) {

  let IconPessoa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
        <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
        <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
        <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
        </g>
    </svg>
  );

  let IconProduto = props=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" viewBox="0 0 19.5 19.499">
      <path id="box" d="M21.287,6.882V6.875l-.009-.013A3.741,3.741,0,0,0,19.523,5.24l-6-2.667a3.757,3.757,0,0,0-3.046,0l-6,2.667A3.741,3.741,0,0,0,2.724,6.862l-.009.013v.007A3.729,3.729,0,0,0,2.25,8.667v6.666A3.752,3.752,0,0,0,4.477,18.76l6,2.667a3.766,3.766,0,0,0,1.515.322h.016a3.766,3.766,0,0,0,1.515-.322l6-2.667a3.752,3.752,0,0,0,2.227-3.427V8.667A3.729,3.729,0,0,0,21.287,6.882Zm-10.2-2.938a2.25,2.25,0,0,1,1.828,0l6,2.667a2.2,2.2,0,0,1,.462.292L16.643,8.118,9.384,4.7ZM7.573,5.505l7.259,3.418-1.912.849a2.3,2.3,0,0,1-1.835,0L4.624,6.9a2.2,2.2,0,0,1,.462-.292ZM5.086,17.389A2.251,2.251,0,0,1,3.75,15.333V8.667a2.26,2.26,0,0,1,.07-.48l6.66,2.96a3.773,3.773,0,0,0,.77.24v8.722c-.054-.019-.111-.029-.164-.053ZM20.25,15.333a2.251,2.251,0,0,1-1.336,2.056l-6,2.667c-.053.024-.11.034-.164.053V11.386a3.75,3.75,0,0,0,.774-.241L15.88,10.1v1.79a.75.75,0,0,0,1.5,0V9.432l2.8-1.245a2.26,2.26,0,0,1,.07.48Z" transform="translate(-2.25 -2.25)" fill="#4a4a4a"/>
    </svg>
  );

  let IconEstoque = props=>(
    <svg id="compras" xmlns="http://www.w3.org/2000/svg" width="20"  viewBox="0 0 53.91 53.91">
      <path id="Caminho_375" data-name="Caminho 375" d="M53.91,43.381V26.534A2.106,2.106,0,0,0,51.8,24.428H45.487V9.687a2.106,2.106,0,0,0-2.106-2.106H26.534a2.106,2.106,0,0,0-2.106,2.106V24.428H18.11A2.106,2.106,0,0,0,16,26.534V41.275H13.9a2.108,2.108,0,0,1-2.106-2.106V6.318A6.325,6.325,0,0,0,5.475,0H2.106a2.106,2.106,0,0,0,0,4.212H5.475A2.108,2.108,0,0,1,7.581,6.318V39.169A6.325,6.325,0,0,0,13.9,45.487h2.468a6.318,6.318,0,1,0,11.911,0h13.36a6.317,6.317,0,1,0,11.656-.616A2.1,2.1,0,0,0,53.91,43.381ZM49.7,41.275H37.063V28.64h4.212v2.106a2.106,2.106,0,0,0,4.212,0V28.64H49.7ZM32.851,11.793V13.9a2.106,2.106,0,0,0,4.212,0V11.793h4.212V24.428H28.64V11.793Zm-8.423,35.8a2.106,2.106,0,1,1-2.106-2.106A2.108,2.108,0,0,1,24.428,47.592Zm-4.212-6.318V28.64h4.212v2.106a2.106,2.106,0,0,0,4.212,0V28.64h4.212V41.275ZM47.592,49.7A2.106,2.106,0,1,1,49.7,47.592,2.108,2.108,0,0,1,47.592,49.7Z" fill="#4a4a4a"/>
    </svg>
  );

  let IconContagemEstoque = props=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 28 28">
      <g id="_x32_0" transform="translate(-2 -2)">
        <path id="Caminho_376" data-name="Caminho 376" d="M29,2H27a1,1,0,0,0-.345,1.93L21.8,8.783,18.447,7.105a1,1,0,0,0-1.154.188l-5,5a1,1,0,1,0,1.414,1.414L17,10.414V28H4V10H6.842A5.86,5.86,0,0,0,6,13a1,1,0,0,0,1,1,5.868,5.868,0,0,0,4.121-1.707l6.172-6.172a2.419,2.419,0,0,0,0-3.415,2.473,2.473,0,0,0-3.413,0L8.586,8H3A1,1,0,0,0,2,9V29a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V9.618l2.553,1.276a1,1,0,0,0,1.154-.187L28.07,5.345A1,1,0,0,0,30,5V3a1,1,0,0,0-1-1ZM15.293,4.121a.414.414,0,1,1,.586.586L9.707,10.879a3.848,3.848,0,0,1-1.515.929,3.849,3.849,0,0,1,.929-1.515l.585-.585,0,0Z" fill="#4a4a4a"/>
        <path id="Caminho_377" data-name="Caminho 377" d="M8.447,15.106a1,1,0,0,0-1.342.447l-.38.759-.019-.019a1,1,0,0,0-1.414,1.414l1,1a1,1,0,0,0,1.6-.26l1-2a1,1,0,0,0-.447-1.342Z" fill="#4a4a4a"/>
        <path id="Caminho_378" data-name="Caminho 378" d="M8.447,21.105a1,1,0,0,0-1.342.447l-.38.759-.019-.019a1,1,0,0,0-1.414,1.414l1,1a1,1,0,0,0,1.6-.26l1-2a1,1,0,0,0-.447-1.342Z" fill="#4a4a4a"/>
        <path id="Caminho_379" data-name="Caminho 379" d="M15,17H11a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" fill="#4a4a4a"/>
        <path id="Caminho_380" data-name="Caminho 380" d="M15,23H11a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" fill="#4a4a4a"/>
        <path id="Caminho_381" data-name="Caminho 381" d="M29.447,21.105l-4-2a1,1,0,0,0-.895,0l-4,2A1,1,0,0,0,20,22v5a1,1,0,0,0,.553.895l4,2a1,1,0,0,0,.895,0l4-2A1,1,0,0,0,30,27V22A1,1,0,0,0,29.447,21.105ZM25,21.118,26.764,22,25,22.882,23.236,22Zm-3,2.5,2,1v2.764l-2-1Zm4,3.764V24.618l2-1v2.764Z" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconRelatorios = props=>(
    <svg id="statistics" xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 53 53">
      <path id="Caminho_382" data-name="Caminho 382" d="M35.658,24.844a4.641,4.641,0,0,0,3.23-1.307l4.57,2.285a4.658,4.658,0,1,0,8.824-1.433l6.212-6.212a4.658,4.658,0,0,0,6.452-5.6l5.4-4.047a4.652,4.652,0,1,0-1.865-2.483l-5.4,4.047A4.657,4.657,0,0,0,56.3,15.982l-6.212,6.212a4.648,4.648,0,0,0-5.238.852L40.28,20.76a4.658,4.658,0,1,0-4.621,4.084ZM72.924,3.105a1.553,1.553,0,1,1-1.553,1.553A1.555,1.555,0,0,1,72.924,3.105ZM60.5,12.422a1.553,1.553,0,1,1-1.553,1.553A1.555,1.555,0,0,1,60.5,12.422ZM48.08,24.844A1.553,1.553,0,1,1,46.527,26.4,1.555,1.555,0,0,1,48.08,24.844ZM35.658,18.633a1.553,1.553,0,1,1-1.553,1.553A1.555,1.555,0,0,1,35.658,18.633Zm0,0" transform="translate(-27.791)" fill="#4a4a4a"/>
      <path id="Caminho_383" data-name="Caminho 383" d="M51.447,184.367H49.791V151.553A1.553,1.553,0,0,0,48.238,150H42.027a1.553,1.553,0,0,0-1.553,1.553v32.814H37.369v-23.5a1.553,1.553,0,0,0-1.553-1.553H29.605a1.553,1.553,0,0,0-1.553,1.553v23.5H24.947V173.291a1.553,1.553,0,0,0-1.553-1.553H17.184a1.553,1.553,0,0,0-1.553,1.553v11.076H12.525V167.08a1.553,1.553,0,0,0-1.553-1.553H4.762a1.553,1.553,0,0,0-1.553,1.553v17.287H1.553a1.553,1.553,0,0,0,0,3.105H51.447a1.553,1.553,0,0,0,0-3.105ZM43.58,153.105h3.105v31.262H43.58Zm-12.422,9.316h3.105v21.945H31.158ZM18.736,174.844h3.105v9.523H18.736ZM6.314,168.633H9.42v15.734H6.314Zm0,0" transform="translate(0 -134.473)" fill="#4a4a4a"/>
    </svg>
  );

  let IconServico = props=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 50.702 50.702">
      <defs>
        <clipPath id="clipPath">
          <path id="path2643" d="M0-682.665H50.7v50.7H0Z" transform="translate(0 682.665)" fill="#4a4a4a"/>
        </clipPath>
      </defs>
      <g id="servico" transform="translate(0 -631.964)">
        <g id="g2637" transform="translate(0 1314.629)">
          <g id="g2639" transform="translate(0 -682.665)">
            <g id="g2641" clipPath="url(#clipPath)">
              <g id="g2647" transform="translate(39.329 0)">
                <path id="path2649" d="M-126.05-57.712a1.483,1.483,0,0,1,1.051.435l2.8,2.8a1.485,1.485,0,0,1,.138,1.942l-4.2,5.6a1.485,1.485,0,0,1-1.188.594h-4.2a1.485,1.485,0,0,1-1.485-1.485v-4.2a1.485,1.485,0,0,1,.594-1.188l5.6-4.2A1.481,1.481,0,0,1-126.05-57.712Zm.84,4.426-.979-.979-3.977,2.983v1.973h1.973Z" transform="translate(133.137 57.712)" fill="#4a4a4a"/>
              </g>
              <g id="g2651" transform="translate(0 28.697)">
                <path id="path2653" d="M-155.546-31.716a4.427,4.427,0,0,1,3.151,1.305l2.8,2.8a4.462,4.462,0,0,1,0,6.3l-10.292,10.292a4.427,4.427,0,0,1-3.151,1.305,4.427,4.427,0,0,1-3.151-1.305l-2.8-2.8a4.427,4.427,0,0,1-1.305-3.151,4.427,4.427,0,0,1,1.305-3.151L-158.7-30.411A4.427,4.427,0,0,1-155.546-31.716Zm-7.491,19.035a1.476,1.476,0,0,0,1.05-.435l10.292-10.292a1.487,1.487,0,0,0,0-2.1l-2.8-2.8a1.476,1.476,0,0,0-1.05-.435,1.476,1.476,0,0,0-1.05.435l-10.292,10.292a1.476,1.476,0,0,0-.435,1.05,1.475,1.475,0,0,0,.435,1.05l2.8,2.8A1.476,1.476,0,0,0-163.037-12.681Z" transform="translate(170.294 31.716)" fill="#4a4a4a"/>
              </g>
              <g id="g2655" transform="translate(0.002 0)">
                <path id="path2657" d="M-610.639-543.691a10.31,10.31,0,0,1-7.339-3.039,10.328,10.328,0,0,1-2.756-9.758l-17.407-17.407a10.443,10.443,0,0,1-2.418.283,10.31,10.31,0,0,1-7.339-3.038,10.413,10.413,0,0,1-2.025-11.827,1.485,1.485,0,0,1,1.089-.824,1.485,1.485,0,0,1,1.3.414l4.885,4.885,2.1-2.1-4.885-4.885a1.485,1.485,0,0,1-.414-1.3,1.485,1.485,0,0,1,.824-1.089,10.488,10.488,0,0,1,4.488-1.014,10.31,10.31,0,0,1,7.339,3.039,10.33,10.33,0,0,1,2.756,9.758l17.407,17.407a10.443,10.443,0,0,1,2.418-.283,10.31,10.31,0,0,1,7.339,3.038,10.413,10.413,0,0,1,2.025,11.827,1.486,1.486,0,0,1-1.089.824,1.485,1.485,0,0,1-1.3-.414l-4.885-4.885-2.1,2.1,4.885,4.885a1.485,1.485,0,0,1,.414,1.3,1.485,1.485,0,0,1-.824,1.089A10.487,10.487,0,0,1-610.639-543.691Zm-27.068-33.356a1.485,1.485,0,0,1,1.051.435l18.641,18.641a1.485,1.485,0,0,1,.358,1.523,7.387,7.387,0,0,0,1.78,7.618,7.359,7.359,0,0,0,5.238,2.169,7.445,7.445,0,0,0,1.056-.076l-4.194-4.194a1.485,1.485,0,0,1-.435-1.05,1.485,1.485,0,0,1,.435-1.05l4.2-4.2a1.485,1.485,0,0,1,2.1,0l4.194,4.194a7.431,7.431,0,0,0-2.094-6.3,7.359,7.359,0,0,0-5.239-2.168,7.469,7.469,0,0,0-2.379.388,1.485,1.485,0,0,1-1.523-.358l-18.641-18.641a1.485,1.485,0,0,1-.358-1.523,7.387,7.387,0,0,0-1.78-7.618,7.359,7.359,0,0,0-5.238-2.169,7.445,7.445,0,0,0-1.056.076l4.194,4.194a1.485,1.485,0,0,1,0,2.1l-4.2,4.2a1.485,1.485,0,0,1-2.1,0l-4.194-4.194a7.431,7.431,0,0,0,2.094,6.3,7.359,7.359,0,0,0,5.239,2.168,7.469,7.469,0,0,0,2.379-.388A1.484,1.484,0,0,1-637.707-577.047Z" transform="translate(650.934 594.393)" fill="#4a4a4a"/>
              </g>
              <g id="g2659" transform="translate(16.764 26.667)">
                <path id="path2661" d="M-76.416-12.729a1.481,1.481,0,0,1-1.05-.435,1.485,1.485,0,0,1,0-2.1l4.3-4.3a1.485,1.485,0,0,1,2.1,0,1.485,1.485,0,0,1,0,2.1l-4.3,4.3A1.481,1.481,0,0,1-76.416-12.729Z" transform="translate(77.901 20)" fill="#4a4a4a"/>
              </g>
              <g id="g2663" transform="translate(26.667 8.601)">
                <path id="path2665" d="M-18.515-172.382a1.481,1.481,0,0,1-1.05-.435,1.485,1.485,0,0,1,0-2.1L-7.1-187.382a1.485,1.485,0,0,1,2.1,0,1.485,1.485,0,0,1,0,2.1l-12.464,12.464A1.481,1.481,0,0,1-18.515-172.382Z" transform="translate(20 187.817)" fill="#4a4a4a"/>
              </g>
              <g id="g2667" transform="translate(0 37.758)">
                <path id="path2669" d="M-62.171-129.621a4.427,4.427,0,0,1-3.151-1.305l-2.8-2.8a4.427,4.427,0,0,1-1.305-3.151,4.427,4.427,0,0,1,1.305-3.151l2.1-2.1a1.485,1.485,0,0,1,2.1,0l7,7a1.485,1.485,0,0,1,.435,1.05,1.485,1.485,0,0,1-.435,1.05l-2.1,2.1A4.427,4.427,0,0,1-62.171-129.621Zm-2.8-9.358-1.05,1.05a1.476,1.476,0,0,0-.435,1.05,1.476,1.476,0,0,0,.435,1.05l2.8,2.8a1.476,1.476,0,0,0,1.05.435,1.476,1.476,0,0,0,1.05-.435l1.05-1.05Z" transform="translate(69.428 142.565)" fill="#4a4a4a"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )


  let fornecedores, servicos, produtos, entradas, contagem, relatorios = '';

  switch (active) {

    case 'fornecedores':
        fornecedores = 'active';
        break;

    case 'servicos':
      servicos = 'active';
      break;

    case 'produtos':
        produtos = 'active';
        break;

    case 'entradas':
        entradas = 'active';
        break;

    case 'contagem':
        contagem = 'active';
        break;

    case 'relatorios':
        relatorios = 'active';
        break;

    default:
    
        break;
  }


  let itensSubMenu = [
    { active: fornecedores, title: "Fornecedores", rota: "/estoque/fornecedores", icon: <IconPessoa/> },
    { active: servicos, title: "Serviços", rota: "/estoque/servicos", icon: <IconServico/> },
    { active: produtos, title: "Produtos", rota: "/estoque/produtos", icon: <IconProduto/> },
    { active: entradas, title: "Entradas", rota: "/estoque/entradas", icon: <IconEstoque/> },
    { active: contagem, title: "Contagem de estoque", rota: "/estoque/contagem", icon: <IconContagemEstoque/> },
    // { active: relatorios, title: "Relatorios", rota: "/estoque/relatorios", icon: <IconRelatorios/> },
  ]


  return (<Submenu itens={ itensSubMenu } />);
}