import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import api from "../services/api";

import Login from "../pages/Login";
import RecoveryAccount from "../pages/RecoveryAccount";

import Resumo from "../pages/Resumo";
import Pessoas from "../pages/Cadastros/Pessoas";
import NovaPessoa from "../pages/Cadastros/Pessoas/novaPessoa";
import PessoasDetalhes from '../pages/Cadastros/Pessoas/pessoasDetalhes';
import NovoUsuario from '../pages/Cadastros/Usuarios/novoUsuario';
import UsuarioDetalhes from '../pages/Cadastros/Usuarios/usuarioDetalhes';

import EmailInbox from '../pages/Email/inbox';

import ModeloMensagem from '../pages/Cadastros/ModeloMensagens';

import Imoveis from "../pages/Cadastros/Imoveis";
import ImovelDetalhes from '../pages/Cadastros/Imoveis/detalhesImovel';
import NovoImovel from '../pages/Cadastros/Imoveis/novoImovel';

import EstoqueFornecedores from '../pages/Estoque/fornecedores';
import Produtos from '../pages/Estoque/Produtos';
import NovoProduto from '../pages/Estoque/Produtos/novoProduto';
import ProdutoDetalhes from '../pages/Estoque/Produtos/ProdutoDetalhes';

import Servicos from '../pages/Estoque/Servicos';

import ContagemEstoque from '../pages/Estoque/ContagemEstoque';

import Entradas from '../pages/Estoque/Entradas';
import NovaCompra from '../pages/Estoque/Entradas/novaCompra';
import EstoqueDetalhes from '../pages/Estoque/Entradas/estoqueDetalhes';

import VendasClientes from '../pages/Vendas/clientes';
import Vendas from '../pages/Vendas';
import PontoVenda from '../pages/Vendas/pontoVenda';

import Orcamentos from '../pages/Vendas/orcamentos';

import OrdemServicos from '../pages/OS';

import FluxoCaixa from '../pages/Financas/fluxoCaixa';
import ControleCaixa from '../pages/Financas/controleCaixa';
import ContasBancarias from '../pages/Financas/contasBancarias';
import Contas from '../pages/Financas/contas';

import EmpresaConfig from '../pages/Configuracoes/EmpresaConfig';

import Header from '../componentes/Header';

export default function Rotas() {

    const [dadosEmpresa, setdadosEmpresa] = useState([]);

    const getDadosEmpresa = async()=>{

        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let response = await api.post('dadosEmpresa', {}, 
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        });
    
        setdadosEmpresa(response.data);    
    }

    useEffect(async()=>{ 
        await getDadosEmpresa();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Header/>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/recoveryAccount" component={RecoveryAccount} />
                <Route exact path="/register" component={Login} />
                <Route exact path="/resumo" component={Resumo} />

                <Route exact path="/email/inbox" component={EmailInbox} />

                <Route exact path="/cadastros/pessoas" render={(props) => <Pessoas {...props} dadosEmpresa={dadosEmpresa} />} />
                <Route exact path="/cadastros/pessoas/nova" component={NovaPessoa} />
                <Route exact path="/cadastro/pessoa/:codigo" render={(props) => <PessoasDetalhes {...props} dadosEmpresa={dadosEmpresa} />} />
                <Route exact path="/cadastros/usuario/novo" component={NovoUsuario} />
                <Route exact path="/cadastro/usuario/:codigo" component={UsuarioDetalhes} />

                <Route exact path="/cadastros/modelosmensagem" render={(props) => <ModeloMensagem {...props} dadosEmpresa={dadosEmpresa} />} />

                <Route exact path="/cadastros/imoveis" render={(props) => <Imoveis {...props} dadosEmpresa={dadosEmpresa} />} />
                <Route exact path="/cadastro/imovel/:codigo" render={(props) => <ImovelDetalhes {...props} dadosEmpresa={dadosEmpresa} />} />
                <Route exact path="/cadastros/imoveis/novo" component={NovoImovel} />                

                <Route exact path="/estoque/fornecedores" component={EstoqueFornecedores} />
                <Route exact path="/estoque" component={Produtos} />
                <Route exact path="/estoque/produtos" component={Produtos} />
                <Route exact path="/estoque/produto/novo" component={NovoProduto} />
                <Route exact path="/estoque/produto/detalhes/:codigo" component={ProdutoDetalhes} />

                
                <Route exact path="/estoque/entradas" component={Entradas} />
                <Route exact path="/estoque/entradas/detalhes/:codigo" component={EstoqueDetalhes} />
                <Route exact path="/estoque/compra/nova" component={NovaCompra} />

                <Route exact path="/estoque/servicos" component={Servicos} />

                <Route exact path="/estoque/contagem" component={ContagemEstoque} />
                
                <Route exact path="/vendas/clientes" component={VendasClientes} />
                <Route exact path="/vendas" component={Vendas} />
                <Route exact path="/vendas/ponto-de-venda/:codigo?" component={PontoVenda} />

                <Route exact path="/vendas/orcamentos" component={Orcamentos} />                

                <Route exact path="/os" component={OrdemServicos} />

                <Route exact path="/financas/fluxoCaixa" component={FluxoCaixa} />
                <Route exact path="/financas/controleCaixa" component={ControleCaixa} />
                <Route exact path="/financas/contasBancarias" component={ContasBancarias} />
                <Route exact path="/financas/contas" component={Contas} />
                <Route exact path="/financas/cartoes" component={FluxoCaixa} />
                <Route exact path="/financas/cheques" component={FluxoCaixa} />
                <Route exact path="/financas/notasPromissorias" component={FluxoCaixa} />

                <Route exact path="/empresa/config" component={EmpresaConfig} />
            </Switch>
        </Router>
    );
}