import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import api from '../../../services/api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import ButtonDefault from '../../../componentes/ButtonDefault';
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import Fancybox from '../../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react

import generatePdfThumbnails from 'pdf-thumbnails-generator';
import ModalEditTitleAnexo from './modals/modalEditTitleAnexo';
import ModalEnvioAnexoWhatsApp from './modals/modalEnvioAnexoWhatsApp';

import { Buffer } from 'buffer';

export default function AnexosImovelVenda({dadosImovel}) {

  let IconUpload = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="30"viewBox="0 0 38.069 31.819">
        <g id="upload" transform="translate(-0.997 -4.993)">
        <path id="Caminho_87" data-name="Caminho 87" d="M29.948,12.692a10.167,10.167,0,0,0-19.722-.011,10.179,10.179,0,0,0,.954,20.314H15a1.272,1.272,0,1,0,0-2.545H11.18a7.634,7.634,0,0,1-.045-15.269,1.321,1.321,0,0,0,1.4-1.087,7.623,7.623,0,0,1,15.093,0,1.374,1.374,0,0,0,1.365,1.087,7.634,7.634,0,1,1,0,15.269H25.176a1.272,1.272,0,0,0,0,2.545h3.817a10.179,10.179,0,0,0,.954-20.3Z" transform="translate(0)" fill="#fff"/>
        <path id="Caminho_88" data-name="Caminho 88" d="M23.1,21.534a1.272,1.272,0,1,0,1.8-1.8l-6.362-6.362a1.272,1.272,0,0,0-1.8,0l-6.362,6.362a1.272,1.272,0,1,0,1.8,1.8l4.19-4.19V33.358a1.272,1.272,0,0,0,2.545,0V17.344Z" transform="translate(2.452 2.181)" fill="#fff"/>
        </g>
    </svg>
  ) 

  let IconZoom = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 12.515 12.516">
      <g id="icon" transform="translate(-5.267 -5.266)">
        <path id="XMLID_231_" d="M17.782,5.835V8.68a.569.569,0,1,1-1.137,0V7.209l-3.3,3.308a.569.569,0,0,1-.8-.8L15.841,6.4h-1.47a.569.569,0,0,1,0-1.138h2.843a.569.569,0,0,1,.569.569ZM17.214,13.8a.569.569,0,0,0-.569.569v1.47L13.4,12.616a.588.588,0,0,0-.82,0,.569.569,0,0,0-.008.8l3.217,3.223H14.311a.569.569,0,1,0,0,1.138h2.842a.642.642,0,0,0,.433-.167.6.6,0,0,0,.2-.4V14.368a.569.569,0,0,0-.569-.569Zm-7.56-1.207L6.4,15.838V14.368a.564.564,0,0,0-.564-.568h0a.568.568,0,0,0-.568.568v2.845a.569.569,0,0,0,.569.569H8.688a.569.569,0,0,0,0-1.138H7.218L10.464,13.4a.571.571,0,0,0-.81-.8ZM7.208,6.455h1.47a.569.569,0,0,0,0-1.138H5.836a.568.568,0,0,0-.569.569V8.731a.569.569,0,1,0,1.137,0V7.261l3.279,3.282a.569.569,0,1,0,.8-.8Z" fill="#fff"/>
      </g>
    </svg>
  )

  let IconCloseTimes = props =>(
    <svg id="close" xmlns="http://www.w3.org/2000/svg" width="13.663" height="13.506" viewBox="0 0 13.663 13.506">
      <g id="close_1_" transform="translate(0 0)">
        <path id="Caminho_85" data-name="Caminho 85" d="M1.3,15.943a1.294,1.294,0,0,1-.919-.311,1.294,1.294,0,0,1,0-1.825L11.365,2.82a1.294,1.294,0,0,1,1.89,1.76L2.2,15.633a1.294,1.294,0,0,1-.906.311Z" transform="translate(-0.001 -2.444)" fill="#fff"/>
        <path id="Caminho_86" data-name="Caminho 86" d="M13.733,15.343a1.294,1.294,0,0,1-.906-.375L1.839,3.98A1.294,1.294,0,0,1,3.664,2.155L14.717,13.143a1.294,1.294,0,0,1,.06,1.829q-.029.031-.06.06a1.294,1.294,0,0,1-.984.311Z" transform="translate(-1.463 -1.844)" fill="#fff"/>
      </g>
    </svg>
  )

  let IconTypeImage = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 59.148 54.221">
      <g id="image-gallery" transform="translate(-0.001 -2)">
        <g id="Grupo_354" data-name="Grupo 354" transform="translate(0.001 9.394)">
          <g id="Grupo_353" data-name="Grupo 353" transform="translate(0 9.691)">
            <path id="Caminho_282" data-name="Caminho 282" d="M15.4,34.978a9.227,9.227,0,0,1-8.73-6.287l-.086-.283a8.991,8.991,0,0,1-.426-2.672V8.932L.182,28.89a5.6,5.6,0,0,0,3.924,6.79l38.11,10.206a5.652,5.652,0,0,0,1.42.182,5.5,5.5,0,0,0,5.326-4.03l2.221-7.061Z" transform="translate(-0.001 -8.932)" fill="#fff"/>
          </g>
          <path id="Caminho_283" data-name="Caminho 283" d="M11.929,14.858A4.929,4.929,0,1,0,7,9.929,4.934,4.934,0,0,0,11.929,14.858Z" transform="translate(10.251 -5)" fill="#fff"/>
        </g>
        <path id="Caminho_284" data-name="Caminho 284" d="M47.13,2H10.161A6.17,6.17,0,0,0,4,8.161v27.11a6.17,6.17,0,0,0,6.161,6.161H47.13a6.17,6.17,0,0,0,6.161-6.161V8.161A6.17,6.17,0,0,0,47.13,2ZM10.161,6.929H47.13a1.233,1.233,0,0,1,1.232,1.232v17.5l-7.786-9.084a4.414,4.414,0,0,0-3.305-1.516,4.309,4.309,0,0,0-3.293,1.555L24.826,27.6l-2.982-2.975a4.326,4.326,0,0,0-6.112,0l-6.8,6.8V8.161a1.233,1.233,0,0,1,1.232-1.232Z" transform="translate(5.857 0)" fill="#fff"/>
      </g>
    </svg>
  )

  let IconTypePDF = props =>(
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 58.31 60">
      <g id="Layer_1" data-name="Layer 1">
        <g id="pdf">
          <path id="Caminho_285" data-name="Caminho 285" d="M39,60H11A11,11,0,0,1,0,49V11A11,11,0,0,1,11,0H35a3,3,0,0,1,2.12.88l12,12A3,3,0,0,1,50,15v4a3,3,0,0,1-3,3H31a3,3,0,0,1-3-3V6H11a5,5,0,0,0-5,5V49a5,5,0,0,0,5,5H39a3,3,0,0,1,0,6Z" fill="#fff"/>
          <path id="Caminho_286" data-name="Caminho 286" d="M33.84,49.5H29.52A2.5,2.5,0,0,1,27,47V28.81a2.5,2.5,0,0,1,2.5-2.5h4.32a8.51,8.51,0,0,1,8.5,8.5V41a8.51,8.51,0,0,1-8.48,8.5ZM32,44.5h1.82a3.5,3.5,0,0,0,3.5-3.5V34.81a3.5,3.5,0,0,0-3.5-3.5H32Z" fill="#fff"/>
          <path id="Caminho_287" data-name="Caminho 287" d="M13,49.5A2.5,2.5,0,0,1,10.5,47V28.81a2.5,2.5,0,0,1,2.5-2.5h4.48a7.05,7.05,0,0,1,0,14.1h-2V47A2.5,2.5,0,0,1,13,49.5Zm2.5-14.09h2a2.05,2.05,0,0,0,0-4.1h-2Z" fill="#fff"/>
          <path id="Caminho_288" data-name="Caminho 288" d="M47.88,49.5a2.5,2.5,0,0,1-2.5-2.5V32.12a5.82,5.82,0,0,1,5.81-5.81h4.62a2.5,2.5,0,1,1,0,5H51.19a.81.81,0,0,0-.81.81V47a2.49,2.49,0,0,1-2.5,2.5Z" fill="#fff"/>
          <path id="Caminho_289" data-name="Caminho 289" d="M55.81,39.41H47.88a2.5,2.5,0,0,1,0-5h7.93a2.5,2.5,0,1,1,0,5Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  )

  let IconTitle = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="43.205" height="43.206" viewBox="0 0 43.205 43.206">
      <path id="content-marketing" d="M26.16,43.205H10.126A10.138,10.138,0,0,1,0,33.079V10.126A10.138,10.138,0,0,1,10.126,0H33.079A10.138,10.138,0,0,1,43.205,10.126v16.16a1.688,1.688,0,0,1-3.375,0V10.126a6.759,6.759,0,0,0-6.751-6.751H10.126a6.759,6.759,0,0,0-6.751,6.751V33.079a6.759,6.759,0,0,0,6.751,6.751H26.16a1.688,1.688,0,1,1,0,3.375Zm8.607-32.573a1.688,1.688,0,0,0-1.688-1.688H17.637a1.688,1.688,0,0,0,0,3.375H33.079A1.688,1.688,0,0,0,34.767,10.633Zm0,6.751A1.688,1.688,0,0,0,33.079,15.7H10.126a1.688,1.688,0,1,0,0,3.375H33.079A1.688,1.688,0,0,0,34.767,17.383ZM17.89,24.134A1.688,1.688,0,0,0,16.2,22.447H10.126a1.688,1.688,0,1,0,0,3.375H16.2A1.688,1.688,0,0,0,17.89,24.134ZM8.439,10.464a2.11,2.11,0,1,0,2.11-2.11A2.11,2.11,0,0,0,8.439,10.464ZM41.4,41.4a6.167,6.167,0,0,0,0-8.712l-5.916-5.916a12.167,12.167,0,0,0-6.238-3.338l-4.782-.956a1.688,1.688,0,0,0-1.986,1.986l.956,4.782a12.168,12.168,0,0,0,3.338,6.238L32.689,41.4a6.16,6.16,0,0,0,8.712,0ZM28.585,26.745A8.8,8.8,0,0,1,33.1,29.16l5.916,5.916a2.785,2.785,0,1,1-3.938,3.938L29.16,33.1a8.8,8.8,0,0,1-2.415-4.513l-.46-2.3Z" fill="#fff"/>
    </svg>
  )

  let IconWhatsApp = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="44.084" height="44.084" viewBox="0 0 44.084 44.084">
      <g id="XMLID_468_" transform="translate(0)">
        <path id="XMLID_469_" d="M101.932,93.579c-.086-.041-3.3-1.624-3.871-1.829a2.223,2.223,0,0,0-.749-.165,1.274,1.274,0,0,0-1.082.641c-.321.477-1.293,1.613-1.593,1.953-.039.045-.093.1-.125.1s-.526-.2-.677-.27A16.912,16.912,0,0,1,87.412,88.3a.355.355,0,0,1-.054-.127,1.036,1.036,0,0,1,.188-.222c.175-.173.365-.4.548-.622.087-.1.174-.209.259-.308a3.462,3.462,0,0,0,.522-.829l.072-.145a1.5,1.5,0,0,0-.044-1.411c-.076-.152-1.433-3.427-1.577-3.771-.347-.83-.805-1.217-1.442-1.217-.059,0,0,0-.248.01a7.7,7.7,0,0,0-2.673.687A5.594,5.594,0,0,0,80.89,85.1a10.257,10.257,0,0,0,2.224,5.647c.017.022.047.067.091.132a20.936,20.936,0,0,0,8.98,7.8,14.6,14.6,0,0,0,5.424,1.387h0a8.475,8.475,0,0,0,.883-.052L98.65,100c1.075-.1,3.438-1.32,3.975-2.813a4.447,4.447,0,0,0,.253-2.928A2.029,2.029,0,0,0,101.932,93.579Z" transform="translate(-69.312 -68.248)" fill="#fff"/>
        <path id="XMLID_470_" d="M22.433,0A21.6,21.6,0,0,0,.781,21.49,21.332,21.332,0,0,0,3.749,32.357L.031,43.327a.572.572,0,0,0,.716.729l11.439-3.635a21.687,21.687,0,0,0,31.9-18.932A21.6,21.6,0,0,0,22.433,0Zm0,38.5A17.153,17.153,0,0,1,13,35.687a.573.573,0,0,0-.487-.067l-5.73,1.821,1.85-5.457a.573.573,0,0,0-.08-.522A16.78,16.78,0,0,1,5.283,21.49,17.15,17.15,0,1,1,22.433,38.5Z" transform="translate(0)" fill="#fff"/>
      </g>
    </svg>
  )


  const [loaderAnexos, setloaderAnexos] = useState(true);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const [progressUpload, setprogressUpload] = useState(0);
  const [anexosVenda, setanexosVenda] = useState([]);

  const [showModalEditTitleAnexo, setshowModalEditTitleAnexo] = useState(false);
  const [idAnexoEditTitle, setidAnexoEditTitle] = useState();

  const [urlAnexoEnvioWhatsApp, seturlAnexoEnvioWhatsApp] = useState();
  const [urlFilenameEnvioWhatsApp, seturlFilenameEnvioWhatsApp] = useState();
  const [showModalEnvioWhatsApp, setshowModalEnvioWhatsApp] = useState(false);
 
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };
 
  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.produtos);
  }

  const generateTumbOfPDF = async(pdf_source, thumbnail_size)=>{
    try {
        const thumbnails = await generatePdfThumbnails(pdf_source, thumbnail_size);
        return thumbnails[0].thumbnail;
    } catch (err) {
        return null;
    }
  }

  const getAnexos = async(imovelVendaId)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    setloaderAnexos(true);

    let anexos = await api.post(`listagemAnexosImovelVenda`,
    {
        "id": imovelVendaId
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });
    

    let anexosvenda = [];

    for await (let anexo of anexos.data) {

        let tumbPDF = null;
          
        if(anexo.type === 'application/pdf'){
          // tumbPDF = await generateTumbOfPDF(anexo.url, 350);

          if(anexo.thumbnail){

            let buffer = new Buffer.from( anexo.thumbnail.data, 'binary' );
            let bufferBase64 = buffer.toString('base64');

            tumbPDF = window.atob(bufferBase64); //decode base64

          }else{
            tumbPDF = await generateTumbOfPDF(anexo.url, 250);

            //SET THUMBNAIL IN ANEXO
            api.post(`setThumbnailAnexosImovelVenda`,{
                "id": anexo.id,
                "thumbnail": tumbPDF,
            },
            {
                headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
                }
            }).then( async request => {
      
            }).catch((error) => {
              
            });
          }

        }

        let dados = {
            id: anexo.id,
            ordem: anexo.ordem,
            principal: anexo.principal,
            titulo: anexo.titulo,
            type: anexo.type,
            tumbPDF: tumbPDF,
            url: anexo.url
        }

        anexosvenda.push(dados);
      
    }
  
    setanexosVenda(anexosvenda);
    setloaderAnexos(false);

  }
  

  const handleUpload = async(e)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let anexos = e.target.files;

    let formData = new FormData();
    formData.append('id', dadosImovel.imovelVendaId);

    if(anexos.length > 7){
      Swal.fire(
        'Ops, Limite por vez atingido!',
        'Você só pode enviar 7 arquivos por vez.',
        'warning'
      )
    }else{
    
      for await (let anexo of anexos) {

        if(anexo.type === 'image/png' || anexo.type === 'image/gif' || anexo.type === 'image/jpeg' || anexo.type === 'application/pdf'){
          formData.append('anexos', anexo);
        }else{
          setalertErrorMsg('Foto inválida, Apenas os formatos .png, .jpg, .jpeg e .PDF são permitidos!')
          setalertError(true);
          setTimeout(() => { setalertError(false) }, 6000);   
        }
        
      }

      let response = await api.put("uploadAnexosImovelVenda", formData, {
        headers: { 
          "Content-type": "multipart/form-data",
          "Authorization": `Bearer ${auth.token}`
        },
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          setprogressUpload(progress)
        },
      });

      if(response.data.error_code === "MAXIMUM_FILES"){
        setalertErrorMsg(response.data.message)
        setalertError(true);
        setTimeout(() => { setalertError(false) }, 6000);
      }

      setprogressUpload(0);
      getAnexos(dadosImovel.imovelVendaId);
    }
    

  }

  const handleDeleteAnexo = async(idFoto)=>{
    
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar o anexo selecionado?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
        
        await api.delete('deleteAnexoImovelVenda', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "id": idFoto, "imovelVendaId": dadosImovel.imovelVendaId }
        });

        await getAnexos(dadosImovel.imovelVendaId);

        Swal.fire(
          'Deletado!',
          'Anexo deletado com sucesso!',
          'success'
        )
      }
    })

  }

  const handleDragAnexo = async(result)=>{
    
    if(permissoesdoUsuario[3]?.update){

      const items = Array.from(anexosVenda);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      let dadosArray = [];

      for await (let [key, item] of  Object.entries(items)) {
        let dados = {
          id: item.id,
          ordem: parseInt(key)
        }

        dadosArray.push(dados);

      }

      setanexosVenda(items);

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put(`updateOrdemAnexosImovelVenda`,
      {
          "imovelVendaId": dadosImovel.imovelVendaId,
          "dados": dadosArray
      },
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      })

    }else{
      setalertErrorMsg('Você não possui autorização para modificar a ordem dos anexos.')
      setalertError(true);
      setTimeout(() => { setalertError(false) }, 6000);
    }

  }

  useEffect(async()=>{
    await permissoesUsuario();
    await getAnexos(dadosImovel.imovelVendaId);

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);
  
  const Input = styled('input')({
    display: 'none',
  });

  const refreshanexos = async()=>{
    getAnexos(dadosImovel.imovelVendaId);
  }

  const handleEditTitleAnexo = async(anexoId)=>{
    setidAnexoEditTitle(anexoId)
    setshowModalEditTitleAnexo(true);
  }

  const handleEnvioAnexoWhatsApp = async(anexo, filename)=>{
    seturlAnexoEnvioWhatsApp(anexo);
    seturlFilenameEnvioWhatsApp(filename);
    setshowModalEnvioWhatsApp(true);
  }


  return (
    <>

      <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertSucessMsg}
        </Alert>
      </Snackbar>

      <ModalEditTitleAnexo idAnexo={idAnexoEditTitle} visible={showModalEditTitleAnexo} change={setshowModalEditTitleAnexo} refreshAnexos={refreshanexos}/>
      <ModalEnvioAnexoWhatsApp anexo={urlAnexoEnvioWhatsApp} filename={urlFilenameEnvioWhatsApp} visible={showModalEnvioWhatsApp} change={setshowModalEnvioWhatsApp} />

      <div>
        <Grid container spacing={2} >
          <Grid item xs={12} md={12}>
              <div className='box-content-detalhes-estoque'>

                  {
                    loaderAnexos ?

                    <Grid container item spacing={2} xs={12} md={12}>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                      <Grid item xs={12} md={1.5}>
                        <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                      </Grid>
                    </Grid>

                  :

                  <>
                    <div className='header-box-content-detalhes-estoque'>
                        <h3 className='title'>Anexos info. venda</h3>
                    </div>
              
                    <div className='box-anexos'>
                        
                      <Fancybox>
                        <DragDropContext onDragEnd={handleDragAnexo}>
                            <Droppable droppableId="droppable" direction="horizontal">
                            {(provided) => (
                                <aside {...provided.droppableProps} ref={provided.innerRef} id={dadosImovel.imovelVendaId}>
                                {anexosVenda.map((v, k) =>{
                                    return (
                                    <Draggable key={v.id} draggableId={v.id.toString()} index={k}>
                                        {(provided) => (
                                        <div className='box-element-anexo' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            
                                            {
                                                v.type === 'image/png' || v.type === 'image/gif' || v.type === 'image/jpeg' ?

                                                <div style={{ marginBottom: '50px' }}>
                                                  <div className='element-anexo' style={{ backgroundImage: `url(${v.url})` }}>

                                                      <div className='box-header-item-anexo'>
                                                          <div className='iconTypeFile'><IconTypeImage/></div>
                                                          {
                                                              permissoesdoUsuario[1]?.delete &&
                                                              <button type='button' onClick={()=>{ handleDeleteAnexo(v.id) }} className='deleteAnexo'><IconCloseTimes/></button>
                                                          }
                                                      </div>

                                                      <div className='box-abrirAnexo'>
                                                        <button type='button' onClick={()=>{ handleEditTitleAnexo(v.id) }}>
                                                            <IconTitle />
                                                        </button>
                                                        <button type='button' data-fancybox="gallery" href={v.url} data-caption={v.titulo}>
                                                          <IconZoom/>
                                                        </button>
                                                        <button type='button' className='btnWhatsapp' onClick={()=>{ handleEnvioAnexoWhatsApp(v.url, v.titulo) }}>
                                                            <IconWhatsApp />
                                                          </button>
                                                      </div>

                                                  </div>
                                                  {
                                                    v.titulo && 
                                                    <div className='title'>
                                                      <Tooltip title={v.titulo} arrow followCursor>
                                                        <Box>
                                                          <p>{v.titulo}</p>
                                                        </Box>
                                                      </Tooltip>
                                                    </div>
                                                  }
                                                </div>
                                                

                                                : //IF PDF
                                              
                                              <div style={{ marginBottom: '50px' }}>
                                                <div className='element-anexo' style={{ backgroundImage: `url("${v.tumbPDF}")` }}> 
                                                    
                                                    <div className='box-header-item-anexo'>
                                                        <div className='iconTypeFile'><IconTypePDF/></div>
                                                        {
                                                            permissoesdoUsuario[3]?.update &&
                                                            <button type='button' onClick={()=>{ handleDeleteAnexo(v.id) }} className='deleteAnexo'><IconCloseTimes/></button>
                                                        }
                                                    </div>

                                                    <div className='box-abrirAnexo'>
                                                      <button type='button' onClick={()=>{ handleEditTitleAnexo(v.id) }}>
                                                            <IconTitle />
                                                      </button>
                                                      <button type='button' data-fancybox="gallery" href={v.url} data-caption={v.titulo}>
                                                        <IconZoom/>
                                                      </button>
                                                      <button type='button' className='btnWhatsapp' onClick={()=>{ handleEnvioAnexoWhatsApp(v.url, v.titulo) }}>
                                                        <IconWhatsApp />
                                                      </button>
                                                    </div>
                                                </div>
                                                {
                                                  v.titulo && 
                                                  <div className='title'>
                                                    <Tooltip title={v.titulo} arrow followCursor>
                                                      <Box>
                                                        <p>{v.titulo}</p>
                                                      </Box>
                                                    </Tooltip>
                                                  </div>
                                                }
                                              </div>

                                            }

                                        </div>
                                        )}
                                    </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                                </aside>
                            )}
                            </Droppable>
                        </DragDropContext>
                      </Fancybox>          

                        
                        {
                          permissoesdoUsuario[3]?.update &&

                          <div className='box-button-upload'>
                              <div style={{ width: '220px' }}>
                                <label htmlFor="button-file-upload">

                                  <Input accept="image/png, image/gif, image/jpeg, .pdf" id="button-file-upload" multiple onChange={handleUpload} type="file" />

                                  <ButtonDefault type="button" icon={<IconUpload/>} component="span" txt="Inserir anexos"  />

                                </label>
                                {
                                  progressUpload > 0 && 
                                  <LinearProgress variant="determinate" value={progressUpload} />
                                }

                              </div>
                              <p>Formatos aceitos: .JPG, .JPEG, .PNG, .PDF | Tamanho máximo: 5MB</p>
                            </div>
                        }

                    </div>
                  </>
                  }

              </div>
          </Grid>
        </Grid>
      </div>
    </>
    
  );
}