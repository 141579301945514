import React from 'react';
import './style.css';

export default function HelpersMenu({ mode }) {
   
    let IconInfo = props => (
        <svg id="info" xmlns="http://www.w3.org/2000/svg" width="22.634" height="22.634" viewBox="0 0 22.634 22.634">
          <path id="Caminho_71" data-name="Caminho 71" d="M11.317,0A11.317,11.317,0,1,0,22.634,11.317,11.317,11.317,0,0,0,11.317,0Zm0,20.748a9.431,9.431,0,1,1,9.431-9.431A9.431,9.431,0,0,1,11.317,20.748Z" fill="#2b2b2b"/>
          <path id="Caminho_72" data-name="Caminho 72" d="M11.943,9A.943.943,0,0,0,11,9.943v7.545a.943.943,0,1,0,1.886,0V9.943A.943.943,0,0,0,11.943,9Z" transform="translate(-0.626 -0.512)" fill="#2b2b2b"/>
          <circle id="Elipse_2" data-name="Elipse 2" cx="1" cy="1" r="1" transform="translate(10 4.634)" fill="#2b2b2b"/>
        </svg>
    );

    let IconSuport = props => (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 21 21">
            <path id="Caminho_81" data-name="Caminho 81" d="M19.646,13.558a9.728,9.728,0,0,0,0-6.116A4.307,4.307,0,0,0,16.693,0a4.269,4.269,0,0,0-3.136,1.354,9.729,9.729,0,0,0-6.116,0A4.307,4.307,0,0,0,0,4.307,4.269,4.269,0,0,0,1.354,7.442a9.728,9.728,0,0,0,0,6.116A4.307,4.307,0,0,0,4.307,21a4.269,4.269,0,0,0,3.136-1.354,9.729,9.729,0,0,0,6.116,0,4.307,4.307,0,1,0,6.088-6.088Zm-2.784,2.434-2.038-2.038a5.525,5.525,0,0,0,0-6.908l2.038-2.038a8.392,8.392,0,0,1,0,10.984Zm-12.724,0a8.392,8.392,0,0,1,0-10.984L6.176,7.046a5.525,5.525,0,0,0,0,6.908ZM6.193,10.5A4.307,4.307,0,1,1,10.5,14.807,4.312,4.312,0,0,1,6.193,10.5Zm10.5-9.27a3.078,3.078,0,0,1,2.434,4.96,9.73,9.73,0,0,0-4.318-4.318,3.051,3.051,0,0,1,1.884-.643Zm-.7,2.908L13.954,6.176a5.525,5.525,0,0,0-6.908,0L5.008,4.138a8.392,8.392,0,0,1,10.984,0ZM1.23,4.307a3.078,3.078,0,0,1,4.96-2.434A9.73,9.73,0,0,0,1.873,6.191,3.052,3.052,0,0,1,1.23,4.307ZM4.307,19.77a3.078,3.078,0,0,1-2.434-4.96,9.73,9.73,0,0,0,4.318,4.318,3.051,3.051,0,0,1-1.884.643Zm.7-2.908,2.038-2.038a5.525,5.525,0,0,0,6.908,0l2.038,2.038a8.392,8.392,0,0,1-10.984,0ZM16.693,19.77a3.052,3.052,0,0,1-1.884-.643,9.73,9.73,0,0,0,4.318-4.318,3.078,3.078,0,0,1-2.434,4.96Z" transform="translate(0 0)" fill="#2b2b2b"/>
        </svg>
    );

    let Iconfaq = props => (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.64" height="22.634" viewBox="0 0 22.64 22.634">
            <g id="Line" transform="translate(-1.007 -1.004)">
                <path id="Caminho_82" data-name="Caminho 82" d="M20.325,4.313A11.319,11.319,0,0,0,2.307,17.588l-.813,3.036a2.062,2.062,0,0,0,2.521,2.521l3.036-.813a11.206,11.206,0,0,0,5.269,1.307,11.926,11.926,0,0,0,1.708-.123,11.319,11.319,0,0,0,6.3-19.2Zm1.255,8.85a9.261,9.261,0,0,1-13.84,7.2,1.029,1.029,0,0,0-.515-.134,1.183,1.183,0,0,0-.268,0l-3.437.926.926-3.437a1.029,1.029,0,0,0-.1-.782A9.261,9.261,0,1,1,21.58,13.163Z" transform="translate(0 0)" fill="#2b2b2b"/>
                <circle id="Elipse_3" data-name="Elipse 3" cx="1.544" cy="1.544" r="1.544" transform="translate(10.775 16.435)" fill="#2b2b2b"/>
                <path id="Caminho_83" data-name="Caminho 83" d="M12.816,5h-1.4A3.416,3.416,0,0,0,8,8.416v.7a1.029,1.029,0,0,0,2.058,0v-.7a1.358,1.358,0,0,1,1.358-1.358h1.4a1.369,1.369,0,0,1,.432,2.655,3.149,3.149,0,0,0-2.161,2.994v.525a1.029,1.029,0,0,0,2.058,0v-.525a1.08,1.08,0,0,1,.751-1.029A3.427,3.427,0,0,0,12.816,5Z" transform="translate(0.203 0.116)" fill="#2b2b2b"/>
            </g>
        </svg>
    );

    let Icontutorial = props => (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <path id="video-tutorial" d="M16.246,7.124,9.178,4.489a.9.9,0,0,0-1.212.842V10.6a.9.9,0,0,0,1.212.842l7.068-2.635A.9.9,0,0,0,16.246,7.124ZM9.763,9.308V6.625l3.6,1.341ZM20.3,0H2.7A2.7,2.7,0,0,0,0,2.7V13.237a2.7,2.7,0,0,0,2.7,2.7H20.3a2.7,2.7,0,0,0,2.7-2.7V2.7A2.7,2.7,0,0,0,20.3,0Zm.9,13.237a.9.9,0,0,1-.9.9H2.7a.9.9,0,0,1-.9-.9V2.7a.9.9,0,0,1,.9-.9H20.3a.9.9,0,0,1,.9.9Zm-.868,6.2H17.543a2.665,2.665,0,0,0-5.018,0H2.665a.9.9,0,1,0,0,1.8h9.859a2.665,2.665,0,0,0,5.018,0h2.792a.9.9,0,1,0,0-1.8Zm-5.3,1.767a.868.868,0,1,1,.868-.868A.869.869,0,0,1,15.034,21.2Z" fill="#2b2b2b"/>
        </svg>
    );

    let IconInfoLight = props => (
        <svg id="info" xmlns="http://www.w3.org/2000/svg" width="22.634" height="22.634" viewBox="0 0 22.634 22.634">
            <path id="Caminho_71" data-name="Caminho 71" d="M11.317,0A11.317,11.317,0,1,0,22.634,11.317,11.317,11.317,0,0,0,11.317,0Zm0,20.748a9.431,9.431,0,1,1,9.431-9.431A9.431,9.431,0,0,1,11.317,20.748Z" fill="#fff"/>
            <path id="Caminho_72" data-name="Caminho 72" d="M11.943,9A.943.943,0,0,0,11,9.943v7.545a.943.943,0,1,0,1.886,0V9.943A.943.943,0,0,0,11.943,9Z" transform="translate(-0.626 -0.512)" fill="#fff"/>
            <circle id="Elipse_2" data-name="Elipse 2" cx="1" cy="1" r="1" transform="translate(10 4.634)" fill="#fff"/>
        </svg>
    );

    let IconSuportLight = props => (
        <svg id="lifeguard" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 21 21">
            <path id="Caminho_81" data-name="Caminho 81" d="M19.646,13.558a9.728,9.728,0,0,0,0-6.116A4.307,4.307,0,0,0,16.693,0a4.269,4.269,0,0,0-3.136,1.354,9.729,9.729,0,0,0-6.116,0A4.307,4.307,0,0,0,0,4.307,4.269,4.269,0,0,0,1.354,7.442a9.728,9.728,0,0,0,0,6.116A4.307,4.307,0,0,0,4.307,21a4.269,4.269,0,0,0,3.136-1.354,9.729,9.729,0,0,0,6.116,0,4.307,4.307,0,1,0,6.088-6.088Zm-2.784,2.434-2.038-2.038a5.525,5.525,0,0,0,0-6.908l2.038-2.038a8.392,8.392,0,0,1,0,10.984Zm-12.724,0a8.392,8.392,0,0,1,0-10.984L6.176,7.046a5.525,5.525,0,0,0,0,6.908ZM6.193,10.5A4.307,4.307,0,1,1,10.5,14.807,4.312,4.312,0,0,1,6.193,10.5Zm10.5-9.27a3.078,3.078,0,0,1,2.434,4.96,9.73,9.73,0,0,0-4.318-4.318,3.051,3.051,0,0,1,1.884-.643Zm-.7,2.908L13.954,6.176a5.525,5.525,0,0,0-6.908,0L5.008,4.138a8.392,8.392,0,0,1,10.984,0ZM1.23,4.307a3.078,3.078,0,0,1,4.96-2.434A9.73,9.73,0,0,0,1.873,6.191,3.052,3.052,0,0,1,1.23,4.307ZM4.307,19.77a3.078,3.078,0,0,1-2.434-4.96,9.73,9.73,0,0,0,4.318,4.318,3.051,3.051,0,0,1-1.884.643Zm.7-2.908,2.038-2.038a5.525,5.525,0,0,0,6.908,0l2.038,2.038a8.392,8.392,0,0,1-10.984,0ZM16.693,19.77a3.052,3.052,0,0,1-1.884-.643,9.73,9.73,0,0,0,4.318-4.318,3.078,3.078,0,0,1-2.434,4.96Z" transform="translate(0 0)" fill="#fff"/>
        </svg>
    );

    let IconfaqLight = props => (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.64" height="22.634" viewBox="0 0 22.64 22.634">
            <g id="Line" transform="translate(-1.007 -1.004)">
                <path id="Caminho_82" data-name="Caminho 82" d="M20.325,4.313A11.319,11.319,0,0,0,2.307,17.588l-.813,3.036a2.062,2.062,0,0,0,2.521,2.521l3.036-.813a11.206,11.206,0,0,0,5.269,1.307,11.926,11.926,0,0,0,1.708-.123,11.319,11.319,0,0,0,6.3-19.2Zm1.255,8.85a9.261,9.261,0,0,1-13.84,7.2,1.029,1.029,0,0,0-.515-.134,1.183,1.183,0,0,0-.268,0l-3.437.926.926-3.437a1.029,1.029,0,0,0-.1-.782A9.261,9.261,0,1,1,21.58,13.163Z" transform="translate(0 0)" fill="#fff"/>
                <circle id="Elipse_3" data-name="Elipse 3" cx="1.544" cy="1.544" r="1.544" transform="translate(10.775 16.435)" fill="#fff"/>
                <path id="Caminho_83" data-name="Caminho 83" d="M12.816,5h-1.4A3.416,3.416,0,0,0,8,8.416v.7a1.029,1.029,0,0,0,2.058,0v-.7a1.358,1.358,0,0,1,1.358-1.358h1.4a1.369,1.369,0,0,1,.432,2.655,3.149,3.149,0,0,0-2.161,2.994v.525a1.029,1.029,0,0,0,2.058,0v-.525a1.08,1.08,0,0,1,.751-1.029A3.427,3.427,0,0,0,12.816,5Z" transform="translate(0.203 0.116)" fill="#fff"/>
            </g>
        </svg>
    );

    let IcontutorialLight = props => (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
            <path id="video-tutorial" d="M16.246,7.124,9.178,4.489a.9.9,0,0,0-1.212.842V10.6a.9.9,0,0,0,1.212.842l7.068-2.635A.9.9,0,0,0,16.246,7.124ZM9.763,9.308V6.625l3.6,1.341ZM20.3,0H2.7A2.7,2.7,0,0,0,0,2.7V13.237a2.7,2.7,0,0,0,2.7,2.7H20.3a2.7,2.7,0,0,0,2.7-2.7V2.7A2.7,2.7,0,0,0,20.3,0Zm.9,13.237a.9.9,0,0,1-.9.9H2.7a.9.9,0,0,1-.9-.9V2.7a.9.9,0,0,1,.9-.9H20.3a.9.9,0,0,1,.9.9Zm-.868,6.2H17.543a2.665,2.665,0,0,0-5.018,0H2.665a.9.9,0,1,0,0,1.8h9.859a2.665,2.665,0,0,0,5.018,0h2.792a.9.9,0,1,0,0-1.8Zm-5.3,1.767a.868.868,0,1,1,.868-.868A.869.869,0,0,1,15.034,21.2Z" fill="#fff"/>
        </svg>
    );



  return (
    <div className='box-helpers-menu'>
        <div className={mode}><a href='https://nexcom.digital/sobre' target="_blank" rel="noreferrer">{ mode === 'light' ? <IconInfoLight/> : <IconInfo/> }  SOBRE</a></div>
        <div className={mode}><a href='https://nexcom.digital/suporte' target="_blank" rel="noreferrer">{ mode === 'light' ? <IconSuportLight/> : <IconSuport/> } SUPORTE</a></div>
        <div className={mode}><a href='https://nexcom.digital/faq' target="_blank" rel="noreferrer">{ mode === 'light' ? <IconfaqLight/> : <Iconfaq/> } FAQ</a></div>
        <div className={mode}><a href='https://nexcom.digital/tutoriais' target="_blank" rel="noreferrer">{ mode === 'light' ? <IcontutorialLight/> : <Icontutorial/> } TUTORIAIS</a></div>
    </div>
  );
}