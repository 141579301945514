import React, {useState, useRef } from 'react';
import './style.css';

import api from '../../../services/api';
import axios from 'axios';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { CEPMask } from '../../../vendor/mascaras';

export default function ModalNovoTransportador({ visible, change, refreshTransportadores }) {

  const inputNumero = useRef(null);
  const [loaderPage, setloaderPage] = useState(false);
  
  const validationSchema = yup.object({
    nome: yup
      .string('Insira o Nome')
      .required('Nome Obrigatório'),
    cpfcnpj: yup
      .string('Insira o CPF/CNPJ')
      .required('CPF/CNPJ Obrigatório'),
    cep: yup
      .string('Insira o CEP')
      .required('CEP Obrigatório'),
    rua: yup
      .string('Insira o nome da rua')
      .required('Rua Obrigatória'),
    numero: yup
      .string('Insira o número')
      .required('Número Obrigatório'),
    bairro: yup
      .string('Insira o Bairro')
      .required('Bairro Obrigatório'),
    cidade: yup
      .string('Insira a Cidade')
      .required('Cidade Obrigatória'),
    estado: yup
      .string('Insira o estado')
      .required('Estado Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      nome: '',
      cpfcnpj: '',
      ie: '',
      cep: '',
      rua: '',
      numero: '',
      bairro: '',
      cidade: '',
      codigocidade: '',
      estado: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let response = await api.post('addTransportador', {
        "nome": values.nome,
        "cpfcnpj": values.cpfcnpj,
        "ie": values.ie,
        "cep": values.cep,
        "rua": values.rua,
        "numero": values.numero,
        "bairro": values.bairro,
        "cidade": values.cidade,
        "codigocidade": values.codigocidade,
        "estado": values.estado,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      formik.resetForm();
      refreshTransportadores(response.data.id);
      change(false);
      
    }
  });

  const handleBuscaCep = async(props)=>{
    formik.handleChange(props);
    
    let cep = props.target.value;
    
    if(cep.length >= 9){

      setloaderPage(true);

      let response = await axios({
        method: 'get',
        url: `https://viacep.com.br/ws/${cep}/json/`,
        headers: { 
          "Content-type": "application/json"
        }
      });

      let data = response.data;
        
      if(data.cep){
        formik.setFieldValue('cep', data.cep);
        formik.setFieldValue('rua', data.logradouro);
        formik.setFieldValue('bairro', data.bairro);
        formik.setFieldValue('cidade', data.localidade);
        formik.setFieldValue('estado', data.uf);
        formik.setFieldValue('codigocidade', data.ibge);
        
        inputNumero.current.focus();
      }

      setloaderPage(false);
    }

  }


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='md' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo Transportador
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} > 
          
          <Grid item xs={12} md={4} >
            <TextField
              fullWidth
              id="nome"
              name="nome"
              label="Nome"
              margin="normal"
              type="text"
              value={formik.values.nome}
              onChange={formik.handleChange}
              error={formik.touched.nome && Boolean(formik.errors.nome)}
              helperText={formik.touched.nome && formik.errors.nome}
            />
          </Grid>

          <Grid item xs={12} md={4} >
            <TextField
              fullWidth
              id="cpfcnpj"
              name="cpfcnpj"
              label="CPF/CNPJ"
              margin="normal"
              type="text"
              value={formik.values.cpfcnpj}
              onChange={formik.handleChange}
              error={formik.touched.cpfcnpj && Boolean(formik.errors.cpfcnpj)}
              helperText={formik.touched.cpfcnpj && formik.errors.cpfcnpj}
            />
          </Grid>

          <Grid item xs={12} md={4} >
            <TextField
              fullWidth
              id="ie"
              name="ie"
              label="IE"
              margin="normal"
              type="text"
              value={formik.values.ie}
              onChange={formik.handleChange}
              error={formik.touched.ie && Boolean(formik.errors.ie)}
              helperText={formik.touched.ie && formik.errors.ie}
            />
          </Grid>

          <Grid item xs={12} md={2.5} >
            <TextField
              fullWidth
              id="cep"
              name="cep"
              label="CEP"
              margin="normal"
              type="text"
              value={formik.values.cep}
              onChange={handleBuscaCep}
              error={formik.touched.cep && Boolean(formik.errors.cep)}
              helperText={formik.touched.cep && formik.errors.cep}
              InputProps={{
                inputComponent: CEPMask,
              }}
            />
          </Grid>

          <Grid item xs={12} md={7.3} >
            <TextField
              fullWidth
              id="rua"
              name="rua"
              label="Rua"
              margin="normal"
              type="text"
              value={formik.values.rua}
              onChange={formik.handleChange}
              error={formik.touched.rua && Boolean(formik.errors.rua)}
              helperText={formik.touched.rua && formik.errors.rua}
            />
          </Grid>

          <Grid item xs={12} md={2.2} >
            <TextField
              inputRef={inputNumero}
              fullWidth
              id="numero"
              name="numero"
              label="Número"
              margin="normal"
              type="text"
              value={formik.values.numero}
              onChange={formik.handleChange}
              error={formik.touched.numero && Boolean(formik.errors.numero)}
              helperText={formik.touched.numero && formik.errors.numero}
            />
          </Grid>

          <Grid item xs={12} md={4} >
            <TextField
              fullWidth
              id="bairro"
              name="bairro"
              label="Bairro"
              margin="normal"
              type="text"
              value={formik.values.bairro}
              onChange={formik.handleChange}
              error={formik.touched.bairro && Boolean(formik.errors.bairro)}
              helperText={formik.touched.bairro && formik.errors.bairro}
            />
          </Grid>

          <Grid item xs={12} md={4} >
            <TextField
              fullWidth
              id="cidade"
              name="cidade"
              label="Cidade"
              margin="normal"
              type="text"
              value={formik.values.cidade}
              onChange={formik.handleChange}
              error={formik.touched.cidade && Boolean(formik.errors.cidade)}
              helperText={formik.touched.cidade && formik.errors.cidade}
            />
          </Grid>

          <Grid item xs={12} md={2} >
            <TextField
              fullWidth
              id="estado"
              name="estado"
              label="UF"
              margin="normal"
              type="text"
              value={formik.values.estado}
              onChange={formik.handleChange}
              error={formik.touched.estado && Boolean(formik.errors.estado)}
              helperText={formik.touched.estado && formik.errors.estado}
            />
          </Grid>

          <Grid item xs={12} md={2} >
            <TextField
              fullWidth
              id="codigocidade"
              name="codigocidade"
              label="Código Município"
              margin="normal"
              type="text"
              value={formik.values.codigocidade}
              onChange={formik.handleChange}
              error={formik.touched.codigocidade && Boolean(formik.errors.codigocidade)}
              helperText={formik.touched.codigocidade && formik.errors.codigocidade}
            />
          </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}