import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";
import moment from 'moment';
import '../style.css';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Footer from '../../../../componentes/Footer';
import Submenu from '../../../../componentes/SubMenu';
import ButtonCirclePrimary from '../../../../componentes/ButtonCirclePrimary';
import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonSquadPrimaryForInput from '../../../../componentes/ButtonSquadPrimaryForInput';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';
import ButtonCircleDanger from '../../../../componentes/ButtonCircleDanger';

import api from '../../../../services/api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Skeleton from '@mui/material/Skeleton';

import { mvalor, mPeso, mDimensoes, mnumero, mplaca } from '../../../../vendor/mascaras';
import { formataDinheiroforBanco, formataDinheiro } from '../../../../vendor/formatar';

import Swal from 'sweetalert2';

import Fancybox from '../../../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FsLightbox from 'fslightbox-react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ModalNovoSetorInterno from './modalNovoSetorInterno';
import ModalEditSetorInterno from './modalEditSetorInterno';

import ModalNovoTipoProduto from './modalNovoTipoProduto';
import ModalEditTipoProduto from './modalEditTipoProduto';

import ModalNovaComissao from './modalNovaComissao';
import ModalEditComissao from './modalEditComissao';

import SubMenuEstoque from '../../subMenu';

export default function ModalDetalhesProduto({ codigo, getProdutos, visible, change }) {
  
      
    let IconSave = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 24.15 24.15">
        <path id="floppy-disk" d="M22.97,3.037,21.113,1.179A4.02,4.02,0,0,0,18.267,0H2.767A2.767,2.767,0,0,0,0,2.767V21.383A2.767,2.767,0,0,0,2.767,24.15H21.383a2.767,2.767,0,0,0,2.767-2.767V5.882A4.02,4.02,0,0,0,22.97,3.037ZM4.025,6.289V3.773a.755.755,0,0,1,.755-.755H15.345a.755.755,0,0,1,.755.755V6.289a.755.755,0,0,1-.755.755H4.78A.755.755,0,0,1,4.025,6.289Zm8.05,13.836A4.528,4.528,0,1,1,16.6,15.6,4.529,4.529,0,0,1,12.075,20.125Zm0,0" transform="translate(0)" fill="#fff"/>
    </svg>
    )

    let IconZoom = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 12.515 12.516">
        <g id="icon" transform="translate(-5.267 -5.266)">
        <path id="XMLID_231_" d="M17.782,5.835V8.68a.569.569,0,1,1-1.137,0V7.209l-3.3,3.308a.569.569,0,0,1-.8-.8L15.841,6.4h-1.47a.569.569,0,0,1,0-1.138h2.843a.569.569,0,0,1,.569.569ZM17.214,13.8a.569.569,0,0,0-.569.569v1.47L13.4,12.616a.588.588,0,0,0-.82,0,.569.569,0,0,0-.008.8l3.217,3.223H14.311a.569.569,0,1,0,0,1.138h2.842a.642.642,0,0,0,.433-.167.6.6,0,0,0,.2-.4V14.368a.569.569,0,0,0-.569-.569Zm-7.56-1.207L6.4,15.838V14.368a.564.564,0,0,0-.564-.568h0a.568.568,0,0,0-.568.568v2.845a.569.569,0,0,0,.569.569H8.688a.569.569,0,0,0,0-1.138H7.218L10.464,13.4a.571.571,0,0,0-.81-.8ZM7.208,6.455h1.47a.569.569,0,0,0,0-1.138H5.836a.568.568,0,0,0-.569.569V8.731a.569.569,0,1,0,1.137,0V7.261l3.279,3.282a.569.569,0,1,0,.8-.8Z" fill="#fff"/>
        </g>
    </svg>
    )

    let IconCloseTimes = props =>(
    <svg id="close" xmlns="http://www.w3.org/2000/svg" width="13.663" height="13.506" viewBox="0 0 13.663 13.506">
        <g id="close_1_" transform="translate(0 0)">
        <path id="Caminho_85" data-name="Caminho 85" d="M1.3,15.943a1.294,1.294,0,0,1-.919-.311,1.294,1.294,0,0,1,0-1.825L11.365,2.82a1.294,1.294,0,0,1,1.89,1.76L2.2,15.633a1.294,1.294,0,0,1-.906.311Z" transform="translate(-0.001 -2.444)" fill="#fff"/>
        <path id="Caminho_86" data-name="Caminho 86" d="M13.733,15.343a1.294,1.294,0,0,1-.906-.375L1.839,3.98A1.294,1.294,0,0,1,3.664,2.155L14.717,13.143a1.294,1.294,0,0,1,.06,1.829q-.029.031-.06.06a1.294,1.294,0,0,1-.984.311Z" transform="translate(-1.463 -1.844)" fill="#fff"/>
        </g>
    </svg>
    )

    let IconUpload = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="30"viewBox="0 0 38.069 31.819">
        <g id="upload" transform="translate(-0.997 -4.993)">
        <path id="Caminho_87" data-name="Caminho 87" d="M29.948,12.692a10.167,10.167,0,0,0-19.722-.011,10.179,10.179,0,0,0,.954,20.314H15a1.272,1.272,0,1,0,0-2.545H11.18a7.634,7.634,0,0,1-.045-15.269,1.321,1.321,0,0,0,1.4-1.087,7.623,7.623,0,0,1,15.093,0,1.374,1.374,0,0,0,1.365,1.087,7.634,7.634,0,1,1,0,15.269H25.176a1.272,1.272,0,0,0,0,2.545h3.817a10.179,10.179,0,0,0,.954-20.3Z" transform="translate(0)" fill="#fff"/>
        <path id="Caminho_88" data-name="Caminho 88" d="M23.1,21.534a1.272,1.272,0,1,0,1.8-1.8l-6.362-6.362a1.272,1.272,0,0,0-1.8,0l-6.362,6.362a1.272,1.272,0,1,0,1.8,1.8l4.19-4.19V33.358a1.272,1.272,0,0,0,2.545,0V17.344Z" transform="translate(2.452 2.181)" fill="#fff"/>
        </g>
    </svg>

    )

    let IconEdit = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 19.987 19.992">
        <g id="Layer_92" data-name="Layer 92" transform="translate(-2.013 -2.008)">
        <path id="Caminho_344" data-name="Caminho 344" d="M21.1,2.9a3,3,0,0,0-4.27,0L14.91,4.85l4.24,4.24,1.92-1.92A3,3,0,0,0,21.1,2.9Z" fill="#fff"/>
        <path id="Caminho_345" data-name="Caminho 345" d="M13.49,6.27,5.1,14.66a1.15,1.15,0,0,0-.19.26L2.13,20.53A1,1,0,0,0,3.44,21.9l5.61-2.78a1.15,1.15,0,0,0,.26-.19l8.39-8.39Z" fill="#fff"/>
        <path id="Caminho_346" data-name="Caminho 346" d="M21,22H12a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Z" fill="#fff"/>
        </g>
    </svg>
    )

    let IconModeloMensagem = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.703 26.675">
        <g id="mensagem" transform="translate(0 0)">
        <path id="Caminho_375" data-name="Caminho 375" d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0" transform="translate(0 0)" fill="#4a4a4a"/>
        <path id="Caminho_376" data-name="Caminho 376" d="M135,150h12.986v1.623H135Zm0,0" transform="translate(-127.696 -141.884)" fill="#4a4a4a"/>
        <path id="Caminho_377" data-name="Caminho 377" d="M135,210h12.986v1.623H135Zm0,0" transform="translate(-127.696 -198.637)" fill="#4a4a4a"/>
        <path id="Caminho_378" data-name="Caminho 378" d="M135,270h9.739v1.623H135Zm0,0" transform="translate(-127.696 -255.391)" fill="#4a4a4a"/>
        </g>
    </svg>
    );

    let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
    );

    let IconInfo = props =>(
        <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
            <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
        </svg>
    );

    let IconComissao = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 20.093 19.5">
            <path id="comissoes" d="M6.75,13v7A1.752,1.752,0,0,1,5,21.75H4A1.752,1.752,0,0,1,2.25,20V13A1.752,1.752,0,0,1,4,11.25H5A1.752,1.752,0,0,1,6.75,13ZM17.5,9.25H15a.75.75,0,0,0,0,1.5h1.25V11a.75.75,0,0,0,1.5,0v-.275A2.244,2.244,0,0,0,17.5,6.25h-1a.75.75,0,0,1,0-1.5H19a.75.75,0,0,0,0-1.5H17.75V3a.75.75,0,0,0-1.5,0v.275A2.244,2.244,0,0,0,16.5,7.75h1a.75.75,0,0,1,0,1.5Zm4.64,6.34a1.961,1.961,0,0,0-2.37-.97l-1.7.57c-.12.04-.74.56-1.07.56H12a.75.75,0,0,1,0-1.5h3.68a1.767,1.767,0,0,0-1.1-1.19l-2.2-.76c-.04-.01-.08-.02-.13-.03l-.29-.05a.57.57,0,0,0-.13-.01H8.15a2.951,2.951,0,0,1,.1.79v7a3.081,3.081,0,0,1-.09.75h7.37a2.779,2.779,0,0,0,1.23-.29l4.5-2.25a1.962,1.962,0,0,0,.88-2.62Z" transform="translate(-2.25 -2.25)" fill="#4a4a4a"/>
        </svg>
    );

    let IconLixeira = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 21.241 28.294">
            <g id="delete" transform="translate(-63.818 0)">
            <path id="Caminho_186" data-name="Caminho 186" d="M85.059,9.084H71.973l5.86-5.86a.829.829,0,0,0,0-1.172L76.348.567a1.934,1.934,0,0,0-2.735,0L72.086,2.094,71.3,1.312a2.213,2.213,0,0,0-3.126,0L65.13,4.36a2.213,2.213,0,0,0,0,3.126l.782.782L64.385,9.795a1.934,1.934,0,0,0,0,2.735l1.485,1.485a.829.829,0,0,0,1.172,0l2.1-2.1V24.7a3.592,3.592,0,0,0,3.592,3.592h8.732A3.592,3.592,0,0,0,85.059,24.7ZM67.084,7.1,66.3,6.314a.553.553,0,0,1,0-.782L69.35,2.484a.553.553,0,0,1,.782,0l.782.782Zm7.158,16.557a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Z" transform="translate(0 0)" fill="#fff"/>
            <path id="Caminho_187" data-name="Caminho 187" d="M305.186,86.046a1.384,1.384,0,0,0-.919,1.152h7.954V85.82a1.382,1.382,0,0,0-1.825-1.309L305.2,86.04Z" transform="translate(-227.162 -79.771)" fill="#fff"/>
            </g>
        </svg>
    );

    let IconPlusC = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 26.723 26.721">
            <path id="adicionar" d="M24.84,11.635H18.547a.461.461,0,0,1-.461-.461V4.88A3.348,3.348,0,0,0,15.033,1.5a3.231,3.231,0,0,0-2.392.881,3.2,3.2,0,0,0-1.005,2.34v6.45a.461.461,0,0,1-.461.461H4.882A3.349,3.349,0,0,0,1.5,14.688a3.225,3.225,0,0,0,3.221,3.4h6.45a.461.461,0,0,1,.461.461v6.293a3.334,3.334,0,0,0,3.228,3.382A3.224,3.224,0,0,0,18.086,25v-6.45a.461.461,0,0,1,.461-.461H25a3.225,3.225,0,0,0,3.221-3.4A3.351,3.351,0,0,0,24.84,11.635Z" transform="translate(-1.5 -1.5)" fill="#fff"/>
        </svg>
    )

    let IconEntrada = props =>(
        <svg id="compras" xmlns="http://www.w3.org/2000/svg" width="36" viewBox="0 0 53.91 53.91">
            <path id="Caminho_375" data-name="Caminho 375" d="M53.91,43.381V26.534A2.106,2.106,0,0,0,51.8,24.428H45.487V9.687a2.106,2.106,0,0,0-2.106-2.106H26.534a2.106,2.106,0,0,0-2.106,2.106V24.428H18.11A2.106,2.106,0,0,0,16,26.534V41.275H13.9a2.108,2.108,0,0,1-2.106-2.106V6.318A6.325,6.325,0,0,0,5.475,0H2.106a2.106,2.106,0,0,0,0,4.212H5.475A2.108,2.108,0,0,1,7.581,6.318V39.169A6.325,6.325,0,0,0,13.9,45.487h2.468a6.318,6.318,0,1,0,11.911,0h13.36a6.317,6.317,0,1,0,11.656-.616A2.1,2.1,0,0,0,53.91,43.381ZM49.7,41.275H37.063V28.64h4.212v2.106a2.106,2.106,0,0,0,4.212,0V28.64H49.7ZM32.851,11.793V13.9a2.106,2.106,0,0,0,4.212,0V11.793h4.212V24.428H28.64V11.793Zm-8.423,35.8a2.106,2.106,0,1,1-2.106-2.106A2.108,2.108,0,0,1,24.428,47.592Zm-4.212-6.318V28.64h4.212v2.106a2.106,2.106,0,0,0,4.212,0V28.64h4.212V41.275ZM47.592,49.7A2.106,2.106,0,1,1,49.7,47.592,2.108,2.108,0,0,1,47.592,49.7Z" fill="#4a4a4a"/>
        </svg>
    )

    let history = useHistory();

    const [tabPage, settabPage] = useState("1");
    const [loaderPage, setloaderPage] = useState(false);

    const [dadosProduto, setdadosProduto] = useState([]);
    const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

    const [precoPromocionalVencimento, setprecoPromocionalVencimento] = useState(null);

    const [alertError, setalertError] = useState(false);
    const [alertErrorMsg, setalertErrorMsg] = useState('');

    const [alertSucess, setalertSucess] = useState(false);
    const [alertSucessMsg, setalertSucessMsg] = useState('');

    const [setores, setsetores] = useState([]);

    const [tiposproduto, settiposproduto] = useState([]);
    const [showModalNovoTipo, setshowModalNovoTipo] = useState(false);
    const [showModalEditTipo, setshowModalEditTipo] = useState(false);
    const [idEditTipo, setidEditTipo] = useState();

    const [especificacoesdoproduto, setespecificacoesdoproduto] = useState([]);
    const [especificacoesdoprodutovalores, setespecificacoesdoprodutovalores] = useState([]);

    const [fotosDoProduto, setfotosDoProduto] = useState([]);
    const [progressUploadFotos, setprogressUploadFotos] = useState(0);

    const [showModalNovoSetor, setshowModalNovoSetor] = useState(false);
    const [showModalEditSetor, setshowModalEditSetor] = useState(false);
    const [idEditSetor, setidEditSetor] = useState();

    const [comissoes, setcomissoes] = useState([]);
    const [comissoesSelecionadas, setcomissoesSelecionadas] = useState([]);
    const [pageSizeDataGridComissoes, setpageSizeDataGridComissoes] = useState(10);
    const [loadingDataGridComissoes, setloadingDataGridComissoes] = useState(true);

    const [entradas, setentradas] = useState([]);
    const [entradasSelecionadas, setentradasSelecionadas] = useState([]);
    const [pageSizeDataGridEntradas, setpageSizeDataGridEntradas] = useState(10);
    const [loadingDataGridEntradas, setloadingDataGridEntradas] = useState(true);

    const [showModalNovaComissao, setshowModalNovaComissao] = useState(false);
    const [showModalEditComissao, setshowModalEditComissao] = useState(false);
    const [idDetalhesComissao, setidDetalhesComissao] = useState();

    const handleChangePrecoPromocionalVencimento= (data) => {
    setprecoPromocionalVencimento(data);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setalertError(false);
    };

    const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setalertSucess(false);
    };


    const getsetores = async(idSetor=null, codigoSetor=null)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemSetoresInterno`, {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let arraySetores = [];
    
    for await (let setor of response.data) {
        let dados = {
            codigo: setor.codigo,
            nome: setor.nome,
            id: setor.id
        }    
        
        arraySetores.push(dados);
    }

    setsetores(arraySetores);


    if(codigoSetor){
        //populando setor no select
        let setorcode = arraySetores.find(x => x.codigo === codigoSetor);
        setorcode && formik.setFieldValue('setor', JSON.stringify(setorcode));
        setidEditSetor(setorcode.id);
    }else if(idSetor){
        //populando setor no select
        let setorid = arraySetores.find(x => x.id === idSetor);
        setorid && formik.setFieldValue('setor', JSON.stringify(setorid));
        setidEditSetor(idSetor);
    }

    }

    const getTiposProduto = async(termo='', status='', idTipo=null)=>{

        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        let response = await api.get(`listagemTiposProduto?termo=&status=1`, {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });
    
        let arrayTipos = [];
        
        for await (let tip of response.data) {
            let dados = {
              id: tip.id,
              tipo: tip.tipo,
              codigo: tip.code,
            }    
            
            arrayTipos.push(dados);
        }
    
        settiposproduto(arrayTipos);
  
        if(idTipo){
          //populando tipo no select
          let tipoid = arrayTipos.find(x => x.id === idTipo);
          tipoid && formik.setFieldValue('tipo', JSON.stringify(tipoid));
          setidEditTipo(idTipo);
        }
  
        // dadosProduto?.tipo_id && await handleEspecificacoesproduto(dadosProduto?.tipo_id);
    
    }


    const getFotosdoProduto = async(produtoid)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let fotos = await api.post(`listagemFotosProduto`,
    {
        "id": produtoid
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });
    

    let fotosGaleria = [];

    for await (let foto of fotos.data) {
        fotosGaleria.push(foto.url)
    }
    
    setfotosDoProduto(fotos.data);

    }

    const detalhesProduto = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        // codigo === 'undefined' && history.push("/cadastros/veiculos");
    
        api.post('detalhesProduto', {
            "codigo": codigo
        }, 
        {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
        }
        }).then( async response => {
            
            let data = response.data;
            setdadosProduto(response.data);
        
            formik.setFieldValue('status', data.status);
            data.titulo && formik.setFieldValue('titulo', data.titulo);
            data.precoVenda && formik.setFieldValue('precovenda', formataDinheiro(data.precoVenda));
            data.precoPromocional && formik.setFieldValue('precopromocional', formataDinheiro(data.precoPromocional));
            data.precoPromocionalVencimento && setprecoPromocionalVencimento(data.precoPromocionalVencimento);
            data.ean && formik.setFieldValue('ean', data.ean);
            data.codigoInterno && formik.setFieldValue('codigointerno', data.codigoInterno);
            data.comprimento && formik.setFieldValue('comprimento', data.comprimento);
            data.largura && formik.setFieldValue('largura', data.largura);
            data.altura && formik.setFieldValue('altura', data.altura);
            data.peso && formik.setFieldValue('peso', data.peso);

            data.ncm && formik.setFieldValue('ncm', data.ncm);
            data.cfop && formik.setFieldValue('cfop', data.cfop);
            data.cst && formik.setFieldValue('cst', data.cst);
            data.cest && formik.setFieldValue('cest', data.cest);
            
            data.icms_csosn && formik.setFieldValue('icmscsosn', data.icms_csosn);
            data.icms_cst && formik.setFieldValue('icmscst', data.icms_cst);
            data.icms_orig && formik.setFieldValue('icmsorig', data.icms_orig);

            data.descricao && formik.setFieldValue('descricao', data.descricao);

            await getsetores(data.setor_id);
            await getTiposProduto('', '', data.tipo_id);
            await handleEspecificacoesproduto(data.tipo_id);
            await populandoEspecificacoesProduto(data.id);
            await getFotosdoProduto(data.id);
            await getEntradas(data.id); 

            
        }).catch((error) => {
            // history.push("/estoque/produtos");
        });
        
        
    }

    const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    setpermissoesdoUsuario(permissoes.data.produtos);
    }

    const validationSchema = yup.object({

    status: yup
        .string('Selecione o status do produto')
        .test({
        message: () => "Selecione o status do produto",
        test: async (values) => {
            if(!values){
                return false
            }else{
                return true
            } 
        },
        }),
    titulo: yup
        .string('Informe o título do produto')
        .test({
            message: () => "Título Obrigatório",
            test: async (values) => {
            if(!values){
                return false
            }else{
                return true
            } 
            },
        }),

    setor: yup
        .string('Selecione o setor do produto')
        .test({
        message: () => "Selecione o setor do produto",
        test: async (values) => {
            if(!values){
                return false
            }else{
                return true
            } 
        },
        }),

    precovenda: yup
        .string('Informe o preço de venda do veículo')
        .test({
        message: () => "Preço de venda Obrigatório",
        test: async (values) => {
            if(!values){
            return false
            }else{
            return true
            } 
        },
        }),

    });

    const formik = useFormik({
    initialValues: {
        status: '',
        titulo: '',
        tipo: 'null',
        especificacoesdoproduto: [],
        setor: 'null',
        precovenda: '',
        precopromocional: '',
        ean: '',
        codigointerno: '',
        comprimento: '',
        largura: '',
        altura: '',
        peso: '',
        ncm: '',
        cfop: '',
        cst: '',
        cest: '',
        icmscsosn: '',
        icmscst: '',
        icmsorig: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
        
    
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        // let promocionalVencimento = moment(moment(precoPromocionalVencimento, 'DD-MM-YYYY')).format('DD/MM/YYYY');

        let precodeVenda = formataDinheiroforBanco(values.precovenda);
        let precopromocional = values.precopromocional;
        let setor = JSON.parse(values.setor);
        let tipo = JSON.parse(values.tipo);

        await api.put(`updateProduto`, {
            "id": dadosProduto.id,
            "status": values.status,
            "titulo":values.titulo,
            "tipo": tipo?.id ? tipo.id : null,
            "especificacoes": especificacoesdoprodutovalores,
            "especificacoesdoproduto": especificacoesdoproduto,
            "setor": setor.id,
            "precoVenda": precodeVenda,
            "precoPromocional": precopromocional ? formataDinheiroforBanco(precopromocional) : null,
            "precoPromocionalVencimento": precoPromocionalVencimento ? precoPromocionalVencimento : null,
            "ean": values.ean ? values.ean : null,
            "codigoInterno": values.codigointerno ? values.codigointerno : null,
            "comprimento": values.comprimento ? values.comprimento : 0,
            "largura": values.largura ? values.largura : 0,
            "altura": values.altura ? values.altura : 0,
            "peso": values.peso ? values.peso : 0,
            "ncm": values.ncm ? values.ncm : null,
            "cfop": values.cfop ? values.cfop : null,
            "cst": values.cst ? values.cst : null,
            "cest": values.cest ? values.cest : null,
            "icmscsosn": values.icmscsosn ? values.icmscsosn : null,
            "icmscst": values.icmscst ? values.icmscst : null,
            "icmsorig": values.icmsorig ? values.icmsorig : null,
            "descricao": values.descricao ? values.descricao : null,
        },
        {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
        }
        });

        setalertSucess(true); 
        getProdutos();       
        setalertSucessMsg(`Dados Atualizados com sucesso!`);    
        setTimeout(() => { setalertSucess(false) }, 6000);   

    }
    });

    
    useEffect(async ()=>{ 
        if(visible){
            formik.resetForm();
            await detalhesProduto();
            await permissoesUsuario();
            await getComissoes(codigo);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[visible]);

    
    const Input = styled('input')({
        display: 'none',
    });

    let handleFotoProduto = async(e)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let imagens = e.target.files;

    let formData = new FormData();
    formData.append('id', dadosProduto.id);


    if(imagens.length > 7){
        Swal.fire(
        'Ops, Limite por vez atingido!',
        'Você só pode enviar 7 imagens por vez, e inserir até 20 fotos no veículo.',
        'warning'
        )
    }else{

        for await (let imagem of imagens) {

        if(imagem.type === 'image/png' || imagem.type === 'image/gif' || imagem.type === 'image/jpeg'){
            formData.append('imagens', imagem);
        }else{
            setalertErrorMsg('Foto inválida, Apenas os formatos .png, .jpg e .jpeg são permitidos!')
            setalertError(true);
            setTimeout(() => { setalertError(false) }, 6000);   
        }
        
        }

        let response = await api.put("uploadFotosProduto", formData, {
        headers: { 
            "Content-type": "multipart/form-data",
            "Authorization": `Bearer ${auth.token}`
        },
        onUploadProgress: e => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total));
            setprogressUploadFotos(progress)
        },
        });

        if(response.data.error_code === "MAXIMUM_FILES"){
        setalertErrorMsg(response.data.message)
        setalertError(true);
        setTimeout(() => { setalertError(false) }, 6000);
        }

        // await new Promise(r => setTimeout(r, imagens.length * 150));
        setprogressUploadFotos(0);
        getFotosdoProduto(dadosProduto.id);
    }
    

    }

    const handleDeleteFoto = async(idFoto)=>{
    
    Swal.fire({
        title: 'Tem certeza?',
        text: "Deseja deletar a Foto selecionada?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0554C2',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim deletar',
        cancelButtonText: 'Cancelar',
    }).then(async(result) => {
        if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
        
        await api.delete('deleteFotoProduto', {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            },
            data: {  "id": idFoto, "produtoId": dadosProduto.id }
        });

        await getFotosdoProduto(dadosProduto.id);

        Swal.fire(
            'Deletado!',
            'Foto deletada com sucesso!',
            'success'
        )
        }
    })

    }

    const handleDragFoto = async(result)=>{
    

    if(permissoesdoUsuario[3]?.update){

        const items = Array.from(fotosDoProduto);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let dadosArray = [];

        for await (let [key, item] of  Object.entries(items)) {
        let dados = {
            id: item.id,
            ordem: parseInt(key)
        }

        dadosArray.push(dados);

        }

        setfotosDoProduto(items);

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        await api.put(`updateOrdemFotosProduto`,
        {
            "produtoId": dadosProduto.id,
            "dados": dadosArray
        },
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        })

    }else{
        setalertErrorMsg('Você não possui autorização para modificar a ordem das fotos.')
        setalertError(true);
        setTimeout(() => { setalertError(false) }, 6000);
    }

    }

    const handleChangeSetor = async(event, value, reason, details)=>{
        formik.setFieldValue('setor', JSON.stringify(value));
        setidEditSetor(value?.id);
    } 
    
    const handleChangeTabPage = (event, newValue) => {
        settabPage(newValue); 
    };

    
    const populandoEspecificacoesProduto = async(produtoId)=>{
      
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`especificacoesDoProduto`, {
            "produtoId": produtoId,
        },
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        });

        for await (let esp of response.data) {
            await formik.setFieldValue(`especificacoesdoproduto[${esp.especificacao_id}].valor`, esp.nome);
        }

    }

    const handleEspecificacoesproduto = async(tipoId)=>{
        
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`listagemEspecificacoesTipoProduto`, {
            "tipoId": tipoId,
            "principal": 1,
        },
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        });

        response = response.data;
        setespecificacoesdoproduto(response);
    }
    
    const handleChangeTipo = async(event, value, reason, details)=>{

        formik.setFieldValue('tipo', JSON.stringify(value));
        setidEditTipo(value?.id);

        formik.setFieldValue('especificacoesdoproduto', []);
        setespecificacoesdoproduto([])

        value?.id && handleEspecificacoesproduto(value?.id)

    }

    function primeiraLetraMaiuscula(string) {
        if (!string) {
            return '';
        }
        
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    useEffect(() => {
        let tipo = JSON.parse(formik.values.tipo);
        
        // Obtenha os valores válidos
        let valoresValidos = especificacoesdoproduto
            .map((v) => formik.values.especificacoesdoproduto[v.id]?.valor)
            .filter((valor) => typeof valor === 'string' && valor !== 'false' && valor !== 'undefined');
        
        // Obtenha os valores válidos juntamente com seus IDs e ordem
        let valoresComIds = especificacoesdoproduto
            .map((v) => ({
            id: v.id,
            valor: formik.values.especificacoesdoproduto[v.id]?.valor,
            ordem: v.ordem
            }))
            .filter((item) => typeof item.valor === 'string' && item.valor !== 'false' && item.valor !== 'undefined');
            

        let tituloConcatenado = `${primeiraLetraMaiuscula(tipo?.tipo)} ${valoresValidos.join(' ')}`;
        
        let titulo = formik.values.tipo !== 'null' ? tituloConcatenado : '';
        titulo && formik.setFieldValue('titulo', titulo);

        setespecificacoesdoprodutovalores(valoresComIds); // Saída com valores, IDs e suas respectivas ordens
    }, [formik.values.especificacoesdoproduto]);


    const getComissoes = async(codigoProduto)=>{

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        //COMISSOES
        setloadingDataGridComissoes(true);
        let response = await api.post('listagemComissoesProduto', {
          "codigo": codigoProduto
        }, 
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        })
    
        setcomissoes(response.data);
        setloadingDataGridComissoes(false);
    }


    const columnsDataGridComissoes = [
        { field: 'id', hide: true, headerName: 'ID', width: 90  },
        {
          field: 'usuario',
          headerName: 'Usuário',
          flex: 1,
          minWidth: 150,
          editable: false,
          valueFormatter: (params) => {
            return params.value
          },
        },
        {
            field: 'tipo',
            headerName: 'Tipo de calculo',
            flex: 1,
            minWidth: 150,
            editable: false,
            renderCell: (params) => { //renderiza componente
            
                switch (params.value) {
                    case 1:
                        return <span>Por valor</span>
                        break;
                    case 2:
                        return <span>Por %</span>
                        break;
                }
              },
        },
        {
            field: 'porcentagem',
            headerName: 'Porcentagem',
            flex: 1,
            minWidth: 150,
            editable: false,
            valueFormatter: (params) => {
              return params.value+'%'
            },
        },
        {
            field: 'valor',
            headerName: 'Valor',
            flex: 1,
            minWidth: 150,
            editable: false,
            valueFormatter: (params) => {
              return formataDinheiro(params.value);
            },
        },
    ];
      
    
    function nenhumaComissaoEncontrada(){
        return(
            <GridOverlay>
            <div className="container-no-data-grid">
                <IconComissao/>
                <p>Nenhuma Comissão Encontrada</p>
            </div>
            </GridOverlay>
        )
    }

    const getEntradas = async(idProduto)=>{

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
    
        //ENTRADAS
        setloadingDataGridEntradas(true);
        let response = await api.post('entradasProdutoEstoque', {
          "idProduto": idProduto
        }, 
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        })
    
        setentradas(response.data);
        setloadingDataGridEntradas(false);
    }

    const columnsDataGridEntradas = [
        { field: 'id', hide: true, headerName: 'ID', width: 90  },
        {
            field: 'dataCompra',
            headerName: 'Data',
            flex: 1,
            minWidth: 80,
            editable: false,
            valueFormatter: (params) => {
              return moment(params.value).format('DD/MM/YYYY')
            },
          },
        {
          field: 'fornecedor',
          headerName: 'Fornecedor',
          flex: 1,
          minWidth: 170,
          editable: false,
          valueFormatter: (params) => {
            return params.value
          },
        },
        {
            field: 'quantidadeEntrada',
            headerName: 'Quantidade',
            flex: 1,
            minWidth: 50,
            editable: false,
            valueFormatter: (params) => {
              return params.value;
            },
        },
        {
            field: 'estoqueAtual',
            headerName: 'Estoque Atual',
            flex: 1,
            editable: false,
            renderCell: (params) => { //renderiza componente
              
              if(Number(params.value) > 0){
                return <p style={{ color: 'green', fontWeight: 'bold' }}>{params.value}</p>; 
              }else if(Number(params.value) === 0){
                return <p style={{ color: '#ff8a00', fontWeight: 'bold'  }}>{params.value}</p>; 
              }else{
                return <p style={{ color: 'red', fontWeight: 'bold'  }}>{params.value}</p>; 
              }
          
            },
        },
        {
            field: 'precoCompra',
            headerName: 'Preço de compra',
            flex: 1,
            minWidth: 150,
            editable: false,
            valueFormatter: (params) => {
              return formataDinheiro(params.value);
            },
        },
    ];
      
    
    function nenhumaEntradaEncontrada(){
        return(
            <GridOverlay>
            <div className="container-no-data-grid">
                <IconEntrada/>
                <p>Nenhuma Entrada Encontrada</p>
            </div>
            </GridOverlay>
        )
    }

    const handleDeleteComissao = async()=>{
  
        Swal.fire({
          title: 'Tem certeza?',
          text: "Deseja deletar as comissões selecionadas?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#02A680',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, deletar',
          cancelButtonText: 'Cancelar',
        }).then(async(result) => {
          if (result.isConfirmed) {
    
            let auth = localStorage.getItem("auth");
            auth = JSON.parse(auth);
    
            let comissoesid = [];
    
            for await (let id of comissoesSelecionadas) {
              let dados = { id: id }
              comissoesid.push(dados);
            }
            
            await api.delete('deleteComissaoProduto', {
              headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
              },
              data: {  "codigoProduto": codigo, "comissoesid": comissoesid }
            });
    
            await getComissoes(codigo);
    
            Swal.fire(
              'Deletado!',
              'Commissões deletada(s) com sucesso!',
              'success'
            )
          }
        })
    }

    const detalhesComissao = async(id)=>{
        setidDetalhesComissao(id);
        setshowModalEditComissao(true);
    }
    

  return(
    <>

    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
            {alertSucessMsg}
        </Alert>
    </Snackbar>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
            {alertErrorMsg}
        </Alert>
    </Snackbar>

    <ModalNovoSetorInterno visible={showModalNovoSetor} change={setshowModalNovoSetor} getSetores={getsetores}/> 
    <ModalEditSetorInterno id={idEditSetor} visible={showModalEditSetor} change={setshowModalEditSetor} getSetores={getsetores}/> 

    <ModalNovoTipoProduto visible={showModalNovoTipo} change={setshowModalNovoTipo} getTipos={getTiposProduto} setidEditTipo={setidEditTipo} setshowModalEditTipo={setshowModalEditTipo} /> 
    <ModalEditTipoProduto id={idEditTipo} visible={showModalEditTipo} change={setshowModalEditTipo} getTipos={getTiposProduto}/> 

    <ModalNovaComissao visible={showModalNovaComissao} change={setshowModalNovaComissao} getComissoes={getComissoes} codigoProduto={codigo}/>
    <ModalEditComissao id={idDetalhesComissao} visible={showModalEditComissao} change={setshowModalEditComissao} getComissoes={getComissoes} codigoProduto={codigo}/>

    <Dialog open={visible} maxWidth='lg' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Detalhes Produto
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} >
            <TabContext value={tabPage}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}> 
                <Tabs value={tabPage} onChange={handleChangeTabPage} aria-label="estoque-tabs" textColor="primary" indicatorColor="primary" variant="scrollable">
                    <Tab label="Detalhes" value="1" />
                    <Tab label="Peso e dimensões" value="2" />
                    <Tab label="Dados Fiscais" value="3" />
                    <Tab label="Fotos" value="4" />
                    <Tab label="Comissões" value="5" />
                    <Tab label="Entradas" value="6" />
                    
                </Tabs>
                </Box>

                <TabPanel value="1" style={{width: '100%'}}>
                    <Grid container item spacing={2}>

                    {
                        dadosProduto.length === 0 ?

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={132} style={{ borderRadius: '5px' }}/>
                            </Grid>
                        </Grid>
                        
                        :
                        <>
                        
                        <Grid item xs={12} md={1.5} >
                            <FormControl sx={{ m: 0, width: '100%' }} >
                            <InputLabel id="label-status">Status</InputLabel>
                            <Select
                                labelId="label-status"
                                id="status-select"
                                name="status"
                                label="Status"
                                placeholder='Status'
                                value={formik.values.status}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                            >
                                <MenuItem value={0}>Inativo</MenuItem>
                                <MenuItem value={1}>Ativo</MenuItem>
                            </Select>
                            <FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <TextField
                              className='input'
                              fullWidth
                              id="codigointerno"
                              name="codigointerno"
                              label="Código Interno"
                              type="text"
                              value={formik.values.codigointerno}
                              onChange={formik.handleChange}
                              error={formik.touched.codigointerno && Boolean(formik.errors.codigointerno)}
                              helperText={formik.touched.codigointerno && formik.errors.codigointerno}
                              inputProps={{
                                  maxLength: 25,
                              }}
                          />
                        </Grid>

                        <Grid container item xs={12} md={3.5}>
                            <div style={{ width: '100%', display: 'flex' }}>
                            <Grid item style={{width: '100%'}}>
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <Autocomplete
                                        className='input'
                                        // disablePortal
                                        id="combo-box-tipo"
                                        options={tiposproduto}
                                        value={JSON.parse(formik.values.tipo)}
                                        getOptionLabel={(option) => option.tipo}
                                        renderOption={(props, option) => (
                                        <li {...props} key={option.id}>{option.tipo}</li>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={handleChangeTipo}
                                        noOptionsText="Nenhum tipo encontrado."
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Tipo de produto"  error={formik.touched.tipo && Boolean(formik.errors.tipo)}/>}
                                    />
                                    <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid>
                                <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovoTipo(true) }}/>
                            </Grid>
                            
                            {
                                formik.values.tipo !== 'null' &&
                                <Grid>
                                <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalEditTipo(true) }}/>
                                </Grid>
                            }
                            </div>
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <TextField
                                className='input'
                                fullWidth
                                id="titulo"
                                name="titulo"
                                label="Título"
                                type="text"
                                value={formik.values.titulo}
                                onChange={formik.handleChange}
                                error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                                helperText={formik.touched.titulo && formik.errors.titulo}
                                inputProps={{
                                    maxLength: 245,
                                }}
                            />
                        </Grid>
                        
                        <Grid container item xs={12} md={3}>
                            <div style={{ width: '100%', display: 'flex' }}>
                            <Grid item style={{width: '100%'}}>
                                <FormControl sx={{ m: 0, width: '100%' }} >
                                    <Autocomplete
                                        className='input'
                                        // disablePortal
                                        id="combo-box-setor"
                                        options={setores}
                                        value={JSON.parse(formik.values.setor)}
                                        getOptionLabel={(option) => option.nome}
                                        renderOption={(props, option) => (
                                        <li {...props} key={option.id}>{option.nome}</li>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={handleChangeSetor}
                                        noOptionsText="Nenhum setor encontrado."
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Setor"  error={formik.touched.setor && Boolean(formik.errors.setor)}/>}
                                    />
                                    <FormHelperText error>{formik.touched.setor && formik.errors.setor}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid>
                                <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovoSetor(true) }}/>
                            </Grid>
                            
                            {
                                formik.values.setor !== 'null' &&
                                <Grid>
                                <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalEditSetor(true) }}/>
                                </Grid>
                            }
                            </div>
                        </Grid>
                                                
                        <Grid item xs={12} md={2} >
                            <TextField
                            fullWidth
                            id="precovenda"
                            name="precovenda"
                            label="Preço de venda"
                            margin="none"
                            type="text"
                            value={formik.values.precovenda}
                            disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.precovenda && Boolean(formik.errors.precovenda)}
                            helperText={formik.touched.precovenda && formik.errors.precovenda}
                            onKeyUp={()=>{ formik.setFieldValue('precovenda', mvalor(formik.values.precovenda)) }}
                            inputProps={{
                                maxLength: 16,
                            }}
                            />
                        </Grid>

                        <Grid item xs={12} md={2} >
                            <TextField
                            fullWidth
                            id="precopromocional"
                            name="precopromocional"
                            label="Preço promocional"
                            margin="none"
                            type="text"
                            value={formik.values.precopromocional}
                            disabled={!permissoesdoUsuario[3]?.update}
                            onChange={formik.handleChange}
                            error={formik.touched.precopromocional && Boolean(formik.errors.precopromocional)}
                            helperText={formik.touched.precopromocional && formik.errors.precopromocional}
                            onKeyUp={()=>{ formik.setFieldValue('precopromocional', mvalor(formik.values.precopromocional)) }}
                            inputProps={{
                                maxLength: 13,
                            }}
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                            <DesktopDatePicker
                                label="Vencimento da promoção"
                                inputFormat="dd/MM/yyyy"
                                name="precopromocionalvencimento"
                                value={precoPromocionalVencimento}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={handleChangePrecoPromocionalVencimento}
                                allowSameDateSelection={true}
                                renderInput={(params) => <TextField autoComplete='off' style={{ width: '100%', marginTop: '0px'}} {...params} />}
                            />
                            </LocalizationProvider>
                        </Grid>
                        
                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="ean"
                                name="ean"
                                label="Código EAN"
                                type="text"
                                value={formik.values.ean}
                                onChange={formik.handleChange}
                                error={formik.touched.ean && Boolean(formik.errors.ean)}
                                helperText={formik.touched.ean && formik.errors.ean}
                                inputProps={{
                                    maxLength: 245,
                                }}
                            />
                        </Grid>


                        {especificacoesdoproduto.map((v) => (
                          <Grid key={v.id} item xs={12} md={3}>
                            <TextField
                              className="input"
                              fullWidth
                              name={`especificacoesdoproduto[${v.id}].valor`}
                              label={`${primeiraLetraMaiuscula(v.nome)}`}
                              type="text"
                              value={formik.values.especificacoesdoproduto[v.id]?.valor || ''}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur} // Importante adicionar onBlur para marcar o campo como "tocado"
                              error={
                                formik.touched.especificacoesdoproduto &&
                                Boolean(formik.errors.especificacoesdoproduto?.[v.id]?.valor)
                              }
                              helperText={
                                formik.touched.especificacoesdoproduto &&
                                formik.errors.especificacoesdoproduto?.[v.id]?.valor
                              }
                              onKeyUp={()=>{ v.nome === "PLACA" && formik.setFieldValue(`especificacoesdoproduto[${v.id}].valor`, mplaca(formik.values.especificacoesdoproduto[v.id]?.valor)) }}
                              inputProps={{
                                maxLength: v.nome === "PLACA" ? 8 : 140,
                              }}
                            />
                          </Grid>
                        ))}

                        
                        <Grid item xs={12} md={12} >
                            <TextField
                                fullWidth
                                id="descricao"
                                name="descricao"
                                label="Descrição"
                                margin="none"
                                multiline
                                disabled={!permissoesdoUsuario[3]?.update}
                                rows={4.5}
                                defaultValue={formik.values.descricao}
                                onChange={formik.handleChange}
                                error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                                helperText={formik.touched.descricao && formik.errors.descricao}
                            />
                        </Grid>
                       

                        </>
                    }
                    
                    </Grid>
                </TabPanel>
                
                <TabPanel value="2" style={{width: '100%'}}>
                    <Grid container item spacing={2}>

                    {
                        dadosProduto.length === 0 ?

                        <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                        <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        </Grid>
                        
                        :
                        <>
   
                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="peso"
                                name="peso"
                                label="Peso (kg)"
                                type="text"
                                value={formik.values.peso}
                                onChange={formik.handleChange}
                                error={formik.touched.peso && Boolean(formik.errors.peso)}
                                helperText={formik.touched.peso && formik.errors.peso}
                                onKeyUp={()=>{ formik.setFieldValue('peso', mPeso(formik.values.peso)) }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="comprimento"
                                name="comprimento"
                                label="Comprimento (cm)"
                                type="text"
                                value={formik.values.comprimento}
                                onChange={formik.handleChange}
                                error={formik.touched.comprimento && Boolean(formik.errors.comprimento)}
                                helperText={formik.touched.comprimento && formik.errors.comprimento}
                                onKeyUp={()=>{ formik.setFieldValue('comprimento', mDimensoes(formik.values.comprimento)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="largura"
                                name="largura"
                                label="Largura (cm)"
                                type="text"
                                value={formik.values.largura}
                                onChange={formik.handleChange}
                                error={formik.touched.largura && Boolean(formik.errors.largura)}
                                helperText={formik.touched.largura && formik.errors.largura}
                                onKeyUp={()=>{ formik.setFieldValue('largura', mDimensoes(formik.values.largura)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="altura"
                                name="altura"
                                label="Altura (cm)"
                                type="text"
                                value={formik.values.altura}
                                onChange={formik.handleChange}
                                error={formik.touched.altura && Boolean(formik.errors.altura)}
                                helperText={formik.touched.altura && formik.errors.altura}
                                onKeyUp={()=>{ formik.setFieldValue('altura', mDimensoes(formik.values.altura)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>
                        </>

                    }

                    </Grid>
                </TabPanel>

                <TabPanel value="3" style={{width: '100%'}}>
                    <Grid container item spacing={2}>

                    {
                        dadosProduto.length === 0 ?
                        
                        <Grid container spacing={2} item xs={12} md={12} style={{ marginTop: '10px' }}>
                        <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                        </Grid>
                        </Grid>

                        :

                        <>
                           
                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="ncm"
                                name="ncm"
                                label="NCM"
                                type="text"
                                value={formik.values.ncm}
                                onChange={formik.handleChange}
                                error={formik.touched.ncm && Boolean(formik.errors.ncm)}
                                helperText={formik.touched.ncm && formik.errors.ncm}
                                onKeyUp={()=>{ formik.setFieldValue('ncm', mnumero(formik.values.ncm)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="cfop"
                                name="cfop"
                                label="CFOP"
                                type="text"
                                value={formik.values.cfop}
                                onChange={formik.handleChange}
                                error={formik.touched.cfop && Boolean(formik.errors.cfop)}
                                helperText={formik.touched.cfop && formik.errors.cfop}
                                onKeyUp={()=>{ formik.setFieldValue('cfop', mnumero(formik.values.cfop)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="cst"
                                name="cst"
                                label="CST"
                                type="text"
                                value={formik.values.cst}
                                onChange={formik.handleChange}
                                error={formik.touched.cst && Boolean(formik.errors.cst)}
                                helperText={formik.touched.cst && formik.errors.cst}
                                onKeyUp={()=>{ formik.setFieldValue('cst', mnumero(formik.values.cst)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                className='input'
                                fullWidth
                                id="cest"
                                name="cest"
                                label="CEST"
                                type="text"
                                value={formik.values.cest}
                                onChange={formik.handleChange}
                                error={formik.touched.cest && Boolean(formik.errors.cest)}
                                helperText={formik.touched.cest && formik.errors.cest}
                                onKeyUp={()=>{ formik.setFieldValue('cest', mnumero(formik.values.cest)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                className='input'
                                fullWidth
                                id="icmscsosn"
                                name="icmscsosn"
                                label="CSOSN (ICMS)"
                                type="text"
                                value={formik.values.icmscsosn}
                                onChange={formik.handleChange}
                                error={formik.touched.icmscsosn && Boolean(formik.errors.icmscsosn)}
                                helperText={formik.touched.icmscsosn && formik.errors.icmscsosn}
                                onKeyUp={()=>{ formik.setFieldValue('icmscsosn', mnumero(formik.values.icmscsosn)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                className='input'
                                fullWidth
                                id="icmscst"
                                name="icmscst"
                                label="CST (ICMS)"
                                type="text"
                                value={formik.values.icmscst}
                                onChange={formik.handleChange}
                                error={formik.touched.icmscst && Boolean(formik.errors.icmscst)}
                                helperText={formik.touched.icmscst && formik.errors.icmscst}
                                onKeyUp={()=>{ formik.setFieldValue('icmscst', mnumero(formik.values.icmscst)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                className='input'
                                fullWidth
                                id="icmsorig"
                                name="icmsorig"
                                label="ORIG (ICMS)"
                                type="text"
                                value={formik.values.icmsorig}
                                onChange={formik.handleChange}
                                error={formik.touched.icmsorig && Boolean(formik.errors.icmsorig)}
                                helperText={formik.touched.icmsorig && formik.errors.icmsorig}
                                onKeyUp={()=>{ formik.setFieldValue('icmsorig', mnumero(formik.values.icmsorig)) }}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>
                        </>
                    }
    
                    </Grid>
                </TabPanel>

                <TabPanel value="4" style={{width: '100%'}}>
                    <Grid container item spacing={2}>
                    <div className='box-content' style={{ width: '100%', marginLeft: '22px'}}>

                        {
                            dadosProduto.length === 0 ?

                            <Grid container spacing={2} item xs={12} md={12} style={{ marginTop: '10px' }}>
                            <Grid item xs={12} md={1.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            </Grid>
                            
                            :

                            <>
                           
                            <div className='box-fotos-detalhes-produtos'>

                            <Fancybox>
                            <DragDropContext onDragEnd={handleDragFoto}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                {(provided) => (
                                    <aside {...provided.droppableProps} ref={provided.innerRef} id={codigo}>
                                    {fotosDoProduto.map((v, k) =>{
                                        return (
                                        <Draggable key={v.id} draggableId={v.id.toString()} index={k}>
                                            {(provided) => (
                                            <div className='box-element-foto' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div className='element-foto' style={{ backgroundImage: `url(${v.url})` }}>
                                                
                                                {
                                                    permissoesdoUsuario[3]?.update &&

                                                    <div className='box-deleteFotoDProduto'>
                                                    <button type='button' onClick={()=>{ handleDeleteFoto(v.id) }} className='deleteFotoDProduto'><IconCloseTimes/></button>
                                                    </div>
                                                }

                                                <div className='box-abrirFotoDProduto'>
                                                    <button type='button' data-fancybox="gallery" href={v.url}>
                                                        <IconZoom/>
                                                    </button>
                                                </div>

                                                </div>
                                            </div>
                                            )}
                                        </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                    </aside>
                                )}
                                </Droppable>
                            </DragDropContext>
                            </Fancybox>
                            
                            {
                                permissoesdoUsuario[2]?.insert &&
                                
                                <div className='box-button-upload'>
                                    <div style={{ width: '220px' }}>

                                    {
                                        progressUploadFotos === 0 &&

                                        <label htmlFor="button-file-fotos-produto">
                                        <Input accept="image/png, image/gif, image/jpeg" id="button-file-fotos-produto" multiple onChange={handleFotoProduto} type="file" />
                                        <ButtonDefault type="button" icon={<IconUpload/>} component="span" txt="Adicionar fotos"  />
                                        </label>
                                    }
                                    
                                    {
                                        progressUploadFotos > 0 && 
                                        <LinearProgress variant="determinate" value={progressUploadFotos} />
                                    }

                                    </div>
                                    <p>Formatos aceitos: .JPG, .JPEG, .PNG | Tamanho máximo: 5MB</p>
                                </div>
                            }

                            </div>
                            </>
                        }

                    </div>
    
                    </Grid>
                </TabPanel>

                <TabPanel value="5" style={{width: '100%'}}>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>

                            {
                            permissoesdoUsuario[1]?.delete &&
                            
                            <>
                            {
                                comissoesSelecionadas.length > 0 &&

                                <div style={{ marginRight: '10px' }}>
                                <ButtonCircleDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteComissao() }}/>
                                </div>
                            }
                            </>

                            }

                            {
                            permissoesdoUsuario[2]?.insert &&

                            <>
                            <ButtonCirclePrimary icon={<IconPlusC/>} onClick={()=>{ setshowModalNovaComissao(true) }}/>
                            </>

                            }
                        </div>

                        <DataGrid
                            sx={{ minHeight: '300px' }}
                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                            autoHeight
                            rows={comissoes}
                            columns={columnsDataGridComissoes}
                            pageSize={pageSizeDataGridComissoes}
                            onPageSizeChange={(newPageSize) => setpageSizeDataGridComissoes(newPageSize)}
                            rowsPerPageOptions={[10, 50, 100]} 
                            checkboxSelection={permissoesdoUsuario[3]?.update}
                            disableSelectionOnClick
                            editMode='row'
                            loading={loadingDataGridComissoes}
                            disableColumnMenu={true}
                            onSelectionModelChange={(props)=>{
                                setcomissoesSelecionadas(props)
                            }}
                            // onRowClick
                            onCellClick={(props)=>{
                                if(props.field !== "__check__"){
                                    detalhesComissao(props.id);
                                }
                            }}
                            components={{
                                NoRowsOverlay: nenhumaComissaoEncontrada,
                            }}
                        />    
                    </div>
                </TabPanel>     

                <TabPanel value="6" style={{width: '100%'}}>
                    <div style={{ width: '100%' }}>
                        
                        <DataGrid
                            sx={{ minHeight: '300px' }}
                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                            autoHeight
                            rows={entradas}
                            columns={columnsDataGridEntradas}
                            pageSize={pageSizeDataGridEntradas}
                            onPageSizeChange={(newPageSize) => setpageSizeDataGridEntradas(newPageSize)}
                            rowsPerPageOptions={[10, 50, 100]} 
                            // checkboxSelection={permissoesdoUsuario[3]?.update}
                            disableSelectionOnClick
                            editMode='row'
                            loading={loadingDataGridEntradas}
                            disableColumnMenu={true}
                            onSelectionModelChange={(props)=>{
                                setentradasSelecionadas(props)
                            }}
                            // onRowClick
                            onCellClick={(props)=>{
                                if(props.field !== "__check__"){
                                    // detalhesComissao(props.id);
                                }
                            }}
                            components={{
                                NoRowsOverlay: nenhumaEntradaEncontrada,
                            }}
                        />    
                    </div>
                </TabPanel>         

            </TabContext>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Salvar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}