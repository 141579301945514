import React from 'react';
import Submenu from '../../componentes/SubMenu';

export default function SubMenuVendas({ active }) {
  
  let IconPessoa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
        <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
        <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
        <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
        </g>
    </svg>
  );

  let IconVenda = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 50.679 51.646">
      <g id="Layer_2" data-name="Layer 2" transform="translate(-1.25 -1.248)">
        <path id="Caminho_398" data-name="Caminho 398" d="M13.855,30.461a6.605,6.605,0,1,1,6.605-6.605A6.605,6.605,0,0,1,13.855,30.461Zm0-9.608a3,3,0,1,0,3,3A3,3,0,0,0,13.855,20.853Z" transform="translate(8.412 22.434)" fill="#4a4a4a"/>
        <path id="Caminho_399" data-name="Caminho 399" d="M21.855,30.461a6.605,6.605,0,1,1,6.605-6.605A6.605,6.605,0,0,1,21.855,30.461Zm0-9.608a3,3,0,1,0,3,3A3,3,0,0,0,21.855,20.853Z" transform="translate(19.627 22.434)" fill="#4a4a4a"/>
        <path id="Caminho_400" data-name="Caminho 400" d="M22.659,22.853H13.051a1.8,1.8,0,1,1,0-3.6h9.608a1.8,1.8,0,1,1,0,3.6Z" transform="translate(14.019 25.238)" fill="#4a4a4a"/>
        <path id="Caminho_401" data-name="Caminho 401" d="M16.646,34.7a1.794,1.794,0,0,1-1.785-1.547L8.9,5.331a.605.605,0,0,0-.588-.48H3.051a1.8,1.8,0,1,1,0-3.6H8.314a4.225,4.225,0,0,1,4.1,3.331l5.99,27.93a1.794,1.794,0,0,1,.038.377,1.813,1.813,0,0,1-1.8,1.813Z" transform="translate(0 0)" fill="#4a4a4a"/>
        <path id="Caminho_402" data-name="Caminho 402" d="M37.174,34.274H16.241a6.639,6.639,0,0,1-6.456-5.219L4.93,6.429A1.8,1.8,0,0,1,6.691,4.25H40.222a6.605,6.605,0,0,1,6.5,7.787L43.664,28.851a6.605,6.605,0,0,1-6.49,5.424ZM8.92,7.853,13.32,28.3a3.014,3.014,0,0,0,2.933,2.373H37.186A3,3,0,0,0,40.14,28.2L43.2,11.391a3,3,0,0,0-2.974-3.538Z" transform="translate(5.104 4.209)" fill="#4a4a4a"/>
        <path id="Caminho_403" data-name="Caminho 403" d="M12.659,18.853H3.051a1.8,1.8,0,0,1,0-3.6h9.608a1.8,1.8,0,0,1,0,3.6Z" transform="translate(0 19.63)" fill="#4a4a4a"/>
        <path id="Caminho_404" data-name="Caminho 404" d="M8.855,15.853h-4.8a1.8,1.8,0,0,1,0-3.6h4.8a1.8,1.8,0,0,1,0,3.6Z" transform="translate(1.402 15.424)" fill="#4a4a4a"/>
        <path id="Caminho_405" data-name="Caminho 405" d="M15.051,19.059a1.8,1.8,0,0,1-1.8-1.8V10.051a1.8,1.8,0,0,1,3.6,0v7.206a1.8,1.8,0,0,1-1.8,1.8Z" transform="translate(16.823 9.816)" fill="#4a4a4a"/>
        <path id="Caminho_406" data-name="Caminho 406" d="M18.051,17.157a1.8,1.8,0,0,1-1.8-1.8v-4.8a1.8,1.8,0,0,1,3.6,0v4.8A1.8,1.8,0,0,1,18.051,17.157Z" transform="translate(21.029 10.517)" fill="#4a4a4a"/>
        <path id="Caminho_407" data-name="Caminho 407" d="M12.051,17.157a1.8,1.8,0,0,1-1.8-1.8v-4.8a1.8,1.8,0,1,1,3.6,0v4.8A1.8,1.8,0,0,1,12.051,17.157Z" transform="translate(12.617 10.517)" fill="#4a4a4a"/>
      </g>
    </svg>

  );

  let IconOrcamento = props=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 66.478 67.384">
      <g id="orcamento" transform="translate(-3.429)">
        <path id="Caminho_396" data-name="Caminho 396" d="M67.72,34.584H54.584A20.8,20.8,0,0,0,53.5,29.962H67.924A1.982,1.982,0,0,0,69.905,28,27.66,27.66,0,0,0,42.236,0a1.982,1.982,0,0,0-1.982,1.982V17.113a20.805,20.805,0,0,0-4.511-.936V8.7a1.982,1.982,0,0,0-1.982-1.982,30.331,30.331,0,1,0,18.391,54.45,1.983,1.983,0,0,0,.364-2.788l-4.569-5.9a21.127,21.127,0,0,0,3.541-4.24l5.7,7.37a1.982,1.982,0,0,0,2.77.362A24.608,24.608,0,0,0,69.7,36.589,1.982,1.982,0,0,0,67.72,34.584ZM44.217,4.045A23.7,23.7,0,0,1,65.885,26H51.572a21.129,21.129,0,0,0-7.354-7.117Zm3.9,55.13A26.1,26.1,0,0,1,33.761,63.42a26.367,26.367,0,0,1-1.982-52.66v5.417a20.97,20.97,0,1,0,13.012,38.7ZM33.76,54.06A17.007,17.007,0,1,1,50.767,37.053,17.026,17.026,0,0,1,33.76,54.06Zm25.307-2.5-5.624-7.266a20.819,20.819,0,0,0,1.234-5.743H65.62a20.619,20.619,0,0,1-6.553,13.009Z" fill="#4a4a4a"/>
        <path id="Caminho_397" data-name="Caminho 397" d="M183.015,190.866h-4.256a1.841,1.841,0,0,1,0-3.681h4.105a2.772,2.772,0,0,1,1.974.9,1.982,1.982,0,0,0,2.892-2.711,6.748,6.748,0,0,0-4.861-2.15v-1.726a1.982,1.982,0,0,0-3.964,0v1.726a5.805,5.805,0,1,0-.146,11.609h4.256a1.841,1.841,0,1,1-.028,3.681h-4.447a2.8,2.8,0,0,1-1.537-.522,1.982,1.982,0,0,0-2.26,3.257,6.739,6.739,0,0,0,4.162,1.229V204.2a1.982,1.982,0,1,0,3.964,0v-1.726a5.805,5.805,0,1,0,.147-11.609Z" transform="translate(-147.126 -155.795)" fill="#4a4a4a"/>
      </g>
    </svg>
  );


  let clientes, vendas, orcamentos = '';

  switch (active) {

    case 'clientes':
      clientes = 'active';
        break;

    case 'vendas':
        vendas = 'active';
        break;

    case 'orcamentos':
        orcamentos = 'active';
        break;

    default:
    
        break;
  }


  let itensSubMenu = [
    { active: clientes, title: "Clientes", rota: "/vendas/clientes", icon: <IconPessoa/> },
    { active: vendas, title: "Vendas", rota: "/vendas", icon: <IconVenda/> },
    { active: orcamentos, title: "Orçamentos", rota: "/vendas/orcamentos", icon: <IconOrcamento/> },

  ]


  return (<Submenu itens={ itensSubMenu } />);
}