import React, {useState, useEffect, useRef} from 'react';
import { pdf } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import api from '../../../services/api';

import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ButtonSquadPrimary from '../../../componentes/ButtonSquadPrimary';
import ButtonSquadSecondary from '../../../componentes/ButtonSquadSecondary';
import ButtonSquadDanger from '../../../componentes/ButtonSquadDanger';
import ButtonSquadGreen from '../../../componentes/ButtonSquadGreen';
import InputSearchActions from '../../../componentes/InputSearchActions';

import { formataDinheiro } from '../../../vendor/formatar';

import modalEnvioProdutosWhatsApp from './modals/modalEnvioProdutosWhatsApp';
import ModalEnvioProdutosWhatsApp from './modals/modalEnvioProdutosWhatsApp';

import ModalDetalhesProduto from './modals/modalDetalhesProduto';
import DocumentPDFprodutos from './documentPDFprodutos';

export default function TabProdutos() {

  let IconPlusBtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconPDFbtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="27.758" viewBox="0 0 24.93 27.758">
      <g id="pdf" transform="translate(-26.077)">
        <g id="Grupo_19" data-name="Grupo 19" transform="translate(28.387 25.607)">
          <g id="Grupo_18" data-name="Grupo 18">
            <path id="Caminho_62" data-name="Caminho 62" d="M68.677,472.334a3.073,3.073,0,0,0,2.914,2.15H86.074a3.072,3.072,0,0,0,2.914-2.15Z" transform="translate(-68.677 -472.334)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_21" data-name="Grupo 21" transform="translate(43.912 0.508)">
          <g id="Grupo_20" data-name="Grupo 20">
            <path id="Caminho_63" data-name="Caminho 63" d="M359.085,13.324l-3.563-3.563a3.106,3.106,0,0,0-.475-.389v4.407a.182.182,0,0,0,.182.182h4.345A3.046,3.046,0,0,0,359.085,13.324Z" transform="translate(-355.047 -9.372)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_23" data-name="Grupo 23" transform="translate(28.238)">
          <g id="Grupo_22" data-name="Grupo 22">
            <path id="Caminho_64" data-name="Caminho 64" d="M86.543,6.724H81.791a1.811,1.811,0,0,1-1.809-1.808V0H69a3.066,3.066,0,0,0-3.063,3.063v8.354H86.543V6.724Z" transform="translate(-65.935)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_25" data-name="Grupo 25" transform="translate(37.827 16.638)">
          <g id="Grupo_24" data-name="Grupo 24">
            <path id="Caminho_65" data-name="Caminho 65" d="M243.562,306.9h-.744v3.836h.744q.752,0,.752-.836v-2.165Q244.314,306.9,243.562,306.9Z" transform="translate(-242.818 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_27" data-name="Grupo 27" transform="translate(33.072 16.638)">
          <g id="Grupo_26" data-name="Grupo 26">
            <path id="Caminho_66" data-name="Caminho 66" d="M155.928,306.9H155.1v1.763h.827q.752,0,.752-.836v-.092Q156.681,306.9,155.928,306.9Z" transform="translate(-155.101 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_29" data-name="Grupo 29" transform="translate(26.077 13.044)">
          <g id="Grupo_28" data-name="Grupo 28">
            <path id="Caminho_67" data-name="Caminho 67" d="M48.952,240.6H28.132a2.057,2.057,0,0,0-2.055,2.055v6.826a2.057,2.057,0,0,0,2.055,2.055h20.82a2.057,2.057,0,0,0,2.055-2.055V242.66A2.057,2.057,0,0,0,48.952,240.6Zm-13,4.421h0a1.806,1.806,0,0,1-.564,1.467,2.248,2.248,0,0,1-1.492.472h-.827v1.805a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.316a.356.356,0,0,1,.134-.276.514.514,0,0,1,.351-.117h1.73a2.121,2.121,0,0,1,1.433.464,1.823,1.823,0,0,1,.539,1.458v.042Zm4.672,2.173a1.859,1.859,0,0,1-.56,1.5,2.232,2.232,0,0,1-1.5.476H37.092a.672.672,0,0,1-.41-.117.338.338,0,0,1-.159-.276v-5.324a.338.338,0,0,1,.159-.276.673.673,0,0,1,.41-.117h1.479a2.232,2.232,0,0,1,1.5.476,1.859,1.859,0,0,1,.56,1.5Zm4.584-3.168a.324.324,0,0,1-.288.167H42.759v1.412h1.262a.333.333,0,0,1,.288.15.615.615,0,0,1,.1.359.6.6,0,0,1-.1.343.33.33,0,0,1-.293.15H42.759v2.156a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.307a.331.331,0,0,1,.167-.3.742.742,0,0,1,.4-.1h2.9a.322.322,0,0,1,.293.167.749.749,0,0,1,.1.393A.759.759,0,0,1,45.212,244.032Z" transform="translate(-26.077 -240.605)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  );

  let IconCSVBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21"  viewBox="0 0 22.974 27.355">
      <g id="csv" transform="translate(-41)">
        <path id="Caminho_199" data-name="Caminho 199" d="M41,465.107a2.407,2.407,0,0,0,2.4,2.4H59.432a2.407,2.407,0,0,0,2.4-2.4V465H41Z" transform="translate(0 -440.156)" fill="#fff"/>
        <path id="Caminho_200" data-name="Caminho 200" d="M55.729,13.927V8.787l-5.942,5.942h5.14A.8.8,0,0,0,55.729,13.927Z" transform="translate(-8.318 -8.318)" fill="#fff"/>
        <path id="Caminho_201" data-name="Caminho 201" d="M61.837,2.4a2.407,2.407,0,0,0-2.4-2.4H49.014V5.61a2.407,2.407,0,0,1-2.4,2.4H41v2.939H61.837Z" fill="#fff"/>
        <path id="Caminho_202" data-name="Caminho 202" d="M63.172,235H41v10.685H63.172a.8.8,0,0,0,.8-.8V235.8A.8.8,0,0,0,63.172,235Zm-13.636,7.306a2.35,2.35,0,0,1-1.858.842,2.8,2.8,0,1,1,1.569-5.13.668.668,0,0,1-.748,1.107,1.469,1.469,0,1,0-.821,2.688,1.059,1.059,0,0,0,.729-.255,1.048,1.048,0,0,0,.106-.109.668.668,0,0,1,1.023.858Zm5.093-.633a1.893,1.893,0,0,1-1.927,1.475,2.667,2.667,0,0,1-1.981-.851.668.668,0,1,1,.99-.9,1.348,1.348,0,0,0,.991.413.591.591,0,0,0,.612-.374c.032-.177-.224-.338-.473-.43-.666-.245-1.257-.509-1.282-.52a.671.671,0,0,1-.093-.05,1.489,1.489,0,0,1-.664-1.474,1.633,1.633,0,0,1,1.182-1.321,2.249,2.249,0,0,1,2.449.7.668.668,0,0,1-1.108.735,1.112,1.112,0,0,0-.956-.152.309.309,0,0,0-.245.232.161.161,0,0,0,.044.143c.167.073.635.273,1.135.458A1.732,1.732,0,0,1,54.63,241.673Zm5.437-3.249-1.4,4.2a.766.766,0,0,1-.72.523h-.005a.765.765,0,0,1-.72-.508l-1.463-4.215a.668.668,0,0,1,1.262-.438l.909,2.62L58.8,238a.668.668,0,0,1,1.268.421Z" transform="translate(0 -222.445)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconPessoaCancel = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="35" viewBox="0 0 312.858 306.449">
      <path id="user_1_" data-name="user (1)" d="M238.867,143.97a73.556,73.556,0,0,0-35.6,9.14,114.809,114.809,0,0,0-32.255-17.6,96.246,96.246,0,0,0,20.239-40.263c3.921-16.946,2.821-34.358-3.1-49.028-10.332-25.608-33.585-40.541-63.8-40.97h-.208c-30.212.43-53.464,15.362-63.8,40.97-5.919,14.671-7.019,32.082-3.1,49.029a96.241,96.241,0,0,0,20.236,40.26c-25.212,9-45.505,25.418-59.135,47.992C4.02,207.249-2.234,237.279.747,268.06A7.332,7.332,0,0,0,4.1,273.532c35.231,22.509,71.1,35.193,106.607,37.7q6.614.467,13.244.467c31.078,0,62.428-7.684,93.377-22.9A74.014,74.014,0,1,0,238.867,143.97ZM73.954,51.709c8.121-20.128,25.982-31.415,50.3-31.792,24.319.376,42.179,11.664,50.3,31.792,10.219,25.327,2.015,58.228-19.949,80.01-10.236,10.152-20.448,15.3-30.351,15.3s-20.115-5.148-30.352-15.3C71.938,109.937,63.735,77.036,73.954,51.709ZM15.042,263.1C10.873,206.363,39.566,161.484,89.082,147.18c11.4,9.628,23.2,14.5,35.173,14.5s23.776-4.877,35.173-14.5a103.183,103.183,0,0,1,31.148,14.76,73.94,73.94,0,0,0,9.145,118.828C137.649,307.506,77.08,301.689,15.041,263.1Zm223.825,14.234a59.347,59.347,0,1,1,59.347-59.347,59.414,59.414,0,0,1-59.347,59.347ZM269.1,198.118l-19.864,19.865L269.1,237.847a7.333,7.333,0,1,1-10.37,10.371l-19.864-19.864L219,248.218a7.333,7.333,0,1,1-10.37-10.371L228.5,217.983l-19.864-19.864A7.333,7.333,0,0,1,219,187.748l19.864,19.864,19.864-19.865A7.333,7.333,0,1,1,269.1,198.118Z" transform="translate(-0.02 -5.25)" fill="#4a4a4a"/>
    </svg>
  )

  let IconNoAuth = props =>(
    <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
      <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
      <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
    </svg>
  )

  let IconProduto = props=>(
    <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 19.5 19.499">
      <path id="box" d="M21.287,6.882V6.875l-.009-.013A3.741,3.741,0,0,0,19.523,5.24l-6-2.667a3.757,3.757,0,0,0-3.046,0l-6,2.667A3.741,3.741,0,0,0,2.724,6.862l-.009.013v.007A3.729,3.729,0,0,0,2.25,8.667v6.666A3.752,3.752,0,0,0,4.477,18.76l6,2.667a3.766,3.766,0,0,0,1.515.322h.016a3.766,3.766,0,0,0,1.515-.322l6-2.667a3.752,3.752,0,0,0,2.227-3.427V8.667A3.729,3.729,0,0,0,21.287,6.882Zm-10.2-2.938a2.25,2.25,0,0,1,1.828,0l6,2.667a2.2,2.2,0,0,1,.462.292L16.643,8.118,9.384,4.7ZM7.573,5.505l7.259,3.418-1.912.849a2.3,2.3,0,0,1-1.835,0L4.624,6.9a2.2,2.2,0,0,1,.462-.292ZM5.086,17.389A2.251,2.251,0,0,1,3.75,15.333V8.667a2.26,2.26,0,0,1,.07-.48l6.66,2.96a3.773,3.773,0,0,0,.77.24v8.722c-.054-.019-.111-.029-.164-.053ZM20.25,15.333a2.251,2.251,0,0,1-1.336,2.056l-6,2.667c-.053.024-.11.034-.164.053V11.386a3.75,3.75,0,0,0,.774-.241L15.88,10.1v1.79a.75.75,0,0,0,1.5,0V9.432l2.8-1.245a2.26,2.26,0,0,1,.07.48Z" transform="translate(-2.25 -2.25)" fill="#4a4a4a"/>
    </svg>
  );

  let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 115.029 153.224">
      <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z" transform="translate(-168.493 -59.168)" fill="#fff"/>
      </g>
    </svg>
  )

  let IconWhatsAppBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" viewBox="0 0 44.084 44.084">
      <g id="XMLID_468_" transform="translate(0)">
        <path id="XMLID_469_" d="M101.932,93.579c-.086-.041-3.3-1.624-3.871-1.829a2.223,2.223,0,0,0-.749-.165,1.274,1.274,0,0,0-1.082.641c-.321.477-1.293,1.613-1.593,1.953-.039.045-.093.1-.125.1s-.526-.2-.677-.27A16.912,16.912,0,0,1,87.412,88.3a.355.355,0,0,1-.054-.127,1.036,1.036,0,0,1,.188-.222c.175-.173.365-.4.548-.622.087-.1.174-.209.259-.308a3.462,3.462,0,0,0,.522-.829l.072-.145a1.5,1.5,0,0,0-.044-1.411c-.076-.152-1.433-3.427-1.577-3.771-.347-.83-.805-1.217-1.442-1.217-.059,0,0,0-.248.01a7.7,7.7,0,0,0-2.673.687A5.594,5.594,0,0,0,80.89,85.1a10.257,10.257,0,0,0,2.224,5.647c.017.022.047.067.091.132a20.936,20.936,0,0,0,8.98,7.8,14.6,14.6,0,0,0,5.424,1.387h0a8.475,8.475,0,0,0,.883-.052L98.65,100c1.075-.1,3.438-1.32,3.975-2.813a4.447,4.447,0,0,0,.253-2.928A2.029,2.029,0,0,0,101.932,93.579Z" transform="translate(-69.312 -68.248)" fill="#fff"/>
        <path id="XMLID_470_" d="M22.433,0A21.6,21.6,0,0,0,.781,21.49,21.332,21.332,0,0,0,3.749,32.357L.031,43.327a.572.572,0,0,0,.716.729l11.439-3.635a21.687,21.687,0,0,0,31.9-18.932A21.6,21.6,0,0,0,22.433,0Zm0,38.5A17.153,17.153,0,0,1,13,35.687a.573.573,0,0,0-.487-.067l-5.73,1.821,1.85-5.457a.573.573,0,0,0-.08-.522A16.78,16.78,0,0,1,5.283,21.49,17.15,17.15,0,1,1,22.433,38.5Z" transform="translate(0)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconXLSXBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 66.915 62.735">
      <g id="excel" transform="translate(0 -15.99)">
        <g id="Grupo_415" data-name="Grupo 415" transform="translate(0 15.989)">
          <g id="Grupo_414" data-name="Grupo 414" transform="translate(0 0)">
            <path id="Caminho_445" data-name="Caminho 445" d="M64.824,24.357H37.64V18.083a2.086,2.086,0,0,0-.757-1.61,2.055,2.055,0,0,0-1.719-.443L1.706,22.3A2.085,2.085,0,0,0,0,24.357v46a2.089,2.089,0,0,0,1.706,2.053l33.457,6.273a1.968,1.968,0,0,0,.385.038,2.091,2.091,0,0,0,2.091-2.091V70.361H64.824a2.092,2.092,0,0,0,2.091-2.091V26.448A2.092,2.092,0,0,0,64.824,24.357ZM28.757,54.347A2.089,2.089,0,1,1,25.612,57.1l-6.081-6.947L14.2,57.011a2.094,2.094,0,0,1-3.3-2.572l5.83-7.494-5.75-6.574a2.091,2.091,0,0,1,3.149-2.752l5.219,5.964,6.194-7.963a2.092,2.092,0,1,1,3.3,2.572l-6.691,8.6ZM62.733,66.178H37.64V62h6.273a2.091,2.091,0,0,0,0-4.182H37.64V53.632h6.273a2.091,2.091,0,0,0,0-4.182H37.64V45.267h6.273a2.091,2.091,0,1,0,0-4.182H37.64V36.9h6.273a2.091,2.091,0,0,0,0-4.182H37.64V28.539H62.733Z" transform="translate(0 -15.989)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_417" data-name="Grupo 417" transform="translate(50.186 32.721)">
          <g id="Grupo_416" data-name="Grupo 416" transform="translate(0 0)">
            <path id="Caminho_446" data-name="Caminho 446" d="M390.273,144.011h-4.182a2.091,2.091,0,1,0,0,4.182h4.182a2.091,2.091,0,0,0,0-4.182Z" transform="translate(-384 -144.011)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_419" data-name="Grupo 419" transform="translate(50.186 41.085)">
          <g id="Grupo_418" data-name="Grupo 418" transform="translate(0 0)">
            <path id="Caminho_447" data-name="Caminho 447" d="M390.273,208.011h-4.182a2.091,2.091,0,1,0,0,4.182h4.182a2.091,2.091,0,0,0,0-4.182Z" transform="translate(-384 -208.011)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_421" data-name="Grupo 421" transform="translate(50.186 49.45)">
          <g id="Grupo_420" data-name="Grupo 420">
            <path id="Caminho_448" data-name="Caminho 448" d="M390.273,272.011h-4.182a2.091,2.091,0,1,0,0,4.182h4.182a2.091,2.091,0,0,0,0-4.182Z" transform="translate(-384 -272.011)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_423" data-name="Grupo 423" transform="translate(50.186 57.814)">
          <g id="Grupo_422" data-name="Grupo 422">
            <path id="Caminho_449" data-name="Caminho 449" d="M390.273,336.011h-4.182a2.091,2.091,0,0,0,0,4.182h4.182a2.091,2.091,0,0,0,0-4.182Z" transform="translate(-384 -336.011)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  );
  
  let history = useHistory();
  let csvInstance = useRef();

  const [loaderPage, setloaderPage] = useState(false);

  const [produtos, setprodutos] = useState([]);
  const [produtosSelecionados, setprodutosSelecionados] = useState([]);
  const [pageSizeDataGridProdutos, setpageSizeDataGridProdutos] = useState(10);
  const [loadingDataGridProdutos, setloadingDataGridProdutos] = useState(true);

  const [filtroTermo, setfiltroTermo] = useState();
  const [filtroStatus, setfiltroStatus] = useState('all');
  const [filtroEstoque, setfiltroEstoque] = useState(0);

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  const [listagemProdutosExport, setlistagemProdutosExport] = useState([]);

  const [showModalEnvioWhatsApp, setshowModalEnvioWhatsApp] = useState(false);

  const [showModalDetalhesProduto, setshowModalDetalhesProduto] = useState(false); 
  const [codigoDetalhesProduto, setcodigoDetalhesProduto] = useState();

  const getProdutos = async(termo='', status='', estoque=0)=>{
    setloadingDataGridProdutos(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemProdutos?termo=${termo}&status=${status}&estoque=${estoque}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    !response.data.error_code && setprodutos(response.data);
    setloadingDataGridProdutos(false);
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });
    

    setpermissoesdoUsuario(permissoes.data.produtos);
  }


  useEffect(()=>{
    getProdutos();
    permissoesUsuario();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  
  const handleDetalhesProduto = async(props)=>{
    let codigo = props.row.codigo;
    history.push(`/estoque/produto/detalhes/${codigo}`);
  }

  const getDataExportProdutos = async()=>{
      //GET DADOS USER
      // let auth = localStorage.getItem("auth");
      // auth = JSON.parse(auth);

      // let pessoas = await api.post('listagemPessoasSelecionadas', {
      //   'pessoasIds': pessoasSelecionadas
      // }, 
      // {
      //   headers: {
      //     "Content-type": "application/json",
      //     "Authorization": `Bearer ${auth.token}`
      //   }
      // });

      // setlistagemProdutosExport(pessoas.data);
  }

  // const handleExportCSVProdutos = async()=>{
  //   await getDataExportProdutos();

  //   csvInstance.current.link.dataset.interception = 'off'
  //   csvInstance.current.link.click();
  // }


  const handleDeleteProduto = async()=>{
    
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar o(s) produto(s) selecionado(s)?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let produtosid = [];

        for await (let id of produtosSelecionados) {
          let dados = { id: id }
          produtosid.push(dados);
        }
        
        await api.delete('deleteProduto', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "produtosIds": produtosid }
        });

        getProdutos(filtroTermo, filtroStatus);

        Swal.fire(
          'Deletado!',
          'Produto(s) deletado(s) com sucesso!',
          'success'
        )
      }
    })

  }

  const handleSearchProduto = async(event)=>{
    let termo = event.target.value;
    setfiltroTermo(termo);

    if(termo.length >= 3){
      getProdutos(termo, filtroStatus, filtroEstoque);
    }else{
      getProdutos('', filtroStatus, filtroEstoque);
    }
  }

  const handleFiltroStatus = async(props)=>{
    let status = props.target.value;
    setfiltroStatus(status);
    getProdutos(filtroTermo, status, filtroEstoque);
  }

  const handleFiltroEstoque = async(props)=>{
    let estoque = props.target.value;
    setfiltroEstoque(estoque);
    getProdutos(filtroTermo, filtroStatus, estoque);
  }

  const columnsDataGridProdutos = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0,
      minWidth: 20,
      editable: false,
      renderCell: (params) => { //renderiza componente
        
        switch (params.value) {
            case 0:
                    return <span className='dotStatus inativo'></span>
                break;
            case 1:
                    return <span className='dotStatus ativo'></span>
                break;
        }
    
      },
    },
    {
      field: 'codigoInterno',
      headerName: 'Código Interno',
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'ean',
      headerName: 'Código EAN',
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'tipo_produto',
      headerName: 'Tipo',
      flex: 0,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'titulo',
      headerName: 'Título',
      flex: 4,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'estoque_atual',
      headerName: 'Estoque',
      flex: 1,
      editable: false,
      renderCell: (params) => { //renderiza componente
        
        if(Number(params.value) > 0){
          return <p style={{ color: 'green', fontWeight: 'bold' }}>{params.value}</p>; 
        }else if(Number(params.value) === 0){
          return <p style={{ color: '#ff8a00', fontWeight: 'bold'  }}>{params.value}</p>; 
        }else{
          return <p style={{ color: 'red', fontWeight: 'bold'  }}>{params.value}</p>; 
        }
    
      },
    },
    {
      field: 'nome_setor',
      headerName: 'Setor',
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'precoVenda',
      headerName: 'Preço de venda',
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value); 
      },
    },
    {
      field: 'ultimo_valor_compra',
      headerName: 'Última compra',
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value); 
      },
    },
    
  ];

  function nenhumProdutoEncontrado(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          {
            permissoesdoUsuario[0]?.view ?
              <>
                <IconProduto/>
                <p>Nenhum produto encontrado</p>
              </>
            :

            <>
              <IconNoAuth/>
              <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar o cadastro de produtos!</p>
            </>

          }
        </div>
      </GridOverlay>
    )
  }

  const handleExportPDFprodutos = async()=>{

    setloaderPage(true);      
    let custototal = 0;
    let totalprecovenda = 0;

    for await (let produto of produtos) {

      let qtd = 0;

      if(produto.estoque_atual > 0){
        qtd = produto.estoque_atual;
      }

      custototal += qtd * Number(produto.ultimo_valor_compra);
      totalprecovenda += qtd * Number(produto.precoVenda);
    }

    let blob = await pdf(<DocumentPDFprodutos produtos={produtos} custototal={custototal} totalprecovenda={totalprecovenda} />).toBlob();
    
    let url = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.download = 'Estoque-de-produtos.pdf';
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);

    setloaderPage(false);
    
  }

  const handleExportXLSXprodutos = async()=>{
    setloaderPage(true);

    let lista = [];

    for await (let produto of produtos) {
      lista.push({
        Codigo: produto.codigo,
        CodigoInterno: produto.codigoInterno,
        Ean: produto.ean,
        Titulo: produto.titulo,
        Preco: formataDinheiro(produto.precoVenda),
        Tipo: produto.tipo_produto,
        Setor: produto.nome_setor,
        Estoque: produto.estoque_atual,
        UltimaCompra: formataDinheiro(produto.ultimo_valor_compra),
      });
    }

    const ws = XLSX.utils.json_to_sheet(lista);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Cadastro-de-Produtos.xlsx');

    setloaderPage(false);
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <HelmetProvider>
        <Helmet>
            <title>Cadastro de produtos - Estoque - Nexcom</title>
        </Helmet>
      </HelmetProvider>

      <CSVLink
          data={listagemProdutosExport}
          filename={'cadastros-de-produtos.csv'}
          ref={csvInstance}
          data-interception='off'
      />

      <ModalEnvioProdutosWhatsApp produtosSelecionados={produtosSelecionados} visible={showModalEnvioWhatsApp} change={setshowModalEnvioWhatsApp} />
      <ModalDetalhesProduto codigo={codigoDetalhesProduto} getProdutos={getProdutos} visible={showModalDetalhesProduto} change={setshowModalDetalhesProduto} />

      <div className='box-actions-produtos'>

          {
              permissoesdoUsuario[2]?.insert &&
              
              <ButtonSquadPrimary icon={<IconPlusBtn/>} onClick={()=>{ history.push("/estoque/produto/novo"); }} />
          }


          <div style={{ 'marginLeft': '5px' }}>
            <ButtonSquadSecondary  icon={<IconPDFbtn/>} onClick={()=>{ handleExportPDFprodutos() }}/>
          </div> 

          <div style={{ 'marginLeft': '5px' }}>
            <ButtonSquadSecondary  icon={<IconXLSXBtn/>} onClick={()=>{ handleExportXLSXprodutos() }}/>
          </div>          

          {
          produtosSelecionados.length > 0 &&
          <>  
              
              <div style={{ 'marginLeft': '5px' }}>
                  <ButtonSquadGreen icon={<IconWhatsAppBtn/>} onClick={()=>{ setshowModalEnvioWhatsApp(true) }}/>
              </div>

              {/* <div style={{ 'marginLeft': '5px' }}>
                  <ButtonSquadSecondary icon={<IconCSVBtn/>} onClick={()=>{ handleExportCSVProdutos() }}/> 
              </div> */}

              {
                  permissoesdoUsuario[1].delete &&

                  <div style={{ 'marginLeft': '5px' }}>
                      <ButtonSquadDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteProduto() }} />
                  </div>
              }

          </>
          }

          <div style={{ 'marginRight': '5px' }}>
            <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
              <InputLabel id="label-status">Status</InputLabel>
              <Select
                labelId="label-status"
                id="demo-simple-select"
                name="status"
                label="Status"
                size="small"
                placeholder='Status'
                value={filtroStatus}
                onChange={handleFiltroStatus}
                style={{ height: '43px' }}
              >
                <MenuItem value='all'>Todos</MenuItem>
                <MenuItem value={1}>Ativos</MenuItem>
                <MenuItem value={0}>Inativos</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{ 'marginRight': '5px' }}>
            <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
              <InputLabel id="label-status">Estoque</InputLabel>
              <Select
                labelId="label-status"
                id="demo-simple-select"
                name="status"
                label="Status"
                size="small"
                placeholder='Status'
                value={filtroEstoque}
                onChange={handleFiltroEstoque}
                style={{ height: '43px' }}
              >
                <MenuItem value={0}>Todos</MenuItem>
                <MenuItem value={1}>Positivo</MenuItem>
                <MenuItem value={-1}>Negativo</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{ 'marginLeft': '5px', 'width': '100%' }}>
              <InputSearchActions onChange={handleSearchProduto}  placeholder="Procure um produto" />
          </div>
      </div>

      <div className='box-legendas-status'>
          <article> <span className='ativo'></span> <p>Ativo</p> </article> 
          <article> <span className='inativo'></span> <p>Inativo</p> </article> 
      </div>

      <div className='container-data-grid'>
          <DataGrid
            sx={{ minHeight: '400px' }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={produtos}
            columns={columnsDataGridProdutos}
            pageSize={pageSizeDataGridProdutos}
            onPageSizeChange={(newPageSize) => setpageSizeDataGridProdutos(newPageSize)}
            rowsPerPageOptions={[10, 50, 100]} 
            checkboxSelection
            disableSelectionOnClick
            editMode='row'
            loading={loadingDataGridProdutos}
            disableColumnMenu={true}
            onSelectionModelChange={(props)=>{
                setprodutosSelecionados(props);
            }}
            // onRowClick
            onCellClick={(props)=>{
                if(props.field !== "__check__"){
                  // handleDetalhesProduto(props);
                  setcodigoDetalhesProduto(props.row.codigo)
                  setshowModalDetalhesProduto(true);
                }
            }}
            components={{
                NoRowsOverlay: nenhumProdutoEncontrado,
            }}
          />
      </div>
          
    </>
    
  );
}