import React, {useState, useRef, useEffect } from 'react';
import './style.css';

import api from '../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { TelMask, mtel } from '../../../vendor/mascaras';

export default function ModalEnvioDanfeVendaWhatsApp({ vendasSelecionadas, visible, change }) {

  const inputNumero = useRef(null);
  const [loaderPage, setloaderPage] = useState(false);
  const [modelosmensagens, setmodelosmensagens] = useState([]);
  const [contaswhatsapp, setcontaswhatsapp] = useState([]);
  

  const getModelosMensagens = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemModelosMensagens`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayModelos = [];
    
    for await (let modelo of response.data) {
        let dados = {
            id: modelo.id,
            nome: modelo.nome
        }    
        
        arrayModelos.push(dados);
    }

    setmodelosmensagens(arrayModelos);

  }

  const getContasWhatsApp = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemContasWhatsApp`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });
    
    let arrayContas = [];
    
    for await (let conta of response.data) {
        let dados = {
            deviceid: conta.device_id,
            nome: conta.nome
        }    
        
        arrayContas.push(dados);
    }
    
    arrayContas.length > 0 && formik.setFieldValue('contawhatsapp', JSON.stringify(arrayContas[0]));
    setcontaswhatsapp(arrayContas);

  }


  useEffect(async()=>{

    if(visible){
      getModelosMensagens();
      getContasWhatsApp();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[visible]);



  
  const validationSchema = yup.object({

    contawhatsapp: yup
      .string('Selecione a conta do WhatsApp')
      .required('Conta do WhatsApp Obrigatória')
      .test({
        message: () => "Selecione a conta do WhatsApp",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),

    // modelomensagem: yup
    //   .string('Selecione o modelo de mensagem')
    //   .required('Modelo de mensagem Obrigatório')
    //   .test({
    //     message: () => "Selecione o modelo de mensagem",
    //     test: async (values) => {
    //       if (values) { 
    //         if (values === 'null') { return false; } else{ return true; }
    //       }
    //     },
    //   }),

    // mensagem: yup
    //   .string('Informa a mensagem')
    //   .required('Mensagem Obrigatória'),

  });

  const formik = useFormik({
    initialValues: {
      contawhatsapp: 'null',
      modelomensagem: 'null',
      mensagem: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setloaderPage(true);

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let vendaid = [];
  
      for await (let id of vendasSelecionadas) {
        let dados = { id: id }
        vendaid.push(dados);
      }

      let contawhatsapp = JSON.parse(values.contawhatsapp);

      await api.post('sendWhatsAppDanfePDF', {
          "deviceId": contawhatsapp.deviceid,
          "vendasIds": vendaid,
          "mensagem": values.mensagem
      }, 
      {
          headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
          }
      });
      
      formik.resetForm();
      change(false);
      setloaderPage(false);
  
      Swal.fire(
        'Envio concluído!',
        'Mensagem enviada sucesso!',
        'success'
      )
      
    }
  });

  const handleChangeModelomensagem = async(event, value, reason, details)=>{
    if(value?.id){
        setloaderPage(true);

        formik.setFieldValue('modelomensagem', JSON.stringify(value));

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post('detalhesModeloMensagem', {
                'id': value.id
            }, 
            {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
            }
        });

        formik.setFieldValue('mensagem', response.data.mensagem);
        setloaderPage(false);
    }

  }

  const handleChangeContawhatsapp = async(event, value, reason, details)=>{
    formik.setFieldValue('contawhatsapp', JSON.stringify(value));
  }

  


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Envio Danfe Venda WhatsApp
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} >

            <Grid item xs={12} md={12} lg={12}>
                <FormControl sx={{ m: 0, width: '100%' }} >
                <Autocomplete
                    disablePortal
                    id="combo-box-contawhats"
                    options={contaswhatsapp}
                    value={JSON.parse(formik.values.contawhatsapp)}
                    getOptionLabel={(option) => option.nome}
                    isOptionEqualToValue={(option, value) => option.deviceid === value.deviceid}
                    onChange={handleChangeContawhatsapp}
                    noOptionsText="Nenhuma conta encontrada."
                    sx={{ width: '100%' }}
                    // disabled={!permissoesdoUsuario[3]?.update}
                    renderInput={(params) => <TextField {...params} label="Conta de WhatsApp"  error={formik.touched.contawhatsapp && Boolean(formik.errors.contawhatsapp)}/>}
                />
                <FormHelperText error>{formik.touched.contawhatsapp && formik.errors.contawhatsapp}</FormHelperText>
                </FormControl>
            </Grid>
        
            <Grid item xs={12} md={12} lg={12}>
                <FormControl sx={{ m: 0, width: '100%' }} >
                <Autocomplete
                    disablePortal
                    id="combo-box-modelomsg"
                    options={modelosmensagens}
                    value={JSON.parse(formik.values.modelomensagem)}
                    getOptionLabel={(option) => option.nome}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={handleChangeModelomensagem}
                    noOptionsText="Nenhum Modelo encontrado."
                    sx={{ width: '100%' }}
                    // disabled={!permissoesdoUsuario[3]?.update}
                    renderInput={(params) => <TextField {...params} label="Modelo de mensagem"  error={formik.touched.modelomensagem && Boolean(formik.errors.modelomensagem)}/>}
                />
                <FormHelperText error>{formik.touched.modelomensagem && formik.errors.modelomensagem}</FormHelperText>
                </FormControl>
            </Grid>
        
            <Grid item xs={12} md={12} >
                <TextField
                fullWidth
                id="mensagem"
                name="mensagem"
                label="Mensagem"
                margin="normal"
                type="text"
                multiline
                rows={5}
                value={formik.values.mensagem}
                onChange={formik.handleChange}
                error={formik.touched.mensagem && Boolean(formik.errors.mensagem)}
                helperText={formik.touched.mensagem && formik.errors.mensagem}
                />
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Enviar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}