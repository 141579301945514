import React, { useEffect, useState } from 'react';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config
import { formataDinheiro } from '../../../vendor/formatar';

export default function DocumentPDFetiquetas({ listagemExport, tipo, barcodes}) {

  const comecarApartir = 6 -1;
  
  const largura = '44.45mm';
  const altura = '12.8mm';
  const margemEsquerda = '1.8cm';
  const margemSuperior = '0.5cm';
  const espacoHorizontal = '0.3cm';

  const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        display: 'flex',
        flexDirection: 'row', // Alterado para 'row'
        flexWrap: 'wrap', // Permite que as etiquetas quebrem para a próxima linha
        marginLeft: margemEsquerda,
        marginTop: margemSuperior,

    },
    etiqueta: {
        display: 'flex',
        flexDirection: 'row',
        padding: '4px',
        width: largura,
        height: altura,
        marginRight: espacoHorizontal,
        // backgroundColor: '#c4c4c4',
    },
    txt: {
        textTransform: 'uppercase',
        fontSize: '6px',
        textAlign: 'left',
    },
  });
  
  return (
    <Document>
      {
        tipo === "detalhesProduto" ?

        <Page size="LETTER" orientation="portrait" style={styles.page}>
          {/* { Array.from({ length: comecarApartir }).map((_, k) => (
              <View key={k} style={styles.etiqueta}>
              </View>
          ))} */}

          {listagemExport.map((item, index) => (
            Array.from({ length: item.quantidade }).map((_, k) => (
              <View key={k} style={styles.etiqueta}>
                <View style={{ display: 'flex', flex: '1' }}>
                  <Text style={styles.txt}>{item.ean}</Text>
                  <Text style={styles.txt}>{formataDinheiro(item.precoVenda)}</Text>
                  <Text style={styles.txt}>{item.titulo}</Text>
                </View>
              </View>
            ))
          ))}

        </Page>

        : //barCode

        <Page size="LETTER" orientation="portrait" style={styles.page}>

          {barcodes.map((barcodeImage, index) => (
            <View key={index} style={styles.etiqueta}>
              <View style={{ display: 'flex', flex: '1' }}>
                <Image src={barcodeImage} />
              </View>
            </View>
          ))}

        </Page>

      }
      
    </Document> 
  );
}