import React, {useState, useEffect, useRef} from 'react';
import './style.css';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useHistory } from "react-router-dom";

export default function ButtonSquadDropDownGreen({ icon, submenus }) {

    let history = useHistory();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [menusdrop, setmenusdrop] = useState([]);

    useEffect(()=>{
        setmenusdrop(submenus);
    
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[submenus]);


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

        setOpen(false);
    };
    
  return (
    <>
        <ButtonGroup variant="text" ref={anchorRef} aria-label="">
            <Button id='button-squad-drop-down-green' type='button' onClick={handleToggle}>
                {icon}
            
                <Button
                    id='btn-arrow-sdp'
                    type='button'
                >
                    <ArrowDropDownIcon />
                </Button>
            </Button>
        </ButtonGroup>
        <Popper
            sx={{
            zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{
                
                }}
            >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                    {menusdrop?.map((v, k) =>{
                       
                       return (
                        <MenuItem
                            disabled={v.disabled}
                            key={k}
                            onClick={()=>{ setOpen(false); v.onClick() }} //history.push(`${v.push}`);
                        >
                        {v.title}
                        </MenuItem>
                        )
                    })}
                    </MenuList>
                </ClickAwayListener>
                </Paper>
            </Grow>
            )}
        </Popper>
    </>

  );
}