import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useHistory } from "react-router-dom";
import './style.css';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import api from '../../../services/api';
import { CpfMask, CnpjMask } from '../../../vendor/mascaras';


export default function NovoUsuario() {

  let IconPessoa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
      <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
        <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
        <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconCarro = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 47.701 17.828">
      <g id="sedan" transform="translate(0 -160.323)">
        <path id="Caminho_46" data-name="Caminho 46" d="M91.224,293.383a.7.7,0,0,0,.494-.2l1.056-1.056a.7.7,0,0,0-.988-.988L90.73,292.19a.7.7,0,0,0,.494,1.193Z" transform="translate(-82.091 -118.438)" fill="#4a4a4a"/>
        <path id="Caminho_47" data-name="Caminho 47" d="M404.416,293.177a.7.7,0,1,0,.988-.988l-1.056-1.056a.7.7,0,1,0-.988.988Z" transform="translate(-365.595 -118.437)" fill="#4a4a4a"/>
        <path id="Caminho_48" data-name="Caminho 48" d="M45.594,167.577c-3.947-1.833-9.883-2.008-11.082-2.025l-5.661-3.6a10.391,10.391,0,0,0-5.588-1.627H16.61a11.211,11.211,0,0,0-4.988,1.178l-3.544,1.772A10.477,10.477,0,0,1,4.683,164.3l-2.762.345A2.2,2.2,0,0,0,0,166.821v4.46a2.952,2.952,0,0,0,2.132,2.826l3.3.943a4.431,4.431,0,0,0,8.078.86H34.937a4.437,4.437,0,1,0-.51-1.4H14.021a4.432,4.432,0,1,0-8.789-.973l-2.717-.776A1.548,1.548,0,0,1,1.4,171.281v-2.092h.795a.7.7,0,1,0,0-1.4H1.4v-.97a.8.8,0,0,1,.7-.789l2.762-.345A11.872,11.872,0,0,0,8.7,164.523l.319-.159,1.293,1.287a4.4,4.4,0,0,0,3.135,1.3h1.441a.7.7,0,1,0,0-1.4H13.448a3.074,3.074,0,0,1-.362-.022l.772-3.41a9.794,9.794,0,0,1,2.752-.4h3.554v3.83H17.876a.7.7,0,1,0,0,1.4h16.43c.066,0,6.668.024,10.7,1.9a2.2,2.2,0,0,1,.627.44h-.871a.7.7,0,1,0,0,1.4h1.529c.008.076.013.152.013.229v2.479c0,.692-.3,1.122-.795,1.122a.7.7,0,1,0,0,1.4,2.068,2.068,0,0,0,1.583-.717,2.7,2.7,0,0,0,.609-1.8v-2.479A3.678,3.678,0,0,0,45.594,167.577Zm-6.807,3.105a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,38.787,170.682Zm-29.127,0a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,9.661,170.682Zm2.1-5.641a3.067,3.067,0,0,1-.461-.38l-.959-.959,1.9-.952.039-.019Zm9.8-3.321h1.7a9,9,0,0,1,4.837,1.409l3.806,2.422H21.562Z" fill="#4a4a4a"/>
        <path id="Caminho_49" data-name="Caminho 49" d="M169.038,280.564a.7.7,0,1,0,0,1.4h15.683a.7.7,0,0,0,0-1.4Z" transform="translate(-152.655 -109.039)" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let IconContrato = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 27.107 27.107">
      <g id="contract" transform="translate(0 -0.5)">
        <path id="Caminho_50" data-name="Caminho 50" d="M20.743,27.607H.868A.867.867,0,0,1,0,26.74V1.367A.867.867,0,0,1,.868.5H20.743a.867.867,0,0,1,.867.867v9.059a.867.867,0,1,1-1.735,0V2.234H1.734V25.873H19.875V21.82a.867.867,0,1,1,1.735,0v4.92a.867.867,0,0,1-.867.867Zm0,0" fill="#4a4a4a"/>
        <path id="Caminho_51" data-name="Caminho 51" d="M215.468,127.775h-.153a.868.868,0,0,1-.849-.85c-.009-.443,0-2.706,1.009-3.718L226.4,112.276a.894.894,0,0,1,1.227,0l2.333,2.333a.867.867,0,1,1-1.227,1.227l-1.719-1.72L216.7,124.432a3.154,3.154,0,0,0-.458,1.564,3.141,3.141,0,0,0,1.565-.458l9.231-9.233a.867.867,0,0,1,1.478.541c.425,5.1-2.163,6.576-3.553,7.371a3.948,3.948,0,0,0-.684.443,2.034,2.034,0,0,0-.546.9.861.861,0,0,1-.973.733.877.877,0,0,1-.742-.987,3.532,3.532,0,0,1,1-1.834,4.548,4.548,0,0,1,1.086-.761,4.487,4.487,0,0,0,2.658-3.675l-7.726,7.728c-.927.926-2.9,1.008-3.568,1.008Zm0,0" transform="translate(-203.11 -105.627)" fill="#4a4a4a"/>
        <path id="Caminho_52" data-name="Caminho 52" d="M67.856,302.92a2.018,2.018,0,0,1-1.388-.532,2.906,2.906,0,0,1-1.69.532,3.527,3.527,0,0,1-1.858-.473,4.2,4.2,0,0,1-1.881.473.867.867,0,1,1,0-1.735,2.285,2.285,0,0,0,.738-.131,3.175,3.175,0,0,1-.116-.359,4.6,4.6,0,0,1,1.682-4.7,1.835,1.835,0,0,1,2.318.379,2.594,2.594,0,0,1,.205,2.9,6.557,6.557,0,0,1-1.27,1.906c.057,0,.118.006.182.006.533,0,1.018-.373,1.018-.782a.867.867,0,1,1,1.735,0,1.328,1.328,0,0,0,.113.687l1-.86a.868.868,0,1,1,1.133,1.314l-1.353,1.165A.87.87,0,0,1,67.856,302.92Zm-3.584-5.444c-.055,0-.294.135-.544.556a3.326,3.326,0,0,0-.435,2,4.839,4.839,0,0,0,.975-1.439c.263-.619.2-.961.147-1.015-.146-.151-.142-.107-.143-.107Zm0,0" transform="translate(-56.986 -279.609)" fill="#4a4a4a"/>
        <path id="Caminho_53" data-name="Caminho 53" d="M84.287,80.145H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -73.785)" fill="#4a4a4a"/>
        <path id="Caminho_54" data-name="Caminho 54" d="M84.287,151.852H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -141.696)" fill="#4a4a4a"/>
        <path id="Caminho_55" data-name="Caminho 55" d="M78.516,223.543H71.938a.867.867,0,1,1,0-1.735h6.579a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -209.591)" fill="#4a4a4a"/>
        <path id="Caminho_56" data-name="Caminho 56" d="M248.739,302.848a.866.866,0,0,1-.614-.254l-.982-.982a.867.867,0,1,1,1.227-1.227l.982.982a.867.867,0,0,1-.613,1.481Zm0,0" transform="translate(-233.817 -283.767)" fill="#4a4a4a"/>
      </g>
    </svg>
  );

  let history = useHistory();

  const [step, setstep] = useState(0);
  const [errorStep1, seterrorStep1] = useState(false);

  const [dataNascimento, setdataNascimento] = useState(null);

  const handleChangeDataNascimento = (data) => {
    setdataNascimento(data);
  };

  const validationSchema = yup.object({
    tipo: yup
      .number('Seleciona o tipo de pessoa')
      .required('Tipo Obrigatório'),
    nome: yup
      .string('Insira o nome da pessoa')
      .required('Nome Obrigatório'),
    email: yup
      .string('Insira seu E-mail')
      .email('Insira um E-mail válido')
      .required('E-mail Obrigatório')
      .test({
        message: () => "Já Existe um usuário usando o E-mail, tente outro E-mail.",
        test: async (values) => {
          if (values) { 
            try {

              let auth = localStorage.getItem("auth");
              auth = JSON.parse(auth);

              let response = await api.post(`verificaEmailExisteUsuario`, {
                  "email": values,
              },
              {
                headers: {
                  "Content-type": "application/json",
                  "Authorization": `Bearer ${auth.token}`
                }
              });

              if (response.data.emailExiste) {
                return false;
              }else{
                return true;
              }

            }catch (error) {
              // console.log(error);
            }
          }
        },
      }),
    senha: yup
      .string('Insira sua Senha')
      .min(8, 'A senha deve ter no mínimo 8 caracteres')
      .required('Senha Obrigatória'),
    confirmaSenha: yup
     .string()
     .required('Insira a Confirmação de senha')
     .oneOf([yup.ref('senha'), null], 'As senhas não são iguais'),
    rg: yup
      .string('Insira o RG'),
    cpf: yup
      .string('Insira o RG'),
    nascimento: yup
      .string('Insira a data de nascimento'),
    cnpj: yup
      .string('Insira o CNPJ'),
    representante: yup
      .string('Insira o nome do representante da empresa'),
    inscricaoestadual: yup
      .string('Insira a inscrição estadual da empresa'),
    inscricaomunicipal: yup
      .string('Insira a inscrição municipal da empresa'),
  });

  const formik = useFormik({
    initialValues: {
      tipo: '',
      nome: '',
      email: '',
      senha: '',
      rg: '',
      cpf: '',
      nascimento: '',
      cnpj: '',
      representante: '',
      inscricaoestadual: '',
      inscricaomunicipal: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      step < 1 && setstep(step+1)

      if(step === 1){ //TUDO CERTO PRA CADASTRAR
               
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`addUsuario`, {
            "tipo": values.tipo,
            "nome": values.nome,
            "email": values.email,
            "senha": values.senha,
            "cpf": values.cpf,
            "rg":  values.rg,
            "nascimento": dataNascimento,
            "cnpj": values.cnpj,
            "inscricaoestadual": values.inscricaoestadual,
            "inscricaomunicipal": values.inscricaomunicipal,
            "representante": values.representante
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        history.push(`/cadastro/usuario/${response.data.codigo}`);

      }      
    }
  });


  useEffect(()=>{
    let erros = JSON.stringify(formik.errors);
    erros = erros.length - 2;

    if(erros === 0){
      seterrorStep1(false);
    }else{
      seterrorStep1(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.errors]);



  // let itensSubMenu = [
  //   { active: "active", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
  //   { active: "", title: "Veículos", rota: "/cadastros/veiculos", icon: <IconCarro/> },
  //   { active: "", title: "Contratos", rota: "/cadastros/contratos", icon: <IconContrato/> },
  // ];



  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcom - Novo Usuário</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>
          {/* <Submenu itens={ itensSubMenu } /> */}
          
          <div className='box-steps'>
            <Stepper activeStep={step} alternativeLabel>
                <Step>
                  <StepLabel error={errorStep1}>Pessoa</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Detalhes</StepLabel>
                </Step>
                <Step>
                  <StepLabel error={false}>Finalizar</StepLabel>
                </Step>
            </Stepper>
          </div>

          <div className='container'>
            
            <div className='box-form-cadastro-usuarios'>

                <form method='post' onSubmit={formik.handleSubmit}>
                  <h2>Cadastro de usuário</h2>

                  {
                    step === 0 &&

                    <>
                      <FormControl sx={{ m: 1, width: '100%' }} >
                        <InputLabel id="label-tipo">Tipo</InputLabel>
                        <Select
                          className='input'
                          labelId="label-tipo"
                          id="demo-simple-select"
                          name="tipo"
                          label="Tipo"
                          placeholder='Tipo'
                          value={formik.values.tipo}
                          onChange={formik.handleChange}
                          error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                        >
                          <MenuItem value={1}>Física</MenuItem>
                          <MenuItem value={2}>Jurídica</MenuItem>
                        </Select>
                        <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                      </FormControl>

                      {
                        formik.values.tipo &&

                        <Grid container spacing={1} rowSpacing={-1} >
                          <Grid item xs={12} md={12}>  
                            <TextField
                              className='input'
                              fullWidth
                              id="nome"
                              name="nome"
                              label="nome"
                              margin="normal"
                              type="text"
                              value={formik.values.nome}
                              onChange={formik.handleChange}
                              error={formik.touched.nome && Boolean(formik.errors.nome)}
                              helperText={formik.touched.nome && formik.errors.nome}
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>  
                            <TextField
                              className='input'
                              fullWidth
                              id="email"
                              name="email"
                              label="E-mail"
                              margin="normal"
                              type="text"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                              helperText={formik.touched.email && formik.errors.email}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>  
                            <TextField
                              className='input'
                              fullWidth
                              id="senha"
                              name="senha"
                              label="Senha"
                              margin="normal"
                              type="password"
                              value={formik.values.senha}
                              onChange={formik.handleChange}
                              error={formik.touched.senha && Boolean(formik.errors.senha)}
                              helperText={formik.touched.senha && formik.errors.senha}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>  
                            <TextField
                              className='input'
                              fullWidth
                              id="confirmaSenha"
                              name="confirmaSenha"
                              label="Confirmar senha"
                              margin="normal"
                              type="password"
                              value={formik.values.confirmaSenha}
                              onChange={formik.handleChange}
                              error={formik.touched.confirmaSenha && Boolean(formik.errors.confirmaSenha)}
                              helperText={formik.touched.confirmaSenha && formik.errors.confirmaSenha}
                            />
                          </Grid>
                        </Grid>



                      }

                    </>

                  }


                  {
                    step === 1 &&

                    <>
                      {
                        formik.values.tipo === 1 ? 

                        <>
                          <TextField
                            className='input'
                            fullWidth
                            id="rg"
                            name="rg"
                            label="RG"
                            margin="normal"
                            type="text"
                            value={formik.values.rg}
                            onChange={formik.handleChange}
                            error={formik.touched.rg && Boolean(formik.errors.rg)}
                            helperText={formik.touched.rg && formik.errors.rg}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="cpf"
                            name="cpf"
                            label="CPF"
                            margin="normal"
                            type="text"
                            value={formik.values.cpf}
                            onChange={formik.handleChange}
                            error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                            helperText={formik.touched.cpf && formik.errors.cpf}
                            InputProps={{
                              inputComponent: CpfMask,
                            }}
                          />

                          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                            <DesktopDatePicker
                              label="Data de Nascimento"
                              inputFormat="dd/MM/yyyy"
                              name="nascimento"
                              value={dataNascimento}
                              onChange={handleChangeDataNascimento}
                              renderInput={(params) => <TextField className='input' style={{ width: '100%', marginTop: '15px'}} {...params} />}
                            />
                          </LocalizationProvider>

                          {/* <TextField
                            fullWidth
                            id="nascimento"
                            name="nascimento"
                            label="Data de Nascimento"
                            margin="normal"
                            type="text"
                            value={formik.values.nascimento}
                            onChange={formik.handleChange}
                            error={formik.touched.nascimento && Boolean(formik.errors.nascimento)}
                            helperText={formik.touched.nascimento && formik.errors.nascimento}
                            InputProps={{
                              inputComponent: DataMask,
                            }}
                          /> */}
                        
                         
                        </>

                        : formik.values.tipo === 2 &&

                        <>
                          <TextField
                            className='input'
                            fullWidth
                            id="cnpj"
                            name="cnpj"
                            label="CNPJ"
                            margin="normal"
                            type="text"
                            value={formik.values.cnpj}
                            onChange={formik.handleChange}
                            error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                            helperText={formik.touched.cnpj && formik.errors.cnpj}
                            InputProps={{
                              inputComponent: CnpjMask,
                            }}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="representante"
                            name="representante"
                            label="Representante"
                            margin="normal"
                            type="text"
                            value={formik.values.representante}
                            onChange={formik.handleChange}
                            error={formik.touched.representante && Boolean(formik.errors.representante)}
                            helperText={formik.touched.representante && formik.errors.representante}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="inscricaoestadual"
                            name="inscricaoestadual"
                            label="Inscrição estadual"
                            margin="normal"
                            type="text"
                            value={formik.values.inscricaoestadual}
                            onChange={formik.handleChange}
                            error={formik.touched.inscricaoestadual && Boolean(formik.errors.inscricaoestadual)}
                            helperText={formik.touched.inscricaoestadual && formik.errors.inscricaoestadual}
                          />

                          <TextField
                            className='input'
                            fullWidth
                            id="inscricaomunicipal"
                            name="inscricaomunicipal"
                            label="Inscrição municipal"
                            margin="normal"
                            type="text"
                            value={formik.values.inscricaomunicipal}
                            onChange={formik.handleChange}
                            error={formik.touched.inscricaomunicipal && Boolean(formik.errors.inscricaomunicipal)}
                            helperText={formik.touched.inscricaomunicipal && formik.errors.inscricaomunicipal}
                          />
                        </>

                      }
                    </>

                  }

                  <div className='box-buttons-steps'>
                    {
                      step > 0 &&

                      <div className='box-tbn-steps'>
                        <ButtonOutlineSecondary type="button" icon="" txt="Voltar" onClick={ ()=>{  step > 0 && setstep(step-1)  } } />
                      </div>
                    }

                    <div className='box-tbn-steps'>
                      <ButtonDefault type="submit" icon="" txt={  step < 1 ? 'Continuar' : 'Finalizar'} onClick={ ()=>{  } } />
                    </div>
                  </div>
                </form>

            </div>

          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}