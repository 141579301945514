import React, {useState, useEffect} from 'react';
import './style.css';
import { useHistory, Link, useLocation } from "react-router-dom";
import api from "../../services/api";

import Navbar from '../Navbar';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MailIcon from '@mui/icons-material/Mail';

export default function Header() {

  const location = useLocation();

  let Logo = props =>(
    <svg id="Camada_x0020_1" xmlns="http://www.w3.org/2000/svg" width="158" viewBox="0 0 340.408 76.549">
      <g id="_2401349046560" transform="translate(0 0)">
        <path id="Caminho_5" data-name="Caminho 5" d="M8.67,389.08,0,438.253H14.006l4.426-25.1,24.677,25.1h6.427l8.67-49.172H44.2l-4.487,25.4-24.435-25.4H8.67Zm52.688,0-8.67,49.172H93.614l1.94-11.1H68.695l1.576-8.974H90.583l1.94-11.156H72.211l1.213-6.79H99.557l2-11.156h-40.2Z" transform="translate(0 -377.675)"/>
        <path id="Caminho_6" data-name="Caminho 6" d="M3264.08,1190.667c3.881-8.153,4.043-15.735.194-23.038l-26.644,26.352h23.136Z" transform="translate(-3142.728 -1133.404)" fill="#005440" fillRule="evenodd"/>
        <path id="Caminho_7" data-name="Caminho 7" d="M435.422,34.992c3.848,7.3,3.73,14.848-.151,23l17.4-17.4c4.024-4.022,3.819-7.749,0-11.568C418.3-5.338,369.085-1.107,324.15,2.089,377.795,3.884,401,4.2,435.422,34.992Z" transform="translate(-314.649 0.001)" fill="#02a680" fillRule="evenodd"/>
        <path id="Caminho_8" data-name="Caminho 8" d="M4585.587,344.911,4608.7,321.79h-20.39l-12.2,12.2C4581.2,339.086,4584.368,342.226,4585.587,344.911Z" transform="translate(-4441.975 -312.358)" fill="#02a680" fillRule="evenodd"/>
        <path id="Caminho_9" data-name="Caminho 9" d="M5429.793,368.607l2.2-12.351a31.178,31.178,0,0,1-17.339,5.225c-10.985,0-17.992-6.056-16.687-13.479,1.308-7.482,10.451-13.42,21.437-13.42a21.374,21.374,0,0,1,15.26,5.76l2.256-12.944c-3.859-3.148-9.737-4.929-16.565-4.929-16.864,0-32.659,11.4-35.152,25.533s9.263,25.592,26.126,25.592a34.507,34.507,0,0,0,18.467-4.988Zm40.613-46.137c-16.922,0-32.716,11.4-35.21,25.533s9.263,25.592,26.186,25.592,32.658-11.46,35.151-25.592S5487.33,322.47,5470.406,322.47ZM5447.962,348c1.307-7.482,10.392-13.42,20.308-13.42s16.8,5.938,15.5,13.42c-1.306,7.423-10.331,13.479-20.247,13.479S5446.655,355.425,5447.962,348Zm55.578-24.048-8.491,48.155h13.716l3.92-22.385,14.31,15.438h2.375l19.833-15.438-3.919,22.385h13.656l8.492-48.155h-5.107L5531.21,348.18l-22.622-24.226Z" transform="translate(-5227.023 -313.018)"/>
        <path id="Caminho_10" data-name="Caminho 10" d="M4576.109,1288.913c21.534,21.692,63.3,27.886,102.646,28.169,32.892.236,79.605-7.655,95.891-10.08-31.546,2.275-73.234,6.1-94.871,4.359-63.969-5.135-88.253-23.917-94.208-33.362C4584.338,1280.677,4581.193,1283.831,4576.109,1288.913Z" transform="translate(-4441.974 -1240.539)" fill="#02a680" fillRule="evenodd"/>
      </g>
    </svg>
  );

  const [activeMenu, setactiveMenu] = useState("");
  const [dadosUser, setdadosUser] = useState([]);
  const [dadosEmpresa, setdadosEmpresa] = useState([]);
  let history = useHistory();

  const refreshToken = async()=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);
    
    if(!auth){
      history.push("/login");
    }

    if(auth){
      let refreshToken = await api.put('refreshToken', {}, {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.refreshToken}`
        }
      });

      if(refreshToken.data.error_code === 'expirad_refresh_token'){
        localStorage.removeItem("auth");
        history.push("/login");
      }else{
        localStorage.setItem("auth",JSON.stringify(refreshToken.data));
        // window.location.href = `/resumo`;
      }
    }
  
  }

  const logout = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.post('logout', {}, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    localStorage.removeItem("auth");
    history.push("/login");
    // window.location.reload();
  }

  const getdataUser = async()=>{
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    if(auth){
      let response = await api.post('detalhesUsuarioLogado', {}, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      setdadosUser(response.data);
    }
  }

  const getDadosEmpresa = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('dadosEmpresa', {}, 
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });
    
    setdadosEmpresa(response.data);
  }
  
  useEffect(async()=>{ 
    await getdataUser();
    await refreshToken();
    await getDadosEmpresa();

    // setInterval(function() { refreshToken(); }, 5000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  useEffect(()=>{ 
    let rotaPrincipal = location.pathname.split('/');
    setactiveMenu(rotaPrincipal[1]);
  },[location]);
  

  let activeResumo, activeCadastros, activeEstoque, activeVendas, activeOs, activeFinancas = '';

  switch (activeMenu) {
    case 'resumo':
        activeResumo = 'active';
        break;
    case 'cadastros':
        activeCadastros = 'active';
        break;
    case 'cadastro':
        activeCadastros = 'active';
        break;
    case 'estoque':
        activeEstoque = 'active';
        break;
    case 'vendas':
        activeVendas = 'active';
      break;
    case 'os':
        activeOs = 'active';
        break;
    case 'financas':
        activeFinancas = 'active';
        break;
    default:
        
        break;
}

  // INFO USER MENU
  const [anchorEluser, setAnchorEluser] = useState(null);
  const openuser = Boolean(anchorEluser);
  const handleClickuser = (event) => {
    setAnchorEluser(event.currentTarget);
  };
  const handleCloseuser = () => {
    setAnchorEluser(null);
  };

  // MENU
  const [anchorElmenu, setAnchorElmenu] = useState(null);
  const openmenu = Boolean(anchorElmenu);
  const handleClickmenu = (event) => {
    setAnchorElmenu(event.currentTarget);
  };
  const handleClosemenu = () => {
    setAnchorElmenu(null);
  };


  return (
    <div className='header'>
      <div className='container'>
          
          <div className='content-left'>
            <div className='box-logo'>
              <Logo/>
            </div>

            <ul className='menu'>
              {
                dadosEmpresa?.ramoAtividadeId === 3 ? (
                  <>
                    <li className={activeResumo}><Link to="/resumo">Resumo</Link></li>
                    <li className={activeCadastros}><Link to="/cadastros/imoveis">Cadastros</Link></li>
                    <li className={activeFinancas}><Link to="/financas/fluxoCaixa">Finanças</Link></li>
                  </>
                ) : (
                  (dadosEmpresa?.ramoAtividadeId === 1 || dadosEmpresa?.ramoAtividadeId === 2) && 
                  <>
                    <li className={activeResumo}><Link to="/resumo">Resumo</Link></li>
                    <li className={activeCadastros}><Link to="/cadastros/pessoas">Cadastros</Link></li>
                    <li className={activeEstoque}><Link to="/estoque">Estoque</Link></li>
                    <li className={activeVendas}><Link to="/vendas">Vendas</Link></li>
                    <li className={activeOs}><Link to="/os">Ordem de serviços</Link></li>
                    <li className={activeFinancas}><Link to="/financas/fluxoCaixa">Finanças</Link></li>
                  </>
                )
              }
            </ul>
          </div>

          <div className='box-user-info'>

            <div className='menu-mobile'>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openmenu ? 'long-menu' : undefined}
                aria-expanded={openmenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClickmenu}
              >
                <MoreVertIcon />
              </IconButton>

              {
                  dadosEmpresa?.ramoAtividadeId === 3 ? //For Igual a Imobiliaria

                  <Menu
                    anchorEl={anchorElmenu}
                    id="geral-menu"
                    open={openmenu}
                    onClose={handleClosemenu}
                    onClick={handleClosemenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                      
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/resumo"); }}>Resumo</MenuItem>
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/cadastros/imoveis"); }}>Cadastros</MenuItem>
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/financas"); }}>Finanças</MenuItem>

                  </Menu>

                  : 

                  <Menu
                    anchorEl={anchorElmenu}
                    id="geral-menu"
                    open={openmenu}
                    onClose={handleClosemenu}
                    onClick={handleClosemenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/resumo"); }}>Resumo</MenuItem>
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/cadastros/pessoas"); }}>Cadastros</MenuItem>
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/estoque"); }}>Estoque</MenuItem>
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/vendas"); }}>Vendas</MenuItem>
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/os"); }}>Ordem de serviços</MenuItem>
                    <MenuItem onClick={()=>{ handleClosemenu(); history.push("/financas"); }}>Finanças</MenuItem>

                  </Menu>

              } 
              
            </div>

            <div className='box-user' onClick={handleClickuser}>
              <div className='box-avatar' >
                <Avatar src={dadosUser.foto} sx={{bgcolor: '#2B2B2B', width: 50, height: 50 }} />
              </div>

              <div className='user-info'>
                  <h3>{dadosUser.nome}</h3>
                  <p>{dadosUser.email}</p>
                  <b>{dadosUser.empresa}</b>
              </div>
            </div>
          </div>

          <Menu
              anchorEl={anchorEluser}
              id="account-menu"
              open={openuser}
              onClose={handleCloseuser}
              onClick={handleCloseuser}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {/* <MenuItem>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                Minha conta
              </MenuItem> */}
              <Divider />
              <MenuItem onClick={()=>{ history.push(`/email/inbox`); }}>
                <ListItemIcon>
                  <MailIcon fontSize="small" />
                </ListItemIcon>
                E-mail
              </MenuItem>
              <MenuItem onClick={()=>{ history.push('/empresa/config') }}>
                <ListItemIcon>
                  <CorporateFareIcon fontSize="small" />
                </ListItemIcon>
                Empresa
              </MenuItem>
              {/* <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Configurações
              </MenuItem> */}
              <MenuItem onClick={()=>{logout()}}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Sair
              </MenuItem>
          </Menu>

      </div>
    </div>
  );
}